import React from 'react';
import { useIsB2B } from 'components/B2BComponents/useIsB2B';

interface EstimateComponentProps {
  b2b?: React.ReactNode;
  b2c?: React.ReactNode;
}

const B2BComponent = ({ b2b, b2c }: EstimateComponentProps) => {
  const isB2B = useIsB2B();
  return isB2B ? b2b : b2c;
};

export default B2BComponent;
