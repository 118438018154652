/* eslint-disable max-len */
import { CROSS_SELLING_GET_REFERENCES_URL } from '@1po/1po-bff-fe-spec/generated/backoffice/BackofficeURLs';
import { GetCrossSellingReferences } from '@1po/1po-bff-fe-spec/generated/backoffice/cross_selling/request/GetCrossSellingReferences';
import { UserContext } from '@1po/1po-bff-fe-spec/generated/basket/request/AddReferences';
import { GetEquivalentReferencesRequest } from '@1po/1po-bff-fe-spec/generated/catalog/references/dh/request/GetEquivalentReferencesRequest';
import { GetPlatesForReferenceRequest } from '@1po/1po-bff-fe-spec/generated/catalog/references/dh/request/GetPlatesForReferenceRequest';
import { GetReuseStockRequest } from '@1po/1po-bff-fe-spec/generated/catalog/references/dh/request/GetReuseStockRequest';
import { CheckIfStocksAreStillUpToDateRequest } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/request/CheckIfStocksAreStillUpToDateRequest';
import {
  GetReferencesStockRequest,
  ReferenceStockTradingDataRequestDetail,
} from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/request/GetReferencesStockRequest';
import {
  GetReferenceTradingDataRequest,
  ReferenceTradingDataRequestDetail,
} from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/request/GetReferenceTradingDataRequest';
import {
  CHECK_IF_STOCKS_ARE_UP_TO_DATE_URL,
  GET_EQUIVALENT_REFERENCES_URL,
  GET_PLATES_FOR_REFERENCE,
  GET_REFERENCES_DISCOUNTS_URL,
  GET_REFERENCES_PRICE_URL,
  GET_REFERENCES_REPAIR_PRICES_URL,
  GET_REFERENCES_STOCKS_URL,
  GET_REUSE_STOCK_URL,
} from '@1po/1po-bff-fe-spec/generated/common/WebsocketConstants';
import { WebSocketAction } from 'utils';
import { wsSendAction } from 'utils/domainStore/api';

export function sendGetPlatesForReference(request: GetPlatesForReferenceRequest): WebSocketAction {
  return wsSendAction(GET_PLATES_FOR_REFERENCE, request);
}

export function sendGetReferencesPriceRequest(references: ReferenceTradingDataRequestDetail[]): WebSocketAction {
  const request: GetReferenceTradingDataRequest = {
    references,
  };
  return wsSendAction(GET_REFERENCES_PRICE_URL, request);
}

export function sendGetReferencesDetailsRequest(references: ReferenceTradingDataRequestDetail[]): WebSocketAction {
  const request: GetReferenceTradingDataRequest = {
    references,
  };
  return wsSendAction(GET_REFERENCES_DISCOUNTS_URL, request);
}

export function sendGetReferencesRepairPricesRequest(references: ReferenceTradingDataRequestDetail[]): WebSocketAction {
  const request: GetReferenceTradingDataRequest = {
    references,
  };
  return wsSendAction(GET_REFERENCES_REPAIR_PRICES_URL, request);
}

export function sendGetCrossSellingReferences(request: GetCrossSellingReferences): WebSocketAction {
  return wsSendAction(CROSS_SELLING_GET_REFERENCES_URL, request);
}

export function sendGetReferencesStocksRequest(
  references: ReferenceStockTradingDataRequestDetail[],
  userContext: UserContext,
): WebSocketAction {
  const request: GetReferencesStockRequest = {
    references,
    userContext,
  };
  return wsSendAction(GET_REFERENCES_STOCKS_URL, request);
}

export function sendGetReuseStocks(referenceNumbers: string[], nodeId: string | undefined): WebSocketAction {
  const request: GetReuseStockRequest = {
    references: referenceNumbers,
    nodeId,
  };
  return wsSendAction(GET_REUSE_STOCK_URL, request);
}

export function sendGetEquivalents(request: GetEquivalentReferencesRequest): WebSocketAction {
  return wsSendAction(GET_EQUIVALENT_REFERENCES_URL, request);
}

export function sendCheckIfStocksAreStillUpToDateRequest(
  request: CheckIfStocksAreStillUpToDateRequest,
): WebSocketAction {
  return wsSendAction(CHECK_IF_STOCKS_ARE_UP_TO_DATE_URL, request);
}
