import React, { ReactNode } from 'react';
import { Tooltip } from 'antd-v5';

interface TooltipProps {
  title: React.ReactNode;
  placement?: 'top' | 'bottom' | 'left' | 'right' | 'topLeft' | 'bottomLeft' | 'topRight' | 'bottomRight';
  children: ReactNode;
  open?: boolean;
}

export const WithTooltip = ({ title, children, placement, open }: TooltipProps): ReactNode => {
  return (
    <Tooltip title={title} placement={placement} open={open}>
      {children}
    </Tooltip>
  );
};
