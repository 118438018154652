import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { LaborTimeDetails } from '@1po/1po-bff-fe-spec/generated/catalog/labor_time/model/LaborTime';
import { LaborTimeField } from '@1po/1po-bff-fe-spec/generated/estimate/request/UpdateLaborTime';
import { LaborTime, Technicity } from '@1po/1po-bff-fe-spec/generated/estimate/response/model/Estimate';
import { Table } from 'antd';
import { isEmpty } from 'fast-glob/out/utils/string';
import { useTheme } from 'styled-components';
import { RootState } from 'app/AppStore';
import { ChevronDownIcon, ChevronUpIcon, DotCircleIcon } from 'assets/icons';
import { getTechnicityTier } from 'domains/catalog/Catalog.types';
import { getLaborTimes, removeLaborTime, updateLaborTime } from 'domains/estimate/Estimate.store';
import { getCurrency } from 'domains/user';
import {
  DiscountInput,
  EstimatePriceInput,
  GetTitle,
  HoursInput,
  QuantityInput,
  TextInput,
  VatInput,
} from 'pages/EstimatePage/TableSection/tableComponents';
import { TableProps } from 'pages/EstimatePage/TableSection/TableSection';
import { ThemeColorKeyType, ThemeDisplayStyleKeyType, ThemeFontKeyType } from 'styles';
import { Flex, Icon, MarginBox, Pipeline, Text } from 'UI';
import { textFormatter } from 'utils/format';
import { ExpandedLaborTimeDotCircleWrapper, ExpandedLaborTimeWrapper, STable } from './TableSection.styled';

function LineInDetails({ title, text }: { title?: React.ReactElement; text?: string }) {
  if (text === undefined || isEmpty(text)) return null;
  return (
    <Text type={'light_12_medium_black_85'}>
      <Flex>
        {title}
        {title && <MarginBox ml={5} />}
        {text}
      </Flex>
    </Text>
  );
}

export default function LaborTimeTable({ estimateId }: TableProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const data = useSelector((state: RootState) => getLaborTimes(state, estimateId));

  const currency = useSelector(getCurrency);

  const handleUpdate = (itemId: string, field: LaborTimeField) => (newValue: string) => {
    dispatch(updateLaborTime({ itemId, field, newValue }));
  };

  function removeRow(rowId: string) {
    dispatch(removeLaborTime(rowId));
  }

  const tableColumns = [
    {
      title: GetTitle(t('common.code', 'Code')),
      dataIndex: 'code',
      render: function rowSelector(code: string, row: LaborTime) {
        return (
          <TextInput
            value={code}
            saveFunction={handleUpdate(row.itemId, 'CODE')}
            removeRow={() => removeRow(row.itemId)}
            readOnly={!row.isCustom}
            minWidth={90}
          />
        );
      },
    },
    {
      title: GetTitle(t('common.designation', 'Designation')),
      dataIndex: 'designation',
      width: '25vw',
      render: function rowSelector(designation: string, row: LaborTime) {
        return (
          <TextInput value={designation} saveFunction={handleUpdate(row.itemId, 'NAME')} readOnly={!row.isCustom} />
        );
      },
    },
    {
      title: GetTitle(t('common.details', 'Details')),
      dataIndex: 'details',
      width: '15vw',
      render: (details?: LaborTimeDetails) => (
        <>
          <Flex direction={'column'}>
            {details && (
              <>
                <LineInDetails text={details.precision} />
                <LineInDetails text={details.additionalLabel} />
                <LineInDetails
                  title={<Trans i18nKey="estimate.labor_time.details.quantity">Quantity:</Trans>}
                  text={details.quantity}
                />
                <LineInDetails
                  title={<Trans i18nKey="estimate.labor_time.details.number_of_layers">Number of layers:</Trans>}
                  text={details.paintType}
                />
                <LineInDetails
                  title={<Trans i18nKey="estimate.labor_time.details.paint_class">Paint class:</Trans>}
                  text={details.paintClass}
                />
              </>
            )}
          </Flex>
        </>
      ),
    },
    {
      title: GetTitle(t('common.technicity', 'Technicity')),
      dataIndex: 'technicity',
      width: '10vw',
      render: function rowSelector(technicity: Technicity) {
        return (
          <Flex justify={'center'}>
            <Text type={'lead_dim'}>{getTechnicityTier(technicity)}</Text>
          </Flex>
        );
      },
    },
    {
      title: GetTitle(t('common.rate', 'Rate')),
      dataIndex: 'pricePerHour',
      render: function rowSelector(pricePerHour: string, row: LaborTime) {
        return (
          <EstimatePriceInput
            value={pricePerHour}
            saveFunction={handleUpdate(row.itemId, 'HOURLY_RATE')}
            minWidth={125}
          />
        );
      },
    },
    {
      title: GetTitle(t('common.time', 'Time'), 'time'),
      dataIndex: 'numberOfHours',
      render: function rowSelector(numberOfHours: number, row: LaborTime) {
        return <HoursInput value={numberOfHours} saveFunction={handleUpdate(row.itemId, 'DURATION')} />;
      },
    },
    {
      title: GetTitle(t('common.quantity', 'Quantity')),
      dataIndex: 'quantity',
      render: function rowSelector(quantity: number, row: LaborTime) {
        return (
          <QuantityInput
            value={quantity}
            saveFunction={handleUpdate(row.itemId, 'QUANTITY')}
            removeRow={() => removeRow(row.itemId)}
          />
        );
      },
    },
    {
      title: GetTitle(t('common.discount', 'Discount')),
      dataIndex: 'discount',
      render: function rowSelector(discount: string, row: LaborTime) {
        return (
          <DiscountInput
            value={discount}
            saveFunction={handleUpdate(row.itemId, 'DISCOUNT')}
            discountType={row.discountType}
            saveDiscountType={handleUpdate(row.itemId, 'DISCOUNT_TYPE')}
          />
        );
      },
    },
    {
      title: GetTitle(t('common.price.vat_excl_price', 'VAT excl.Price')),
      dataIndex: 'priceVatExcluded',
      render: function rowSelector(vatExclPrice: string) {
        return (
          <Flex justify={'center'} align={'center'}>
            <Text type={'light_14'}>
              {textFormatter.formatCurrency(isNaN(Number(vatExclPrice)) ? 0 : vatExclPrice, currency)}
            </Text>
          </Flex>
        );
      },
    },
    {
      title: GetTitle(t('common.price.vat.title', 'VAT')),
      dataIndex: 'vatPercentage',
      width: 130,
      render: function rowSelector(vat: number, row: LaborTime) {
        return <VatInput value={vat} saveFunction={handleUpdate(row.itemId, 'VAT_PERCENTAGE')} />;
      },
    },
    Table.EXPAND_COLUMN,
  ];

  if (data.length === 0) {
    return null;
  }
  return (
    <STable<LaborTime>
      columns={tableColumns}
      dataSource={data}
      pagination={false}
      rowKey={(row) => row.itemId}
      size={'large'}
      expandable={{
        expandedRowRender: ExpandedRowRender,
        expandIcon: ExpandIcon,
        defaultExpandAllRows: true,
        rowExpandable: (record) => record.includedOperations.length > 0 || record.notIncludedOperations.length > 0,
      }}
    />
  );
}

function ExpandedRowRender(record: LaborTime) {
  if (record.includedOperations.length === 0 && record.notIncludedOperations.length === 0) {
    return null;
  }
  return (
    <ExpandedLaborTimeWrapper>
      <IncludedSection
        title={<Trans i18nKey={'common.included'}>Included</Trans>}
        color={'BLUE'}
        items={record.includedOperations}
      />
      <IncludedSection
        title={<Trans i18nKey={'common.not_included'}>Not included</Trans>}
        color={'RED'}
        items={record.notIncludedOperations}
      />
    </ExpandedLaborTimeWrapper>
  );
}

type colorType = 'RED' | 'BLUE';

function colorSwitch(
  color: colorType,
): { text: ThemeFontKeyType; circle: ThemeColorKeyType; displayStyle: ThemeDisplayStyleKeyType } {
  return color === 'RED'
    ? {
        text: 'light_14',
        circle: 'error',
        displayStyle: 'error',
      }
    : {
        text: 'light_14',
        circle: 'clear_blue',
        displayStyle: 'link',
      };
}

function IncludedSection({ title, color, items }: { title: React.ReactElement; color: colorType; items: string[] }) {
  const theme = useTheme();
  const colors = colorSwitch(color);
  if (items.length === 0) {
    return null;
  }
  return (
    <>
      <Pipeline size={0} offsetRatio={100} color={theme.color.grey85} />
      <ExpandedLaborTimeDotCircleWrapper>
        <Icon IconComponent={DotCircleIcon} size={8} color={theme.color[colors.circle]} noPointer />
      </ExpandedLaborTimeDotCircleWrapper>
      <MarginBox ml={15} />
      <Flex direction={'column'}>
        <Text type={colors.text}>{title}</Text>
        {items.map((i, key) => (
          <Text key={key} type={'light_14_black_65'}>
            {i}
          </Text>
        ))}
      </Flex>
    </>
  );
}

function ExpandIcon({ expanded, record, onExpand }: { expanded: boolean; record: LaborTime; onExpand: any }) {
  if (record.includedOperations.length === 0 && record.notIncludedOperations.length === 0) {
    return null;
  }
  return (
    <Icon
      IconComponent={expanded ? ChevronDownIcon : ChevronUpIcon}
      onClick={(e) => onExpand(record, e)}
      height={15}
      width={15}
    />
  );
}
