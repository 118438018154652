import React from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { LinkedReference, Reference } from '@1po/1po-bff-fe-spec/generated/estimate/response/model/Estimate';
import { v4 } from 'uuid';
import { RootState } from 'app/AppStore';
import { useIsB2B } from 'components/B2BComponents/useIsB2B';
import {
  getEstimateClient,
  getEstimateCreatedBy,
  getEstimateFreeBundlesSectionPriceVatExcl,
  getEstimateFreeBundlesSectionVat,
  getEstimateLaborTimeSectionPriceVatExcl,
  getEstimateLaborTimeSectionVat,
  getEstimateObservations,
  getEstimateOtherItemSectionPriceVatExcl,
  getEstimateOtherItemSectionVat,
  getEstimateReferenceSectionPriceVatExcl,
  getEstimateReferenceSectionVat,
  getEstimateSequenceNumber,
  getEstimateSettings,
  getEstimateTireSectionPriceVatExcl,
  getEstimateTireSectionVat,
  getEstimateTotalPrice,
  getEstimateVehicle,
  getEstimateWasteRecyclingSectionPriceVatExcl,
  getEstimateWasteRecyclingSectionVat,
  getFreeBundlesWithValidPrices,
  getLaborTimesWithValidPrices,
  getOtherItemsWithValidPrices,
  getReferencesWithValidPrices,
  getTiresWithValidPrices,
  getValidRowTablesCount,
  getWasteRecyclingWithValidPrices,
} from 'domains/estimate/Estimate.store';
import { getUserGarageInfo } from 'domains/garage/Garage.store';
import { getPictures } from 'domains/pictures/Pictures.store';
import { getCurrency, getUserCommercialLink } from 'domains/user';
import { EstimatePdfData, TireReference } from 'pages/EstimatePdf';
import { getData } from 'utils';
import { useFetchEprelBase64 } from './useFetchEprelBase64';

export function useEstimatePdfData(estimateId: string): EstimatePdfData {
  const currency = useSelector(getCurrency);
  const commercialLink = useSelector(getUserCommercialLink);
  const isB2B = useIsB2B();
  const createdBy = useSelector((state: RootState) => getEstimateCreatedBy(state, estimateId));

  const referenceData = useSelector((state: RootState) => getReferencesWithValidPrices(state, estimateId));
  const getGarageName = () => (isB2B ? commercialLink?.name : commercialLink?.seller?.name);
  const r1Dealer = {
    garageName: getGarageName() ?? '',
    createdBy: createdBy?.creatorName ?? '',
    tel: '',
    email: '',
  };

  const referencesWithLinked = referenceData.reduce((refs: (Reference | LinkedReference)[], ref) => {
    if (ref.linkedReferences) {
      return [...refs, ref, ...ref.linkedReferences];
    }
    return [...refs, ref];
  }, []);
  const laborTimeData = useSelector((state: RootState) => getLaborTimesWithValidPrices(state, estimateId));
  const tireData = useSelector((state: RootState) => getTiresWithValidPrices(state, estimateId));
  const wasteRecycling = useSelector((state: RootState) => getWasteRecyclingWithValidPrices(state, estimateId));
  const freeBundles = useSelector((state: RootState) => getFreeBundlesWithValidPrices(state, estimateId));
  const otherData = useSelector((state: RootState) => getOtherItemsWithValidPrices(state, estimateId));

  const getTiresEprelUrls = () => tireData.map((t) => t.eprelUrl);
  useFetchEprelBase64(getTiresEprelUrls());
  const eprelsLocal = useSelector((state: RootState) => getPictures(state, getTiresEprelUrls()));

  const numberOfItems = useSelector((state: RootState) => getValidRowTablesCount(state, estimateId));
  const priceDetail = useSelector((state: RootState) => getEstimateTotalPrice(state, estimateId));

  const vehicleDetail = useSelector((state: RootState) => getEstimateVehicle(state, estimateId));
  const client = useSelector((state: RootState) => getEstimateClient(state, estimateId));

  const garageInfo = useSelector(getUserGarageInfo);
  const garageInfoData = getData(garageInfo);

  const referencesPriceVatExcl = useSelector((state: RootState) =>
    getEstimateReferenceSectionPriceVatExcl(state, estimateId),
  );
  const referencesVat = useSelector((state: RootState) => getEstimateReferenceSectionVat(state, estimateId));
  const freeBundlesPriceVatExcl = useSelector((state: RootState) =>
    getEstimateFreeBundlesSectionPriceVatExcl(state, estimateId),
  );
  const freeBundlesVat = useSelector((state: RootState) => getEstimateFreeBundlesSectionVat(state, estimateId));
  const laborTimePriceVatExcl = useSelector((state: RootState) =>
    getEstimateLaborTimeSectionPriceVatExcl(state, estimateId),
  );
  const laborTimeVat = useSelector((state: RootState) => getEstimateLaborTimeSectionVat(state, estimateId));
  const tirePriceVatExcl = useSelector((state: RootState) => getEstimateTireSectionPriceVatExcl(state, estimateId));
  const tireVat = useSelector((state: RootState) => getEstimateTireSectionVat(state, estimateId));
  const otherItemPriceVatExcl = useSelector((state: RootState) =>
    getEstimateOtherItemSectionPriceVatExcl(state, estimateId),
  );
  const otherItemVat = useSelector((state: RootState) => getEstimateOtherItemSectionVat(state, estimateId));
  const wasteRecyclingPriceVatExcl = useSelector((state: RootState) =>
    getEstimateWasteRecyclingSectionPriceVatExcl(state, estimateId),
  );
  const wasteRecyclingVat = useSelector((state: RootState) => getEstimateWasteRecyclingSectionVat(state, estimateId));
  const sequenceNumber = useSelector((state: RootState) => getEstimateSequenceNumber(state, estimateId));
  const estimateObservations = useSelector((state: RootState) => getEstimateObservations(state, estimateId));
  const settings = useSelector(getEstimateSettings);
  const settingsData = getData(settings);
  const intraCommunityVat = settingsData?.intraCommunityVat;
  const estimateValidFor = settingsData?.estimateValidFor;
  const validityUnit = settingsData?.validityUnit;

  const legalMention = settingsData?.legalMentions;
  const observation = sequenceNumber ? estimateObservations : settingsData?.observations;
  const text = {
    title: <Trans i18nKey="estimate.pdf.estimate">Estimate</Trans>,
    reference: <Trans i18nKey="estimate.pdf.reference">Reference</Trans>,
    eprel: <Trans i18nKey="catalog.tires.eprel">EPREL</Trans>,
    designation: <Trans i18nKey="estimate.pdf.designation">Designation</Trans>,
    unit_price: <Trans i18nKey="estimate.pdf.unit_price">Unit price</Trans>,
    quantity: <Trans i18nKey="estimate.pdf.quantity">Quantity</Trans>,
    discount: <Trans i18nKey="estimate.pdf.discount">Discount</Trans>,
    price_vat_excl: <Trans i18nKey="estimate.pdf.price_vat_excl">Price VAT Excl.</Trans>,
    vat: <Trans i18nKey="estimate.pdf.vat">VAT</Trans>,
  };

  const key = v4();

  const mapTires = (): TireReference[] => {
    return tireData.map((t) => {
      return {
        ...t,
        eprelBase64: eprelsLocal.get(t.eprelUrl),
      };
    });
  };

  return {
    reference: referencesWithLinked,
    laborTime: laborTimeData,
    tire: mapTires(),
    other: otherData,
    date: new Date(),
    dealer: garageInfoData,
    orderDetails: {
      reference: { priceVatExcl: referencesPriceVatExcl, vat: referencesVat },
      laborTime: { priceVatExcl: laborTimePriceVatExcl, vat: laborTimeVat },
      freeBundles: { priceVatExcl: freeBundlesPriceVatExcl, vat: freeBundlesVat },
      tire: { priceVatExcl: tirePriceVatExcl, vat: tireVat },
      other: { priceVatExcl: otherItemPriceVatExcl, vat: otherItemVat },
      wasteRecycling: { priceVatExcl: wasteRecyclingPriceVatExcl, vat: wasteRecyclingVat },
    },
    price: priceDetail,
    observation: observation ?? '',
    sequenceNumber,
    r1Dealer,
    freeBundles,
    wasteRecycling,
    vehicleDetail,
    currency,
    client,
    numberOfItems,
    intraCommunityVat,
    estimateValidFor,
    validityUnit,
    legalMention,
    key,
    text,
  };
}
