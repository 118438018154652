/* eslint-disable max-len */
import { ClientLogRequestMetaData } from '@1po/1po-bff-fe-spec/generated/client_log/request/ClientLogRequestMetaData';
import bowser from 'bowser';
import { v4 as uuidv4 } from 'uuid';
import { Language } from 'utils/i18n/Language';

export default function get(): ClientLogRequestMetaData {
  const browserInfo = bowser.getParser(window.navigator.userAgent);
  return {
    browserId: getBrowserId(),
    browser: browserInfo.getBrowser(),
    os: browserInfo.getOS(),
    platform: browserInfo.getPlatform(),
    engine: browserInfo.getEngine(),
  };
}

function getBrowserId(): string {
  const storageKey = '1po.browserId';
  const browserId = localStorage.getItem(storageKey);
  if (browserId !== undefined && browserId !== null) {
    return browserId;
  }
  const newBrowserId = uuidv4();
  localStorage.setItem(storageKey, newBrowserId);
  return newBrowserId;
}

export function logAndSetGTMLanguage(language: Language | undefined): void {
  if (language) {
    document.documentElement.setAttribute('lang', language.locale);
    if ('dataLayer' in window) {
      (window as any).dataLayer.push({ event: 'languageChange' });
    }
  }
}

export function pushProductFruitsUserEvent(ipn: string | undefined): void {
  if ('dataLayer' in window) {
    const userId = ipn ?? getBrowserId();
    const gtmDataLayer = (window as any).dataLayer; //NOSONAR
    gtmDataLayer.push({ event: 'productFruitsUserSet', productFruitsUser: userId });
  }
}
