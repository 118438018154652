/* eslint-disable max-len */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchFileDiscontinuedReferencesRequestSaga,
  getDiscontinuedReferencesFileFetchStatus,
} from 'domains/discontinuedReferences/DiscontinuedReferences.store';

export const useFetchDiscontinuedReferences = (): void => {
  const dispatch = useDispatch();
  const status = useSelector(getDiscontinuedReferencesFileFetchStatus);

  useEffect(() => {
    if (!status) {
      dispatch(fetchFileDiscontinuedReferencesRequestSaga());
    }
  }, [dispatch, status]);
};
