/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { OtherSection, Reference } from '@1po/1po-bff-fe-spec/generated/basket/response/model/OtherSection';
import { TFunction } from 'i18next';
import { clearActionStatuses } from 'domains/basket/Basket.store';
import {
  BasketActionStatus,
  STATUS_MISSING_PRICE,
  STATUS_NOT_IN_STOCK,
  STATUS_SUCCESS,
  STATUS_UNKNOWN_REFERENCE,
} from 'domains/basket/Basket.types';
import { NotificationLink, notifyTop } from 'UI/Notification/notification';

type CartAddReferenceResultProps = {
  otherSection: OtherSection | undefined;
  addedOtherReference?: string;
  otherReferencesAction: BasketActionStatus;
  setExpanded?: (expanded: boolean) => void;
  setHighlightedRef?: (referenceNumber: string | undefined) => void;
};

const getSuccessMessage = (t: TFunction, lastAddedRef: Reference) => {
  return (
    <>
      {lastAddedRef.quantity > 1
        ? t('cart.action.referenceUpdated', 'Your reference has been added and the quantity has been updated')
        : t('cart.action.referenceAdded', '1 Reference has been added to your cart.')}
    </>
  );
};

const CartAddReferenceResult = ({
  addedOtherReference,
  otherReferencesAction,
  otherSection,
  setExpanded,
  setHighlightedRef,
}: CartAddReferenceResultProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const referenceMap = otherSection?.references.reduce(
    (acc, next) => acc.set(next.referenceNumber, next),
    new Map<string, Reference>(),
  );
  const lastAddedRef = addedOtherReference && referenceMap ? referenceMap.get(addedOtherReference) : undefined;

  useEffect(() => {
    switch (otherReferencesAction.status) {
      case STATUS_UNKNOWN_REFERENCE:
        notifyTop(
          'error',
          t(
            'cart.action.add_reference.unknown_reference',
            'Your reference has not been added to your cart, please try again.',
          ),
        );
        dispatch(clearActionStatuses());
        break;
      case STATUS_SUCCESS:
        if (lastAddedRef) {
          notifyTop(
            'success',
            getSuccessMessage(t, lastAddedRef),
            undefined,
            lastAddedRef && !!setHighlightedRef && !!setExpanded && (
              <NotificationLink
                onClick={() => {
                  setExpanded(true);
                  setHighlightedRef(lastAddedRef.referenceNumber);
                }}
              >
                {t('cart.action.seeReference', 'See reference')}
              </NotificationLink>
            ),
          );
          dispatch(clearActionStatuses());
        }
        break;
      case STATUS_MISSING_PRICE:
        notifyTop(
          'error',
          t('cart.action.add_reference.missing_price', 'Price currently unavailable, please try again later.'),
        );
        dispatch(clearActionStatuses());
        break;
      case STATUS_NOT_IN_STOCK:
        notifyTop(
          'error',
          t('cart.action.addref_errormessage_stock', 'This product is not longer in stock and cannot be ordered.'),
        );
        dispatch(clearActionStatuses());
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherReferencesAction.status, lastAddedRef]);

  return <></>;
};

export default CartAddReferenceResult;
