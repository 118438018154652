import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ROUTER_CART } from 'app/AppRouter';
import { trackAppEvent } from 'app/AppTracker';
import { FirstHelpPopin } from 'components/Help/FirstHelpPopin';
import { ReferenceInput } from 'components/ReferenceInput/ReferenceInput';
import { addReferenceByRefNumberRequest } from 'domains/basket/Basket.store';
import { Box } from 'UI';
import { TRACKING_EVENT_ADD_REFERENCE_TO_CART } from 'utils/eventTracker/EventTracker.types';

type CartAddReferenceProps = {
  setAddedByReferenceBox: (referenceNumber: string | undefined) => void;
  expandable?: boolean;
};

const CartAddReference = ({ setAddedByReferenceBox, expandable }: CartAddReferenceProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  //TODO unify with dissapearing notification at TireReferenceCard
  const clearTimeoutListener = (e: MouseEvent, timeOut: NodeJS.Timeout) => {
    if (e.target instanceof Element) {
      const elem = e?.target?.closest('#see_reference_link');
      if (!elem) {
        clearTimeout(timeOut);
        setAddedByReferenceBox(undefined);
      } else {
        addEventListener(timeOut);
      }
    }
  };
  const addEventListener = (timeOut: NodeJS.Timeout) => {
    document.addEventListener('mousedown', (e) => clearTimeoutListener(e, timeOut), { once: true });
  };

  const handleAddReference = (reference: string) => {
    if (reference) {
      setAddedByReferenceBox(undefined);
      setAddedByReferenceBox(reference.trim());

      const timeOut = setTimeout(() => {
        setAddedByReferenceBox(undefined);
      }, 5000);

      addEventListener(timeOut);
      trackAppEvent(TRACKING_EVENT_ADD_REFERENCE_TO_CART);
      dispatch(
        addReferenceByRefNumberRequest({
          reference,
        }),
      );
    }
  };

  return (
    <Box width={expandable ? undefined : 335}>
      <FirstHelpPopin streamId={ROUTER_CART} popinId={`${ROUTER_CART}_add_reference`} placement={'bottom'}>
        <ReferenceInput
          expandable={expandable}
          placeholder={t('cart.action.add_reference_to_cart', 'Add reference to your cart..')}
          onConfirm={handleAddReference}
        />
      </FirstHelpPopin>
    </Box>
  );
};

export default CartAddReference;
