/* eslint-disable max-len */
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Season } from '@1po/1po-bff-fe-spec/generated/tire/model/Season';
import { TireItem } from '@1po/1po-bff-fe-spec/generated/tire/model/TireItem';
import { ROUTER_CART, ROUTER_ESTIMATE } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { trackAppEvent } from 'app/AppTracker';
import { StarPatchIcon } from 'assets/icons';
import { AddToEstimateButtonAndDialog } from 'components/AddToEstimate/AddToEstimateButtonAndDialog';
import { CopyToClipboardButton } from 'components/CopyToClipboardButton';
import { DataContainer } from 'components/DataContainer';
import { DiscountCorner } from 'components/Discount';
import ProductModal from 'components/ProductModal';
import { ReferencePriceSection } from 'components/ReferencePriceSection';
import { SFlex } from 'components/ReferencePriceSection/ReferencePriceSection.styled';
import { ReferenceUnavailable } from 'components/ReferenceUnavailableBox/ReferenceUnavailable';
import TireStockDisplay from 'components/StockInfo/TireStockDisplay';
import TireBasketPriceInfo from 'components/TireBasketPriceInfo';
import TireFeaturesIcons from 'components/TireIcons/TireFeaturesIcons';
import TiresRatingIcons from 'components/TireIcons/TireRatingIcons';
import PrivateComponent from 'composers/PrivateComponent';
import { addTireFromCatalogToCart } from 'domains/catalog/Catalog.store';
import { DATAHUB, LIFECYCLE_W1, LIFECYCLE_W2, LIFECYCLE_W3, LIFECYCLE_W4, TIRE } from 'domains/catalog/Catalog.types';
import { convertTireToEstimateTire } from 'domains/estimate/Estimate.mapper';
import { addCatalogTire, setSelectedTab } from 'domains/estimate/Estimate.store';
import { EstimateTabName } from 'domains/estimate/Estimate.types';
import { getIsMprAccepted, getIsStockAvailable, getPrice } from 'domains/references';
import { useFetchMprValidation, useFetchTireReplacementProducts } from 'domains/tires/Tire.requests';
import { getTireSearchResult, getTireSetParam } from 'domains/tires/Tire.store';
import { GarageView, SparePartsViewType, UserRole } from 'domains/user';
import { BorderedBox } from 'pages/CatalogPage/DH/Product/Product.styled';
import { GrayedOut } from 'pages/CatalogPage/DH/Product/ProductTab.styled';
import { useMyStoreTireDiscount } from 'pages/MyStorePage/useMyStoreTireDiscount';
import { TireBrandImage } from 'pages/TiresPage/TireReferencesSection/TireReferenceCard/TireBrandImage';
import {
  TireStockDetail,
  W1TireStockDetail,
} from 'pages/TiresPage/TireReferencesSection/TireReferenceCard/TireStockDetail';
import { TireReferenceCardsContainer } from 'pages/TiresPage/TireReferencesSection/TireReferencesContainer/TireReferenceCardsContainer';
import { tireBrandsData } from 'pages/TiresPage/TireSearch/TiresSearchbar/TireBrandsData';
import { theme } from 'styles';
import { Box, Flex, Icon, Image, InfoAlert, Lowercase, MarginBox, NotificationLink, notifyTop, Text } from 'UI';
import { getData, useExtraLarge } from 'utils';
import {
  TRACKING_EVENT_ADD_TIRES_TO_CART,
  TRACKING_EVENT_ADD_TIRES_TO_ESTIMATE,
  TRACKING_EVENT_GO_TO_CART_SHORTCUT,
  TRACKING_EVENT_GO_TO_ESTIMATE,
} from 'utils/eventTracker/EventTracker.types';
import { SBrandImageBox, SCard, SelectionCard, SStockWrapper } from './TireReferenceCard.styled';

const TireBackground = () => <Image src={'tires/tire.jpg'} height={160} alt={'tire'} />;

interface BrandImageWrapperProps extends React.PropsWithChildren {
  availableInUserCountry: boolean;
}

const BrandImageWrapper = ({ children, availableInUserCountry }: BrandImageWrapperProps) => {
  if (availableInUserCountry) {
    return <>{children}</>;
  }
  return <SBrandImageBox>{children}</SBrandImageBox>;
};

interface TireReferenceCardProps {
  tire: TireItem;
  sparePartsView: SparePartsViewType;
  compact?: boolean;
}

const TireReferenceCard = React.memo(function TireReferenceCard({
  tire,
  sparePartsView,
  compact,
}: TireReferenceCardProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const availableInUserCountry = useSelector((state: RootState) => getIsStockAvailable(state, tire.partNumber));
  const extraLarge = useExtraLarge();
  const price = getData(useSelector((state: RootState) => getPrice(state, tire.partNumber)));
  const tireSetParam = useSelector(getTireSetParam);
  const tireBrand = tireBrandsData.find((brand) => tire.brandId === brand.brandId);
  const myStoreTireDiscount = useMyStoreTireDiscount(tire.brandName, tire.baseDiameter);

  useFetchMprValidation(tire.partNumber);

  const accepted = useSelector((state: RootState) => getIsMprAccepted(state, tire.partNumber) ?? true);
  const isAvailableSoon = tire.lifeCycle === LIFECYCLE_W1;
  const isNoLongerAvailable = tire.isNonCommercialized || !accepted;

  useFetchTireReplacementProducts(tire.partNumber, compact);

  const replacementTires = useSelector((state: RootState) =>
    getTireSearchResult(state, {
      searchParamsBase64: tire.partNumber,
    }),
  );
  const replacementTiresFound = replacementTires?.data?.tires;

  const handleAddToCartClick = () => {
    notifyTop(
      'success',
      <Trans i18nKey={'catalog.tires.reference_card.added_to_basket.description'}>
        {'Tire has been added to your cart'}
      </Trans>,
      undefined,
      <NotificationLink
        onClick={() => {
          trackAppEvent(TRACKING_EVENT_GO_TO_CART_SHORTCUT);
          history.push(ROUTER_CART);
        }}
      >
        <Trans i18nKey={'catalog.reference_card.added_to_basket.go_to_cart'}>{'Go to cart'}</Trans>
      </NotificationLink>,
    );
    dispatch(addTireFromCatalogToCart({ tire }));
    trackAppEvent(TRACKING_EVENT_ADD_TIRES_TO_CART);
  };

  const handleAddToEstimateClick = () => {
    if (price?.garageView?.vatExcludedPrice) {
      notifyTop(
        'success',
        <Trans i18nKey={'catalog.reference_card.added_to_estimate.description'}>
          {'Reference has been added to your estimate.'}
        </Trans>,
        undefined,
        <NotificationLink
          onClick={() => {
            trackAppEvent(TRACKING_EVENT_GO_TO_ESTIMATE);
            dispatch(setSelectedTab(EstimateTabName));
            history.push(`${ROUTER_ESTIMATE}`);
          }}
        >
          <Trans i18nKey={'catalog.parts.category.car_parts.labor_time.notification.see_estimate'}>
            {'See estimate'}
          </Trans>
        </NotificationLink>,
      );
      trackAppEvent(TRACKING_EVENT_ADD_TIRES_TO_ESTIMATE);
      dispatch(
        addCatalogTire({
          reference: convertTireToEstimateTire(
            tire.partNumber,
            tire.profile ?? '',
            price,
            true,
            tireSetParam ?? 1,
            String(tire.baseDiameter),
            tire.brandId,
            tire.eprelUrl,
            tire.designation,
          ),
        }),
      );
    }
  };

  const renderRefInfoRow = () => (
    <Flex direction={'row'}>
      <ProductModal
        key={'tire-ref-num'}
        id={'tire-ref-num'}
        triggerComponent={
          <Text type={'light_14_black_85'} cursor={'pointer'} disableGutter noWrap hoverUnderLine>
            {t('catalog.reference_card.reference_number', 'Ref:')} {tire.partNumber}
          </Text>
        }
        referenceNumber={tire.partNumber}
      />
      <MarginBox mx={5}>
        <CopyToClipboardButton
          textToCopy={tire.partNumber}
          message={t(
            'catalog.reference_card.reference_number.copied_to_clipboard',
            'Reference number {{referenceNumber}} copied to clipboard',
            { referenceNumber: tire.partNumber },
          )}
        />
      </MarginBox>
      {tireBrand && (
        <>
          <MarginBox mr={2} />
          <Text type={'light_14_black_85'} disableGutter noWrap>
            {`| ${tireBrand.name}`}
          </Text>
        </>
      )}
    </Flex>
  );

  const renderTireFeaturesIcons = () => (
    <>
      <MarginBox mt={3} />
      <Flex>
        {!(tire.lifeCycle === LIFECYCLE_W1 || tire.lifeCycle === LIFECYCLE_W4) && (
          <MarginBox mr={15}>
            <TireStockDisplay referenceNumber={tire.partNumber} size={40} spinSize={'default'} />
          </MarginBox>
        )}
        <TireFeaturesIcons
          season={tire.season as Season}
          extraLoad={tire.extraLoad}
          runFlat={tire.runFlat}
          pmsf={tire.pmsf}
          selfSeal={tire.selfSeal}
          lifeCycle={tire.lifeCycle}
        />
      </Flex>
    </>
  );

  const renderTireRatingsIcons = () => (
    <>
      <MarginBox mt={7} />
      <TiresRatingIcons
        availableInUserCountry={availableInUserCountry}
        wetGrip={tire.wetGrip}
        exteriorSound={tire.exteriorSound}
        fuelConsumption={tire.fuelConsumption}
      />
    </>
  );

  const Wrapper = (isAvailableSoon || isNoLongerAvailable) && !compact ? GrayedOut : React.Fragment;

  return (
    <>
      {!compact && (
        <SelectionCard>
          <Flex align={'center'} gap={10}>
            <Icon IconComponent={StarPatchIcon} size={20} color={theme.color.brand} />
            <Text type={'h5_light_white'} disableGutter>
              {t('catalog.tires.reference.rpartstore_selection', 'Rpartstore Selection')}
            </Text>
          </Flex>
        </SelectionCard>
      )}
      <SCard>
        {sparePartsView === GarageView && <DiscountCorner reference={tire.partNumber} type={'catalog'} />}
        <Flex direction={'row'} basis={5}>
          {!compact && (
            <Wrapper>
              <MarginBox mr={20}>
                <Box width={200} height={160}>
                  <BrandImageWrapper availableInUserCountry={availableInUserCountry}>
                    <TireBackground />
                    <MarginBox mt={-30} ml={90}>
                      <TireBrandImage referenceBrandId={tire.brandId} width={100} height={30} />
                    </MarginBox>
                  </BrandImageWrapper>
                </Box>
              </MarginBox>
            </Wrapper>
          )}
          <Flex size={compact ? 1 : 3} align={'center'}>
            <Wrapper>
              <MarginBox mr={10}>
                <Flex direction={'column'} minWidth={extraLarge ? 315 : 0} wrap={'wrap'}>
                  <ProductModal
                    key={'tire-ref-num'}
                    id={'tire-ref-num'}
                    triggerComponent={
                      <Text
                        type={availableInUserCountry ? (compact ? 'text_dim_bold' : 'h2') : 'h2_black_45'}
                        cursor={'pointer'}
                        disableGutter
                        hoverUnderLine
                      >
                        {tire.profile}
                      </Text>
                    }
                    referenceNumber={tire.partNumber}
                  />
                  <Text type={availableInUserCountry ? 'lead' : 'lead_dim'} transform={'capitalize'}>
                    <Lowercase>{tire.designation}</Lowercase>
                    {tireBrand && ` - ${tireBrand.name}`}
                  </Text>
                  {compact && renderRefInfoRow()}
                  {!compact && (
                    <>
                      {renderTireFeaturesIcons()}
                      {renderTireRatingsIcons()}
                      {renderRefInfoRow()}
                    </>
                  )}
                </Flex>
              </MarginBox>
            </Wrapper>
          </Flex>
          {compact && (
            <Flex direction={'column'}>
              {renderTireFeaturesIcons()}
              {renderTireRatingsIcons()}
            </Flex>
          )}
          {availableInUserCountry ? (
            <Flex direction={'row'} justify={'flex-end'} align={compact ? 'center' : 'flex-start'}>
              <Flex
                direction={'column'}
                minWidth={compact ? 360 : 220}
                justify={'flex-end'}
                align={compact ? 'flex-end' : 'inherit'}
              >
                <Wrapper>
                  <ReferencePriceSection
                    referenceNumber={tire.partNumber}
                    sparePartsView={sparePartsView}
                    handleAddToCartClick={handleAddToCartClick}
                    vehicleKey={undefined}
                    catalogSource={DATAHUB}
                    referenceType={TIRE}
                    align={'left'}
                    narrow={compact}
                    useCompactView={compact}
                    isAvailableSoon={isAvailableSoon}
                    isNoLongerAvailable={isNoLongerAvailable}
                    clientDiscount={myStoreTireDiscount?.discount}
                  />
                </Wrapper>
                {!compact && (
                  <TireBasketPriceInfo
                    referenceNumber={tire.partNumber}
                    sparePartsView={sparePartsView}
                    clientDiscount={myStoreTireDiscount?.discount}
                  />
                )}
              </Flex>
              {!isAvailableSoon && !isNoLongerAvailable && price?.garageView?.vatExcludedPrice ? (
                <>
                  <MarginBox ml={10} />
                  <AddToEstimateButtonAndDialog
                    handleAddToEstimateClick={handleAddToEstimateClick}
                    size={compact ? 'lg' : 'sm'}
                  />
                </>
              ) : (
                <Box width={40} />
              )}
            </Flex>
          ) : (
            <Flex minWidth={extraLarge ? 400 : 185} maxWidth={400} align={'center'} justify={'flex-end'}>
              <ReferenceUnavailable
                size={'medium'}
                message={t(
                  'catalog.tire.reference.unavailable',
                  'Looks like this item is not available in your country.',
                )}
              />
            </Flex>
          )}
        </Flex>
        {!compact && availableInUserCountry && isNoLongerAvailable && (
          <Flex align={'center'} justify={'flex-end'}>
            <InfoAlert
              size={'lg'}
              message={
                <SFlex direction={'column'}>
                  <Text type={'search_result_highlighted'}>
                    {t('catalog.tires.search.no_longer_available_header', 'Product no longer available')}
                  </Text>
                  <Text type={'search_result'}>
                    {t(
                      'catalog.tires.search.no_longer_available_text',
                      'We have listed our best equivalent products below',
                    )}
                  </Text>
                </SFlex>
              }
            />
          </Flex>
        )}
      </SCard>
      <PrivateComponent
        render={() => {
          if (isAvailableSoon || isNoLongerAvailable) {
            return <></>;
          }
          return (
            <MarginBox mt={-20}>
              <SStockWrapper>
                <MarginBox mt={40} mb={10} mx={50}>
                  {tire.lifeCycle === LIFECYCLE_W1 ? (
                    <W1TireStockDetail />
                  ) : (
                    <TireStockDetail referenceNumber={tire.partNumber} />
                  )}
                </MarginBox>
              </SStockWrapper>
            </MarginBox>
          );
        }}
        requiredRights={[UserRole.COMMAND, UserRole.CONNECT_COMMANDE]}
      />
      <MarginBox mt={5} />
      {isAvailableSoon ||
        (isNoLongerAvailable && !compact && (
          <Flex direction={['column', 'row']} justify={'center'}>
            <BorderedBox>
              <MarginBox>
                <DataContainer data={replacementTires?.searchStatus}>
                  {replacementTiresFound && (
                    <TireReferenceCardsContainer
                      title={
                        replacementTiresFound.length === 0
                          ? t('catalog.search.iam.no_replacements_found', 'No replacements found')
                          : t('catalog.search.iam.replacement_products_other', 'Replacement products ({{count}})', {
                              count: replacementTiresFound.length,
                            })
                      }
                      searchParamsBase64={tire.partNumber}
                      tires={[...replacementTiresFound]
                        .filter(
                          (replacementTire) =>
                            replacementTire.lifeCycle === LIFECYCLE_W2 || replacementTire.lifeCycle === LIFECYCLE_W3,
                        )
                        .sort((a, b) => a.brandName?.localeCompare(b.brandName ?? '') ?? 0)}
                      sparePartsView={sparePartsView}
                      singleSection={replacementTires && replacementTiresFound.length === 0}
                      isAvailableSoon={isAvailableSoon}
                      isNoLongerAvailable={isNoLongerAvailable}
                      initShowCount={5}
                      open={false}
                      compact
                    />
                  )}
                </DataContainer>
              </MarginBox>
            </BorderedBox>
          </Flex>
        ))}
      <MarginBox mt={20} />
    </>
  );
});

export default TireReferenceCard;
