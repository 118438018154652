import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootState } from 'app/AppStore';
import { getLastSearchedVehicleKey } from 'domains/catalog/Catalog.store';
import { getDHReference } from 'domains/references';
import { theme } from 'styles';
import { Box, Flex, InfoAlert, Text } from 'UI';
import { getData } from 'utils';

interface CompatibilityProps {
  referenceNumber: string;
}

const ModelBox = styled(Flex)`
  border: solid 1px ${theme.color.grey90};
  border-radius: 15px;
  padding-left: 10px;
  padding-right: 10px;
`;

const Applicability = ({ referenceNumber }: CompatibilityProps) => {
  const { t } = useTranslation();
  const [showAll, setShowAll] = useState(false);
  const vehicleKey = useSelector(getLastSearchedVehicleKey);
  const referenceData = useSelector((state: RootState) => getDHReference(state, { vehicleKey, referenceNumber }));
  const applicableVehicles = getData(referenceData)?.applicableVehicles ?? [];
  // eslint-disable-next-line
  const showVehicles = useMemo(() => (showAll ? applicableVehicles : applicableVehicles.slice(0, 2)), [showAll]);
  return (
    <Flex direction={'column'} gap={24}>
      <Text type={'h2'}>{t('common.compatible_with', 'Compatible with')}</Text>
      {showVehicles.map((brand) => {
        return (
          <Flex key={brand.brandCode} direction={'column'} gap={15}>
            <Text type={'h3'} disableGutter>
              {brand.brandLabel}
            </Text>
            <Flex wrap={'wrap'} gap={15}>
              {brand.families.map((family) => {
                return (
                  <ModelBox
                    key={family.code}
                    align={'center'}
                    justify={'center'}
                    minHeight={50}
                    minWidth={200}
                    maxWidth={200}
                  >
                    <Text type={'text_dim'}>{family.label}</Text>
                  </ModelBox>
                );
              })}
            </Flex>
          </Flex>
        );
      })}
      <Flex justify={'center'}>
        <Text type={'link'} hoverUnderLine cursor={'pointer'} onClick={() => setShowAll(!showAll)}>
          {showAll ? t('common.action.show_less', 'Show less') : t('common.action.show_more', 'Show more')}
        </Text>
      </Flex>
      <Box width={'60%'}>
        <InfoAlert
          size={'md'}
          message={
            <Text type={'h6_light'} disableGutter>
              {t(
                'catalog.reference.more_vehicle_details_alert',
                'For more details, please refer to the vehicle specific documentation',
              )}
            </Text>
          }
        />
      </Box>
    </Flex>
  );
};

export default Applicability;
