import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  OperationByMileageOrTimeList,
  OperationByServiceList,
} from '@1po/1po-bff-fe-spec/generated/catalog/maintenance_plan/response/GetMaintenancePlanResponse';
import { VehicleDetail } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { Document, Image, Page, Text, Text as PdfText, View } from '@react-pdf/renderer';
import castrolLogo from 'assets/logos/castrol_logo.png';
import {
  BoltIcon,
  CheckCircleColoured,
  CircleClockColoured,
  EngineIcon,
  ExclamationCircleColoured,
  GasIcon,
  GearboxIcon,
  PictureCalendar,
  PictureCar,
  PictureCheck,
  PictureFlag,
  PictureInfo,
} from 'components/Pdf/PdfSvg';
import {
  ItemNameTitle,
  PageNumber,
  Section,
  Spacer,
  Subtitle,
  Table,
  TextMid,
  Title,
  VerticalSpacer,
} from 'components/Pdf/PdfUI';
import { components, fonts, styles } from 'components/Pdf/PdfUI.styles';
import { EngineOilLocal } from 'domains/catalog/Catalog.types';
import { MaintenancePdfData } from 'pages/CatalogPage/DH/Maintenance/MaintenancePdf/MaintenancePdfType';
import { formatYear } from 'pages/CatalogPage/DH/Maintenance/MileageTimeSection';
import { splitOperations } from 'pages/CatalogPage/DH/Maintenance/ServiceSection';
import { CarDetailHeaderImage } from 'pages/CatalogPage/IAM/MaintenancePlan/ChecklistPdf';
import { theme } from 'styles';
import { capitalize } from 'UI';
import { getCondArrayItem, isQueryBMM, textFormatter } from 'utils';
import { Country } from 'utils/i18n/Country';

const VehicleDHContext = ({
  vehicleDetail,
  signature,
}: {
  vehicleDetail: VehicleDetail | undefined;
  signature: string | undefined;
}) => {
  const { t } = useTranslation();
  if (!vehicleDetail) {
    return <></>;
  }
  const country = Country.findByKey(vehicleDetail.country);
  const isBMM = isQueryBMM(vehicleDetail.vehicleKey);
  const hpPowerDHKWsub =
    vehicleDetail.dataHubVehicle &&
    vehicleDetail.dataHubVehicle.powerKw &&
    vehicleDetail.dataHubVehicle.powerKw.substring(0, 3);
  const hpPowerDHKW = isNaN(Number(hpPowerDHKWsub)) ? hpPowerDHKWsub : Number(hpPowerDHKWsub);
  const hpPowerDHHP = typeof hpPowerDHKW === 'number' && Math.floor(1.341 * hpPowerDHKW);
  return (
    <View>
      <View
        style={[
          {
            marginTop: 9,
            marginBottom: 10,
            height: 20,
            width: 250,
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            backgroundColor: theme.color.grey00,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          },
        ]}
      >
        <>
          <VerticalSpacer width={10} />
          <PdfText style={[fonts.lead_text_white]}>{`${vehicleDetail.vehicleBrand} ${vehicleDetail.model}`}</PdfText>
          <VerticalSpacer width={50} />
          {!isBMM && country && <PictureFlag Flag={country.icon} />}
          <VerticalSpacer width={5} />
          {vehicleDetail.vrn && <PdfText style={[fonts.lead_text_white]}>{vehicleDetail.vrn}</PdfText>}
          <VerticalSpacer width={10} />
        </>
      </View>
      <View style={styles.row}>
        <CarDetailHeaderImage imageUrl={vehicleDetail.imageUrl} signature={signature} />
        <View style={styles.col}>
          <View style={[styles.row, { alignItems: 'center' }]}>
            <ItemNameTitle>
              {t('catalog.iam.vehicle.vin', 'VIN: {{vin}}', { vin: vehicleDetail.vin ? vehicleDetail.vin : '-' })}
            </ItemNameTitle>
            {vehicleDetail.dataHubVehicle && vehicleDetail.dataHubVehicle.modelType && (
              <>
                <VerticalSpacer width={5} />
                <PictureCar />
                <VerticalSpacer width={5} />
                <Section>{vehicleDetail.dataHubVehicle?.modelType && vehicleDetail.dataHubVehicle.modelType}</Section>
              </>
            )}
            {vehicleDetail.manufacturingDate && (
              <>
                <VerticalSpacer width={5} />
                <PictureCalendar />
                <VerticalSpacer width={5} />
                <Section>
                  {vehicleDetail.dataHubVehicle &&
                    vehicleDetail.manufacturingDate &&
                    textFormatter.formatDate(new Date(vehicleDetail.manufacturingDate))}
                </Section>
              </>
            )}
          </View>
          <View style={[styles.row, { alignItems: 'center' }]}>
            {vehicleDetail.dataHubVehicle?.engine && (
              <>
                <EngineIcon size={9} />
                <VerticalSpacer width={5} />
                <Section>{`${vehicleDetail.dataHubVehicle.engine}`}</Section>
              </>
            )}
            <VerticalSpacer width={10} />
            {!isBMM && vehicleDetail.dataHubVehicle?.powerKw && (
              <>
                <BoltIcon />
                <VerticalSpacer width={5} />
                {hpPowerDHKW && <Section>{t('catalog.parts.banner.kw', '{{kw}} kW', { kw: hpPowerDHKW })}</Section>}
                {hpPowerDHHP && (
                  <Section>{t('catalog.parts.banner.hp_alt', '/ {{hp}} HP', { hp: hpPowerDHHP })}</Section>
                )}
                <VerticalSpacer width={15} />
              </>
            )}
            {vehicleDetail.dataHubVehicle?.energyType && (
              <>
                <GasIcon />
                <VerticalSpacer width={5} />
                <Section>{`${capitalize(vehicleDetail.dataHubVehicle.energyType.toLowerCase())}`}</Section>
                <VerticalSpacer width={15} />
              </>
            )}
            {vehicleDetail.dataHubVehicle && vehicleDetail.dataHubVehicle.gearbox && (
              <>
                <GearboxIcon />
                <VerticalSpacer width={5} />
                <Section>{`${capitalize(vehicleDetail.dataHubVehicle.gearbox.toLowerCase())}`}</Section>
              </>
            )}
          </View>
        </View>
      </View>
      <View style={{ width: '100%', height: '1px', backgroundColor: theme.color.grey95, margin: '8px 0 15px 0' }} />
    </View>
  );
};

const Header = ({
  vehicleDetail,
  signature,
}: {
  vehicleDetail: VehicleDetail | undefined;
  signature: string | undefined;
}) => {
  const { t } = useTranslation();
  return (
    <View>
      <Title>{t('catalog.parts.banner.maintenance_plan.title', 'Maintenance plan')}</Title>
      <Subtitle>{textFormatter.formatDate(new Date())}</Subtitle>
      <VehicleDHContext vehicleDetail={vehicleDetail} signature={signature} />
    </View>
  );
};

const SectionHeader = ({ title, description }: { title: string; description?: string }) => {
  return (
    <View wrap={false} style={{ width: '300px' }}>
      <ItemNameTitle>{title}</ItemNameTitle>
      {description && (
        <>
          <Spacer height={10} />
          <TextMid>{description}</TextMid>
        </>
      )}
      <Spacer height={20} />
    </View>
  );
};
type statusType = { status: string };
const MileageTimeSection = ({
  data,
  getLabel,
  mileage,
  mileageUnits,
  operations,
  displayOperations,
}: MaintenancePdfData & {
  getLabel: (id: number) => string;
}) => {
  const { t } = useTranslation();
  const getColumns = () => {
    return [
      {
        title: t('catalog.maintenance.operations', 'Operations'),
        render: (item: OperationByMileageOrTimeList) => item.label,
        width: displayOperations ? 160 : 270,
      },
      {
        title: `${getLabel(50)} ${getLabel(16)}`,
        render: (item: OperationByMileageOrTimeList) => item.fromKm || '.',
        width: 70,
      },
      {
        title: `${getLabel(50)} ${capitalize(getLabel(10))}`,
        render: (item: OperationByMileageOrTimeList) => formatYear(item.fromYears),
        width: 70,
      },
      {
        title: `${capitalize(getLabel(51))} ${getLabel(40)} ${getLabel(16)}`,
        render: (item: OperationByMileageOrTimeList) => item.everyKm || '.',
        width: 70,
      },
      {
        title: `${capitalize(getLabel(51))} ${getLabel(40)} ${capitalize(getLabel(10))}`,
        render: (item: OperationByMileageOrTimeList) => formatYear(item.everyYears),
        width: 70,
      },
      ...getCondArrayItem(
        displayOperations && {
          title: `${t('catalog.maintenance.planned_maintenance', 'Planned maintenance')} ${mileageUnits}: ${mileage}`,
          render: () => <></>,
          width: 100,
          renderNode: (item: OperationByMileageOrTimeList & statusType) => getIcon(item.status),
        },
      ),
    ];
  };

  const getIcon = (status: string | undefined) => {
    switch (status) {
      case '0':
        return <CheckCircleColoured color={theme.color.selected} />;
      case '1':
        return <ExclamationCircleColoured color={theme.color.warning} />;
      case '2':
        return <CircleClockColoured color={theme.color.error} />;
      default:
        return <></>;
    }
  };

  return (
    <View style={[styles.col, { marginBottom: '30px' }]} wrap={data.operationByMileageOrTimeList.length > 10}>
      <SectionHeader title={getLabel(27)} description={getLabel(26)} />
      <Table
        data={data.operationByMileageOrTimeList.map((i) => {
          const status =
            operations?.data?.operations?.find((o) => o.code === i.codeAction + i.codeLibele)?.isOpen?.toString() ?? '';
          return { ...i, status };
        })}
        columns={getColumns()}
        alignTitle
      />
    </View>
  );
};

const SingleServices = ({ operationByServiceList }: { operationByServiceList: OperationByServiceList[] }) => {
  const [table1Data, table2Data] = splitOperations(operationByServiceList);

  const SingleTable = ({ tableData }: { tableData: OperationByServiceList[] }) => {
    return (
      <View style={styles.col}>
        {tableData.map((service, index) => {
          return (
            <View key={index} wrap={false}>
              <View style={[styles.row, { height: '24px', alignItems: 'center' }]}>
                <View style={{ width: '250px' }}>
                  <Section>{service.label}</Section>
                </View>
                <View style={[styles.row_reverse, { marginLeft: '5px', marginRight: '5px' }]}>
                  <PictureCheck />
                </View>
              </View>
              <View style={{ width: '100%', height: '1px', backgroundColor: theme.color.grey95 }} />
            </View>
          );
        })}
      </View>
    );
  };

  return (
    <View style={[styles.row, { marginBottom: '30px' }]}>
      <SingleTable tableData={table1Data} />
      <VerticalSpacer width={50} />
      {table2Data.length > 0 ? <SingleTable tableData={table2Data} /> : <View style={styles.row} />}
    </View>
  );
};

const getColumns = (allServices: number[], operationByMileageOrTimeList: OperationByMileageOrTimeList[]) => {
  const servicesColumns = allServices.map((serviceColumnNumber) => {
    const serviceData = operationByMileageOrTimeList.find((o) => o.id === serviceColumnNumber);
    return {
      title: serviceData?.label ?? '',
      render: () => <></>,
      renderNode: (service: OperationByServiceList) => {
        return (
          <View>
            {service.carriedOutByOperationByMileageOrTimeIdList.includes(serviceColumnNumber) && <PictureCheck />}
          </View>
        );
      },
      width: 60,
    };
  });
  return [
    {
      title: '',
      render: () => <></>,
      renderNode: (operation: OperationByServiceList) => {
        return (
          <View>
            <Section>{operation.label}</Section>
          </View>
        );
      },
      width: 140,
    },
    ...servicesColumns,
  ];
};

const MultipleServices = ({
  operationByServiceList,
  idServices,
  operationByMileageOrTimeList,
}: {
  operationByMileageOrTimeList: OperationByMileageOrTimeList[];
  operationByServiceList: OperationByServiceList[];
  idServices: number[];
}) => {
  const [table1Data, table2Data] = splitOperations(operationByServiceList);
  const columns = getColumns(idServices, operationByMileageOrTimeList);

  return (
    <View style={styles.row}>
      <Table columns={columns} data={table1Data} alignTitle />
      <VerticalSpacer width={24} />
      {table2Data.length > 0 ? <Table columns={columns} data={table2Data} alignTitle /> : <View style={styles.row} />}
    </View>
  );
};

const ServiceSection = ({
  data,
  getLabel,
}: MaintenancePdfData & {
  getLabel: (id: number) => string;
}) => {
  return (
    <View style={[styles.col, { marginBottom: '30px' }]} wrap={data.operationByServiceList.length > 10}>
      <SectionHeader title={getLabel(28)} />
      {data.operationsByServiceIds.length > 0 ? (
        <MultipleServices
          operationByServiceList={data.operationByServiceList}
          idServices={data.operationsByServiceIds}
          operationByMileageOrTimeList={data.operationByMileageOrTimeList}
        />
      ) : (
        <SingleServices operationByServiceList={data.operationByServiceList} />
      )}
    </View>
  );
};
const SpecialInformationSection = ({
  data,
}: MaintenancePdfData & {
  getLabel: (id: number) => string;
}) => {
  const { t } = useTranslation();
  return (
    <View style={[styles.col, { width: '300px', marginBottom: '30px' }]}>
      <SectionHeader title={t('catalog.maintenance.special_information', 'Special information')} />
      {data.specialInformationList.map((si) => (
        <Section key={si}>{si}</Section>
      ))}
    </View>
  );
};
const FluidsSection = ({
  data,
  getLabel,
}: MaintenancePdfData & {
  getLabel: (id: number) => string;
}) => {
  if (data.brakeFluid.length === 0 && data.coolantFluid.length === 0 && data.engineOilList.length === 0) return <></>;
  const hasEngineData = data.engineOilList.length > 0;

  const getFluidHeader = (title: string) => {
    return (
      <View style={[components.card_title_center, { height: '24px', width: '100%' }]}>
        <ItemNameTitle>{title}</ItemNameTitle>
      </View>
    );
  };

  const FluidInfoCard = ({ title, data }: { title: string; data: string[] }) => {
    if (data.length === 0) return <></>;
    return (
      <>
        {getFluidHeader(title)}
        {data.map((d) => {
          return (
            <React.Fragment key={d}>
              <View style={{ margin: '10px 0 10px 10px' }}>
                <Section>{d}</Section>
              </View>
              <View style={{ width: '100%', height: '1px', backgroundColor: theme.color.grey95 }} />
            </React.Fragment>
          );
        })}
      </>
    );
  };

  const EngineFluids = ({
    engineData,
    standards,
    averageCapacity,
  }: {
    engineData: EngineOilLocal[];
    standards: string[];
    averageCapacity: string[];
  }) => {
    const { t } = useTranslation();
    const getColumns = (standards: string[]) => {
      const standardsColumns = standards.map((standard) => {
        return {
          title: standard,
          render: () => <></>,
          renderNode: (row: EngineOilLocal) => {
            const capacities = row.dataForTemp
              .filter((r) => r.standard === standard)
              .map((c) => c.engineCapacity)
              .join(' ');
            return <Section>{capacities}</Section>;
          },
          width: 60,
        };
      });

      return [
        {
          title: '°C',
          render: () => <></>,
          renderNode: (row: EngineOilLocal) => {
            return (
              <View>
                <Section>{`${row.temp} °C`}</Section>
              </View>
            );
          },
          width: 45,
        },
        ...standardsColumns,
      ];
    };

    return (
      <>
        {getFluidHeader(getLabel(30))}
        <View style={styles.row}>
          <View style={[styles.col, { width: '196px', gap: '10px', padding: '0 5px 0 5px' }]}>
            <View />
            <Section>{`${getLabel(32)}: ${standards.join(', ')}`}</Section>
            <View style={{ width: '100%', height: '1px', backgroundColor: theme.color.grey95 }} />
            <Section>{getLabel(36)}</Section>
            <Section>{getLabel(38)}</Section>
            <View style={[styles.row]}>
              <Table data={engineData} columns={getColumns(standards)} />
            </View>
          </View>
          <View style={[styles.col, { width: '140px', gap: '10px', padding: '0 5px 0 5px' }]}>
            <View />
            <Section>{`${t('catalog.maintenance.average_capacity', 'Average capacity:')} ${averageCapacity.join(
              ', ',
            )}`}</Section>
            <View style={{ width: '100%', height: '1px', backgroundColor: theme.color.grey95 }} />
            <Section>{getLabel(33)}</Section>
            <Image src={castrolLogo} style={{ width: '80%' }} />
          </View>
        </View>
        <Spacer height={15} />
        <View style={[styles.row, { backgroundColor: theme.color.info_blue, width: '100%', borderRadius: '5px' }]}>
          <View style={[styles.row, { width: '30px', justifyContent: 'center', alignItems: 'center' }]}>
            <PictureInfo color={theme.color.blue_pale} />
          </View>
          <View style={[styles.col, { width: '100%', padding: '10px 10px 10px 0' }]}>
            <ItemNameTitle>{t('common.note', 'Note')}</ItemNameTitle>
            <Spacer height={10} />
            <Section>{getLabel(39)}</Section>
          </View>
        </View>
      </>
    );
  };

  return (
    <View wrap={false} style={[styles.col]}>
      <View
        style={[
          styles.row,
          {
            backgroundColor: 'black',
            borderTopLeftRadius: '4px',
            borderTopRightRadius: '4px',
            width: '100%',
            height: '22px',
            alignItems: 'center',
            paddingLeft: '15px',
          },
        ]}
      >
        <Text style={fonts.lead_text_white}>{getLabel(29)}</Text>
      </View>
      <Spacer height={20} />
      <View style={styles.row}>
        {hasEngineData && (
          <>
            <View style={[styles.row, { width: '340px' }]}>
              <View style={[styles.col, { width: '100%' }]}>
                <EngineFluids
                  engineData={data.engineOilList}
                  standards={data.engineOilStandards}
                  averageCapacity={data.averageCapacity}
                />
              </View>
            </View>
            <VerticalSpacer width={15} />
          </>
        )}
        <View style={[styles.col, { width: '200px' }]}>
          <View style={styles.col}>
            <FluidInfoCard title={getLabel(31)} data={data.brakeFluid} />
            <Spacer height={45} />
            <FluidInfoCard title={getLabel(37)} data={data.coolantFluid} />
          </View>
        </View>
        {!hasEngineData && <View style={[styles.row, { width: '311px' }]} />}
      </View>
    </View>
  );
};

export const MaintenancePdf = ({
  data,
  vehicleDetail,
  signature,
  mileage,
  mileageUnits,
  operations,
  displayOperations,
}: MaintenancePdfData) => {
  const getLabel = (id: number) => {
    const label = data.headerTranslationsList.find((l) => l.id === id);
    return label?.label ?? '';
  };
  return (
    <Document>
      <Page size={'A4'} style={[styles.page]}>
        <PageNumber />
        <Header vehicleDetail={vehicleDetail} signature={signature} />
        <MileageTimeSection
          data={data}
          getLabel={getLabel}
          mileageUnits={mileageUnits}
          mileage={mileage}
          operations={operations}
          displayOperations={displayOperations}
        />
        <ServiceSection data={data} getLabel={getLabel} />
        <SpecialInformationSection data={data} getLabel={getLabel} />
        <FluidsSection data={data} getLabel={getLabel} />
      </Page>
    </Document>
  );
};
