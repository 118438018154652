import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'app/AppStore';
import { calculatePriceAfterDiscount, getPromotionColor } from 'components/Discount';
import StockDisplay from 'components/StockInfo';
import { ReferenceLocal, VehicleLocal } from 'domains/basket/Basket.types';
import { getDiscount, getPrice } from 'domains/references';
import { getCurrency } from 'domains/user';
import { ReferenceCardWrapper } from 'pages/CartPage/CartPopover/CartPopover.styled';
import color from 'styles/color.json';
import { ColoredText, Flex, Text } from 'UI';
import { getData, textFormatter } from 'utils';

export const ReferenceCard = ({
  reference,
  vehicle,
}: {
  reference: ReferenceLocal;
  vehicle: VehicleLocal | undefined;
}) => {
  const { t } = useTranslation();
  const currency = useSelector(getCurrency);
  const discount = useSelector((state: RootState) => getDiscount(state, reference.referenceNumber));
  const price = useSelector((state: RootState) => getPrice(state, reference.referenceNumber));
  const priceData = getData(price);

  const discountPrice = calculatePriceAfterDiscount(discount, priceData, false, reference.isUrgent);
  const vehicleKey = vehicle?.vehicleDetail.vehicleKey;

  return (
    <ReferenceCardWrapper>
      <Flex direction={'row'} align={'center'} gap={15}>
        <Flex direction={'column'}>
          <Text type={'h4_paragraph'} dataCy={'cart-preview-reference'}>
            {t('cart.preview.reference.short', 'Ref. {{referenceNumber}}', {
              referenceNumber: reference.referenceNumber,
            })}
          </Text>
          <Text type={'text_semibold_black_85'} dataCy={'cart-preview-designation'}>
            {reference.name}
          </Text>
          <StockDisplay
            vehicleKey={vehicleKey}
            referenceNumber={reference.referenceNumber}
            isApplicableToCurrentVehicle
          />
        </Flex>
        <Flex direction={'column'} align={'center'} maxWidth={120}>
          <ColoredText
            type={'h6_dark_dim'}
            dataCy={'cart-preview-price'}
            color={getPromotionColor({
              staticType: undefined,
              referencePromotionType: discount?.promotionType,
              fallbackColor: color.grey15,
            })}
          >
            {textFormatter.formatCurrency(Number(discountPrice) * reference.quantity, currency)}
          </ColoredText>
          <Text type={'light_14_black_45'} dataCy={'cart-preview-quantity'}>
            {t('cart.preview.quantity', 'Quantity: {{count}}', { count: reference.quantity })}
          </Text>
        </Flex>
      </Flex>
    </ReferenceCardWrapper>
  );
};
