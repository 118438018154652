import styled from 'styled-components';
import { theme } from 'styles';
import { Flex } from 'UI';

export const SFlex = styled(Flex)`
  border-left: solid 1px ${theme.color.grey85};
  border-right: solid 1px ${theme.color.grey85};
  border-bottom: solid 1px ${theme.color.grey85};
`;

export const SHeaderFlex = styled(Flex)`
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: solid 1px ${theme.color.grey85};
`;
