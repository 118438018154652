import { ExternalBasketSectionLocal, ReferenceLocal, VehicleLocal } from 'domains/basket/Basket.types';
import { refWithoutDepositRef } from 'domains/references/DepositReference';

export const getSelectedItemsCount = (
  selectedVehiclesReferences: VehicleLocal[],
  selectedExternalsReferences: ExternalBasketSectionLocal[],
  selectedOtherReferences: ReferenceLocal[],
): number => {
  return (
    selectedVehiclesReferences?.reduce(
      (acc: number, next: VehicleLocal) => acc + refWithoutDepositRef(next?.references ?? []).length,
      0,
    ) +
    selectedExternalsReferences?.reduce(
      (acc: number, next: ExternalBasketSectionLocal) => acc + refWithoutDepositRef(next?.references ?? []).length,
      0,
    ) +
    (refWithoutDepositRef(selectedOtherReferences)?.length || 0)
  );
};
