import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { convertMileage } from 'domains/maintenancePlan/MaintenancePlan.mapper';
import { mileage_units } from 'domains/maintenancePlan/MaintenancePlan.types';
import { getDealerType } from 'domains/user';
import { useGetMaintenanceDocumentation } from 'pages/CatalogPage/DH/MaintenanceValues/MaintenanceValues';
import { Box, Flex, MarginBox, Pipeline, PrintButtonRound, Switch, Text } from 'UI';

const MaintenanceHeader = ({
  handlePrint,
  units,
  setUnits,
  setMileage,
  mileage,
  setVal,
}: {
  handlePrint: () => void;
  units: mileage_units;
  setUnits: (units: mileage_units) => void;
  setMileage: (mileage: number | undefined) => void;
  mileage: number | undefined;
  val: string;
  setVal: (mileage: string) => void;
}) => {
  const dealertype = useSelector(getDealerType);
  const { t } = useTranslation();
  const menu = useGetMaintenanceDocumentation();

  const onChangeUnits = () => {
    const mileageConverted = convertMileage(units, mileage ?? 0);
    switch (units) {
      case 'miles':
        setUnits('km');
        break;
      case 'km':
        setUnits('miles');
        break;
      default:
        setUnits('miles');
    }
    setMileage(mileageConverted);
    setVal(mileageConverted ? String(mileageConverted) : '0');
  };
  return (
    <MarginBox my={30}>
      <Flex>
        <Text type={'h1_banner_light'} disableGutter>
          {t('catalog.parts.category.maintenance_plan', 'Maintenance plan')}
        </Text>
        <Flex direction={'row-reverse'} align={'center'}>
          <PrintButtonRound size={24} onClick={handlePrint} />
          <MarginBox mr={20} />
          {menu}
          {(dealertype === 'R1' || dealertype === 'R2') && (
            <>
              <MarginBox mr={20} />
              {t('catalog.maintenance.units_miles', 'Miles')}
              <MarginBox mr={10} />
              <Switch checked={units === 'miles'} onChange={onChangeUnits} />
              <MarginBox mr={10} />
              {t('catalog.maintenance.units_km', 'Km')}
            </>
          )}
        </Flex>
      </Flex>
      <Box height={30} />
      <Pipeline horizontal size={'100%'} />
    </MarginBox>
  );
};
export default MaintenanceHeader;
