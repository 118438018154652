import {
  ExternalBasketSection,
  OtherSection,
  VehicleSection,
} from '@1po/1po-bff-fe-spec/generated/basket/response/BasketResponse';
import { Reference } from '@1po/1po-bff-fe-spec/generated/basket/response/model/Reference';
import { ReferenceDiscount } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/model/ReferenceDiscount';
import { TireItem } from '@1po/1po-bff-fe-spec/generated/tire/model/TireItem';
import { calculateUrgentDeliveryAddition } from 'components/Discount';
import { TireSet } from 'domains/tires/Tire.types';
import { ReferencePriceType, ReferenceTradingDataDetail } from '../references';

export const mapTire = (
  tire: TireItem,
  price: ReferencePriceType,
  discount: ReferenceDiscount | undefined,
  tireSetParam: TireSet | undefined,
): Reference => {
  // todo: fix this
  return {
    referenceNumber: tire.partNumber,
    name: tire.profile ?? '',
    brand: 'OTHER',
    quantity: tireSetParam ?? 1,
    type: 'TIRE',
    isCompatible: true,
    catalogSource: 'DATAHUB',
    price: {
      priceVatExcluded: price?.garageView?.vatExcludedPrice?.toString() ?? '0',
      priceVatIncluded: price?.garageView?.vatIncludedPrice?.toString() ?? '0',
      vatExcludedUrgentDeliveryAdditionalPrice: calculateUrgentDeliveryAddition(price, discount, false).toString(),
      vatIncludedUrgentDeliveryAdditionalPrice: calculateUrgentDeliveryAddition(price, discount, true).toString(),
    },
  };
};

export const mapReferenceToTradingData = (reference: Reference): ReferenceTradingDataDetail => {
  return {
    referenceNumber: reference.referenceNumber,
    quantity: reference.quantity,
    commercialFamily: reference.familyCode ?? '',
    type: reference.type,
    supplierCode: reference.catalogSource === 'IAM' ? reference.supplierCode : undefined,
    origin: reference.catalogSource === 'IAM' ? reference.origin : undefined,
    referenceSource: reference.catalogSource === 'IAM' ? reference.referenceSource : undefined,
    isPrimaryStockRequest: false,
    brand: reference.brand,
  };
};

export const getTradingDataMap = (
  vehicles: VehicleSection[] | undefined,
  externalBaskets: ExternalBasketSection[] | undefined,
  otherSection: OtherSection | undefined,
): Map<string, ReferenceTradingDataDetail> => {
  const tradingDataMap =
    otherSection?.references.reduce((acc, next) => {
      acc.set(next.referenceNumber, mapReferenceToTradingData(next));
      return acc;
    }, new Map<string, ReferenceTradingDataDetail>()) ?? new Map<string, ReferenceTradingDataDetail>();

  vehicles?.forEach((veh) =>
    veh.references.forEach((ref) => {
      const tradingDetail = mapReferenceToTradingData(ref);
      tradingDataMap.set(tradingDetail.referenceNumber, tradingDetail);
    }),
  );
  externalBaskets?.forEach((ext) =>
    ext.references.forEach((ref) => {
      const tradingDetail = mapReferenceToTradingData(ref);
      tradingDataMap.set(tradingDetail.referenceNumber, tradingDetail);
    }),
  );
  return tradingDataMap;
};
