/* eslint-disable max-len */
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'app/AppStore';
import { IAM } from 'domains/catalog/Catalog.types';
import { getIAMReference } from 'domains/references';
import { getFirstImageUrl } from 'pages/CatalogPage/IAM/SubcategorySection/SparePartsSection/ReferenceCardsContainer/PlateReferenceCard/IAMPlateReferenceCard';
import { ProductPreview } from 'UI/ProductPreview';
import { getData } from 'utils';

interface IamProductCardProps {
  referenceNumber: string;
  sparePartsView: string;
  handleClickCompare?: (reference: string) => void;
  showCompare?: boolean;
  vehicleKey?: string;
}

export const IamProductCard = ({
  referenceNumber,
  sparePartsView,
  handleClickCompare,
  showCompare,
  vehicleKey,
}: IamProductCardProps) => {
  const referenceRaw = useSelector((state: RootState) =>
    getIAMReference(state, {
      referenceNumber,
      vehicleKey: undefined,
    }),
  );

  const reference = getData(referenceRaw);
  const imageUrl = getFirstImageUrl(reference);

  if (!referenceNumber) {
    return null;
  }

  return (
    <ProductPreview
      imageUrl={imageUrl}
      productName={reference?.name}
      referenceNumber={referenceNumber}
      sparePartsView={sparePartsView}
      catalogSource={IAM}
      showCompare={showCompare}
      handleClickCompare={handleClickCompare}
      brandImage={reference?.brandImageUrl}
      vehicleKey={vehicleKey}
    />
  );
};
