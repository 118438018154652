import ReactPiwik from 'react-piwik';
import { History } from 'history';
import forEach from 'lodash.foreach';
import ContentsquareReact from 'utils/contentsquare/ContensquareReact';

interface EventTrackerProperties {
  siteId: string | boolean;
  analyticsUrl: string | null;
  trackErrors?: boolean;
}

interface ReactPiwikInterface {
  connectToHistory: (history: History) => unknown;
  disconnectFromHistory: () => unknown;
  track: (loc: Location) => unknown;
  push: (args: Array<string | number>) => unknown;
}

export class EventTracker {
  public tracker: ReactPiwikInterface | null;
  public contentSquareTracker: ContentsquareReact;

  constructor({ siteId, analyticsUrl, trackErrors = true }: EventTrackerProperties) {
    this.tracker =
      siteId !== undefined && analyticsUrl !== undefined
        ? new ReactPiwik({ url: analyticsUrl, siteId, trackErrors })
        : null;
    this.contentSquareTracker = new ContentsquareReact();
  }

  trackUser(id: string, info?: Record<string, string>): void {
    ReactPiwik.push(['setUserId', id]);

    if (info) {
      forEach(info, (key, value) => {
        ReactPiwik.push(['setCustomVariable', 1, key, value, 'visit']);
      });
    }
  }

  trackGarage(user: string): void {
    ReactPiwik.push(['setCustomDimension', 1, user]);
    ReactPiwik.push(['trackPageView']);
  }

  trackEvent(category: string, action: string, name: string, value?: string): void {
    const info = ['trackEvent', category, action, name];
    if (value !== undefined) {
      info.push(value);
    }
    ReactPiwik.push(info);
  }

  connectToHistory(history: History): unknown {
    if (this.tracker !== null) {
      this.tracker.track(window.location);
      return this.tracker.connectToHistory(history);
    }
    return undefined;
  }

  contentsquareConnectToHistory(history: History): unknown {
    return this.contentSquareTracker.connectToHistory(history);
  }
}
