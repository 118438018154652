import React from 'react';
import { useTranslation } from 'react-i18next';
import { WithTooltip } from 'UI';

export const SupersessionTooltip = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();
  return (
    <WithTooltip title={t('catalog.details.supersession_chain', 'Supersession chain')}>
      <div>{children}</div>
    </WithTooltip>
  );
};
