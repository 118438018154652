import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { HeaderTranslationList } from '@1po/1po-bff-fe-spec/generated/catalog/maintenance_plan/response/GetMaintenancePlanResponse';
import { pdf } from '@react-pdf/renderer';
import { RootState } from 'app/AppStore';
import { getLastVehicleDetail, getMaintenancePlan } from 'domains/catalog/Catalog.store';
import { MaintenancePlanLocal } from 'domains/catalog/Catalog.types';
import { convertMileageToCurrentUnits } from 'domains/maintenancePlan/MaintenancePlan.mapper';
import { getPlannedOperations } from 'domains/maintenancePlan/MaintenancePlan.store';
import { mileage_units } from 'domains/maintenancePlan/MaintenancePlan.types';
import { useFetchSignature } from 'domains/pictures/Pictures.requests';
import { getDealerType } from 'domains/user';
import FluidsSection from 'pages/CatalogPage/DH/Maintenance/FluidsSection';
import MaintenanceHeader from 'pages/CatalogPage/DH/Maintenance/MaintenanceHeader';
import { MaintenancePdf } from 'pages/CatalogPage/DH/Maintenance/MaintenancePdf';
import MileageTimeSection from 'pages/CatalogPage/DH/Maintenance/MileageTimeSection';
import ServiceSection from 'pages/CatalogPage/DH/Maintenance/ServiceSection';
import SpecialInformation from 'pages/CatalogPage/DH/Maintenance/SpecialInformation';
import { Container, Space } from 'UI';
import { getData, hasData } from 'utils';

export interface MaintenanceDataProps {
  maintenancePlanData: MaintenancePlanLocal;
  getLabel: (id: number) => string;
  units?: mileage_units;
  mileage?: number;
  setMileage?: (mileage: number | undefined) => void;
  getMaintenanceStatus?: (id: string | undefined) => string;
  val: string;
  setVal: (mileage: string) => void;
}

const Maintenance = () => {
  const [mileage, setMileage] = useState<number | undefined>(undefined);
  const [units, setUnits] = useState<'km' | 'miles'>('km');
  const [val, setVal] = useState(mileage ? String(mileage) : '0');
  const dealertype = useSelector(getDealerType);
  const maintenancePlanData = getData(useSelector(getMaintenancePlan));
  const vehicleDetail = useSelector(getLastVehicleDetail);
  const operationsResponse = useSelector((state: RootState) => getPlannedOperations(state, vehicleDetail?.vin));
  const operationsResponseData = getData(operationsResponse);

  useEffect(() => {
    if (
      !hasData(operationsResponse) ||
      operationsResponse.searchStatus === 'loading' ||
      (operationsResponse.data?.operations ?? []).length === 0
    ) {
      return;
    }

    const firstItem = operationsResponse.data?.operations[0];
    const apiUnits = firstItem?.mileageUnit ?? 'km';
    const apiMileage = firstItem?.usedVehicleMileage ?? 0;

    if (units === 'km' && apiUnits === 'km') {
      setMileage(apiMileage);
      setVal(apiMileage ? String(apiMileage) : '0');
    } else if (apiUnits) {
      const mileConverted = convertMileageToCurrentUnits(units, apiUnits, apiMileage);
      setMileage(mileConverted);
      setVal(mileConverted ? String(mileConverted) : '0');
    } else {
      //
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operationsResponse]);

  const dhSignature = useFetchSignature('DATAHUB');
  if (!maintenancePlanData) {
    return <></>;
  }

  const getLabel = (id: number) => {
    const label = maintenancePlanData.headerTranslationsList.find((l: HeaderTranslationList) => l.id === id);
    return label?.label ?? '';
  };

  const getPdfBlob = async () => {
    return pdf(
      <MaintenancePdf
        data={maintenancePlanData}
        vehicleDetail={vehicleDetail}
        signature={dhSignature}
        mileage={mileage}
        mileageUnits={units}
        operations={operationsResponseData}
        displayOperations={dealertype === 'R1' || dealertype === 'R2'}
      />,
    ).toBlob();
  };

  const handlePrint = async () => {
    const pdfBlob = await getPdfBlob();

    const url = URL.createObjectURL(pdfBlob);
    if (url) {
      window.open(url);
    }
  };

  return (
    <Container>
      <MaintenanceHeader
        handlePrint={handlePrint}
        units={units}
        setUnits={setUnits}
        mileage={mileage}
        setMileage={setMileage}
        val={val}
        setVal={setVal}
      />
      <Space direction={'vertical'} size={60}>
        <MileageTimeSection
          maintenancePlanData={maintenancePlanData}
          getLabel={getLabel}
          units={units}
          mileage={mileage}
          setMileage={setMileage}
          val={val}
          setVal={setVal}
        />
        <ServiceSection maintenancePlanData={maintenancePlanData} getLabel={getLabel} val={val} setVal={setVal} />
        <SpecialInformation specialInformation={maintenancePlanData.specialInformationList} />
        <FluidsSection maintenancePlanData={maintenancePlanData} getLabel={getLabel} val={val} setVal={setVal} />
      </Space>
    </Container>
  );
};

export default Maintenance;
