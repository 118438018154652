import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/AppStore';
import { CheckCircleIcon, ExadisIcon } from 'assets/icons';
import { calculatePriceAfterDiscount, getPromotionColor } from 'components/Discount';
import StockDisplay from 'components/StockInfo';
import { setReferenceDeliveryType } from 'domains/basket/Basket.store';
import { EXADIS_PICKUP_DELIVERY_TYPE, ReferenceLocal, VehicleLocal } from 'domains/basket/Basket.types';
import { IAM } from 'domains/catalog/Catalog.types';
import { getDiscount, getPrice, getStockInfo } from 'domains/references';
import { isRefNumberDeposit } from 'domains/references/DepositReference';
import { getExadisStockInfoWithDeliveryToday } from 'domains/references/References.utils';
import { getCurrency } from 'domains/user';
import { BasketQuantityInput } from 'pages/CartPage/CartStep/CartItems/CartReferenceTable/CartReferenceTable';
import {
  ReferenceCardWrapper,
  ReferencePicBackground,
  SText,
} from 'pages/CartPage/ValidationStep/VerificationCard/VerificationCard.styled';
import { ReferenceImage } from 'pages/CartPage/ValidationStep/VerificationCard/VerificationImage';
import { BrandImage } from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/ReferenceCardsContainer/ReferenceCard/BrandImage';
import { theme } from 'styles';
import color from 'styles/color.json';
import { Box, ColoredText, Flex, Icon, Text } from 'UI';
import { getData, textFormatter } from 'utils';

export const ReferenceCard = ({
  reference,
  vehicle,
  basketExadisDeliveryMode,
  index,
  externalBasketId,
}: {
  reference: ReferenceLocal;
  vehicle: VehicleLocal | undefined;
  basketExadisDeliveryMode: boolean;
  index?: number;
  externalBasketId?: string;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currency = useSelector(getCurrency);
  const discount = useSelector((state: RootState) => getDiscount(state, reference.referenceNumber));
  const price = useSelector((state: RootState) => getPrice(state, reference.referenceNumber));
  const priceData = getData(price);
  const stockInfo = useSelector((state: RootState) => getStockInfo(state, reference.referenceNumber));
  const discountPrice = calculatePriceAfterDiscount(discount, priceData, false, reference.isUrgent);
  const vehicleKey = vehicle?.vehicleDetail.vehicleKey;
  const hasR1warehouses = stockInfo?.data?.warehouses.some((w) => w.type === 'LOCAL');

  const isRequestedExadisPickUp =
    reference.catalogSource === IAM &&
    basketExadisDeliveryMode &&
    reference.brand !== 'MOTRIO' &&
    reference.origin === 'IAM' &&
    !hasR1warehouses;
  const exadisStocksDeliveryToday = getExadisStockInfoWithDeliveryToday(
    useSelector((state: RootState) => getStockInfo(state, reference.referenceNumber)),
  );

  const exadisWarehouses = exadisStocksDeliveryToday?.data?.warehouses || [];
  const exadisConfirmedQuantity: number = exadisStocksDeliveryToday?.data?.confirmedQuantity || 0;
  const showEmptyExadisStock =
    isRequestedExadisPickUp && (exadisWarehouses.length === 0 || exadisConfirmedQuantity < reference.quantity);

  useEffect(() => {
    if (reference.catalogSource === IAM) {
      if (
        reference.deliveryType === EXADIS_PICKUP_DELIVERY_TYPE &&
        (exadisWarehouses.length === 0 || exadisConfirmedQuantity < reference.quantity || !basketExadisDeliveryMode)
      ) {
        dispatch(
          setReferenceDeliveryType({
            deliveryType: undefined,
            referenceId: reference.referenceNumber,
            vehicleKey,
            externalBasketId,
          }),
        );
      }
      if (
        reference.deliveryType !== EXADIS_PICKUP_DELIVERY_TYPE &&
        isRequestedExadisPickUp &&
        exadisWarehouses.length > 0 &&
        exadisConfirmedQuantity >= reference.quantity &&
        exadisStocksDeliveryToday?.searchStatus === 'found'
      ) {
        dispatch(
          setReferenceDeliveryType({
            deliveryType: EXADIS_PICKUP_DELIVERY_TYPE,
            referenceId: reference.referenceNumber,
            vehicleKey,
            externalBasketId,
          }),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exadisStocksDeliveryToday?.searchStatus, reference, hasR1warehouses]);

  return (
    <ReferenceCardWrapper index={index} direction={'row'} align={'center'} gap={15}>
      <ReferencePicBackground>
        {isRefNumberDeposit(reference.referenceNumber) ? (
          <BrandImage referenceBrand={'STANDARD_EXCHANGE'} width={128} height={94} noPointer />
        ) : (
          <ReferenceImage reference={reference} vehicleDetail={vehicle?.vehicleDetail} />
        )}
      </ReferencePicBackground>
      <Flex direction={'column'}>
        <Flex direction={'row'} gap={20}>
          <SText noStock={showEmptyExadisStock} type={'h4_paragraph'} dataCy={'checkout-reference'}>
            {isRefNumberDeposit(reference.referenceNumber)
              ? t('order.verification.reference.depositReferenceNumber.short', 'Deposit Ref. {{referenceNumber}}', {
                  referenceNumber: reference.referenceNumber,
                })
              : t('order.verification.reference.referenceNumber.short', 'Ref. {{referenceNumber}}', {
                  referenceNumber: reference.referenceNumber,
                })}
          </SText>
          {isRequestedExadisPickUp && (
            <Icon IconComponent={ExadisIcon} color={theme.color.brand_black} height={22} noPointer />
          )}
        </Flex>
        <SText noStock={showEmptyExadisStock} type={'text_semibold_black_85'} dataCy={'checkout-designation'}>
          {reference.name}
        </SText>
        <StockDisplay
          vehicleKey={vehicleKey}
          referenceNumber={reference.referenceNumber}
          isApplicableToCurrentVehicle
          isExadisPickUp={isRequestedExadisPickUp}
        />
      </Flex>
      <Flex direction={'column'} align={'center'} maxWidth={150}>
        {isRequestedExadisPickUp && exadisWarehouses.length !== 0 && exadisConfirmedQuantity < reference.quantity ? (
          <BasketQuantityInput
            currentQuantity={reference.quantity}
            vehicleKey={vehicleKey}
            externalBasketId={externalBasketId}
            reference={reference}
            markRemovedReferences={() => undefined}
          />
        ) : (
          <SText noStock={showEmptyExadisStock} type={'text_bold'} dataCy={'checkout-quantity'}>
            {t('order.verification.reference.units', '{{count}} Units', { count: reference.quantity })}
          </SText>
        )}
      </Flex>
      <Flex direction={'column'} align={'center'} maxWidth={150}>
        <ColoredText
          type={'text_bold'}
          dataCy={'checkout-price'}
          color={
            showEmptyExadisStock
              ? theme.color.error
              : getPromotionColor({
                  staticType: undefined,
                  referencePromotionType: discount?.promotionType,
                  fallbackColor: color.grey15,
                })
          }
        >
          {textFormatter.formatCurrency(Number(discountPrice) * reference.quantity, currency)}
        </ColoredText>
        {reference.isUrgent && (
          <Flex align={'center'}>
            <Flex align={'center'} justify={'center'} maxWidth={9} maxHeight={9} background={'#0050b3'}>
              <Icon IconComponent={CheckCircleIcon} size={16} color={theme.color.brand} />
            </Flex>
            <Box width={5} />
            <Text type={'mobile_h3'} displayStyle={'link'}>
              {t('order.verification.reference.urgent', 'Urgent')}
            </Text>
          </Flex>
        )}
      </Flex>
    </ReferenceCardWrapper>
  );
};
