/* eslint-disable max-len */
import { DiscontinuedReferencesFileResponse } from '@1po/1po-bff-fe-spec/generated/backoffice/discontinued_references/response/DiscontinuedReferencesFileResponse';
import { WsResponse } from '@1po/1po-bff-fe-spec/generated/common/WsResponse';
import { call, put, takeEvery } from '@redux-saga/core/effects';
import { SagaIterator } from 'redux-saga';
import { FILE, UPLOADING_FILE } from 'components/FileUpload/paramsByState';
import {
  sendDiscontinuedReferencesFileRequest,
  sendGetDiscontinuedReferencesFileRequest,
} from 'domains/discontinuedReferences/DiscontinuedReferences.api';
import * as actions from 'domains/discontinuedReferences/DiscontinuedReferences.store';
import {
  getDiscontinuedReferencesFileUploadStatus,
  setDiscontinuedReferencesFileModalStatus,
  setDiscontinuedReferencesFileResponse,
  setDiscontinuedReferencesFileUploadStatus,
  setDiscontinuedReferencesSearchFileStatus,
} from 'domains/discontinuedReferences/DiscontinuedReferences.store';
import { FETCHING_FILE, FILE_FETCHED, FILE_REPLACED } from 'domains/electronicPartsRepair/ElectronicPartsRepair.types';
import { notifyTop } from 'UI';
import { AppTranslation, FOUND, LOADING, sagaGuard, select } from 'utils';

export function* fetchDiscontinuedReferencesFileRequestSaga(): SagaIterator {
  yield put(setDiscontinuedReferencesSearchFileStatus(LOADING));
  yield put(setDiscontinuedReferencesFileUploadStatus(FETCHING_FILE));
  yield put(sendGetDiscontinuedReferencesFileRequest());
}

export function* fetchDiscontinuedReferencesFileResponseSaga(
  action: WsResponse<DiscontinuedReferencesFileResponse>,
): SagaIterator {
  const status = yield* select(getDiscontinuedReferencesFileUploadStatus);
  if (status === FETCHING_FILE) {
    yield put(setDiscontinuedReferencesFileUploadStatus(FILE_FETCHED));
  } else {
    yield put(setDiscontinuedReferencesFileUploadStatus(FILE_REPLACED));
    yield call(
      notifyTop,
      'success',
      AppTranslation.t('common.file_upload.success', 'Your file was successfully uploaded.'),
      null,
    );
  }

  yield put(setDiscontinuedReferencesFileResponse(action.payload));
  yield put(setDiscontinuedReferencesSearchFileStatus(FOUND));
  yield put(setDiscontinuedReferencesFileModalStatus(FILE));
}

export function* uploadFileDiscontinuedReferencesSaga({
  payload,
}: ReturnType<typeof actions.uploadFileDiscontinuedReferencesRequestSaga>): SagaIterator {
  yield put(setDiscontinuedReferencesFileUploadStatus(UPLOADING_FILE));
  yield put(sendDiscontinuedReferencesFileRequest({ ...payload }));
}

export function* DiscontinuedReferencesSagas(): SagaIterator {
  yield takeEvery(
    actions.uploadFileDiscontinuedReferencesRequestSaga.type,
    sagaGuard(uploadFileDiscontinuedReferencesSaga),
  );

  yield takeEvery(
    actions.fetchFileDiscontinuedReferencesResponseSaga.type,
    sagaGuard(fetchDiscontinuedReferencesFileResponseSaga),
  );
  yield takeEvery(
    actions.fetchFileDiscontinuedReferencesRequestSaga.type,
    sagaGuard(fetchDiscontinuedReferencesFileRequestSaga),
  );
}
