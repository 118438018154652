import { Layout } from 'antd';
import styled, { css } from 'styled-components';
import Popover from 'components/Popover';
import { DisplayModeType, theme, TOPHEADER_ZINDEX } from 'styles';
import { Flex } from 'UI';

const { Header } = Layout;

export const PageHeaderDiv = styled.div<{ height: number; displayMode: DisplayModeType }>`
  display: flex;
  flex-wrap: nowrap;
  height: ${({ height }) => `${height}px`};
  width: 100%;
  background-color: ${({ theme, displayMode }) => theme.displayMode[displayMode].brandColor};
  color: ${({ theme }) => theme.color.white};
`;

export const SLayoutCorner = styled(Flex)`
  justify-items: center;
  transition: flex-basis 200ms linear;
  user-select: none;
  display: flex;
  flex: 1;
`;

export const AntHeader = styled(Header)<{ height: number }>`
  height: ${({ height }) => `${height}px`};
  position: sticky;
  top: 0;
  z-index: ${TOPHEADER_ZINDEX};
`;

export const SMenuItem = styled(Flex)`
  :hover {
    background-color: ${({ theme }) => theme.color.grey95};
  }

  cursor: pointer;
  border-radius: 4px;
`;

export const SPopover = styled(Popover)`
  .ant-popover-arrow {
    right: 5px;
  }

  .ant-popover-arrow-content,
  .ant-popover-arrow-content::before {
    background: ${({ theme }) => theme.color.white};
  }

  position: fixed;
`;

const dropdownStyle = css`
  border: solid 1px ${theme.color.grey85};
  padding: 0 8px 0 8px;
  max-height: 50px;
`;

export const SectionWrapper = styled(Flex)<{ pointer?: boolean; dropDownInputs: boolean }>`
  ${({ pointer }) => (pointer ? 'cursor: pointer;' : 'cursor: default')};
  border-radius: 4px;

  ${({ dropDownInputs }) => dropDownInputs && dropdownStyle}
  :hover {
    background-color: ${({ theme, pointer }) => pointer && theme.color.grey95};
  }
`;
