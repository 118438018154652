import React from 'react';
import { Trans } from 'react-i18next';
import { useIsB2B } from 'components/B2BComponents/useIsB2B';
import QuantityModule from 'components/QuantityModule';
import { IAMLaborTimeLocal } from 'domains/catalog/Catalog.types';
import { IAMEstimateLaborTimeDetail } from 'pages/CatalogPage/IAM/SubcategorySection/LaborTimeSection/LaborTimeSection';
import { BlackButton, Flex } from 'UI';

export interface IAMEstimateColumnProps {
  row: IAMLaborTimeLocal;
  laborTimeToQuantity: Map<string, IAMEstimateLaborTimeDetail>;
  addLaborTimeToEstimate: (laborTimeLocal: IAMLaborTimeLocal) => void;
  updateLaborTimeQuantity: (laborTimeCode: string, newQuantity: number) => void;
  technicity: string | undefined;
}

export function IAMEstimateColumn({
  row,
  laborTimeToQuantity,
  updateLaborTimeQuantity,
  addLaborTimeToEstimate,
  technicity,
}: IAMEstimateColumnProps) {
  const estimateLaborTimeDetail = laborTimeToQuantity.get(row.id);
  const isB2B = useIsB2B();

  const updateLaborTimeQuantityCallback = (newQuantity: number | string) => {
    if (estimateLaborTimeDetail) {
      updateLaborTimeQuantity(estimateLaborTimeDetail.itemId, Number(newQuantity));
    }
  };

  function buttonClick() {
    addLaborTimeToEstimate(row);
  }

  if (row.isSubLaborTime && row.isIncluded) return null;

  return (
    <Flex justify={'center'}>
      {estimateLaborTimeDetail ? (
        <QuantityModule
          value={estimateLaborTimeDetail.quantity}
          onChange={updateLaborTimeQuantityCallback}
          showDelete={true}
        />
      ) : (
        <BlackButton stretch={false} onClick={buttonClick} disabled={!technicity || isB2B}>
          <Trans i18nKey={'catalog.action.add_to_estimate'}>{'Add to estimate'}</Trans>
        </BlackButton>
      )}
    </Flex>
  );
}
