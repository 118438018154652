/* eslint-disable max-len */
import { GarageUpdate } from '@1po/1po-bff-fe-spec/generated/garage/response/GarageUpdate';
import { GetGarageInfo } from '@1po/1po-bff-fe-spec/generated/garage/response/GetGarageInfo';
import { put, takeEvery, takeLatest } from '@redux-saga/core/effects';
import { SagaIterator } from 'redux-saga';
import { sendChangeGarageEmail, sendConfigureGarageWorkshopId, sendGetGarageInfo } from 'domains/garage/Garage.api';
import { setGarage, setGarageEmail, setGarageNoDataStatus, setGarageWorkshopId } from 'domains/garage/Garage.store';
import { getTradingProfile, getUserCommercialLink } from 'domains/user';
import { LOADING, sagaGuard, select } from 'utils';
import * as actions from './Garage.store';
import { WsResponse } from '../webSockets/WebSocket.types';

export function* fetchGarageInfoRequest({ payload }: ReturnType<typeof actions.fetchGarageInfoRequest>): SagaIterator {
  const { garageId, repository } = payload;
  if (!garageId) {
    return;
  }

  yield put(setGarageNoDataStatus({ garageId, status: LOADING }));
  yield put(sendGetGarageInfo({ garageId, repository: repository === 'TRESOR' ? 'TRESOR' : 'BIR' }));
}

export function* fetchGarageInfoResponse(action: WsResponse<GetGarageInfo>): SagaIterator {
  const { payload } = action;
  yield put(setGarage({ garageId: payload.garageId, garage: payload.garageInfo }));
}

export function* fetchChangeGarageEmailRequest({
  payload,
}: ReturnType<typeof actions.fetchChangeGarageEmailRequest>): SagaIterator {
  const commercialLink = yield* select(getUserCommercialLink);
  if (!commercialLink?.buyerId) return;
  yield put(setGarageEmail({ garageId: commercialLink.tresorCode, email: payload }));
  yield put(sendChangeGarageEmail({ email: payload }));
}

export function* fetchGarageUpdate(action: WsResponse<GarageUpdate>): SagaIterator {
  const { payload } = action;
  const tradingProfile = yield* select(getTradingProfile);
  if (!tradingProfile?.buyerId) return;
  yield put(setGarageEmail({ garageId: payload.garageId, email: payload.email }));
  yield put(setGarageWorkshopId({ garageId: payload.garageId, workshopId: payload.workshopId }));
}

export function* configureWorkshopId({ payload }: ReturnType<typeof actions.configureWorkshopId>): SagaIterator {
  yield put(sendConfigureGarageWorkshopId({ workshopId: payload }));
  const commercialLink = yield* select(getUserCommercialLink);
  if (!commercialLink?.buyerId) return;
  yield put(setGarageWorkshopId({ garageId: commercialLink.tresorCode, workshopId: payload }));
}

export function* GarageSagas(): SagaIterator {
  yield takeLatest(actions.fetchGarageInfoRequest.type, sagaGuard(fetchGarageInfoRequest));
  yield takeLatest(actions.fetchGarageInfoResponse.type, sagaGuard(fetchGarageInfoResponse));
  yield takeLatest(actions.fetchChangeGarageEmailRequest.type, sagaGuard(fetchChangeGarageEmailRequest));
  yield takeLatest(actions.fetchGarageUpdate.type, sagaGuard(fetchGarageUpdate));
  yield takeEvery(actions.configureWorkshopId.type, sagaGuard(configureWorkshopId));
}
