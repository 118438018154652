// Source of country flag icons was https://github.com/HatScripts/circle-flags library

import { ReactComponent as FLAG_AT } from 'assets/flags/at.svg';
import { ReactComponent as FLAG_BE } from 'assets/flags/be.svg';
import { ReactComponent as FLAG_CH } from 'assets/flags/ch.svg';
import { ReactComponent as FLAG_CZ } from 'assets/flags/cz.svg';
import { ReactComponent as FLAG_DE } from 'assets/flags/de.svg';
import { ReactComponent as FLAG_ES } from 'assets/flags/es.svg';
import { ReactComponent as FLAG_FR } from 'assets/flags/fr.svg';
import { ReactComponent as FLAG_GB } from 'assets/flags/gb.svg';
import { ReactComponent as FLAG_IT } from 'assets/flags/it.svg';
import { ReactComponent as FLAG_NL } from 'assets/flags/nl.svg';
import { ReactComponent as FLAG_PL } from 'assets/flags/pl.svg';
import { ReactComponent as FLAG_PT } from 'assets/flags/pt.svg';
import { ReactComponent as FLAG_RO } from 'assets/flags/ro.svg';
import { ReactComponent as FLAG_RU } from 'assets/flags/ru.svg';
import { ReactComponent as FLAG_TR } from 'assets/flags/tr.svg';
import { ReactComponent as FLAG_USA } from 'assets/flags/us.svg';
import { IconType } from 'UI';
import { Language } from 'utils/i18n/Language';

export class Country {
  static readonly GB = new Country('GB', 'Great Britain', FLAG_GB);
  static readonly USA = new Country('USA', 'United States', FLAG_USA);
  static readonly CZ = new Country('CZ', 'Czechia', FLAG_CZ);
  static readonly DE = new Country('DE', 'Germany', FLAG_DE);
  static readonly AT = new Country('AT', 'Austria', FLAG_AT);
  static readonly CH = new Country('CH', 'Switzerland', FLAG_CH);
  static readonly ES = new Country('ES', 'Spain', FLAG_ES);
  static readonly FR = new Country('FR', 'France', FLAG_FR);
  static readonly BE = new Country('BE', 'Belgium', FLAG_BE);
  static readonly IT = new Country('IT', 'Italy', FLAG_IT);
  static readonly NL = new Country('NL', 'Netherlands', FLAG_NL);
  static readonly PL = new Country('PL', 'Poland', FLAG_PL);
  static readonly PT = new Country('PT', 'Portugal', FLAG_PT);
  static readonly RO = new Country('RO', 'Romania', FLAG_RO);
  static readonly RU = new Country('RU', 'Russia', FLAG_RU);
  static readonly TR = new Country('TR', 'Turkey', FLAG_TR);

  static readonly DEFAULT = Country.GB;

  private constructor(
    public readonly key: string,
    public readonly name: string, // locale?
    public readonly icon: IconType,
  ) {}

  toString(): string {
    return this.key;
  }

  public static getCountryByLanguage(lang: string): string {
    const language = Language.listWebLanguages().find((l) => l.key === lang)?.country ?? this.FR;
    return language.key;
  }

  public static findByKey(country?: string): Country | undefined {
    if (!country) {
      return undefined;
    }
    const found = this.listCountries().filter((c) => c.key.toUpperCase() === country.toUpperCase());
    if (found.length === 0) {
      return undefined;
    }
    if (found.length === 1) {
      return found[0];
    }
    throw new Error(`unexpected length for country ${country}: ${found.length}.`);
  }

  public static listCountries(): Array<Country> {
    return [
      this.GB,
      this.USA,
      this.CZ,
      this.DE,
      this.AT,
      this.CH,
      this.ES,
      this.FR,
      this.BE,
      this.IT,
      this.NL,
      this.PL,
      this.PT,
      this.RO,
      this.RU,
      this.TR,
    ];
  }

  public static listPasswordRetrievalCountries(): Array<Country> {
    return [this.FR, this.ES, this.IT, this.AT, this.DE, this.GB, this.PL, this.NL, this.CH, this.BE, this.PT];
  }

  public static canShowTiresByCountry(country?: string): boolean {
    return country
      ? [this.FR, this.ES, this.RO, this.CH, this.BE, this.PT].map((item) => item.key).includes(country)
      : false;
  }

  public static readonly SearchCountryList = Country.listCountries();
}
