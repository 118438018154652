import React, { ReactNode, useCallback, useState } from 'react';
import { CenterFlex, Flex, MarginBox, Modal, Text } from 'UI';

export interface GeneralDialogProps {
  readonly title: string;
  readonly content?: ReactNode;
  readonly handleCancel?: () => void;
  readonly disclosure: Disclosure;
}

interface DialogImplProps extends GeneralDialogProps {
  readonly width: number | string;
}

const ConfirmationDialog = ({ handleCancel, title, content, disclosure, width }: DialogImplProps) => {
  const { isOpen, onClose } = disclosure;

  const handleReject = useCallback(() => {
    if (handleCancel) {
      handleCancel();
    }
    onClose();
  }, [handleCancel, onClose]);

  return (
    <Modal
      title={
        <CenterFlex>
          <MarginBox mt={20}>
            <Text type={'h1'} disableGutter>
              {title}
            </Text>
          </MarginBox>
        </CenterFlex>
      }
      open={isOpen}
      closable
      onCancel={handleReject}
      width={width}
      footer={<></>}
    >
      <MarginBox mt={15}>
        <Flex direction={'column'} align={'center'} justify={'center'}>
          {content && (
            <MarginBox mt={15} key={'content'}>
              {content}
            </MarginBox>
          )}
        </Flex>
      </MarginBox>
    </Modal>
  );
};

export type Disclosure = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onToggle: () => void;
};

export function useDisclosure(): Disclosure {
  const [isOpen, setOpen] = useState(false);
  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);
  const onToggle = () => setOpen(!isOpen);
  return { isOpen, onOpen, onClose, onToggle };
}

export interface DialogProps extends GeneralDialogProps {
  readonly width?: number | string;
}

export function CloseableDialog({ handleCancel, title, content, disclosure, width = '1200px' }: DialogProps) {
  return (
    <>
      {disclosure.isOpen && (
        <ConfirmationDialog
          handleCancel={handleCancel}
          title={title}
          content={content}
          disclosure={disclosure}
          width={width}
        />
      )}
    </>
  );
}
