import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ROUTER_HOME } from 'app/AppRouter';
import {
  getEmailDocumentationAlertMotrioData,
  resetEmailDocumentationAlertMotrioData,
  setEmailDocumentationAlertMotrioDataStatus,
} from 'domains/email/Email.store';
import { FILLING } from 'domains/email/Email.types';
import { SModal } from 'pages/MyOrdersPage/InProgressOrders/InProgressOrders.styled';
import { BlackButton, notifyTop, Space, SuccessResult, WhiteButton } from 'UI';

export const DocumentationAlertResponsePopup = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const emailDocumentationAlertResponseData = useSelector(getEmailDocumentationAlertMotrioData);
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (emailDocumentationAlertResponseData.isSent === 'sent') {
      setIsOpen(true);
    }
  }, [emailDocumentationAlertResponseData]);

  useEffect(() => {
    if (emailDocumentationAlertResponseData.isSent === 'error') {
      notifyTop(
        'warning',
        <Trans type={'h1_banner_light'} i18nKey={'assistance.documentation_alert.title.error'}>
          {'Sorry, something went wrong.'}
        </Trans>,
        <Trans i18nKey={'assistance.documentation_alert.email.first_line.error'}>
          {'Your message has not been sent. Please try again.'}
        </Trans>,
      );
    }
  }, [emailDocumentationAlertResponseData]);

  const handleHome = () => {
    history.push(ROUTER_HOME);
    handleContinue();
  };

  const handleContinue = () => {
    if (!isOpen) {
      dispatch(resetEmailDocumentationAlertMotrioData());
    } else {
      dispatch(setEmailDocumentationAlertMotrioDataStatus(FILLING));
    }
    setIsOpen(false);
  };

  return (
    <SModal footer={''} width={800} open={isOpen} onCancel={() => setIsOpen(false)}>
      <SuccessResult
        title={t('assistance.documentation_alert.title.success', 'Your message has been sent')}
        subTitle={t(
          'assistance.documentation_alert.email.first_line.success',
          'Thank you for the time you took to report this parts documentation anomaly to us. ' +
            'This will allow us to improve the quality of our documentation.',
        )}
        extra={
          <Space size={30}>
            <WhiteButton onClick={handleContinue}>
              {t('common.action.continue_shopping', 'Continue shopping')}
            </WhiteButton>
            <BlackButton onClick={handleHome}>{t('common.home', 'Home')}</BlackButton>
          </Space>
        }
      />
    </SModal>
  );
};
