/* eslint-disable max-len */
import {
  PROMOTION_ADJUST_BY_R1_URL,
  PROMOTION_ARCHIVE_URL,
  PROMOTION_DELETE_URL,
  PROMOTION_GET_BANNERS_URL,
  PROMOTION_GET_FAMILY_CODES_URL,
  PROMOTION_GET_FULL_URL,
  PROMOTION_GET_NUMBER_OF_NOT_YET_ADJUSTED_PROMOTIONS_URL,
  PROMOTION_GET_URL,
  PROMOTION_SAVE_BANNER_URL,
  PROMOTION_SAVE_DISCOUNT_URL,
  PROMOTION_SAVE_FLASH_QUANTITY_LIMITED_URL,
  PROMOTION_SAVE_FLASH_TIME_LIMITED_URL,
  PROMOTION_UPLOAD_REFERENCES_FILE_URL,
  PROMOTION_UPLOAD_TARGETED_AUDIENCE_FILE_URL,
  PROMOTION_VALIDATE_PRODUCTS_URL,
  PROMOTIONS_COUNTRY_ADMIN_GET_URL,
  PROMOTIONS_COUNTRY_FOR_R1_GET_URL,
  PROMOTIONS_GET_CURRENT_URL,
  PROMOTIONS_GET_DASHBOARD_URL,
  PROMOTIONS_R1_FOR_COUNTRY_ADMIN_GET_URL,
  PROMOTIONS_R1_USER_GET_URL,
  PROMOTIONS_SUBSCRIBE_CURRENT_PROMOTIONS_URL,
} from '@1po/1po-bff-fe-spec/generated/backoffice/BackofficeURLs';
import { AdjustPromotionByR1Request } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/request/AdjustPromotionByR1Request';
import { ChangeStatusTypePromotion } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/request/ChangeStatusTypePromotion';
import { DeletePromotion } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/request/DeletePromotion';
import { GetCountryAdminPromotions } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/request/GetCountryAdminPromotions';
import { GetCountryPromotionsForR1User } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/request/GetCountryPromotionsForR1User';
import { GetCurrentPromotions } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/request/GetCurrentPromotions';
import { GetDashboardPromotions } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/request/GetDashboardPromotions';
import { GetFullPromotionForEdit } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/request/GetFullPromotionForEdit';
import { GetPromotion } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/request/GetPromotion';
import { GetR1PromotionsForCountryAdmin } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/request/GetR1PromotionsForCountryAdmin';
import { GetR1UserPromotions } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/request/GetR1UserPromotions';
import { ReferencesUploadRequest } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/request/ReferencesUploadRequest';
import { SaveBannerPromotion } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/request/SaveBannerPromotion';
import { SaveDiscountPromotion } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/request/SaveDiscountPromotion';
import { SaveFlashQuantityLimitedPromotion } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/request/SaveFlashQuantityLimitedPromotion';
import { SaveFlashTimeLimitedPromotion } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/request/SaveFlashTimeLimitedPromotion';
import { SubscribeCurrentPromotions } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/request/SubscribeCurrentPromotions';
import { SubscribeToNumberOfNotYetAdjustedPromotions } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/request/SubscribeToNumberOfNotYetAdjustedPromotions';
import { ValidateProductsRequest } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/request/ValidateProductsRequest';
import { FileUploadRequest } from '@1po/1po-bff-fe-spec/generated/common/file_upload/request/FileUploadRequest';
import { WebSocketAction } from 'utils/domainStore';
import { wsSendAction } from 'utils/domainStore/api';

export function sendSaveDiscountPromotionRequest(request: SaveDiscountPromotion): WebSocketAction {
  return wsSendAction(PROMOTION_SAVE_DISCOUNT_URL, request);
}

export function sendSaveFlashTimeLimitedPromotionRequest(request: SaveFlashTimeLimitedPromotion): WebSocketAction {
  return wsSendAction(PROMOTION_SAVE_FLASH_TIME_LIMITED_URL, request);
}

export function sendSaveFlashQuantityLimitedPromotionRequest(
  request: SaveFlashQuantityLimitedPromotion,
): WebSocketAction {
  return wsSendAction(PROMOTION_SAVE_FLASH_QUANTITY_LIMITED_URL, request);
}

export function sendSaveBannerPromotionRequest(request: SaveBannerPromotion): WebSocketAction {
  return wsSendAction(PROMOTION_SAVE_BANNER_URL, request);
}

export function sendAdjustPromotionByR1Request(request: AdjustPromotionByR1Request): WebSocketAction {
  return wsSendAction(PROMOTION_ADJUST_BY_R1_URL, request);
}

export function sendArchivePromotionRequest(request: ChangeStatusTypePromotion): WebSocketAction {
  return wsSendAction(PROMOTION_ARCHIVE_URL, request);
}

export function sendSubscribeToNumberOfNotYetAdjustedPromotionsRequest(
  request: SubscribeToNumberOfNotYetAdjustedPromotions,
): WebSocketAction {
  return wsSendAction(PROMOTION_GET_NUMBER_OF_NOT_YET_ADJUSTED_PROMOTIONS_URL, request);
}

export function sendSubscribeCurrentPromotionsRequest(request: SubscribeCurrentPromotions): WebSocketAction {
  return wsSendAction(PROMOTIONS_SUBSCRIBE_CURRENT_PROMOTIONS_URL, request);
}

export function sendGetPromotionRequest(request: GetPromotion): WebSocketAction {
  return wsSendAction(PROMOTION_GET_URL, request);
}

export function sendGetCurrentPromotionsRequest(request: GetCurrentPromotions): WebSocketAction {
  return wsSendAction(PROMOTIONS_GET_CURRENT_URL, request);
}

export function sendGetFullPromotionRequest(request: GetFullPromotionForEdit): WebSocketAction {
  return wsSendAction(PROMOTION_GET_FULL_URL, request);
}

export function sendGetPromotionBannersRequest(): WebSocketAction {
  return wsSendAction(PROMOTION_GET_BANNERS_URL, null);
}

export function sendUploadReferencesFileRequest(payload: ReferencesUploadRequest): WebSocketAction {
  return wsSendAction(PROMOTION_UPLOAD_REFERENCES_FILE_URL, payload);
}

export function sendUploadTargetedAudienceRequest(payload: FileUploadRequest): WebSocketAction {
  return wsSendAction(PROMOTION_UPLOAD_TARGETED_AUDIENCE_FILE_URL, payload);
}

export function sendGetDashboardPromotionsRequest(request: GetDashboardPromotions): WebSocketAction {
  return wsSendAction(PROMOTIONS_GET_DASHBOARD_URL, request);
}

export function sendGetR1UserPromotionsRequest(request: GetR1UserPromotions): WebSocketAction {
  return wsSendAction(PROMOTIONS_R1_USER_GET_URL, request);
}

export function sendGetCountryForR1PromotionsRequest(request: GetCountryPromotionsForR1User): WebSocketAction {
  return wsSendAction(PROMOTIONS_COUNTRY_FOR_R1_GET_URL, request);
}

export function sendGetCountryAdminPromotionsRequest(request: GetCountryAdminPromotions): WebSocketAction {
  return wsSendAction(PROMOTIONS_COUNTRY_ADMIN_GET_URL, request);
}

export function sendGetR1PromotionsForCountryAdminRequest(request: GetR1PromotionsForCountryAdmin): WebSocketAction {
  return wsSendAction(PROMOTIONS_R1_FOR_COUNTRY_ADMIN_GET_URL, request);
}

export function sendValidateProducts(request: ValidateProductsRequest): WebSocketAction {
  return wsSendAction(PROMOTION_VALIDATE_PRODUCTS_URL, request);
}

export function sendDeletePromotionRequest(request: DeletePromotion): WebSocketAction {
  return wsSendAction(PROMOTION_DELETE_URL, request);
}

export function sendFamilyCodesSearchRequest(): WebSocketAction {
  return wsSendAction(PROMOTION_GET_FAMILY_CODES_URL, null);
}
