import { Alert } from 'antd';
import styled from 'styled-components';
import { Flex } from 'UI';

export const SAlert = styled(Alert)`
  text-align: left;
  background-color: ${({ theme }) => theme.color.off_white};
  border: solid 1px ${({ theme }) => theme.color.yellow_light};
  margin: auto;
  height: 100%;
  padding: 15px;

  svg {
    display: inline;
    fill: ${({ theme }) => theme.color.yellowish_orange};
  }

  .ant-alert-message {
    display: inline-block;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: ${({ theme }) => theme.color.grey15};
  }

  .ant-alert-description {
    display: inline-block;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: ${({ theme }) => theme.color.grey35};
  }
`;

export const SFlex = styled(Flex)`
  padding: 10px;
`;

export const PclPriceWrapper = styled(Flex)`
  padding: 0 5px;
`;
