import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ROUTER_CATALOG_DH_L3 } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import {
  getDetailOfLevel3,
  getExplodedTreeItem,
  getPlate,
  getPlateReferences,
  getSearchVehicleResult,
  plateHasDescription,
} from 'domains/catalog/Catalog.store';
import {
  ActiveTabParam,
  HighlightIndex,
  PARAM_LABOR_TIME,
  PARAM_MULTIPLATE,
  PARAM_SPARE_PARTS,
  PARAM_STANDARD,
  PlateIdParam,
  PlateViewModeParam,
  PreferredTabParam,
} from 'domains/catalog/Catalog.types';
import { disableEnablePopins } from 'domains/firstHelp/FirstHelp.api';
import { useAutomaticFirstHelp } from 'domains/firstHelp/FirstHelp.requests';
import { getFirstGenericPartWithLaborTimes } from 'domains/laborTime/LaborTime.store';
import { getDHReferences, getLoadedPrices } from 'domains/references';
import { useSubcategorySectionLinkParams } from 'pages/CatalogPage/common/SubcategorySection/SubcategorySection.hook';
import { Container } from 'UI';
import { getArrayWithoutUndefinedMembers, getData, isLoading } from 'utils';
import LaborTimeSection from './LaborTimeSection';
import SparePartsSection from './SparePartsSection';
import MultiplateChoice from './SparePartsSection/MultiplateChoice';
import SubcategorySectionHeader from './SubcategorySectionHeader';
import { TabState } from './SubcategorySectionHeader/SubcategorySectionHeader';

const plateViewModes = [PARAM_STANDARD, PARAM_MULTIPLATE] as const;
type PlateViewModeTuple = typeof plateViewModes;
type PlateViewMode = PlateViewModeTuple[number];

function isValidPlateViewMode(plateViewMode: string | null): plateViewMode is PlateViewMode {
  return plateViewMode !== null && (plateViewModes as readonly string[]).includes(plateViewMode);
}

const SubcategorySection = () => {
  const { query, carPartGroupId, categoryId, subcategoryId } = useParams<{
    query: string;
    carPartGroupId: string;
    categoryId: string;
    subcategoryId: string;
  }>();
  const history = useHistory();
  const dispatch = useDispatch();
  //eslint-disable-next-line
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const activeTab = params.get(ActiveTabParam);
  const plateId = params.get(PlateIdParam);
  const highlightIndex = params.get(HighlightIndex);
  const paramPlateViewMode = params.get(PlateViewModeParam);
  const preferredTab = params.get(PreferredTabParam) || 'spare_parts';
  const [tabState, setTabState] = useState<TabState>(
    activeTab ? (activeTab as TabState) ?? PARAM_SPARE_PARTS : PARAM_SPARE_PARTS,
  );
  const [disabledTabs, setDisabledTabs] = useState<TabState[]>([]);
  const vehicleResult = useSelector((state: RootState) => getSearchVehicleResult(state, query));
  const vehicleKey = vehicleResult?.vehicleDetail?.vehicleKey;
  const tree = vehicleResult?.explodedTree?.data;
  const subcategory = getExplodedTreeItem(tree, [carPartGroupId, categoryId, subcategoryId]);
  const multiplates = useSelector((state: RootState) =>
    getDetailOfLevel3(state, { vehicleKey, nodeId: subcategory?.nodeId }),
  );
  const multiplatesArrayHasMany = Array.isArray(multiplates) && multiplates.length > 1;
  const isSingleMultiplate = Array.isArray(multiplates) && multiplates.length === 1;
  const firstPlateId = useMemo(() => {
    if (multiplatesArrayHasMany || isSingleMultiplate) {
      return multiplates[0].plateIds[0];
    }
    return subcategory && subcategory?.plateIds.length > 0 ? subcategory?.plateIds[0] : undefined;
  }, [multiplatesArrayHasMany, isSingleMultiplate, multiplates, subcategory]);

  const containsPlateId =
    subcategory?.plateIds && subcategory?.plateIds.length > 0 && subcategory?.plateIds.includes(plateId ?? '');
  const validPlateId = plateId === null || !containsPlateId ? firstPlateId : plateId;
  const plateReferences = useSelector((state: RootState) =>
    getPlateReferences(state, { plateId: validPlateId, vehicleKey }),
  );
  const plateReferenceNumbers = getData(plateReferences)?.map((r) => r.referenceNumber) ?? [];
  const references = useSelector((state: RootState) =>
    getDHReferences(state, { vehicleKey, referenceNumbers: plateReferenceNumbers }),
  );
  const isAnyReference = (subcategory?.plateIds.length ?? 0) > 0;
  const prices = useSelector((state: RootState) => getLoadedPrices(state, plateReferenceNumbers));
  const isPricesLoading = prices.some((p) => isLoading(p.prices)) || prices.length === 0;

  const genericParts = getArrayWithoutUndefinedMembers(references.map((r) => r.genericPart));
  const firstGenericPartWithLaborTimes = useSelector((state: RootState) =>
    getFirstGenericPartWithLaborTimes(state, {
      vehicleKey,
      genericParts,
    }),
  );

  const isLaborTimesLoading = firstGenericPartWithLaborTimes !== undefined || genericParts.length === 0;

  const plate = useSelector((state: RootState) => getPlate(state, { plateId: plateId ?? firstPlateId, vehicleKey }));

  const isFirstPlateMultiplate =
    multiplatesArrayHasMany && (multiplates[0].plateIds.length > 1 || plateHasDescription(plate?.plateDetail));

  const plateViewMode = paramPlateViewMode ?? (isFirstPlateMultiplate ? PARAM_MULTIPLATE : PARAM_STANDARD);

  useSubcategorySectionLinkParams(activeTab, setTabState, params);

  useEffect(() => {
    const states: TabState[] = [];
    if (subcategory?.plateIds.length === 0 && !subcategory?.containsMarketingReferences) {
      states.push(PARAM_SPARE_PARTS);
      if (tabState === PARAM_SPARE_PARTS || activeTab === PARAM_SPARE_PARTS) {
        setTabState(PARAM_LABOR_TIME);
        params.set(ActiveTabParam, PARAM_LABOR_TIME);
        history.replace(`${location.pathname}?${params}`);
      }
    }
    if (subcategory?.laborTimeIds.length === 0) {
      states.push(PARAM_LABOR_TIME);
      if (tabState === PARAM_LABOR_TIME || activeTab === PARAM_LABOR_TIME) {
        setTabState(PARAM_SPARE_PARTS);
        params.set(ActiveTabParam, PARAM_SPARE_PARTS);
        history.replace(`${location.pathname}?${params}`);
      }
    }
    if (!params.get(ActiveTabParam)) {
      params.set(ActiveTabParam, preferredTab);
      history.replace(`${location.pathname}?${params}`);
    }
    setDisabledTabs(states);
  }, [
    params,
    history,
    tabState,
    setTabState,
    setDisabledTabs,
    subcategory?.containsMarketingReferences,
    subcategory?.plateIds,
    subcategory?.laborTimeIds,
    activeTab,
    preferredTab,
  ]);

  useEffect(() => {
    const isAnyReferenceWithPrice = prices.some((p) => {
      const refPrice = getData(p.prices);
      return Boolean(refPrice?.garageView?.vatExcludedPrice);
    });
    const isSpareParts = tabState === PARAM_SPARE_PARTS;
    const isAnyReferenceWithLaborTimes = firstGenericPartWithLaborTimes !== undefined;

    disableEnablePopins(dispatch, !isSpareParts || !isAnyReference, [
      `${ROUTER_CATALOG_DH_L3}_quick_search`,
      `${ROUTER_CATALOG_DH_L3}_add_to_estimate`,
      `${ROUTER_CATALOG_DH_L3}_labor_times`,
      `${ROUTER_CATALOG_DH_L3}_add_to_cart`,
      `${ROUTER_CATALOG_DH_L3}_plate_index`,
    ]);
    disableEnablePopins(dispatch, !isSpareParts || !isAnyReferenceWithPrice, [
      `${ROUTER_CATALOG_DH_L3}_add_to_estimate`,
      `${ROUTER_CATALOG_DH_L3}_add_to_cart`,
    ]);
    disableEnablePopins(dispatch, !isSpareParts || !isAnyReferenceWithLaborTimes, [
      `${ROUTER_CATALOG_DH_L3}_labor_times`,
    ]);
    //eslint-disable-next-line
  }, [tabState, JSON.stringify(prices), subcategory?.plateIds.length]);
  useAutomaticFirstHelp(ROUTER_CATALOG_DH_L3, isPricesLoading || isLaborTimesLoading);

  useEffect(() => {
    if (!plateViewMode) {
      params.set(PlateViewModeParam, isFirstPlateMultiplate ? PARAM_MULTIPLATE : PARAM_STANDARD);
      history.replace(`${history.location.pathname}?${params}`);
    }
  }, [subcategoryId, history, isFirstPlateMultiplate, params, plateViewMode]);

  const selectedTab = useMemo(() => {
    const viewMode = isValidPlateViewMode(plateViewMode) ? plateViewMode : PARAM_STANDARD;
    const validHighlightIndex = highlightIndex === null ? undefined : highlightIndex;

    switch (tabState) {
      case PARAM_SPARE_PARTS:
        if (viewMode === PARAM_MULTIPLATE && validPlateId) {
          return (
            <MultiplateChoice
              query={query}
              carPartGroupId={carPartGroupId}
              categoryId={categoryId}
              subcategoryId={subcategoryId}
              plateId={validPlateId}
            />
          );
        }

        return (
          <SparePartsSection
            query={query}
            carPartGroupId={carPartGroupId}
            categoryId={categoryId}
            subcategoryId={subcategoryId}
            plateId={validPlateId}
            highlightIndex={validHighlightIndex}
          />
        );
      case PARAM_LABOR_TIME:
        return <LaborTimeSection query={query} nodeId={subcategoryId} laborTimeIds={subcategory?.laborTimeIds ?? []} />;
      default:
        return <>Not yet implemented</>;
    }
  }, [
    tabState,
    carPartGroupId,
    categoryId,
    highlightIndex,
    plateViewMode,
    query,
    subcategory?.laborTimeIds,
    subcategoryId,
    validPlateId,
  ]);

  return (
    <Container size={'xxl'}>
      <div id={'subcategory_section_container'} />
      <SubcategorySectionHeader
        tabState={tabState}
        setTabState={setTabState}
        disabledTabs={disabledTabs}
        label={subcategory?.label}
      />
      {selectedTab}
    </Container>
  );
};

export default SubcategorySection;
