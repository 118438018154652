import { useSelector } from 'react-redux';
import { getUserStockProvider } from 'domains/user/User.store';
import { getData } from 'utils';

export function useGetUserStockProvider(): string | undefined {
  const provider = useSelector(getUserStockProvider);
  return getData(provider.data);
}

export function useIsUserStockProviderRp4(): boolean {
  return useGetUserStockProvider() === 'RP4';
}
