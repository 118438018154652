import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'app/AppStore';
import {
  getEstimateB2BDiscounts,
  getEstimateB2BPrices,
  getRealEstimateReferencesCount,
  getReferences,
  getTires,
} from 'domains/estimate/Estimate.store';
import { getCurrency } from 'domains/user';
import {
  getTotalDiscountPrice,
  getTotalVatExclPriceForClient,
  getTotalVatInclPriceForClient,
  getTotalVatPriceForClient,
} from 'pages/EstimatePage/OrderEstimate/B2BEstimateOrderValidationStep';
import { Divider, Flex, LightCard, Text } from 'UI';
import { getData } from 'utils';
import { textFormatter } from 'utils/format';

export interface TotalSectionProps {
  estimateId: string;
}

const TotalSection = ({ estimateId }: TotalSectionProps) => {
  const { t } = useTranslation();
  const numberOfItems = useSelector((state: RootState) => getRealEstimateReferencesCount(state, estimateId));
  const references = getData(useSelector((state: RootState) => getReferences(state, estimateId))) ?? [];
  const tires = getData(useSelector((state: RootState) => getTires(state, estimateId))) ?? [];
  const allItems = [...references, ...tires];

  const prices = useSelector((state: RootState) => getEstimateB2BPrices(state, estimateId));
  const discounts = useSelector((state: RootState) => getEstimateB2BDiscounts(state, estimateId));

  const totalVatExclPrice = getTotalVatExclPriceForClient(allItems, prices, discounts);
  const totalVatInclPrice = getTotalVatInclPriceForClient(allItems, prices, discounts);
  const totalVatPrice = getTotalVatPriceForClient(allItems, prices, discounts);
  const totalDiscountPrice = getTotalDiscountPrice(allItems, prices, discounts);
  const currency = useSelector(getCurrency);
  return (
    <LightCard
      title={<Trans i18nKey="estimate.pdf.estimate_total_items">Estimate total: {{ numberOfItems }} items</Trans>}
    >
      <Flex>
        <Text type={'section'}>{`${t('common.price.total_VAT_excl', 'Total VAT. Excl')}:`}</Text>
        <Flex />
        <Text type={'section'}>{textFormatter.formatCurrency(totalVatExclPrice.getValue(), currency)}</Text>
        <Text type={'section'}>{}</Text>
      </Flex>
      <Flex>
        <Text type={'section'}>{t('common.price.vat', 'VAT:')}</Text>
        <Flex />
        <Text type={'section'}>{textFormatter.formatCurrency(totalVatPrice.getValue(), currency)}</Text>
        <Text type={'section'}>{}</Text>
      </Flex>
      {Number(totalDiscountPrice) > 0 && (
        <Flex>
          <Text type={'section'}>{`${t('common.discount', 'Discount')}:`}</Text>
          <Flex />
          <Text type={'section'}>{textFormatter.formatCurrency(totalDiscountPrice, currency)}</Text>
        </Flex>
      )}
      <Divider />
      <Flex>
        <Text type={'price_tag'}>{`${t('common.price.total_vat_incl', 'Total VAT. Incl')}:`}</Text>
        <Flex />
        <Text type={'price_tag'}>{textFormatter.formatCurrency(totalVatInclPrice.getValue(), currency)}</Text>
        <Text type={'price_tag'}>{}</Text>
      </Flex>
    </LightCard>
  );
};

export default TotalSection;
