import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DMSExportIcon } from 'assets/icons';
import { Dialog, useDisclosure } from 'components/Dialog';
import { addDmsOrderNumberRequest, getDmsOrderNumbers, resetDmsOrderNumbers } from 'domains/estimate/Estimate.store';
import { Flex, Radio, Text } from 'UI';
import { FOUND } from 'utils';

const DmsOrderNumbersSelectionPopup = () => {
  const dispatch = useDispatch();
  const [selectedDmsOrderNumber, setSelectedDmsOrderNumber] = useState<string | undefined>(undefined);
  const disclosure = useDisclosure();
  const { onOpen, isOpen } = disclosure;
  const dmsOrderNumbers = useSelector(getDmsOrderNumbers);
  const status = dmsOrderNumbers?.searchStatus;
  const data = dmsOrderNumbers?.data;

  useEffect(() => {
    if (status === FOUND && !isOpen) {
      onOpen();
    }
    // eslint-disable-next-line
  }, [status]);

  const handleConfirm = () => {
    if (selectedDmsOrderNumber && data?.estimateId) {
      dispatch(addDmsOrderNumberRequest({ estimateId: data.estimateId, dmsOrderNumber: selectedDmsOrderNumber }));
    }
  };

  const handleCancel = () => {
    dispatch(resetDmsOrderNumbers());
  };

  return (
    <Dialog
      disclosure={disclosure}
      handleConfirm={handleConfirm}
      isConfirmButtonDisabled={!selectedDmsOrderNumber}
      handleCancel={handleCancel}
      title={'Attach repair order to vehicle'}
      icon={DMSExportIcon}
      status={'info'}
      closable={false}
      content={
        <Flex direction={'column'} minWidth={600} gap={15}>
          <Text type={'h4_paragraph'}>Please select Repair Order</Text>
          <Flex direction={'column'} maxHeight={300} gap={15} overflowX={'scroll'}>
            {data?.orderNumbers.map((dmsOrderNumber) => (
              <Radio
                key={dmsOrderNumber}
                checked={selectedDmsOrderNumber === dmsOrderNumber}
                label={`Repair Order number: ${dmsOrderNumber}`}
                onChange={() => setSelectedDmsOrderNumber(dmsOrderNumber)}
              />
            ))}
          </Flex>
        </Flex>
      }
    />
  );
};

export default DmsOrderNumbersSelectionPopup;
