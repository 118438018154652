import React from 'react';
import styled from 'styled-components';
import { BreakpointSelectable, useBreakpointSelector } from 'utils/hooks/useBreakpoint';

const SFlex = styled.div<FlexProps>`
  display: flex;
  flex: ${({ size }) => size};
  flex-shrink: ${({ shrink }) => shrink};
  flex-direction: ${({ direction }) => direction};
  justify-content: ${({ justify }) => justify};
  align-items: ${({ align }) => align};
  align-self: ${({ alignSelf }) => alignSelf};
  gap: ${({ gap }) => (typeof gap === 'number' ? `${gap}px` : gap)};
  min-width: ${({ minWidth }) => (typeof minWidth === 'number' ? `${minWidth}px` : minWidth)};
  min-height: ${({ minHeight }) => (typeof minHeight === 'number' ? `${minHeight}px` : minHeight)};
  max-width: ${({ maxWidth }) => (typeof maxWidth === 'number' ? `${maxWidth}px` : maxWidth)};
  max-height: ${({ maxHeight }) => (typeof maxHeight === 'number' ? `${maxHeight}px` : maxHeight)};
  padding: ${({ padding }) => (typeof padding === 'number' ? `${padding}px` : padding)};
  flex-wrap: ${({ wrap }) => wrap};
  flex-basis: ${({ basis }) => (typeof basis === 'number' ? `${basis}px` : basis)};
  background-color: ${({ background }) => background};
  overflow-x: ${({ overflowX }) => overflowX};
  overflow-y: ${({ overflowY }) => overflowY};
`;

export const FLEX_START = 'flex-start';
export const FLEX_END = 'flex-end';
export const CENTER = 'center';
export const SPACE_BETWEEN = 'space-between';
export const SPACE_AROUND = 'space-around';
export const SPACE_EVENLY = 'space-evenly';

export type FlexDirectionType = 'row' | 'row-reverse' | 'column' | 'column-reverse' | 'initial' | 'inherit';
export type FlexJustifyType =
  | typeof FLEX_START
  | typeof FLEX_END
  | typeof CENTER
  | typeof SPACE_BETWEEN
  | typeof SPACE_AROUND
  | typeof SPACE_EVENLY;
export type FlexAlignItemsType = 'stretch' | 'center' | 'flex-start' | 'flex-end' | 'baseline' | 'initial' | 'inherit';
export type FlexAlignSelfType = 'auto' | 'center' | 'flex-start' | 'flex-end' | 'baseline' | 'stretch';
export type FlexWrapType = 'wrap' | 'nowrap' | 'initial' | 'inherit';
export type FlexOverflowType = 'visible' | 'hidden' | 'scroll' | 'inherit' | 'auto';

export interface FlexProps {
  id?: string;
  children?: React.ReactNode;
  className?: string;
  size?: BreakpointSelectable<string | number>;
  padding?: BreakpointSelectable<string | number>;
  shrink?: BreakpointSelectable<string | number>;
  direction?: BreakpointSelectable<FlexDirectionType>;
  justify?: BreakpointSelectable<FlexJustifyType>;
  align?: BreakpointSelectable<FlexAlignItemsType>;
  alignSelf?: BreakpointSelectable<FlexAlignSelfType>;
  gap?: BreakpointSelectable<string | number>;
  minWidth?: BreakpointSelectable<string | number>;
  minHeight?: BreakpointSelectable<string | number>;
  maxWidth?: BreakpointSelectable<string | number>;
  maxHeight?: BreakpointSelectable<string | number>;
  wrap?: BreakpointSelectable<FlexWrapType>;
  basis?: BreakpointSelectable<string | number>;
  overflowX?: FlexOverflowType;
  overflowY?: FlexOverflowType;
  background?: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onMouseEnter?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLDivElement>) => void;
  dataCy?: string;
}

// eslint-disable-next-line react/display-name
export const Flex = React.forwardRef<HTMLDivElement, FlexProps>(
  (
    {
      id,
      children,
      className,
      direction,
      size = 1,
      padding,
      justify,
      align,
      alignSelf,
      gap,
      shrink,
      minWidth,
      minHeight,
      maxWidth,
      maxHeight,
      wrap,
      basis,
      overflowX,
      overflowY,
      background,
      onClick,
      onMouseEnter,
      onMouseLeave,
      dataCy,
    },
    ref,
  ) => {
    const breakpointSelector = useBreakpointSelector();
    return (
      <SFlex
        id={id}
        className={className}
        size={breakpointSelector(size)}
        padding={breakpointSelector(padding)}
        shrink={breakpointSelector(shrink)}
        direction={breakpointSelector(direction)}
        justify={breakpointSelector(justify)}
        align={breakpointSelector(align)}
        alignSelf={breakpointSelector(alignSelf)}
        gap={gap}
        minWidth={breakpointSelector(minWidth)}
        minHeight={breakpointSelector(minHeight)}
        maxWidth={breakpointSelector(maxWidth)}
        maxHeight={breakpointSelector(maxHeight)}
        wrap={wrap}
        basis={basis}
        overflowX={overflowX}
        overflowY={overflowY}
        background={background}
        onClick={onClick}
        ref={ref}
        data-cy={dataCy}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {children}
      </SFlex>
    );
  },
);
