import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { TFunction } from 'i18next';
import { matchRoute, ROUTER_ORDERS } from 'app/AppRouter';
import { trackAppEvent } from 'app/AppTracker';
import { getShowFirstHelp } from 'domains/firstHelp/FirstHelp.store';
import { getDealerType } from 'domains/user';
import { InProgressOrders } from 'pages/MyOrdersPage/InProgressOrders/InProgressOrders';
import OrderClaims from 'pages/MyOrdersPage/OrderClaims';
import { Flex, MarginBox, Tab, TabItems, Tabs, Text } from 'UI';
import { getCondArrayItem } from 'utils';
import { TRACKING_EVENT_ARCHIVED_ORDERS, TRACKING_EVENT_EXTERNAL_ORDERS } from 'utils/eventTracker/EventTracker.types';
import { useResetScrollSharp } from 'utils/hooks/useResetScroll';
import { ArchivedOrders } from './ArchivedOrders/ArchivedOrders';
import { ExternalOrders } from './ExternalOrders/ExternalOrders';
import { ARCHIVED, CLAIMS, EXTERNAL, IN_PROGRESS, ORDERS_TAB } from './Orders.type';

const getTabItems = (t: TFunction, isR1: boolean): TabItems[] => {
  return [
    {
      key: IN_PROGRESS,
      label: t('order.order_detail.in_progress', 'In progress'),
      component: <InProgressOrders />,
    },
    {
      key: EXTERNAL,
      label: t('order.order_detail.external', 'External orders'),
      additionalAction: () => trackAppEvent(TRACKING_EVENT_EXTERNAL_ORDERS),
      component: <ExternalOrders />,
    },
    {
      key: ARCHIVED,
      label: t('order.order_detail.archived', 'Archived orders'),
      additionalAction: () => trackAppEvent(TRACKING_EVENT_ARCHIVED_ORDERS),
      component: <ArchivedOrders />,
    },
    ...getCondArrayItem(
      isR1 && {
        key: CLAIMS,
        label: t('order.order_detail.claims', 'Claims'),
        component: <OrderClaims />,
      },
    ),
  ];
};

const MyOrdersPage = (): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname } = useLocation();
  const isR1 = useSelector(getDealerType) === 'R1';
  const tabItems = getTabItems(t, isR1);
  const intialState = tabItems.find((item) => pathname.includes(item.key))?.key ?? IN_PROGRESS;
  const [tabState, setTabState] = useState<ORDERS_TAB>(intialState as ORDERS_TAB);
  const { showStreamId } = useSelector(getShowFirstHelp);

  const changeTabs = (activeKey: ORDERS_TAB) => {
    tabItems.find((item) => activeKey === item.key)?.additionalAction?.();
    setTabState(activeKey);
    if (history.location.pathname.startsWith(ROUTER_ORDERS)) {
      history.replace(`${ROUTER_ORDERS}/${activeKey}`);
    } else {
      history.push(`${ROUTER_ORDERS}/${activeKey}`);
    }
  };

  useEffect(() => {
    matchRoute(location.pathname, ROUTER_ORDERS, true) && changeTabs(IN_PROGRESS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  useEffect(() => {
    if (showStreamId) {
      setTabState(IN_PROGRESS);
    }
  }, [showStreamId]);

  useResetScrollSharp();

  return (
    <MarginBox mt={25}>
      <Flex direction={'column'}>
        <Text type={'h1_banner_light'} dataCy={'title-my-orders'}>
          {t('order.my_orders', 'My Orders')}
        </Text>
        <Tabs activeKey={tabState} onChange={changeTabs} tabBarGutter={15}>
          {tabItems?.map((item) => (
            <Tab tab={item.label} key={item.key}>
              {item.component}
            </Tab>
          ))}
        </Tabs>
      </Flex>
    </MarginBox>
  );
};

export default MyOrdersPage;
