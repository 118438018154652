/* eslint-disable max-len */
import React from 'react';
import { VehicleDetail } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { Steps } from 'antd';
import styled from 'styled-components';
import { SparePartsViewType } from 'domains/user';
import ReferenceCardCompact from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/ReferenceCardsContainer/ReferenceCard/ReferenceCardCompact';
import { Box } from 'UI';
import { LinkedReference } from './LinkedReference';

const AntStep = styled(Steps.Step)`
  .ant-steps-item-title {
    width: 100%;
    padding: 0;
  }
`;

interface LinkedReferencesProps {
  linkedReferences: string[];
  vehicleDetail: VehicleDetail | undefined;
  isApplicableToCurrentVehicle?: boolean;
  useCompactView?: boolean;
  handleAddToEstimateClick: () => void;
  sparePartsView: SparePartsViewType;
  handleAddToCartClick: () => void;
}

const LinkedReferences = ({
  linkedReferences,
  vehicleDetail,
  isApplicableToCurrentVehicle,
  useCompactView = false,
  handleAddToEstimateClick,
  sparePartsView,
  handleAddToCartClick,
}: LinkedReferencesProps) => {
  return (
    //TODO Old view is going to be removed and compact view is going to be used in both cases
    <>
      {useCompactView ? (
        <>
          {linkedReferences.map((referenceNumber) => (
            <ReferenceCardCompact
              key={referenceNumber}
              referenceNumber={referenceNumber}
              linkedReferences={[]}
              linkedReferencesActive={false}
              renderLinkedReferencesSwitch={() => undefined}
              handleAddToEstimateClick={handleAddToEstimateClick}
              isApplicableToCurrentVehicle={isApplicableToCurrentVehicle}
              sparePartsView={sparePartsView}
              handleAddToCartClick={handleAddToCartClick}
              isLinkedReference
            />
          ))}
        </>
      ) : (
        <>
          <Box width={164} />
          <Steps progressDot direction={'vertical'} current={-1}>
            {linkedReferences.map((referenceNumber) => (
              <AntStep
                key={referenceNumber}
                title={
                  <LinkedReference
                    referenceNumber={referenceNumber}
                    vehicleDetail={vehicleDetail}
                    isApplicableToCurrentVehicle={isApplicableToCurrentVehicle}
                  />
                }
              />
            ))}
          </Steps>
        </>
      )}
    </>
  );
};

export default LinkedReferences;
