import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'app/AppStore';
import { DataContainer } from 'components/DataContainer';
import { useFetchTextSearch } from 'domains/catalog/Catalog.requests';
import { getIAMFulltextSearchResult, getLastVehicleDetail, getSearchStatus } from 'domains/catalog/Catalog.store';
import { IAMFulltextSearchResult } from 'domains/catalog/Catalog.types';
import { getIAMReferences, getLoadedPricesMap } from 'domains/references';
import { useFetchFullTradingDatas } from 'domains/references/References.requests';
import SearchResultContainer from 'pages/CatalogPage/IAM/FullTextSearchResult/SearchResultContainer';
import { MarginBox } from 'UI';
import { getData, hasData, LOADING } from 'utils';
import { useResetScroll } from 'utils/hooks/useResetScroll';

const getGroupRefs = (group: IAMFulltextSearchResult) => {
  const mainRefs = getData(group.mainReferences) ?? [];
  const equiRefs = getData(group.equivalentReferences) ?? [];
  return { mainRefs, equiRefs };
};

const FullTextSearchResult = () => {
  const { query, label } = useParams<{
    query: string;
    label: string;
  }>();

  const searchResult = useSelector(getIAMFulltextSearchResult);
  const searchStatus = useSelector(getSearchStatus);
  const allReferences = useMemo(() => {
    return (
      searchResult?.reduce((a: string[], group) => {
        const { mainRefs, equiRefs } = getGroupRefs(group);
        return [...a, ...mainRefs, ...equiRefs];
      }, []) ?? []
    );
  }, [searchResult]);
  const references = useSelector((state: RootState) =>
    getIAMReferences(state, { vehicleKey: undefined, referenceNumbers: allReferences }),
  );
  const stockRequestDetails = useMemo(() => {
    return references.map((ref) => {
      return {
        referenceNumber: ref.referenceNumber,
        type: ref.type,
        quantity: 1,
        origin: ref.origin,
        supplierCode: ref.supplierCode,
        referenceSource: ref.referenceSource,
        isPrimaryStockRequest: false,
      };
    });
  }, [references]);

  const allRefsPrices = useSelector((state: RootState) => getLoadedPricesMap(state, allReferences));
  const vehicleDetail = useSelector(getLastVehicleDetail);

  useResetScroll();
  useFetchTextSearch(label, query, searchResult, searchStatus, vehicleDetail?.catalogSource);
  useFetchFullTradingDatas(allReferences, undefined, stockRequestDetails);

  const refPrice = (ref: string) => allRefsPrices.get(ref);
  const hasPrice = (ref: string): boolean => {
    return hasData(refPrice(ref));
  };

  const searchResultWithPrices = searchResult?.map((group) => {
    const { mainRefs, equiRefs } = getGroupRefs(group);
    const anyRefLoading = [...mainRefs, ...equiRefs].some((r) => {
      return refPrice(r) === LOADING;
    });
    return {
      ...group,
      mainReferences:
        equiRefs.some((equiRef) => hasPrice(equiRef)) && mainRefs.length === 1
          ? mainRefs
          : mainRefs.filter((mainRef) => hasPrice(mainRef)),
      equivalentReferences: equiRefs.filter((equiRef) => hasPrice(equiRef)),
      loading: anyRefLoading,
    };
  });

  const withPrices: IAMFulltextSearchResult[] = [];
  const withEqui: IAMFulltextSearchResult[] = [];
  const rest: IAMFulltextSearchResult[] = [];

  searchResultWithPrices?.forEach((group) => {
    const { mainRefs, equiRefs } = getGroupRefs(group);
    const groupReferences = [...mainRefs, ...equiRefs];
    const anyRefWithPrice = groupReferences.some((ref) => hasPrice(ref));
    if (equiRefs.length > 0) withEqui.push(group);
    else if (anyRefWithPrice) withPrices.push(group);
    else rest.push(group);
  });

  const sortedSearchResult = [...withEqui, ...withPrices, ...rest];

  return (
    <>
      <DataContainer data={searchStatus?.status}>
        <SearchResultContainer searchResult={sortedSearchResult} />
      </DataContainer>
      <MarginBox mt={500} />
    </>
  );
};

export default FullTextSearchResult;
