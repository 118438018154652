import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Type as ContactFormType } from '@1po/1po-bff-fe-spec/generated/email/request/SendEmailToAssistance';
import { CheckCircleIcon, TimesCircleIcon } from 'assets/icons';
import {
  emailToAssistanceInitialState,
  getEmailToAssistanceDataStatus,
  setEmailToAssistanceData,
} from 'domains/email/Email.store';
import { DEFAULT, SENT } from 'domains/email/Email.types';
import { theme } from 'styles';
import { BlackButton, Box, CenteredSpin, Flex, Icon, MarginBox, Text, WithLink } from 'UI';
import { ERROR, LOADING } from 'utils';
import { SFlex } from './ContactUs.styled';
import ContactUsForm from './ContactUsForm';

const AssistanceEmailNotification = ({ success = true }: { success: boolean }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const getIcon = () => (success ? CheckCircleIcon : TimesCircleIcon);

  const getIconColor = () => (success ? theme.color.grass_green : theme.color.error);

  return (
    <SFlex
      direction={'column'}
      align={'center'}
      justify={'center'}
      background={success ? theme.color.success_bg_light_120 : theme.color.pink_bright_light_120}
    >
      <MarginBox mt={45} />
      <Icon IconComponent={getIcon()} size={90} color={getIconColor()} />
      <MarginBox mt={45} />
      <Text type={'h1_banner_light'}>
        {success
          ? t('assistance.contact_us.send.success', 'Your message has been sent.')
          : t('assistance.contact_us.send.error', 'Your message has not been sent.')}
      </Text>
      <Text type={'lead'}>
        {success
          ? t(
              'assistance.contact_us.send.description',
              'Our team will make sure to get back to you as soon as possible.',
            )
          : t('assistance.contact_us.send.error.description', 'Please, try again later.')}
      </Text>
      <MarginBox mt={45} />
      <WithLink to={'/'}>
        <BlackButton onClick={() => dispatch(setEmailToAssistanceData(emailToAssistanceInitialState))}>
          {t('common.back_home', 'Go back Home')}
        </BlackButton>
      </WithLink>
      <MarginBox mt={45} />
    </SFlex>
  );
};

const ContactUs = ({ title, type }: { title: ReactNode | undefined; type: ContactFormType }) => {
  const { t } = useTranslation();
  const emailToAssistanceStatus = useSelector(getEmailToAssistanceDataStatus);
  const renderContactUsItem = () => {
    switch (emailToAssistanceStatus) {
      case LOADING:
        return (
          <Box width={950} height={440}>
            <CenteredSpin />
          </Box>
        );
      case ERROR:
        return <AssistanceEmailNotification success={false} />;
      case SENT:
        return <AssistanceEmailNotification success={true} />;
      default:
        return <ContactUsForm type={type} />;
    }
  };
  return (
    <Flex direction={'column'}>
      <Text type={'h1_banner_light'} id={'assistance-contact_us'}>
        {title}
      </Text>
      <MarginBox mt={30} />
      {type === DEFAULT && (
        <>
          <Text type={'h3'}>
            {t(
              'assistance.contact_us.header.description',
              'Get in touch with our team, we will try to get back to you as soon as possible.',
            )}
          </Text>
          <MarginBox mt={8} />
          <Text type={'light_12_black_45'}>
            {t('assistance.contact_us.detail', 'Fields marked with an asterisk are required.')}
          </Text>
        </>
      )}
      <MarginBox mt={45} />
      {renderContactUsItem()}
      <MarginBox mt={30} />
    </Flex>
  );
};

export default ContactUs;
