import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HistoryEstimate } from '@1po/1po-bff-fe-spec/generated/estimate/response/GetEstimateHistory';
import { ROUTER_ESTIMATE } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { useIsB2B } from 'components/B2BComponents/useIsB2B';
import { getLastVehicleDetail } from 'domains/catalog/Catalog.store';
import { EstimateIdParam, EstimateTabName, EstimateTabParam } from 'domains/estimate/Estimate.types';
import { getTradingProfile, getUserRights, UserRole } from 'domains/user';
import { InfiniteScrollable } from 'UI';
import { getData, hasData, hasUserAnyRight, LOADING, NOT_FOUND } from 'utils';
import {
  fetchEstimateByIdRequest,
  fetchEstimateHistoryRequest,
  fetchLatestEstimateRequest,
  fetchSettingsRequest,
  getCurrentEstimateId,
  getEstimateById,
  getEstimateHistory,
  getEstimateHistoryHasMore,
  getEstimateHistorySearchStatus,
  getEstimateSequenceNumber,
  getEstimateSettings,
  getEstimateVehicleKey,
} from './Estimate.store';

export function useFetchLastVehicleEstimate() {
  const dispatch = useDispatch();
  const estimateId = useSelector(getCurrentEstimateId);
  const catalogVehicleKey = useSelector(getLastVehicleDetail)?.vehicleKey;
  const estimateVehicleKey = useSelector((state: RootState) => getEstimateVehicleKey(state, estimateId));
  const estimateSequenceNumber = useSelector((state: RootState) => getEstimateSequenceNumber(state, estimateId));
  const userRights = getData(useSelector(getUserRights));
  const isB2B = useIsB2B();

  useEffect(() => {
    if (isB2B) {
      return;
    }
    if (!hasUserAnyRight([UserRole.COMMAND, UserRole.CONNECT_COMMANDE], userRights)) {
      return;
    }
    if (
      catalogVehicleKey !== undefined &&
      (estimateSequenceNumber === undefined ||
        (estimateVehicleKey !== undefined && catalogVehicleKey !== estimateVehicleKey))
    ) {
      const params = new URLSearchParams(location.search);
      if (
        !(
          location.pathname.includes(ROUTER_ESTIMATE) &&
          params.get(EstimateTabParam) === EstimateTabName &&
          params.get(EstimateIdParam)
        )
      ) {
        dispatch(fetchLatestEstimateRequest());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, catalogVehicleKey, estimateVehicleKey]);
}

export function useFetchEstimateSettingsSubscription() {
  const dispatch = useDispatch();
  const settings = hasData(useSelector(getEstimateSettings));
  const garageId = useSelector(getTradingProfile)?.buyerId;
  const userRights = getData(useSelector(getUserRights));

  useEffect(() => {
    if (!hasUserAnyRight([UserRole.COMMAND, UserRole.CONNECT_COMMANDE], userRights)) {
      return;
    }
    if (!settings && garageId) {
      dispatch(fetchSettingsRequest());
    }
  }, [dispatch, settings, garageId, userRights]);
}

export const useFetchEstimate = (estimateId: string) => {
  const dispatch = useDispatch();
  const estimate = useSelector((state: RootState) => getEstimateById(state, estimateId));

  useEffect(() => {
    if (estimate === undefined) {
      dispatch(fetchEstimateByIdRequest(estimateId));
    }
  }, [dispatch, estimate, estimateId]);
};

export const useFetchEstimateHistory = (): InfiniteScrollable<HistoryEstimate> => {
  const dispatch = useDispatch();
  const estimateHistory = useSelector(getEstimateHistory);
  const hasMore = useSelector(getEstimateHistoryHasMore);
  const searchStatus = useSelector(getEstimateHistorySearchStatus);

  function onLoadMore() {
    if (searchStatus === NOT_FOUND) {
      return;
    }
    if (searchStatus !== LOADING) {
      dispatch(fetchEstimateHistoryRequest());
    }
  }

  return { loadMore: onLoadMore, hasMore, currentData: estimateHistory };
};
