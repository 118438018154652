import { TextFacetItem } from '@1po/1po-bff-fe-spec/generated/catalog/references/iam/response/GetIAMReferencesResponse';

type SortDirection = 'asc' | 'desc';
type Matcher = string | number;
type PathKey<T> = keyof T & (string | number | symbol);
type Path<T> = string | PathKey<T>;

type PathValue<T, P extends string> = P extends keyof T
  ? T[P]
  : P extends `${infer K}.${infer R}`
  ? K extends keyof T
    ? PathValue<T[K], R>
    : never
  : never;

export class FilterHelper {
  static updateTextFilter(id: string, item: string, prevFilters: Map<string, string[]>): Map<string, string[]> {
    const newFilters = new Map(prevFilters);
    const currentItems = newFilters.get(id) || [];
    const itemIndex = currentItems.indexOf(item);

    if (itemIndex === -1) {
      newFilters.set(id, [...currentItems, item]);
    } else {
      newFilters.set(
        id,
        currentItems.filter((i) => i !== item),
      );
    }

    return newFilters;
  }

  static getValueByPath<T, P extends string>(obj: T, path: P): PathValue<T, P> | PathValue<T, P>[] {
    return path.split('.').reduce((current: unknown, part: string) => {
      if (Array.isArray(current)) {
        return current.map((item) => (item as Record<string, unknown>)[part]);
      }
      return (current as Record<string, unknown>)?.[part];
    }, obj) as PathValue<T, P> | PathValue<T, P>[];
  }

  static calculateFrequencies<T>(
    inputData: (T | undefined)[] | T | undefined,
    path?: Path<T>,
    sortBy: 'label' | 'count' = 'count',
    direction: SortDirection = 'desc',
  ): TextFacetItem[] {
    if (inputData === undefined) {
      return [];
    }
    const data = Array.isArray(inputData) ? inputData : [inputData];

    const frequencies = data.reduce<Record<string, number>>((acc, item) => {
      if (!item) return acc;

      let values: unknown[] = [];
      if (path) {
        const pathStr = String(path);
        const result = FilterHelper.getValueByPath(item, pathStr);
        values = Array.isArray(result) ? result.flat() : [result];
      } else {
        values = [item];
      }

      values.forEach((value) => {
        if (value != null) {
          const strValue = String(value);
          acc[strValue] = (acc[strValue] || 0) + 1;
        }
      });

      return acc;
    }, {});

    return Object.entries(frequencies)
      .map(([value, count]) => ({
        label: value,
        numberOfItems: count,
      }))
      .sort((a, b) => {
        if (sortBy === 'label') {
          return direction === 'asc' ? a.label.localeCompare(b.label) : b.label.localeCompare(a.label);
        }
        return direction === 'asc' ? a.numberOfItems - b.numberOfItems : b.numberOfItems - a.numberOfItems;
      });
  }

  static filterData<T>(
    inputData: (T | undefined)[] | T | undefined,
    path: Path<T>,
    matchers: Matcher[] | undefined,
  ): T[] {
    if (inputData === undefined) {
      return [];
    }
    const data = Array.isArray(inputData) ? inputData : [inputData];

    if (!data || !matchers?.length || matchers.length === 0) return data.filter((i): i is T => i !== undefined);

    return data.filter((item): item is T => {
      if (!item) return false;

      const pathStr = String(path);
      const values = FilterHelper.getValueByPath(item, pathStr);
      const flatValues = Array.isArray(values) ? values.flat() : [values];

      return flatValues.some((value) => matchers.some((matcher) => String(value) === String(matcher)));
    });
  }
}
