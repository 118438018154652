import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserGarageInfo } from 'domains/garage/Garage.store';
import { getAvailableDMSServices, getAvailableDMSServicesRequestSaga, getDealerType } from 'domains/user';
import { getData, LOADING } from 'utils';

export function useFetchAvailableDMSServices() {
  const dispatch = useDispatch();
  const availableServices = useSelector(getAvailableDMSServices);
  const dealerType = useSelector(getDealerType);
  const garageInfo = useSelector(getUserGarageInfo);
  const workshopId = getData(garageInfo)?.workshopId;
  const tresorCode = getData(garageInfo)?.tresorCode;

  useEffect(() => {
    if (!availableServices && availableServices !== LOADING && dealerType === 'R3_MOTRIO' && tresorCode && workshopId) {
      dispatch(getAvailableDMSServicesRequestSaga({ workshopId }));
    }
  }, [dispatch, availableServices, tresorCode, dealerType, workshopId]);
}
