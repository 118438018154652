import React, { PropsWithChildren, ReactNode } from 'react';
import { StyleSheet, Text as PdfText, View } from '@react-pdf/renderer';
import { components, fonts, styles } from './PdfUI.styles';

type CardHeightTypes = 'lg' | 'md' | number;
type PdfStyle = ReturnType<typeof StyleSheet.create>[string];

export const Column = ({ children, width }: PropsWithChildren<{ width?: number }>) => (
  <View style={width ? [styles.col, styles.column_fixed, { minWidth: width }] : [styles.col, styles.column]}>
    {children}
  </View>
);
export const Flex = ({ children }: PropsWithChildren) => <View>{children}</View>;

interface TextProps extends PropsWithChildren {
  style?: PdfStyle | PdfStyle[];
}

export const Text = ({ children, style }: TextProps) => (
  <PdfText style={[fonts.text, ...(Array.isArray(style) ? style : style ? [style] : [])]}>{children}</PdfText>
);
export const TextDark = ({ children }: PropsWithChildren) => <PdfText style={fonts.dark_text}>{children}</PdfText>;
export const TextBold = ({ children }: PropsWithChildren) => (
  <PdfText style={[fonts.text, fonts.bold]}>{children}</PdfText>
);
export const TextMid = ({ children }: PropsWithChildren) => <PdfText style={fonts.textMid}>{children}</PdfText>;
export const TextMidLinethrough = ({ children }: PropsWithChildren) => (
  <PdfText style={[fonts.textMid, { textDecoration: 'line-through' }]}>{children}</PdfText>
);
export const TextMidBold = ({ children }: PropsWithChildren) => (
  <PdfText style={[fonts.textMid, fonts.bold]}>{children}</PdfText>
);
export const ItemNameTitle = ({ children }: PropsWithChildren) => (
  <PdfText style={fonts.itemNameTitle}>{children}</PdfText>
);
export const Title = ({ children }: PropsWithChildren) => <PdfText style={fonts.h4}>{children}</PdfText>;
export const Subtitle = ({ children }: PropsWithChildren) => <PdfText style={fonts.h5}>{children}</PdfText>;
export const Section = ({ children }: PropsWithChildren) => <PdfText style={fonts.h6}>{children}</PdfText>;
export const LeadText = ({ children }: PropsWithChildren) => <PdfText style={fonts.lead_text}>{children}</PdfText>;
export const LeadTextBold = ({ children }: PropsWithChildren) => (
  <PdfText style={[fonts.lead_text, fonts.bold]}>{children}</PdfText>
);
export const Headline = ({ children }: PropsWithChildren) => <PdfText style={fonts.tableHeadline}>{children}</PdfText>;

export const Status = ({ children, color }: PropsWithChildren<{ color: string }>) => (
  <View style={[styles.col]}>
    <PdfText style={[fonts.text, { color: `${color}` }]}>{children}</PdfText>
  </View>
);
export const StatusLabel = ({ children }: PropsWithChildren) => (
  <View style={[styles.col]}>
    <PdfText style={[fonts.text]}>{children}</PdfText>
  </View>
);

export const HeadText = ({ children }: PropsWithChildren) => (
  <PdfText style={[fonts.headBold, { textAlign: 'right' }]}>{children}</PdfText>
);

export const Input = ({ children }: PropsWithChildren) => (
  <View style={[components.input_container]}>
    <View style={[components.input_inner, components.border_grey]}>
      <Text style={{ alignSelf: 'center', width: 100, marginLeft: 5, marginRight: 5 }}>{children}</Text>
    </View>
  </View>
);

export const Checkbox = ({ children }: PropsWithChildren) => (
  <View style={[components.input_container]}>
    <View style={[components.input_inner, components.print_checkbox]}>
      <Text>{children}</Text>
    </View>
  </View>
);

function getCardHeight(height: CardHeightTypes): { height: number } {
  switch (height) {
    case 'lg':
      return { height: 150 };
    case 'md':
      return { height: 92 };
    default:
      return { height };
  }
}

export const Card = ({
  children,
  title,
  height = 'md',
  width,
  alignTitleStart,
}: PropsWithChildren<{
  title: string | ReactNode;
  height: CardHeightTypes;
  width: number;
  alignTitleStart?: boolean;
}>) => (
  <View style={[components.card, getCardHeight(height), { width }]} wrap={false}>
    <View style={[styles.col, { width: '100%', height: '100%' }]}>
      <View style={[alignTitleStart ? components.card_title_start : components.card_title_center]}>
        <Section>{title}</Section>
      </View>
      <View style={[styles.col, { width: '100%', height: '100%' }, components.border_grey]}>
        <View style={[styles.flex]}>{children}</View>
      </View>
    </View>
  </View>
);

export const IconCard = ({
  children,
  title,
  height = 'md',
  width,
  icon,
}: PropsWithChildren<{
  title: string | ReactNode;
  height?: CardHeightTypes;
  width: number;
  icon?: ReactNode;
}>) => (
  <View style={[components.card2, getCardHeight(height), { width }]} wrap={false}>
    <View style={[styles.col, { width: '100%', height: '100%' }]}>
      <View style={[components.card2_title]}>
        {icon}
        <Section>{title}</Section>
      </View>
      <View style={[styles.col, { width: '100%', height: '100%' }, components.border_grey]}>
        <View style={[styles.flex]}>{children}</View>
      </View>
    </View>
  </View>
);

export const BorderlessCard = ({
  children,
  title,
  height,
  width,
  alignTitleStart,
}: PropsWithChildren<{
  title: string | ReactNode;
  height: CardHeightTypes;
  width: number | string;
  alignTitleStart?: boolean;
}>) => (
  <View style={[components.card2, getCardHeight(height), { width }]} wrap={false}>
    <View style={[styles.col, { width: '100%', height: '100%' }]}>
      <View style={[alignTitleStart ? components.card_title_start : components.card_title_center]}>
        <ItemNameTitle>{title}</ItemNameTitle>
      </View>
      <View style={[styles.col, { width: '100%', height: '100%' }]}>
        <View style={[styles.flex]}>{children}</View>
      </View>
    </View>
  </View>
);

export const NoteBox = ({
  children,
  title,
  height,
  width,
}: PropsWithChildren<{ title: string | ReactNode; height?: 'lg' | 'md'; width: number }>) => (
  <View style={[components.card, height === 'lg' ? { height: 132 } : { height: 92 }, { width }]} wrap={false}>
    <View style={[styles.col, { width: '100%', height: '100%' }]}>
      <View style={[components.note_card_title]}>
        <Section>{title}</Section>
      </View>
      <View style={[styles.col, { width: '100%', height: '100%' }, components.border_grey]}>
        <View style={[styles.flex]}>{children}</View>
      </View>
    </View>
  </View>
);

export const Divider = () => <View style={components.divider} />;
export const WideDivider = () => <View style={[components.divider, { height: 20, marginTop: 15, marginBottom: 10 }]} />;

export function Table<T>({
  data,
  columns,
  rowscount,
  alignTitle = false,
}: {
  data: T[];
  columns: {
    title: string | ReactNode;
    render: (item: T) => ReactNode;
    width?: number;
    renderNode?: (item: T) => ReactNode;
  }[];
  //how many rows after repeat header - rowscount[0] for first page and after rowscount[1] rows on next pages
  rowscount?: number[];
  alignTitle?: boolean;
}) {
  function getHeader(spacer: boolean) {
    return (
      <>
        {spacer && <Spacer height={30} />}
        <View style={[styles.row, { maxWidth: 555 }]}>
          {columns.map((column, columnIndex) => (
            <Column key={`header-col-${columnIndex}`} width={column.width}>
              <View
                style={column.width ? [components.header_cell_fixed] : [components.header_cell]}
                key={`column-${columnIndex}`}
              >
                <View style={styles.row}>
                  <Section>{column.title}</Section>
                </View>
              </View>
            </Column>
          ))}
        </View>
      </>
    );
  }

  const defaultHeaderAtBeginning = () => !rowscount && getHeader(false);
  const handleFirstPage = (index: number) => {
    return (
      <>
        {rowscount && rowscount.length === 1 && index % rowscount[0] === 0 && getHeader(false)}
        {rowscount && rowscount.length === 2 && index === 0 && getHeader(false)}
      </>
    );
  };

  const handleNextPages = (index: number) => {
    return (
      <>
        {rowscount &&
          rowscount.length === 2 &&
          (index === rowscount[0] || (index > rowscount[0] && (index - rowscount[0]) % rowscount[1] === 0)) && (
            <Spacer />
          ) &&
          getHeader(true)}
      </>
    );
  };

  const tableHeader = (index: number) => {
    return (
      <>
        {handleFirstPage(index)}
        {handleNextPages(index)}
      </>
    );
  };

  return (
    <View style={styles.col}>
      {defaultHeaderAtBeginning()}
      {data.map((item, index) => (
        <View key={`col-row-${index}`}>
          {tableHeader(index)}
          <View style={[styles.col]}>
            <View style={[styles.row, { maxWidth: 555 }]}>
              {columns.map((column, columnIndex) => {
                const isAlignTitle = alignTitle && columnIndex === 0;
                return (
                  <Column key={`col-item-${columnIndex}`} width={column.width}>
                    <View wrap={false}>
                      <View
                        style={
                          column.width
                            ? [
                                components.cell,
                                components.cell_fixed,
                                {
                                  maxWidth: column.width,
                                },
                                isAlignTitle ? components.cell_left_fixed_padding : {},
                              ]
                            : [
                                components.cell,
                                components.cell_flex,
                                isAlignTitle ? components.cell_left_flex_padding : {},
                              ]
                        }
                        key={`column-${columnIndex}`}
                      >
                        <Text>{column.render(item)}</Text>
                        {column.renderNode && column.renderNode(item)}
                      </View>
                    </View>
                  </Column>
                );
              })}
            </View>
            <Divider />
          </View>
        </View>
      ))}
    </View>
  );
}

export function SpecialTable<T>({
  data,
  columns,
  rowscount,
}: {
  data: T[];
  columns: {
    title: string | ReactNode;
    render: (item: T) => ReactNode;
    width?: number;
    renderNode?: (item: T) => ReactNode;
  }[];
  //how many rows after repeat header - rowscount[0] for first page and after rowscount[1] rows on next pages
  rowscount?: number[];
}) {
  function getHeader(spacer: boolean) {
    return (
      <>
        {spacer && <View style={{ height: 30 }} />}
        <View style={[styles.row, { maxWidth: 555, height: 30 }]}>
          {columns.map((column, columnIndex) => (
            <Column key={`header-col-${columnIndex}`} width={column.width}>
              <View
                style={column.width ? [components.header_cell_fixed_left_white] : [components.header_cell_left_white]}
                key={`column-${columnIndex}`}
              >
                <View style={[styles.row, { height: 30, alignContent: 'center' }]}>
                  <Headline>{column.title}</Headline>
                </View>
              </View>
            </Column>
          ))}
        </View>
      </>
    );
  }

  const defaultHeaderAtBeginning = () => !rowscount && getHeader(true);
  const handleFirstPage = (index: number) => {
    return (
      <>
        {rowscount && rowscount.length === 1 && index % rowscount[0] === 0 && getHeader(false)}
        {rowscount && rowscount.length === 2 && index === 0 && getHeader(false)}
      </>
    );
  };

  const handleNextPages = (index: number) => {
    return (
      <>
        {rowscount &&
          (index === rowscount[0] || (index > rowscount[0] && (index - rowscount[0]) % rowscount[1] === 0)) && (
            <Spacer />
          ) &&
          getHeader(true)}
      </>
    );
  };

  const tableHeader = (index: number) => {
    return (
      <>
        {handleFirstPage(index)}
        {handleNextPages(index)}
      </>
    );
  };

  return (
    <View style={styles.col} wrap={data.length > 10}>
      {defaultHeaderAtBeginning()}
      {data.map((item, index) => (
        <View key={`col-row-${index}`}>
          {tableHeader(index)}
          <View wrap={false} style={[styles.row, { maxWidth: 555 }]}>
            {columns.map((column, columnIndex) => (
              <Column key={`col-item-${columnIndex}`} width={column.width}>
                <View
                  style={
                    column.width
                      ? [
                          components.cell_left,
                          components.cell_left_fixed,
                          { maxWidth: column.width },
                          index % 2 === 1 ? components.white_cell : components.dark_cell,
                        ]
                      : [
                          components.cell_left,
                          components.cell_left_flex,
                          index % 2 === 1 ? components.white_cell : components.dark_cell,
                        ]
                  }
                  key={`column-${columnIndex}`}
                >
                  <View style={{ height: 30 }}>
                    <Text>{column.render(item)}</Text>
                  </View>
                  {column.renderNode && column.renderNode(item)}
                </View>
                <Divider />
              </Column>
            ))}
          </View>
        </View>
      ))}
    </View>
  );
}

export const Spacer = ({ height }: { height?: number }) => <View style={{ height: height ?? 10 }} />;
export const VerticalSpacer = ({ width }: { width?: number }) => (
  <View style={{ width: width ?? 10 }}>
    <PdfText>{'  '}</PdfText>
  </View>
);
export const NewLineSplit = () => <PdfText>{'\n'}</PdfText>;

export const PageNumber = () => (
  <PdfText
    fixed
    style={[
      fonts.text,
      {
        position: 'absolute',
        fontSize: 12,
        marginTop: 20,
        marginBottom: 0,
        bottom: 20,
        left: 0,
        right: 20,
        textAlign: 'right',
        color: 'grey',
      },
    ]}
    render={({ pageNumber, totalPages }) => `Page ${pageNumber}/${totalPages}`}
  />
);

export const Layout2Columns = ({ leftSide, rightSide }: { leftSide: ReactNode; rightSide: ReactNode }) => {
  return (
    <View style={styles.row}>
      <View style={styles.column}>
        <View style={styles.col}>{leftSide}</View>
      </View>
      <View style={[styles.col]}>{rightSide}</View>
    </View>
  );
};

export const Layout3Columns = ({ left, middle, right }: { left: ReactNode; middle: ReactNode; right: ReactNode }) => {
  return (
    <View style={styles.row}>
      <View style={styles.col}>{left}</View>
      <View style={[styles.col]}>{middle}</View>
      <View style={[styles.col]}>{right}</View>
    </View>
  );
};

export const RowItem = ({ children }: PropsWithChildren) => {
  return (
    <View style={styles.row}>
      <View style={styles.column}>
        <View style={styles.col}>{children}</View>
      </View>
    </View>
  );
};
