/* eslint-disable max-len */
import { FlashPromotionBanner } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/model/FlashPromotionBanner';
import { FullPromotion } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/model/FullPromotion';
import {
  DiscountType,
  Image,
  Promotion,
  PromotionType,
} from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/model/Promotion';
import { PromotionBanner } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/model/PromotionBanner';
import { DashboardPromotion } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/response/DashboardPromotionsResponse';
import { DealerType } from '@1po/1po-bff-fe-spec/generated/user/model/CommercialLink';
import { APP_NAMESPACE } from 'app/App.constants';
import { ModalStatusType } from 'components/FileUpload/paramsByState';
import { NO_DATA, SEARCH_STATUS } from 'utils';

export const PROMOTION_NAMESPACE = `${APP_NAMESPACE}/PROMOTION`;
export const SAVE_PROMOTION_REQUEST = `${PROMOTION_NAMESPACE}/SAVE_PROMOTION_REQUEST`;
export const ADJUST_PROMOTION_BY_R1_REQUEST = `${PROMOTION_NAMESPACE}/ADJUST_PROMOTION_BY_R1`;
export const ARCHIVE_PROMOTION_REQUEST = `${PROMOTION_NAMESPACE}/ARCHIVE_PROMOTION_REQUEST`;
export const DELETE_PROMOTION_REQUEST = `${PROMOTION_NAMESPACE}/DELETE_PROMOTION_REQUEST`;
export const GET_PROMOTION_REQUEST = `${PROMOTION_NAMESPACE}/GET_PROMOTION_REQUEST`;
export const GET_CURRENT_PROMOTIONS_REQUEST = `${PROMOTION_NAMESPACE}/GET_PROMOTIONS_REQUEST`;
export const SUBSCRIBE_CURRENT_PROMOTIONS_REQUEST = `${PROMOTION_NAMESPACE}/SUBSCRIBE_CURRENT_PROMOTIONS_REQUEST`;
export const GET_FULL_PROMOTION_REQUEST = `${PROMOTION_NAMESPACE}/GET_FULL_PROMOTION_REQUEST`;

export const GET_DASHBOARD_PROMOTIONS_REQUEST = `${PROMOTION_NAMESPACE}/GET_DASHBOARD_PROMOTIONS_REQUEST`;
export const GET_PROMOTION_BANNERS_REQUEST = `${PROMOTION_NAMESPACE}/GET_PROMOTION_BANNERS_REQUEST`;
export const UPLOAD_REFERENCES_FILE = `${PROMOTION_NAMESPACE}/UPLOAD_REFERENCES_FILE`;
export const VALIDATE_PRODUCTS = `${PROMOTION_NAMESPACE}/VALIDATE_PRODUCTS`;
export const GET_NOTIFICATION_NUMBER = `${PROMOTION_NAMESPACE}/GET_NUMBER_OF_NOT_YET_ADJUSTED_PROMOTIONS`;
export const GET_FAMILY_CODES_REQUEST = `${PROMOTION_NAMESPACE}/GET_FAMILY_CODES_REQUEST`;

export const UPLOAD_TARGETED_AUDIENCE_FILE = `${PROMOTION_NAMESPACE}/UPLOAD_TARGETED_AUDIENCE_FILE`;

export const PromotionTabParam = 'tab';
export const PromotionTabFilterParam = 'addActiveFilter';
export const PromotionAdditionalDiscountPreviewParam = 'additional_discount';
export const PromotionTabPublished = 'published';
export const PromotionTabPlanned = 'planned';
export const PromotionTabSaved = 'saved';
export const PromotionTabArchived = 'archived';

export type PROMOTION_TAB =
  | typeof PromotionTabPublished
  | typeof PromotionTabPlanned
  | typeof PromotionTabSaved
  | typeof PromotionTabArchived;

export type PromotionTypeLocal = PromotionType | 'PROMOTION_FLASH';

export function isFlashPromotion<T>(value: T | PromotionTypeLocal): value is T {
  return (
    value === 'PROMOTION_FLASH' ||
    value === 'PROMOTION_FLASH_TIME_LIMITED' ||
    value === 'PROMOTION_FLASH_QUANTITY_LIMITED'
  );
}

export interface PromotionState {
  createPromotion: CreatePromotionModel;
  duplicatePromotions: Promotion[];
  publishedResponseMap: Map<string, boolean | NO_DATA>;
  currentPromotionDetails: {
    data: Map<string, Promotion | NO_DATA>;
    cursor: string | undefined;
    hasNextPage: boolean;
    status: SEARCH_STATUS;
  };
  fullPromotions: Map<string, FullPromotion | NO_DATA>;
  flashPromotionBanners: FlashPromotionBanner[] | NO_DATA;
  promotionBanners: PromotionBannerCarousel[] | NO_DATA;
  loadedPromotions: {
    published: LoadedPromotions;
    planned: LoadedPromotions;
    saved: LoadedPromotions;
    archived: LoadedPromotions;
  };
  notification: PromotionNotification;
  familyCodes: {
    familyCodes: FamilyCodesItem[];
    familyCodesLanguage: string;
  };
}

export interface PromotionNotification {
  showNotification: boolean;
  unadjustedPromoCount: number | undefined;
}

export interface LoadedPromotions {
  // ToDo - remove unnecessary data
  data: DashboardPromotion[];
  cursor: string | undefined;
  hasNextPage: boolean;
  status: SEARCH_STATUS;
}

export interface PromotionBannerCarousel extends PromotionBanner {
  showInCarousel: boolean;
}

export interface UploadedFromFile {
  rows?: string[];
  alreadyPromoted?: string[];
  alreadyLocallyPromoted?: string[];
  status: ModalStatusType;
  uploadTime?: string;
  fileName?: string;
  fileSize?: number;
  fileId?: string;
}

export interface UploadedReferencesFromFile extends UploadedFromFile {
  discount: number;
  editing?: boolean;
}

export interface AdditionalInformation {
  containsAdditionalTitle: boolean;
  containsAdditionalCatchLine: boolean;
  containsAdditionalDescription: boolean;
  containsAdditionalImage: boolean;
  title?: string;
  catchLine?: string;
  description?: string;
  image?: Image;
  discount?: number;
}

export interface CreatePromotionModel {
  promotionId: string;
  promotionType: PromotionTypeLocal;
  isSaved: boolean;
  editing: boolean;
  containsCustomImage: boolean;
  discountType: DiscountType | undefined;
  targetAudienceType: TargetAudienceType;
  targetAudience: TargetAudienceLocal;
  uploadedTargetedAudienceFromFile: UploadedFromFile;
  uploadedReferencesFromFiles: UploadedReferencesFromFile[];
  additionalInformation: AdditionalInformation;
  duplicatePromotionIds: string[];
  title?: string;
  catchLine?: string;
  description?: string;
  publicationStart?: string;
  publicationEnd?: string;
  image?: Image;
  references?: ReferencesSectionLocal[];
  quantityLimitFlashPromotion?: number;
  commercialFamilyCodes?: CFSectionLocal[];
  referenceFileInfos?: UploadedReferencesFileInfo[];
  copyPromotion?: boolean;
  isUpdated?: boolean;
}

export interface UploadedReferencesFileInfo {
  fileId: string;
  fileName: string;
  fileSize: number;
  created: string;
}

export interface PromotionItem {
  item: string;
  removable: boolean;
  alreadyPromoted?: boolean;
  alreadyLocallyPromoted?: boolean;
}

export interface FamilyCodesItem {
  designation: string;
  segment: string;
  segmentLabel: string;
  familyCode: string;
  familyLabel: string;
  promoCode: number;
}

export interface CFSectionLocal {
  items: PromotionItem[];
  discount: number;
  editing?: boolean;
}

export interface ReferencesSectionLocal {
  items: PromotionItem[];
  discount: number;
}

export type TargetAudienceType = 'NETWORK' | 'DEALERS';

export interface TargetAudienceLocal {
  dealerTypes?: DealerTypeLocal[];
  dealers?: string[];
}

export interface DealerTypeLocal extends PromotionItem {
  dealerType: DealerType;
}

export interface CreatePromotionStepProps {
  promotion: CreatePromotionModel;
  setPromotion: (x: CreatePromotionModel) => void;
  currentStep: number;
  setCurrentStep: (x: number) => void;
  totalSteps: number;
  validateStep: () => boolean;
}

export type PromotionFilterTypeOption = 'ALL' | 'DISCOUNT' | 'PROMOTION_FLASH' | 'BANNER';

export interface DuplicateReferenceAdjustment {
  promoted: string[];
  rows: string[];
}
