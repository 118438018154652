import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CatalogSource } from '@1po/1po-bff-fe-spec/generated/common/vehicle/CatalogSource';
import { getLastVehicleDetail } from 'domains/catalog/Catalog.store';
import {
  getMaintenanceValuesXml,
  setMaintenanceValuesXml,
  setMaintenanceValuesXmlStatus,
} from 'domains/maintenanceValues/MaintenanceValues.store';
import { useFetchSignature } from 'domains/pictures/Pictures.requests';
import { FOUND, LOADING, NOT_FOUND } from 'utils';

export const useFetchMaintenanceValuesXml = (xmlPath: string | undefined, catalogSource: CatalogSource) => {
  const dispatch = useDispatch();
  const signature = useFetchSignature(catalogSource);
  const xmlSelector = useSelector(getMaintenanceValuesXml);
  const xml = xmlSelector?.data;
  const urlSign = signature ? `?${signature}` : '';
  const vehicleDetail = useSelector(getLastVehicleDetail);
  const vehicleImage = vehicleDetail?.imageUrl;
  const url = new URL(vehicleImage ?? '');
  const preUrl = url.protocol + '//' + url.hostname + '/';

  useEffect(() => {
    if (xmlPath && signature && !xml) {
      dispatch(setMaintenanceValuesXmlStatus(LOADING));
      fetch(`${preUrl}${xmlPath}${urlSign}`)
        .then((response) => {
          if (response.status >= 400 && response.status <= 499) {
            throw new Error(`Unable to get data.`);
          }
          return response.text();
        })
        .then((xml) => {
          dispatch(setMaintenanceValuesXml(xml));
          dispatch(setMaintenanceValuesXmlStatus(FOUND));
        })
        .catch(() => {
          dispatch(setMaintenanceValuesXmlStatus(NOT_FOUND));
        });
    }
  }, [dispatch, xml, xmlPath, signature, urlSign, preUrl]);

  return xmlSelector;
};

// example input ../MergedPictures/ILL-000448416.PNG
// example output https://cdn.../SIE/MR/Illustrations/ILL-000448416.PNG
export const useFetchIllustrationsFullPath = (contentPath: string) => {
  //https://cdn.....com/SIE/MR/Illustrations/ILL-000440451.PNG
  const vehicleDetail = useSelector(getLastVehicleDetail);
  const vehicleImage = vehicleDetail?.imageUrl;
  const url = new URL(vehicleImage ?? '');
  const paths = contentPath.split('/');
  return `${url.protocol}//${url.hostname}/SIE/MR/Illustrations/${paths[paths.length - 1]}`;
};

export const useFetchIllustrationsPath = () => {
  //https://cdn.....com/SIE/MR/Illustrations/ILL-000440451.PNG
  const vehicleDetail = useSelector(getLastVehicleDetail);
  const vehicleImage = vehicleDetail?.imageUrl;
  const url = new URL(vehicleImage ?? '');
  return `${url.protocol}//${url.hostname}/SIE/MR/Illustrations/`;
};
