import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';
import { FamilyCodesItem } from 'domains/promotion/Promotion.types';
import { GetTitle } from 'pages/EstimatePage/TableSection/tableComponents';
import { STable } from 'pages/EstimatePage/TableSection/TableSection.styled';
import { theme } from 'styles';
import { Flex, Text } from 'UI';
import { getCondArrayItem } from 'utils';

interface FamilyCodesTableProps {
  familyCodesData: FamilyCodesItem[];
  onSelect: (code: string) => void;
  rowRefs: React.MutableRefObject<(HTMLDivElement | null)[]>;
  highlightedRowIndex: number;
}

export const FamilyCodesTable = ({
  familyCodesData,
  onSelect,
  rowRefs,
  highlightedRowIndex,
}: FamilyCodesTableProps) => {
  const { t } = useTranslation();

  const renderRow = (row: string) => (
    <Flex justify={'center'}>
      <Text type={'lead_dim'}>{row}</Text>
    </Flex>
  );

  const tableColumns = [
    {
      title: GetTitle(t('common.designation', 'Designation')),
      dataIndex: 'designation',
      width: 150,
      render: renderRow,
    },
    {
      title: GetTitle(t('common.segment', 'Segment')),
      dataIndex: 'segment',
      width: 100,
      render: renderRow,
    },
    {
      title: GetTitle(t('common.segment_label', 'Segment label')),
      dataIndex: 'segmentLabel',
      width: 150,
      render: renderRow,
    },
    {
      title: GetTitle(t('common.family_code', 'Family code')),
      dataIndex: 'familyCode',
      width: 100,
      render: renderRow,
    },
    {
      title: GetTitle(t('common.family_label', 'Family label')),
      dataIndex: 'familyLabel',
      width: 150,
      render: renderRow,
    },
    ...getCondArrayItem(
      familyCodesData.some((row) => row.promoCode) && {
        title: GetTitle(t('common.promo_code', 'Promo code')),
        dataIndex: 'promoCode',
        width: 100,
        render: renderRow,
      },
    ),
    Table.EXPAND_COLUMN,
  ];

  return (
    <STable<FamilyCodesItem>
      columns={tableColumns}
      dataSource={familyCodesData}
      pagination={false}
      rowKey={(row) => row.familyCode}
      size={'middle'}
      scroll={{ x: 'max-content', y: 400 }}
      onRow={(record, index?: number) => ({
        ref: (el: HTMLDivElement | null) => {
          if (index !== undefined) {
            rowRefs.current[index] = el;
          }
        },
        tabIndex: 0,
        onClick: () => onSelect(record.familyCode),
        style: {
          cursor: 'pointer',
          background: index === highlightedRowIndex ? theme.color.ice_blue : theme.color.transparent,
          textAlign: 'center',
        },
      })}
    />
  );
};
