import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DataContainer } from 'components/DataContainer';
import {
  getOrderClaimForm,
  getOrderClaimStatus,
  sendOrderClaimRequestSaga,
  setOrderClaimForm,
} from 'domains/order/Order.store';
import { getReasonOption, getReasons } from 'pages/MyOrdersPage/OrderClaims/claimFormData';
import { BlackButton, Flex, Form, Input, InputNumber, MarginBox, Modal, Select, Spin, Text, TextArea } from 'UI';
import { isLoading } from 'utils';

interface ClaimFormProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const isFilled = (s: string) => s.length > 0;

const ClaimFormModal = ({ isOpen, setIsOpen }: ClaimFormProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const claimForm = useSelector(getOrderClaimForm);
  const claimStatus = useSelector(getOrderClaimStatus);
  const [claimSent, setClaimSent] = useState(false);

  const handleSendClaim = () => {
    if (getIsFormValid()) {
      dispatch(sendOrderClaimRequestSaga());
      setClaimSent(true);
    }
  };

  useEffect(() => {
    if (claimStatus === undefined && claimSent) {
      setIsOpen(false);
      setClaimSent(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimStatus]);

  const getIsFormValid = () => {
    const concernedOptions = ['Y10', 'Y01'];
    const isValidOption = concernedOptions.includes(claimForm.reason)
      ? claimForm.option && isFilled(claimForm.option)
      : true;
    const isValidAmount = claimForm.reason === 'Y10' ? claimForm.amount && claimForm.amount > 0 : true;

    return Boolean(
      isFilled(claimForm.reason) &&
        isValidOption &&
        isFilled(claimForm.invoiceNumber) &&
        isFilled(claimForm.referenceNumber) &&
        claimForm.invoiceLineNumber &&
        claimForm.invoiceLineNumber >= 0 &&
        claimForm.quantity > 0 &&
        isValidAmount &&
        !isLoading(claimStatus),
    );
  };

  const reasonOptions = getReasonOption(t).get(claimForm.reason);

  const getTextMandatory = (text: string) => {
    return `${text}(*)`;
  };

  return (
    <Modal open={isOpen} onCancel={() => setIsOpen(false)} zIndex={1000} footer={''}>
      <Form onFinish={handleSendClaim}>
        <Flex direction={'column'} gap={15} minHeight={600}>
          <Text type={'h1'} disableGutter>
            {t('order.claim.title', 'Send Claims form')}
          </Text>
          <Text type={'h6_light'}>
            {t(
              'order.claim.subtitle',
              'File a compensation claim for defective products , Missing part or any wrong delivery mistakes.',
            )}
          </Text>
          <Text type={'h2'} disableGutter noWrap>
            {t('my_orders.details.return_request.reason', 'Reason')}
          </Text>
          <Flex gap={50}>
            <Flex direction={'column'} maxWidth={200}>
              <Text type={'text_dim'} noWrap>
                {getTextMandatory(t('my_orders.details.return_request.reason', 'Reason'))}
              </Text>
              <Select
                value={claimForm.reason !== '' ? claimForm.reason : undefined}
                onChange={(reason: string) => {
                  dispatch(
                    setOrderClaimForm({
                      ...claimForm,
                      reason,
                      option: undefined,
                      amount: undefined,
                    }),
                  );
                }}
                options={getReasons(t)}
                bordered
                placeholder={t('common.action.select', 'Select')}
              />
            </Flex>
            {reasonOptions && (
              <Flex direction={'column'} maxWidth={200}>
                <Text type={'text_dim'} noWrap>
                  {getTextMandatory(t('common.option', 'Option'))}
                </Text>
                <Select
                  value={claimForm.option}
                  onChange={(option: string) => dispatch(setOrderClaimForm({ ...claimForm, option }))}
                  options={reasonOptions}
                  bordered
                  placeholder={t('common.action.select', 'Select')}
                />
              </Flex>
            )}
          </Flex>
          <Text type={'h2'} disableGutter noWrap>
            {t('common.other', 'Other')}
          </Text>
          <Flex gap={30}>
            <Flex direction={'column'} maxWidth={200}>
              <Text type={'text_dim'} noWrap>
                {getTextMandatory(t('order.order_detail.order_number', 'Order Number'))}
              </Text>
              <Input
                bordered
                initialValue={claimForm.orderNumber}
                onChangeDebounced={(orderNumber: string) => dispatch(setOrderClaimForm({ ...claimForm, orderNumber }))}
              />
            </Flex>
            <Flex direction={'column'} maxWidth={200}>
              <Text type={'text_dim'} noWrap>
                {getTextMandatory(t('order.invoice_number', 'Invoice number'))}
              </Text>
              <Input
                bordered
                initialValue={claimForm.invoiceNumber}
                onChangeDebounced={(invoiceNumber: string) =>
                  dispatch(setOrderClaimForm({ ...claimForm, invoiceNumber }))
                }
              />
            </Flex>
            <Flex direction={'column'} maxWidth={200}>
              <Text type={'text_dim'} noWrap>
                {getTextMandatory(t('order.invoice_line_number', 'Invoice Line number'))}
              </Text>
              <InputNumber
                bordered
                initialValue={claimForm.invoiceLineNumber}
                min={0}
                max={999999}
                onChangeDebounced={(invoiceLineNumber: number | undefined) =>
                  dispatch(setOrderClaimForm({ ...claimForm, invoiceLineNumber }))
                }
              />
            </Flex>
            <Flex direction={'column'} maxWidth={200}>
              <Text type={'text_dim'} noWrap>
                {getTextMandatory(t('order.reference_table.reference_number', 'Reference number'))}
              </Text>
              <Input
                bordered
                initialValue={claimForm.referenceNumber}
                onChangeDebounced={(referenceNumber: string) =>
                  dispatch(setOrderClaimForm({ ...claimForm, referenceNumber }))
                }
              />
            </Flex>
            <Flex direction={'column'} maxWidth={200}>
              <Text type={'text_dim'} noWrap>
                {getTextMandatory(t('common.quantity', 'Quantity'))}
              </Text>
              <InputNumber
                bordered
                min={1}
                max={99999}
                value={claimForm.quantity || undefined}
                onChangeDebounced={(quantity: number | undefined) =>
                  quantity && dispatch(setOrderClaimForm({ ...claimForm, quantity }))
                }
              />
            </Flex>
            {claimForm.reason === 'Y10' && (
              <Flex direction={'column'} maxWidth={200}>
                <Text type={'text_dim'} noWrap>
                  {getTextMandatory(t('common.amount', 'Amount'))}
                </Text>
                <InputNumber
                  bordered
                  min={0}
                  max={99999}
                  value={claimForm.amount}
                  onChangeDebounced={(amount: number | undefined) =>
                    amount && dispatch(setOrderClaimForm({ ...claimForm, amount }))
                  }
                />
              </Flex>
            )}
          </Flex>
          <Text type={'h2'} disableGutter noWrap>
            {t('assistance.contact_us.message', 'Message')}
          </Text>
          <TextArea
            initialValue={claimForm.message}
            onChangeDebounced={(message: string) => dispatch(setOrderClaimForm({ ...claimForm, message }))}
            placeholder={t('common.enter_message_here', 'Enter your message here..')}
            showCount
            maxLength={300}
          />
          <Flex minHeight={60}>
            <DataContainer
              data={claimStatus}
              Loading={() => (
                <MarginBox ml={20}>
                  <Spin />
                </MarginBox>
              )}
              Skeleton={() => (
                <BlackButton htmlType={'submit'} disabled={!getIsFormValid()}>
                  {t('common.action.send', 'Send')}
                </BlackButton>
              )}
            />
          </Flex>
        </Flex>
      </Form>
    </Modal>
  );
};

export default ClaimFormModal;
