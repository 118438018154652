import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Type as ContactFormType } from '@1po/1po-bff-fe-spec/generated/email/request/SendEmailToAssistance';
import { DataContainer } from 'components/DataContainer';
import { CAR_PARTS, MEMBERSHIP_AND_SUPPORT, REN_DAC, TECHNICAL_DOCUMENTATION } from 'domains/email/Email.types';
import { useFetchGarageInfo } from 'domains/garage/Garage.requests';
import { getFirstValidAddress } from 'domains/garage/Garage.utils';
import { getTradingProfile, getUserCountry } from 'domains/user';
import { theme } from 'styles';
import { Box, Flex, MarginBox, Text } from 'UI';
import { getData } from 'utils';
import { Language } from 'utils/i18n/Language';
import { getBrowserLanguage } from 'utils/i18n/localeDetector';
import { SFooterBox } from './AssistancePageFooter.styled';

interface AssistanceContact {
  key?: number;
  title?: string;
  phone?: string;
  tariff?: number;
  email?: string;
  openingHours?: React.ReactNode;
}

export const assistanceContactForConnectionIssues: Record<string, AssistanceContact> = {
  DE: { phone: '01 80 52 02 333', email: 'help-de@rparts.online' },
  PL: { email: 'adminpl.rparts@renault.com.pl' },
  AT: { phone: '0820 87 44 40', email: 'help-at@rparts.online' },
  BE: { email: 'help-be@rparts.online' },
  FR: { phone: '0805 980 450', email: 'help-fr@rparts.online' },
  GB: { phone: '0870 9000 370', email: 'help-uk@rparts.online' },
  IT: { phone: '02 385 91 091', email: 'help-it@rparts.online' },
  PT: {
    phone: '218 361 030',
    email: 'info_rparts@renault.pt',
    openingHours: 'Segunda a sexta-feira, das 9h às 18h',
  },
  RO: {
    phone: '0 805 980 450',
    tariff: 9,
    email: 'help-ro@rparts.online',
    openingHours: 'De luni până vineri, între 9 și 18',
  },
  CH: { email: 'help-ch@rparts.online' },
  ES: {
    phone: '902 877 553',
    tariff: 9,
    email: 'help-sp@rparts.online',
    openingHours: 'De lunes a viernes, de 8:30 a 17:30',
  },
};

export const defaultAssistanceContact: Record<string, AssistanceContact | AssistanceContact[]> = {
  DE: { phone: '01 80 52 02 333', tariff: 14, email: 'help-de@rparts.online' },
  PL: { email: 'adminpl.rparts@renault.com.pl' },
  AT: { phone: '0820 87 44 40', tariff: 14, email: 'help-at@rparts.online' },
  BE: { email: 'help-be@rparts.online' },
  GB: { phone: '0870 9000 370', email: 'help-uk@rparts.online' },

  PT: {
    phone: '218 361 030',
    email: 'info_rparts@renault.pt',
    openingHours: 'Segunda a sexta-feira, das 9h às 18h',
  },
  RO: {
    phone: '0 805 980 450*',
    tariff: 9,
    email: 'help-ro@rparts.online',
    openingHours: 'De luni până vineri, între 9 și 18',
  },
  CH: [
    {
      key: 0,
      title: Language.germanSwitzerland.name,
      phone: '+41 44 7770 377',
      email: 'rparts.suisse@renault.ch',
    },
    {
      key: 1,
      title: Language.frenchSwitzerland.name,
      phone: '+41 44 7770 437',
      email: 'rparts.suisse@renault.ch',
    },
    {
      key: 2,
      title: Language.italianSwitzerland.name,
      phone: '+41 44 7770 437',
      email: 'rparts.suisse@renault.ch',
    },
  ],
};

export const assistanceContactForRenaultDacia: Record<string, AssistanceContact> = {
  ES: {
    phone: '902 877 553',
    tariff: 9,
    email: 'help-sp@rparts.online',
    openingHours: 'De lunes a viernes, de 8:30 a 17:30',
  },
  FR: {
    phone: '0 805 980 450',
    tariff: 9,
  },
  IT: {
    phone: '02 385 91 091',
    tariff: 9,
  },
};

export const assistanceContactForCarParts: Record<string, AssistanceContact> = {
  ES: {
    phone: '986 206 000',
    email: 'TBC',
    openingHours: (
      <Trans i18nKey={'assistance.footer.support_centre.car_parts.opening_hours.spain'}>
        {'Monday to Friday from 8h30 to 17h30'}
      </Trans>
    ),
  },
  FR: {
    phone: '0 825 002 123',
    tariff: 15,
    openingHours: (
      <Trans i18nKey={'assistance.footer.support_centre.car_parts.opening_hours.france'}>
        {'Monday to Friday from 8h30 to 12h and 14h to 17h30'}
      </Trans>
    ),
  },
};

export const assistanceContactForMembershipAndSupport: Record<string, AssistanceContact> = {
  ES: {
    phone: '919 440 040',
    openingHours: (
      <Trans i18nKey={'assistance.footer.support_centre.membership_support.opening_hours.spain'}>
        {'Monday to Friday from 8h30 to 17h30'}
      </Trans>
    ),
  },
  FR: {
    phone: '01 89 61 05 12',
    openingHours: (
      <Trans i18nKey={'assistance.footer.support_centre.membership_support.opening_hours.france'}>
        {'Monday to Friday from 8h to 18h'}
      </Trans>
    ),
  },
};

export const AssistanceAdditionalInfo = ({
  textType,
  tariff,
  openingHours,
}: {
  textType: string;
  tariff?: number;
  openingHours?: React.ReactNode;
}) => {
  const { t } = useTranslation();
  return (
    <>
      {tariff && !openingHours && (
        <Text type={textType}>
          {t(
            'assistance.footer.support_centre.description',
            '({{tariff}} cents/min. from the landline / mobile phone tariffs may vary) Monday to Friday from 8h30 to 17h30',
            { tariff },
          )}
        </Text>
      )}
      {tariff && openingHours && (
        <Text type={textType}>
          {t(
            'assistance.footer.support_centre.description.tariff',
            '({{tariff}} cents/min. from the landline / mobile phone tariffs may vary)',
            { tariff },
          )}
          <MarginBox mr={3} />
          {openingHours}
        </Text>
      )}
      {!tariff && openingHours && <Text type={textType}>{openingHours}</Text>}
    </>
  );
};

const AssistanceFooterCard = ({
  title,
  secondTitle,
  phone,
  tariff,
  openingHours,
  email,
}: {
  title?: string;
  secondTitle?: string;
  phone?: string;
  tariff?: number;
  openingHours?: React.ReactNode;
  email?: string;
}) => {
  const { t } = useTranslation();
  if (!phone && !tariff && !email) {
    return null;
  }
  return (
    <Flex direction={'column'} maxWidth={420}>
      <MarginBox my={45}>
        <Flex direction={'column'} maxWidth={420}></Flex>
        {title && <Text type={'h2'}>{title}</Text>}
        {secondTitle && <Text type={'h2'}>{secondTitle}</Text>}
        {phone ? (
          <Text type={'h2'}>{`${t('assistance.footer.support_centre.telephone', 'By phone:')} ${phone}`}</Text>
        ) : (
          <Text type={'text_dim'}>
            {t(
              'assistance.footer.support_centre.telephone.unavailable',
              'Contact number is currently unavailable for your country.',
            )}
          </Text>
        )}
        <MarginBox mt={15} />
        {email && <Text type={'h3_dim'}>{email}</Text>}
        <MarginBox mt={15} />
        <AssistanceAdditionalInfo textType={'text_dim'} tariff={tariff} openingHours={openingHours} />
        <Box height={15} />
      </MarginBox>
    </Flex>
  );
};

const getAssistanceContacts = (country: string | undefined, type: ContactFormType): AssistanceContact[] | undefined => {
  let assistanceContactsForType;
  switch (type) {
    case REN_DAC:
      assistanceContactsForType = assistanceContactForRenaultDacia[country ?? ''];
      break;
    case CAR_PARTS:
      assistanceContactsForType = assistanceContactForCarParts[country ?? ''];
      break;
    case MEMBERSHIP_AND_SUPPORT:
      assistanceContactsForType = assistanceContactForMembershipAndSupport[country ?? ''];
      break;
    case TECHNICAL_DOCUMENTATION:
      assistanceContactsForType = undefined;
      break;
    default:
      assistanceContactsForType = defaultAssistanceContact[country ?? ''];
      break;
  }

  return assistanceContactsForType && !Array.isArray(assistanceContactsForType)
    ? [assistanceContactsForType]
    : assistanceContactsForType;
};

const AssistancePageFooter = ({ type }: { type: ContactFormType }) => {
  const { t } = useTranslation();

  const userCountry = useSelector(getUserCountry);
  const browserCountry = getBrowserLanguage()?.getCountryCode();
  const country = userCountry ?? browserCountry;

  const tradingProfile = useSelector(getTradingProfile);
  const sellerId = tradingProfile?.sellerId ?? '';

  const garageInfoState = useFetchGarageInfo(sellerId, tradingProfile?.repository ?? 'TRESOR');
  const garageInfo = getData(garageInfoState);
  const address = garageInfo ? getFirstValidAddress(garageInfo) : undefined;

  const assistanceContactsForType = getAssistanceContacts(country, type);

  return (
    <SFooterBox>
      <Flex background={theme.color.white} direction={'row'} gap={15}>
        <DataContainer
          data={garageInfoState}
          ErrorState={() => (
            <AssistanceFooterCard
              title={t('assistance.footer.garage.error', 'Garage data temporarily unavailable, please try again later')}
            />
          )}
        >
          <AssistanceFooterCard title={garageInfo?.name} phone={address?.phone} />
        </DataContainer>
        {Array.isArray(assistanceContactsForType) &&
          assistanceContactsForType.map((assistanceContact) => (
            <AssistanceFooterCard
              key={`${country}_${assistanceContact.key ?? ''}`}
              title={t('assistance.footer.customer_service', 'Customer service')}
              secondTitle={assistanceContact?.title}
              phone={assistanceContact?.phone}
              email={assistanceContact?.email}
              tariff={assistanceContact?.tariff}
              openingHours={assistanceContact?.openingHours}
            />
          ))}
      </Flex>
    </SFooterBox>
  );
};

export default AssistancePageFooter;
