import { Information } from '@1po/1po-bff-fe-spec/generated/backoffice/information/model/Information';
import {
  InformationBanner,
  TargetPage,
} from '@1po/1po-bff-fe-spec/generated/backoffice/information/model/InformationBanner';
import { ChangeInformationStatus } from '@1po/1po-bff-fe-spec/generated/backoffice/information/request/ChangeInformationStatus';
import { GetInformation } from '@1po/1po-bff-fe-spec/generated/backoffice/information/request/GetInformation';
import {
  GetInformations,
  StatusType,
} from '@1po/1po-bff-fe-spec/generated/backoffice/information/request/GetInformations';
import { InformationBannersResponse } from '@1po/1po-bff-fe-spec/generated/backoffice/information/response/InformationBannersResponse';
import { InformationBannerUpdate } from '@1po/1po-bff-fe-spec/generated/backoffice/information/response/InformationBannerUpdate';
import { InformationResponse } from '@1po/1po-bff-fe-spec/generated/backoffice/information/response/InformationResponse';
import { InformationsResponse } from '@1po/1po-bff-fe-spec/generated/backoffice/information/response/InformationsResponse';
import {
  BACKOFFICE_ARCHIVE_INFORMATION_RESPONSE,
  BACKOFFICE_INFORMATION_BANNER_UPDATE,
  BACKOFFICE_INFORMATION_BANNERS_RESPONSE,
  BACKOFFICE_INFORMATION_RESPONSE,
  BACKOFFICE_INFORMATIONS_RESPONSE,
  BACKOFFICE_PUBLISH_INFORMATION_RESPONSE,
  BACKOFFICE_SAVE_INFORMATION_RESPONSE,
  BACKOFFICE_SAVE_INFORMATION_TEMPLATE_RESPONSE,
} from '@1po/1po-bff-fe-spec/generated/common/ResponseType';
import { createAction, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { v4 as uuidV4 } from 'uuid';
import { RootState } from 'app/AppStore';
import {
  ARCHIVE_INFORMATION_REQUEST,
  CreateInformationModel,
  CreateInformationTemplateModel,
  GET_INFORMATION_BANNERS_REQUEST,
  GET_INFORMATION_REQUEST,
  GET_INFORMATIONS_REQUEST,
  INFORMATION_NAMESPACE,
  InformationState,
  LoadedInformations,
  PUBLISH_INFORMATION_REQUEST,
  SAVE_INFORMATION_REQUEST,
  SAVE_INFORMATION_TEMPLATE_REQUEST,
} from 'domains/information/Information.types';
import { FOUND, getData, hasData, NO_DATA, NOT_FOUND, SEARCH_STATUS } from 'utils';

function switchLoadedInformations(statusType: StatusType, state: Draft<InformationState>): Draft<LoadedInformations> {
  switch (statusType) {
    case 'PUBLISHED':
      return state.loadedInformations.published;
    case 'PLANNED':
      return state.loadedInformations.planned;
    case 'SAVED':
      return state.loadedInformations.saved;
    case 'ARCHIVED':
      return state.loadedInformations.archived;
  }
}

export function initialCreateInformation(): CreateInformationModel {
  return {
    informationId: uuidV4(),
    createdBy: undefined,
    created: undefined,
    textSection: [],
    targetAudience: [],
    targetCountries: [],
    targetPages: [],
    publicationStart: undefined,
    publicationEnd: undefined,
  };
}

export function initialCreateInformationTemplate(): CreateInformationTemplateModel {
  return {
    informationId: uuidV4(),
    createdBy: undefined,
    created: undefined,
    textSection: [],
    targetAudience: [],
    targetCountries: [],
    targetPages: [],
  };
}

const initLoadedInformation: LoadedInformations = {
  data: [],
  cursor: undefined,
  hasNextPage: true,
  status: undefined,
};

// Init state
const initialState: InformationState = {
  publishedResponseMap: new Map(),
  createInformation: initialCreateInformation(),
  informationDetails: new Map(),
  loadedInformations: {
    published: initLoadedInformation,
    planned: initLoadedInformation,
    saved: initLoadedInformation,
    archived: initLoadedInformation,
  },
  informationBanners: undefined,
  createInformationTemplate: initialCreateInformationTemplate(),
};

// Saga actions
export const fetchInformationRequestSaga = createAction<GetInformation>(GET_INFORMATION_REQUEST);
export const handleInformationResponseSaga = createAction(BACKOFFICE_INFORMATION_RESPONSE);
export const fetchInformationsRequestSaga = createAction<GetInformations>(GET_INFORMATIONS_REQUEST);
export const handleInformationsResponseSaga = createAction(BACKOFFICE_INFORMATIONS_RESPONSE);
export const fetchSaveInformationRequestSaga = createAction(SAVE_INFORMATION_REQUEST);
export const handleSaveInformationResponseSaga = createAction(BACKOFFICE_SAVE_INFORMATION_RESPONSE);
export const fetchPublishInformationRequestSaga = createAction(PUBLISH_INFORMATION_REQUEST);
export const handlePublishInformationResponseSaga = createAction(BACKOFFICE_PUBLISH_INFORMATION_RESPONSE);
export const fetchInformationBannersRequestSaga = createAction(GET_INFORMATION_BANNERS_REQUEST);
export const handleInformationBannersResponseSaga = createAction(BACKOFFICE_INFORMATION_BANNERS_RESPONSE);
export const handleInformationBannerUpdateSaga = createAction(BACKOFFICE_INFORMATION_BANNER_UPDATE);
export const fetchArchiveInformationRequestSaga = createAction<ChangeInformationStatus>(ARCHIVE_INFORMATION_REQUEST);
export const fetchArchiveInformationResponseSaga = createAction(BACKOFFICE_ARCHIVE_INFORMATION_RESPONSE);
export const fetchSaveInformationTemplateRequestSaga = createAction(SAVE_INFORMATION_TEMPLATE_REQUEST);
export const handleSaveInformationTemplateResponseSaga = createAction(BACKOFFICE_SAVE_INFORMATION_TEMPLATE_RESPONSE);

// Slice
const slice = createSlice({
  name: INFORMATION_NAMESPACE,
  initialState,
  reducers: {
    setInitialState: () => initialState,
    setCreateInformation: (state, { payload }) => {
      state.createInformation = payload;
    },
    setInformation: (state, { payload }: PayloadAction<InformationResponse>) => {
      state.informationDetails.set(payload.information.informationId, payload.information);
    },
    resetInformation: (state, { payload }: PayloadAction<{ id: string }>) => {
      state.informationDetails.delete(payload.id);
    },
    setInformationStatus: (state, { payload }: PayloadAction<{ id: string; status: NO_DATA }>) => {
      state.informationDetails.set(payload.id, payload.status);
    },
    setPublishedInformationStatus: (state, { payload }: PayloadAction<{ id: string; status: boolean | NO_DATA }>) => {
      state.publishedResponseMap.set(payload.id, payload.status);
    },
    setLoadedInformations: (state, { payload }: PayloadAction<InformationsResponse>) => {
      const loadedInformations = switchLoadedInformations(payload.status, state);
      const uniqueInformations = payload.informations.filter(
        (info) => !loadedInformations.data.some((dataInfo) => dataInfo.informationId === info.informationId),
      );
      loadedInformations.data.push(...uniqueInformations);
      loadedInformations.cursor = payload.cursor;
      loadedInformations.hasNextPage = payload.hasNextPage;
      loadedInformations.status = payload.informations.length === 0 ? NOT_FOUND : FOUND;
    },
    addCreatedInformation: (state, { payload }: PayloadAction<{ information: Information }>) => {
      const { information } = payload;
      if (!information.status) {
        return;
      }
      const loadedInformations = switchLoadedInformations(information.status, state);
      if (loadedInformations.data.length === 0) {
        loadedInformations.status = FOUND;
      }
      // replace the old information for the same state
      const existingInformationIndex = loadedInformations.data.findIndex(
        (info) => info.informationId === information.informationId,
      );
      if (existingInformationIndex !== -1) {
        loadedInformations.data[existingInformationIndex] = information;
        return;
      }

      // if information changed state, try to delete the old information with old state
      const informationToDelete = state.createInformation;
      if (!informationToDelete.status) {
        loadedInformations.data.unshift(information);
        return;
      }

      const loadedInformationsForOldStatus = switchLoadedInformations(informationToDelete.status, state);
      const informationToDeleteIndex = loadedInformationsForOldStatus.data.findIndex(
        (info) => info.informationId === information.informationId,
      );
      if (informationToDeleteIndex > -1) {
        loadedInformationsForOldStatus.data.splice(informationToDeleteIndex, 1);
      }
      loadedInformations.data.unshift(information);
    },
    setLoadedInformationsSearchStatus: (
      state,
      { payload }: PayloadAction<{ statusType: StatusType; searchStatus: SEARCH_STATUS }>,
    ) => {
      const loadedInformations = switchLoadedInformations(payload.statusType, state);
      loadedInformations.status = payload.searchStatus;
    },
    resetLoadedInformations: (state, { payload }: PayloadAction<StatusType>) => {
      const loadedInformations = switchLoadedInformations(payload, state);
      loadedInformations.data = initLoadedInformation.data;
      loadedInformations.cursor = initLoadedInformation.cursor;
      loadedInformations.hasNextPage = initLoadedInformation.hasNextPage;
      loadedInformations.status = initLoadedInformation.status;
    },
    updateDashboardInformation: (
      state,
      { payload }: PayloadAction<{ status: StatusType; information: Information }>,
    ) => {
      const { status, information } = payload;
      const loadedInformations = switchLoadedInformations(status, state);
      loadedInformations.data = loadedInformations.data.map((d) => {
        if (d.informationId === information.informationId) {
          return {
            ...information,
          };
        }
        return d;
      });
    },
    removeDashboardInformation: (state, { payload }: PayloadAction<{ status: StatusType; informationId: string }>) => {
      const { status, informationId } = payload;
      const loadedInformations = switchLoadedInformations(status, state);
      loadedInformations.data = loadedInformations.data.filter((d) => d.informationId !== informationId);
    },
    addCreatedBanner: (state, { payload }: PayloadAction<{ banner: InformationBanner }>) => {
      if (!hasData(state.informationBanners)) {
        state.informationBanners = [payload.banner];
        return;
      }
      const existingBannerIndex = state.informationBanners.findIndex(
        (banner) => banner.informationId === payload.banner.informationId,
      );

      if (existingBannerIndex !== -1) {
        state.informationBanners[existingBannerIndex] = payload.banner;
        return;
      }

      state.informationBanners.unshift(payload.banner);
    },
    setInformationBanners: (state, { payload }: PayloadAction<InformationBannersResponse | NO_DATA>) => {
      state.informationBanners = hasData(payload) ? payload.informationBanners : payload;
    },
    archiveInformation: (state, { payload }: PayloadAction<Information>) => {
      const { informationId } = payload;
      if (hasData(state.informationBanners)) {
        state.informationBanners = state.informationBanners.filter((ib) => ib.informationId !== informationId);
      }
      state.loadedInformations.published.data = state.loadedInformations.published.data.filter(
        (pi) => pi.informationId !== informationId,
      );
      state.loadedInformations.archived.data.unshift(payload);
    },
    updateInformationBanner: (state, { payload }: PayloadAction<InformationBannerUpdate>) => {
      let arr = getData(state.informationBanners) ?? [];
      switch (payload.type) {
        case 'CREATED':
          arr = arr.concat(payload.informationBanner);
          break;
        case 'UPDATED': {
          const field = arr.find((i) => i.informationId === payload.informationBanner.informationId);
          if (field !== undefined) {
            field.targetPages = payload.informationBanner.targetPages;
            field.title = payload.informationBanner.title;
            field.description = payload.informationBanner.description;
          }
          break;
        }
        case 'DELETED':
          arr = arr.filter((i) => i.informationId !== payload.informationBanner.informationId);
          if (arr.length === 0) {
            state.informationBanners = NOT_FOUND;
            return;
          }
          break;
        default:
          break;
      }
      state.informationBanners = arr;
    },
    setCreateInformationTemplate: (state, { payload }) => {
      state.createInformationTemplate = payload;
    },
    resetInformationTemplate: (state) => {
      state.createInformationTemplate = initialCreateInformationTemplate();
    },
  },
});

// Actions
export const {
  setInitialState,
  setCreateInformation,
  setInformation,
  resetInformation,
  setInformationStatus,
  setPublishedInformationStatus,
  setLoadedInformations,
  addCreatedInformation,
  setLoadedInformationsSearchStatus,
  resetLoadedInformations,
  removeDashboardInformation,
  updateDashboardInformation,
  addCreatedBanner,
  setInformationBanners,
  archiveInformation,
  updateInformationBanner,
  setCreateInformationTemplate,
  resetInformationTemplate,
} = slice.actions;

// Getters/Selectors
export const getCreateInformation = createSelector(
  (state: RootState) => state.information,
  (information) => information.createInformation,
);
export const getInformationDetails = createSelector(
  (state: RootState) => state.information,
  (_state: RootState, informationId: string) => informationId,
  (information, informationId) => {
    if (!informationId) return undefined;
    return information.informationDetails.get(informationId);
  },
);

export const getInformationBanners = createSelector(
  (state: RootState) => state.information.informationBanners,
  (banners) => banners,
);

export const getBannersCount = createSelector(
  (state: RootState) => state.information.informationBanners,
  (state: RootState) => state.promotion.flashPromotionBanners,
  (_state: RootState, page: TargetPage | 'ESTIMATE' | 'MY_STORE') => page,
  (infoBanners, promoBanners, page) => {
    const promoBannersCount = (getData(promoBanners) ?? []).length;
    const infoBannersCountOnPage = (getData(infoBanners) ?? []).filter((i) =>
      i.targetPages.includes(page as TargetPage),
    ).length;
    return infoBannersCountOnPage + promoBannersCount;
  },
);

export const getLoadedInformations = createSelector(
  (state: RootState) => state.information.loadedInformations,
  (_state: RootState, status: StatusType) => status,
  (loadedInformations, status) => {
    switch (status) {
      case 'PLANNED':
        return loadedInformations.planned;
      case 'SAVED':
        return loadedInformations.saved;
      case 'ARCHIVED':
        return loadedInformations.archived;
      case 'PUBLISHED':
      default:
        return loadedInformations.published;
    }
  },
);

export const findStatusTypeByPromotionID = createSelector(
  (state: RootState) => state.information.loadedInformations,
  (_state: RootState, id: string | undefined) => id,
  (loadedInformations, id): StatusType | undefined => {
    const i1 = loadedInformations.published.data.find((i) => i.informationId === id);
    if (i1 !== undefined) {
      return 'PUBLISHED';
    }
    const i2 = loadedInformations.planned.data.find((i) => i.informationId === id);
    if (i2 !== undefined) {
      return 'PLANNED';
    }
    const i3 = loadedInformations.saved.data.find((i) => i.informationId === id);
    if (i3 !== undefined) {
      return 'SAVED';
    }
    const i4 = loadedInformations.archived.data.find((i) => i.informationId === id);
    if (i4 !== undefined) {
      return 'ARCHIVED';
    }
    return undefined;
  },
);

export const getCreateInformationTemplate = createSelector(
  (state: RootState) => state.information,
  (information) => information.createInformationTemplate,
);

export default slice.reducer;
