import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Reference } from '@1po/1po-bff-fe-spec/generated/basket/response/model/OtherSection';
import { AngleDownIcon, AngleRightIcon } from 'assets/icons';
import { ExternalBasketSectionLocal, VehicleLocal } from 'domains/basket/Basket.types';
import { refWithoutDepositRef } from 'domains/references/DepositReference';
import { SectionWrapper } from 'pages/CartPage/CartPopover/CartPopover.styled';
import { ReferenceCard } from 'pages/CartPage/CartPopover/ReferenceCard';
import { renderVehicleInfoBlock } from 'pages/CartPage/ValidationStep/VerificationCard/VerificationCard';
import { SectionPicBackground } from 'pages/CartPage/ValidationStep/VerificationCard/VerificationCard.styled';
import { SectionImage } from 'pages/CartPage/ValidationStep/VerificationCard/VerificationImage';
import { theme } from 'styles';
import { Flex, Icon, Text } from 'UI';

interface SectionCardProps {
  vehicle?: VehicleLocal;
  externalBasket?: ExternalBasketSectionLocal;
  references?: Reference[];
}

const SectionCard = ({ vehicle, externalBasket, references }: SectionCardProps) => {
  const { t } = useTranslation();
  const refArray = (vehicle ? vehicle?.references : references) || [];
  const externalBasketId = externalBasket?.externalBasketId;
  const referencesCount = refWithoutDepositRef(refArray)?.length;
  const vehicleInfoBlock = renderVehicleInfoBlock(vehicle, externalBasket, t);
  const vehicleImageUrl = vehicle?.vehicleDetail?.imageUrl;
  const [isSectionCollapsed, setIsSectionCollapsed] = useState<boolean>(false);

  return (
    <SectionWrapper direction={'column'}>
      <Flex direction={'row'} align={'center'} padding={10} gap={10}>
        <SectionPicBackground showBackground={!vehicle} height={70} width={70} isSmall>
          <SectionImage
            vehicle={vehicle}
            imageUrl={vehicleImageUrl}
            externalBasketId={externalBasketId}
            maxWidth={70}
            maxHeight={70}
          />
        </SectionPicBackground>
        <Flex direction={'column'} justify={'center'} minHeight={70}>
          {vehicleInfoBlock}
        </Flex>
        <Flex direction={'row-reverse'} maxWidth={135}>
          <Text type={'lead'} disableGutter>
            {t('cart.preview.section.reference_count', '{{count}} References', { count: referencesCount })}
          </Text>
        </Flex>
        <Flex direction={'row-reverse'} maxWidth={35} align={'center'}>
          <Icon
            IconComponent={isSectionCollapsed ? AngleRightIcon : AngleDownIcon}
            round
            size={35}
            color={theme.color.grey15}
            onClick={() => setIsSectionCollapsed(!isSectionCollapsed)}
          />
        </Flex>
      </Flex>
      {!isSectionCollapsed &&
        refArray.map((reference) => (
          <ReferenceCard key={`refPrevCard-${reference.referenceNumber}`} reference={reference} vehicle={vehicle} />
        ))}
    </SectionWrapper>
  );
};

export default SectionCard;
