import React, { isValidElement, ReactNode } from 'react';
import { View } from '@react-pdf/renderer';
import { TextMid, TextMidBold } from 'components/Pdf/PdfUI';
import { styles } from 'components/Pdf/PdfUI.styles';

export const RowBreakLine = ({ title, data = '' }: { title: ReactNode; data: string | undefined }) => {
  const lengthOfTitle =
    (isValidElement(title) && typeof title.props.children === 'string' && title.props.children.length) || 0;
  const isLong = data.length + lengthOfTitle > 35;
  return (
    <View style={[styles.col, { width: '100%' }]}>
      <View style={[styles.row, { alignContent: 'center', marginTop: `${isLong ? '-7px' : '0px'}` }]}>
        <TextMidBold>{title}</TextMidBold>
        {!isLong && (
          <View style={[styles.row_reverse, { width: '100%' }]}>
            <TextMid>{data}</TextMid>
          </View>
        )}
      </View>
      {isLong && (
        <View style={[styles.row_reverse, { marginTop: '7px' }]}>
          <TextMid>{data}</TextMid>
        </View>
      )}
    </View>
  );
};
