const PROFILE_PREFIX = "/user-profile/";
const DELEGATE_PREFIX = "/delegate-session/";
export const ADD_FAVORITE_TIRE_BRAND_URL = PROFILE_PREFIX + "add-favorite-tire-brand";
export const REMOVE_FAVORITE_TIRE_BRAND_URL = PROFILE_PREFIX + "remove-favorite-tire-brand";
export const RESET_FAVORITE_TIRE_BRANDS_SELECTION_URL = PROFILE_PREFIX + "reset-favorite-tire-brands-selection";
export const UPDATE_USER_PROFILE_LANGUAGES_URL = PROFILE_PREFIX + "update-languages";
export const UPDATE_USER_PROFILE_SELLER_URL = PROFILE_PREFIX + "update-seller";
export const UPDATE_CATALOG_SETTINGS_URL = PROFILE_PREFIX + "update-catalog-settings";
export const GET_USER_PROFILE_URL = PROFILE_PREFIX + "get";
export const GET_USER_SELLERS_URL = PROFILE_PREFIX + "get-sellers";
export const DELEGATE_SESSION_CONNECT_URL = DELEGATE_PREFIX + "connect";
export const DELEGATE_SESSION_DISCONNECT_URL = DELEGATE_PREFIX + "disconnect";
export const DELEGATE_SESSION_GET_SELLERS_LIST_URL = DELEGATE_PREFIX + "get-sellers";
export const DELEGATE_SESSION_GET_BUYERS_LIST_URL = DELEGATE_PREFIX + "get-buyers";
