import React from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowRightFullIcon } from 'assets/icons';
import { BatteryCard } from 'components/BatteryInfo/BatteryInfo.styled';
import { useIsBatteryUrl } from 'components/BatteryInfo/useIsBatteryUrl';
import { getUniversalProductBatteryPathname } from 'pages/CatalogPage/common/QuickAccess/useQuickAccessLinks';
import { Flex, Icon, Image, Link, Text, WarningAlert } from 'UI';

export const BatteryInfoAlert = () => {
  const { t } = useTranslation();
  const universalProductsBatteryLink = getUniversalProductBatteryPathname();
  const isBatteryUrl = useIsBatteryUrl();
  if (!isBatteryUrl) {
    return null;
  }
  return (
    <BatteryCard direction={'row'} gap={5} padding={24} maxHeight={200}>
      <Flex direction={'column'} gap={8}>
        <Text type={'h2'} disableGutter>
          {t('catalog.battery.no_compatible_items.title', 'No items seems to reach the good conditions')}
        </Text>
        <Text type={'lead'}>
          {t(
            'catalog.battery.no_compatible_items.description',
            'You’ll find below a selection of battery in our universal section. Make sure to verify compatibilities with your vehicle.',
          )}
        </Text>
        <Link to={'/'}>
          <Flex align={'center'} gap={8}>
            <Link to={universalProductsBatteryLink}>
              <Text type={'link'} decoration={'underline'}>
                {t('common.action.go_to_universal_products', 'Go to Universal Products')}
              </Text>
              <Icon IconComponent={ArrowRightFullIcon} size={16} />
            </Link>
          </Flex>
        </Link>
      </Flex>
      <Image alt={'no-items-found'} src={'catalog/no-items-found-alert.png'} maxWidth={250} />
    </BatteryCard>
  );
};

export const BatteryCompatibilityAlert = () => {
  const { t } = useTranslation();
  const isBatteryUrl = useIsBatteryUrl();
  if (!isBatteryUrl) {
    return null;
  }
  return (
    <WarningAlert
      message={
        <Flex direction={'column'}>
          <Text type={'h6'} disableGutter>
            {t('catalog.battery.verify_compatibility.title', 'Verify Compatibility with your vehicle.')}
          </Text>
          <Text type={'h6_light'} disableGutter>
            {t(
              'catalog.battery.verify_compatibility.description',
              'Please verify Compatibility before validating your battery order.',
            )}
          </Text>
        </Flex>
      }
    />
  );
};
