/* eslint-disable max-len */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'app/AppStore';
import { BrandsSearchEnginIcon, ChevronDownIcon, CloneIcon } from 'assets/icons';
import Loader from 'components/Loader';
import { IAMFulltextSearchResult } from 'domains/catalog/Catalog.types';
import { getIAMReferences, getPricesMap, getStocksMap } from 'domains/references';
import { SparePartsViewType } from 'domains/user';
import { IamProductCard } from 'pages/CatalogPage/IAM/FullTextSearchResult/IAMProductCard';

import { useWindowWidth } from 'pages/CatalogPage/IAM/FullTextSearchResult/utils';
import IAMPlateReferenceCard from 'pages/CatalogPage/IAM/SubcategorySection/SparePartsSection/ReferenceCardsContainer/PlateReferenceCard';
import {
  getFilteredMotrioReferences,
  getFilteredOtherBrandsReferences,
  sortIAMReferences,
} from 'pages/CatalogPage/IAM/SubcategorySection/SparePartsSection/ReferenceCardsContainer/ReferenceCardsContainerUtils';
import { theme } from 'styles';
import { Box, CenteredSpin, Flex, Icon, LeftButton, MarginBox, RightButton, Text } from 'UI';
import { getData, isLoading } from 'utils';
import { Header, OpennerWrapper } from './SearchResultSection.styled';

interface SearchResultSectionProps {
  searchResultGroup: IAMFulltextSearchResult;
  sparePartsView: SparePartsViewType;
}

interface EquivalentSectionProps {
  sparePartsView: SparePartsViewType;
  equivalentReferences: string[] | undefined;
  additionalEquivalencesLoading: boolean;
  handleClickCompare?: (reference: string) => void;
  hasMainReference?: boolean;
  vehicleKey?: string;
}

const SearchResultSection = ({ searchResultGroup, sparePartsView }: SearchResultSectionProps) => {
  const equivalentReferences = getData(searchResultGroup.equivalentReferences);
  return (
    <MarginBox mt={10}>
      <MainSection searchResultGroup={searchResultGroup} sparePartsView={sparePartsView} />
      <OldEquivalentSection
        sparePartsView={sparePartsView}
        equivalentReferences={equivalentReferences}
        additionalEquivalencesLoading={searchResultGroup.additionalEquivalencesLoading}
      />
    </MarginBox>
  );
};

const MainSection = ({ searchResultGroup, sparePartsView }: SearchResultSectionProps) => {
  const hasRefs = (searchResultGroup.mainReferences?.length ?? 0) > 0;
  return (
    <>
      <Header type={'main'} $isWide={hasRefs}>
        <Flex direction={'row'}>
          <Icon IconComponent={BrandsSearchEnginIcon} color={theme.color.brand} size={20} mr={15} />
          <Text type={'popover_title'}>{`${searchResultGroup.groupName?.toUpperCase()}`}</Text>
        </Flex>
      </Header>
      {hasRefs ? (
        <MarginBox mt={-25}>
          {searchResultGroup.mainReferences?.map((mainRef, index) => {
            return (
              <div key={index}>
                <IAMPlateReferenceCard
                  referenceNumber={mainRef}
                  sparePartsView={sparePartsView}
                  laborTimesWrapper={undefined}
                  isIamSearchResult
                />
              </div>
            );
          })}
        </MarginBox>
      ) : (
        <>{searchResultGroup.loading && <Loader height={'10vh'} size={'default'} />}</>
      )}
    </>
  );
};

const OldEquivalentSection = ({
  sparePartsView,
  equivalentReferences,
  additionalEquivalencesLoading,
}: EquivalentSectionProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const references = useSelector((state: RootState) =>
    getIAMReferences(state, { vehicleKey: undefined, referenceNumbers: equivalentReferences }),
  );
  const prices = useSelector((state: RootState) => getPricesMap(state, equivalentReferences ?? []));
  const stocks = useSelector((state: RootState) => getStocksMap(state, equivalentReferences ?? []));

  const sortedReferences = useMemo(() => {
    const motrioReferences = sortIAMReferences(getFilteredMotrioReferences(references, prices, stocks), stocks);
    const otherBrandsReferences = sortIAMReferences(getFilteredOtherBrandsReferences(references), stocks);
    return [...motrioReferences, ...otherBrandsReferences].map((r) => r.referenceNumber);
  }, [prices, references, stocks]);

  const isAnyReferenceLoading = references.some((r) => isLoading(r.detailStatus));

  if (isAnyReferenceLoading || additionalEquivalencesLoading) return <CenteredSpin />;
  if (!equivalentReferences || equivalentReferences?.length === 0) return null;

  return (
    <>
      <Header type={'equivalent'} $isWide={isOpen} onClick={() => setIsOpen(!isOpen)}>
        <Icon IconComponent={CloneIcon} color={theme.color.brand_black} size={20} mr={15} />
        <Text type={'h5_bold'}>
          {t('catalog.search.iam.equivalent_products', 'Equivalent products ({{count}})', {
            count: equivalentReferences?.length ?? 0,
          })}
        </Text>
        <Flex direction={'row-reverse'}>
          <OpennerWrapper $isOpen={isOpen}>
            <Icon IconComponent={ChevronDownIcon} color={theme.color.brand_black} size={20} />
          </OpennerWrapper>
        </Flex>
      </Header>
      {isOpen && (
        <MarginBox mt={-25}>
          {sortedReferences.map((eqRef, index) => {
            return (
              <div key={index}>
                <IAMPlateReferenceCard referenceNumber={eqRef} sparePartsView={sparePartsView} isIamSearchResult />
              </div>
            );
          })}
        </MarginBox>
      )}
      <Box height={30} />
    </>
  );
};

export const EquivalentSection = ({
  sparePartsView,
  equivalentReferences,
  additionalEquivalencesLoading,
  handleClickCompare,
  hasMainReference,
  vehicleKey,
}: EquivalentSectionProps) => {
  const { t } = useTranslation();
  const references = useSelector((state: RootState) =>
    getIAMReferences(state, { vehicleKey: undefined, referenceNumbers: equivalentReferences }),
  );
  const prices = useSelector((state: RootState) => getPricesMap(state, equivalentReferences ?? []));
  const stocks = useSelector((state: RootState) => getStocksMap(state, equivalentReferences ?? []));
  const hasReferences = equivalentReferences && equivalentReferences.length > 0;
  const sortedReferences = useMemo(() => {
    const motrioReferences = sortIAMReferences(getFilteredMotrioReferences(references, prices, stocks), stocks);
    const otherBrandsReferences = sortIAMReferences(getFilteredOtherBrandsReferences(references), stocks);
    return [...motrioReferences, ...otherBrandsReferences].map((r) => r.referenceNumber);
  }, [prices, references, stocks]);

  const isAnyReferenceLoading = references.some((r) => isLoading(r.detailStatus));

  const containerRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  const itemWidth = 332;
  const defaultDisplayedCardCount = 3;
  const displayedCardsCount = containerRef.current
    ? Math.floor(containerRef.current.clientWidth / itemWidth)
    : defaultDisplayedCardCount;
  const scrollStepSize = (displayedCardsCount > 1 ? displayedCardsCount - 1 : 1) * itemWidth;

  const [isLeftDisabled, setIsLeftDisabled] = useState(true);
  const [isRightDisabled, setIsRightDisabled] = useState(false);

  const updateButtonStates = (scrollLeft: number) => {
    if (containerRef.current) {
      const { scrollWidth, clientWidth } = containerRef.current;
      setIsLeftDisabled(scrollLeft <= 0);
      setIsRightDisabled(scrollLeft + clientWidth >= scrollWidth);
    }
  };
  const windowWidth = useWindowWidth();

  useEffect(() => {
    if (containerRef.current) {
      updateButtonStates(containerRef.current.scrollLeft);
    }
  }, [windowWidth]);

  const renderSectionPlate = useMemo(() => {
    const handleLeftButtonClick = () => {
      if (containerRef.current) {
        const { scrollLeft } = containerRef.current;
        const scrollAmount = scrollLeft - scrollStepSize;
        containerRef.current.scrollTo({
          left: scrollAmount,
          behavior: 'smooth',
        });
        updateButtonStates(scrollAmount);
      }
    };

    const handleRightButtonClick = () => {
      if (containerRef.current) {
        const { scrollLeft } = containerRef.current;
        const scrollAmount = scrollLeft + scrollStepSize;
        containerRef.current.scrollTo({
          left: scrollAmount,
          behavior: 'smooth',
        });
        updateButtonStates(scrollAmount);
      }
    };

    return (
      <MarginBox mx={15}>
        <Flex direction={'row'} justify={'space-between'} gap={48}>
          <Flex direction={'column'}>
            <Flex direction={'row'}>
              <Text type={'h3_bold_clear_blue'}>
                {t('catalog.search.iam.equivalent_products.title', 'Equivalent products')}
              </Text>
              <Box width={10} />
              <Text type={'h5_light'}>
                {t('catalog.search.iam.equivalent_products.products_count', '({{count}} products)', {
                  count: equivalentReferences?.length,
                })}
              </Text>
            </Flex>
            <Box height={4} />
            <Text type={'h6_light'}>
              {t(
                'catalog.search.iam.equivalent_products.description',
                'All listed products have the same or substantially the same external appearance, and performance characteristics as manufacturer parts.',
              )}
            </Text>
          </Flex>
          {hasReferences && !(isLeftDisabled && isRightDisabled) && (
            <Flex size={0} direction={'row'} justify={'flex-end'} gap={6}>
              <LeftButton disabled={isLeftDisabled} onClick={handleLeftButtonClick} />
              <RightButton disabled={isRightDisabled} onClick={handleRightButtonClick} />
            </Flex>
          )}
        </Flex>
      </MarginBox>
    );
  }, [equivalentReferences?.length, hasReferences, isLeftDisabled, isRightDisabled, scrollStepSize, t]);

  if (isAnyReferenceLoading || additionalEquivalencesLoading) {
    return <CenteredSpin />;
  }
  if (!hasReferences) {
    return renderSectionPlate;
  }

  return (
    <>
      {renderSectionPlate}
      <Box height={12} />
      <div
        ref={containerRef}
        style={{
          display: 'flex',
          flexDirection: 'row',
          overflow: 'hidden',
          padding: '15px 15px 15px 15px',
          margin: '0px 15px',
          gap: '32px',
        }}
      >
        {sortedReferences.map((eqRef) => {
          return (
            <div key={eqRef}>
              <IamProductCard
                referenceNumber={eqRef}
                sparePartsView={sparePartsView}
                handleClickCompare={handleClickCompare}
                showCompare={hasMainReference}
                vehicleKey={vehicleKey}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default SearchResultSection;
