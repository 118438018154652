import { Font, StyleSheet } from '@react-pdf/renderer';
import { theme } from 'styles';

export const components = StyleSheet.create({
  flex: {
    flexDirection: 'column',
    display: 'flex',
  },
  card: {
    width: '100%',
    padding: '5px 5px 5px 5px',
  },
  card2: {
    width: '100%',
    padding: '0',
  },
  card_title_center: {
    backgroundColor: theme.color.grey95,
    justifyContent: 'center',
    alignItems: 'center',
    height: 30,
  },
  card_title_start: {
    backgroundColor: theme.color.grey95,
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingLeft: 10,
    height: 30,
  },
  card2_title: {
    backgroundColor: theme.color.grey95,
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    height: 30,
    paddingLeft: 7,
    paddingRight: 7,
  },
  input_container: {
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 19,
  },
  input_inner: {
    width: '100%',
    padding: '4 6 4 6',
  },
  border_grey: {
    borderColor: theme.color.grey_light,
    borderStyle: 'solid',
    borderWidth: 1,
  },
  divider: { borderTopColor: theme.color.grey_light, borderTopStyle: 'solid', borderTopWidth: 1 },
  dividerBottom: { borderBottomColor: theme.color.grey_light, borderBottomStyle: 'solid', borderBottomWidth: 1 },
  cell: {
    minHeight: 28,
    alignItems: 'center',
  },
  cell_fixed: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  cell_left_fixed_padding: {
    justifyContent: 'flex-start',
    padding: '0 5px 0 5px',
  },
  cell_left_flex_padding: {
    justifyContent: 'flex-start',
    padding: '0 5px 0 5px',
  },
  cell_flex: {
    justifyContent: 'center',
  },
  header_cell: {
    backgroundColor: theme.color.grey95,
    height: 23,
    justifyContent: 'center',
    alignItems: 'center',
    width: 80,
  },
  header_cell_fixed: {
    backgroundColor: theme.color.grey95,
    height: 23,
    justifyContent: 'center',
    alignItems: 'center',
  },
  note_card_title: {
    backgroundColor: theme.color.grey95,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    height: 30,
  },
  print_checkbox: {
    width: 16,
    height: 16,
    borderColor: theme.color.shadow_4,
    borderStyle: 'solid',
    borderRadius: 2,
    borderWidth: 1,
  },
  checkbox_items: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    height: 27,
  },
  header_cell_fixed_left_white: {
    backgroundColor: theme.color.white,
    height: 23,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  header_cell_left_white: {
    backgroundColor: theme.color.white,
    height: 23,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: 80,
  },

  cell_left: {
    height: 28,
    alignItems: 'center',
  },
  cell_left_fixed: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  cell_left_flex: {
    justifyContent: 'flex-start',
  },
  dark_cell: {
    backgroundColor: theme.color.grey95,
  },
  white_cell: {
    backgroundColor: theme.color.white,
  },
});

Font.register({
  family: 'NouvelR',
  fontWeight: 'normal',
  fontStyle: 'normal',
  src: '/fonts/NouvelR-Regular.otf',
});

Font.register({
  family: 'NouvelRBold',
  fontWeight: 'normal',
  fontStyle: 'normal',
  src: '/fonts/NouvelR-Bold.otf',
});

export const styles = StyleSheet.create({
  page: {
    paddingTop: 30,
    paddingRight: 20,
    paddingBottom: 70,
    paddingLeft: 20,
    backgroundColor: theme.color.white,
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
  },
  col_reverse: {
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  row_reverse: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  line: {
    height: 11,
  },
  flex: {
    display: 'flex',
  },
  column: {
    flexGrow: 1,
  },
  column_2: {
    flexGrow: 2,
  },
  column_fixed: {
    flexGrow: 0,
  },
  grid: {
    display: 'flex',
    flexDirection: 'column',
    height: 100,
  },
  dot: {
    fontSize: 8,
    fontFamily: 'Courier',
  },
  col_end: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
});

export const fonts = StyleSheet.create({
  h4: {
    fontSize: 23,
    color: theme.color.grey10,
    fontFamily: 'NouvelRBold',
  },
  headBold: {
    fontSize: 14,
    color: theme.color.grey10,
    fontWeight: 600,
    fontFamily: 'NouvelRBold',
  },
  h5: {
    fontSize: 13,
    color: theme.color.grey10,
    fontWeight: 600,
    fontFamily: 'NouvelR',
  },
  lead_text: {
    fontSize: 11,
    color: theme.color.grey10,
    fontFamily: 'NouvelR',
  },
  itemNameTitle: {
    fontSize: 9,
    color: theme.color.grey10,
    fontWeight: 600,
    fontFamily: 'NouvelRBold',
  },
  input_text: {
    fontSize: 8,
    color: theme.color.grey35,
    fontFamily: 'NouvelR',
  },
  h6: {
    fontSize: 8,
    color: theme.color.grey10,
    fontWeight: 700,
    fontFamily: 'NouvelR',
  },
  textMid: {
    fontSize: 8,
    color: theme.color.grey10,
    fontFamily: 'NouvelR',
  },
  text: {
    fontSize: 8,
    color: theme.color.grey35,
    fontFamily: 'NouvelR',
  },
  text_bold: {
    fontSize: 8,
    color: theme.color.grey35,
    fontWeight: 600,
    fontFamily: 'NouvelR',
  },
  dark_text: {
    fontSize: 8,
    color: theme.color.grey10,
    fontFamily: 'NouvelR',
  },
  bold: {
    fontWeight: 600,
    fontFamily: 'NouvelRBold',
  },
  warning: {
    fontSize: 8,
    color: theme.color.warning,
    fontFamily: 'NouvelR',
  },
  blue: {
    fontSize: 9,
    color: theme.color.clear_blue,
    fontFamily: 'NouvelR',
  },
  cyan: {
    fontSize: 9,
    color: '#13a8a8',
    fontFamily: 'NouvelR',
  },
  dim: {
    fontSize: 8,
    color: 'rgba(0, 0, 0, 0.45)',
    fontFamily: 'NouvelR',
    textDecoration: 'line-through',
  },
  tableHeadline: {
    fontSize: 12,
    color: theme.color.grey10,
    fontWeight: 700,
    fontFamily: 'NouvelR',
  },
  lead_text_white: {
    fontSize: 10,
    color: theme.color.white,
    fontWeight: 700,
    fontFamily: 'NouvelR',
    letterSpacing: -0.09,
  },
});
