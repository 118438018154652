import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
/* eslint-disable max-len */
import {
  OrderDeliveryType,
  OrderMKTPDeliveryMode,
} from '@1po/1po-bff-fe-spec/generated/order/request/CheckoutOrderRequest';
import { Seller } from '@1po/1po-bff-fe-spec/generated/user/model/Seller';
import { DelegationBuyer } from '@1po/1po-bff-fe-spec/generated/user/response/model/DelegationBuyer';
import { TFunction } from 'i18next';
import { v4 as uuidv4 } from 'uuid';
import { ROUTER_CART } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { trackAppEvent } from 'app/AppTracker';
import { useDisclosure } from 'components/Dialog';
import {
  useSelectedExternalsReferencesMemo,
  useSelectedOtherReferencesMemo,
  useSelectedVehiclesReferencesMemo,
} from 'domains/basket/Basket.hooks';
import {
  getBasketExadisDeliveryMode,
  getBasketExternalSection,
  getBasketHasSelectedMKTPReference,
  getBasketIAMWithoutMotrioReferences,
  getBasketOtherSection,
  getBasketPrices,
  getBasketVehicles,
  resetReferencesDeliveryType,
  setExadisDeliveryMode,
} from 'domains/basket/Basket.store';
import {
  EXADIS_PICKUP_DELIVERY_TYPE,
  ExternalBasketSectionLocal,
  ReferenceLocal,
  VehicleLocal,
} from 'domains/basket/Basket.types';
import {
  collectCheckoutExternalReference,
  collectCheckoutOtherSection,
  collectCheckoutVehicleReference,
} from 'domains/order/Order.mapper';
import { checkoutOrderRequestSaga } from 'domains/order/Order.store';
import {
  getDefaultDeliveryAddress,
  getDefaultPaymentMethod,
  getExternalSectionValidation,
  getOrderMKTPDeliveryMode,
  getOtherSectionValidation,
  getVehiclesValidation,
  resetValidationDeliveryAddressSaga,
  resetValidationPaymentMethodSaga,
  setDefaultValidationDeliveryAddressSaga,
  setDefaultValidationPaymentMethodSaga,
  setOrderMKTPDeliveryMode,
} from 'domains/orderValidation';
import {
  checkIfStocksAreStillUpToDateRequestSaga,
  fetchStocks,
  getReferencesToStockInfos,
  getStockUpdateCheckResult,
  setReferencesStocksSnapshotData,
} from 'domains/references';
import { getCurrency, getDelegationSession, getUserCommercialLink } from 'domains/user';
import { CART_STEPS_HEIGHT, CartAndValidationStepProps } from 'pages/CartPage/CartPage';
import CardStockValidation from 'pages/CartPage/CartStep/CartStockValidation';
import CheckoutCardSection from 'pages/CartPage/CartStep/CheckoutCardSection';
import BillingAccountSection from 'pages/CartPage/ValidationStep/BillingAccountSection';
import DeliveryAddressSection from 'pages/CartPage/ValidationStep/DeliveryAddressSection';
import VerificationSection from 'pages/CartPage/ValidationStep/VerificationSection';
import { Flex, MarginBox, StickyBox, Text } from 'UI';
import { notifyTop } from 'UI/Notification/notification';
import { getData, hasData, isLoading, LOADING, NO_DATA, useExtraLarge } from 'utils';
import { TRACKING_EVENT_CART_VALIDATION } from 'utils/eventTracker/EventTracker.types';
import { useOffsetTop } from 'utils/hooks/useOffsetTop';
import { useResetScroll } from 'utils/hooks/useResetScroll';
import { ReferenceCardStockValidation } from '../CartStep/CartStockValidation/CardStockValidation';

const getDeliveryType = (t: TFunction) => {
  return {
    OWN_ADDRESS: t('order.validation.address.options.own', 'Delivery to my address'),
    DEALER_PICKUP: t('order.validation.address.options.r1', 'Dealer pick-up'),
    EXADIS_COUNTER_PICKUP: t('order.validation.address.options.exadis_pickup', 'Exadis counter pick-up'),
  };
};

export const getBillingAccountOptions = (dealer: Seller | DelegationBuyer) => {
  const billingOptions = [{ value: dealer.primaryClientCode, title: dealer.primaryClientCode }];
  if (dealer.secondaryClientCodes) {
    dealer.secondaryClientCodes.forEach((code) => billingOptions.push({ value: code, title: code }));
  }
  return billingOptions;
};

const addReferenceToReferenceWithHighestQTYMap = (ref: ReferenceLocal, map: Map<string, ReferenceLocal>) => {
  const currValue = map.get(ref.referenceNumber);
  map.set(ref.referenceNumber, currValue && currValue.quantity > ref.quantity ? currValue : ref);
};

const getReferenceWithHighestQuantityMap = (vehicles: VehicleLocal[], otherReferences: ReferenceLocal[]) => {
  const map = new Map<string, ReferenceLocal>();

  vehicles.forEach((vehicle) => {
    vehicle.references.forEach((ref) => addReferenceToReferenceWithHighestQTYMap(ref, map));
  });

  otherReferences.forEach((ref) => addReferenceToReferenceWithHighestQTYMap(ref, map));
  return map;
};
const ValidationStep = ({ next }: CartAndValidationStepProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const vehicles = useSelector(getBasketVehicles);
  const externalBaskets = useSelector(getBasketExternalSection);
  const otherSection = useSelector(getBasketOtherSection);
  const basketPrices = useSelector(getBasketPrices);
  const currency = useSelector(getCurrency);
  const { totalPriceVatIncluded, totalPriceVatExcluded, totalDiscount } = basketPrices;
  const defaultDeliveryAddress = useSelector(getDefaultDeliveryAddress);
  const defaultPaymentMethod = useSelector(getDefaultPaymentMethod);
  const vehiclesValidation = useSelector(getVehiclesValidation);
  const externalValidation = useSelector(getExternalSectionValidation);
  const otherSectionValidation = useSelector(getOtherSectionValidation);
  const offsetTop = useOffsetTop('CART');
  const delegateSession = useSelector(getDelegationSession);
  const commercialLink = useSelector(getUserCommercialLink);
  const activeSeller = (delegateSession ? delegateSession.commercialLink?.seller : commercialLink?.seller) ?? null;
  const xlarge = useExtraLarge();
  const [validationDeliveryAddress, setValidationDeliveryAddress] = useState<OrderDeliveryType>(defaultDeliveryAddress);
  const orderMKTPDeliveryMode = useSelector(getOrderMKTPDeliveryMode);
  const basketExadisDeliveryMode = useSelector(getBasketExadisDeliveryMode);
  const basketHasMKTPReference = useSelector(getBasketHasSelectedMKTPReference);
  const [billingAccount, setBillingAccount] = useState(defaultPaymentMethod ?? activeSeller?.primaryClientCode ?? '');
  const basketIAMWithoutMotrioReferences = useSelector(getBasketIAMWithoutMotrioReferences);
  const allStocksBasketIAMWithoutMotrioReferences = useSelector((state: RootState) =>
    getReferencesToStockInfos(
      state,
      basketIAMWithoutMotrioReferences.map((l) => l.referenceNumber),
    ),
  );
  const stocksR1BasketIAMWithoutMotrioReferences = allStocksBasketIAMWithoutMotrioReferences
    .filter((r) => r?.data !== null)
    .map((r) => r?.data)
    .filter((r) => r?.warehouses.some((w) => w.type === 'LOCAL'));
  const basketIAMWithoutMotrioReferencesCount = basketIAMWithoutMotrioReferences.filter(
    (r) => !stocksR1BasketIAMWithoutMotrioReferences.find((s) => s?.reference === r.referenceNumber),
  ).length;
  const billingOptions = activeSeller ? getBillingAccountOptions(activeSeller) : undefined;
  const deliveryType = getDeliveryType(t);
  const deliveryOptions = Object.keys(deliveryType)
    .filter((value) => value !== EXADIS_PICKUP_DELIVERY_TYPE)
    .map((option) => {
      return { value: option, title: deliveryType[option as OrderDeliveryType] };
    });
  const [pendingStockCheckId, setPendingStockCheckId] = useState<string | undefined>(undefined);
  const stockUpdateCheckResult = useSelector((state: RootState) =>
    getStockUpdateCheckResult(state, pendingStockCheckId),
  );
  const disclosureStock = useDisclosure();
  const { onOpen: onOpenStockConfirmation, onClose } = disclosureStock;

  const selectedVehiclesReferences = useSelectedVehiclesReferencesMemo();
  const selectedExternalsReferences = useSelectedExternalsReferencesMemo();
  const selectedOtherReferences = useSelectedOtherReferencesMemo();
  useResetScroll();

  const referenceWithHighestQuantityMap = useMemo(
    () => getReferenceWithHighestQuantityMap(vehicles, otherSection?.references),
    [vehicles, otherSection.references],
  );

  const changedWarehouses: ReferenceCardStockValidation[] | NO_DATA = useMemo(() => {
    if (isLoading(stockUpdateCheckResult)) {
      return LOADING;
    }
    return getData(stockUpdateCheckResult)?.map((stock) => {
      const referenceNumber = stock.reference;
      const ret: ReferenceCardStockValidation = {
        reference: referenceNumber,
        confirmedQuantity: referenceWithHighestQuantityMap.get(referenceNumber)?.quantity ?? 1,
        name: referenceWithHighestQuantityMap.get(referenceNumber)?.name ?? '',
        quantity: referenceWithHighestQuantityMap.get(referenceNumber)?.quantity ?? 1,
        warehouses: stock.warehouses,
      };
      return ret;
    });
  }, [referenceWithHighestQuantityMap, stockUpdateCheckResult]);

  const handleCheckout = useCallback(() => {
    const referencesByVehicles = collectCheckoutVehicleReference(
      selectedVehiclesReferences,
      vehiclesValidation,
      orderMKTPDeliveryMode,
    );
    const referencesByExternalBasket = collectCheckoutExternalReference(
      selectedExternalsReferences,
      externalValidation,
      orderMKTPDeliveryMode,
    );
    const otherReferences = collectCheckoutOtherSection(
      selectedOtherReferences ?? [],
      otherSectionValidation,
      orderMKTPDeliveryMode,
    );
    dispatch(
      checkoutOrderRequestSaga({
        referencesByVehicles,
        referencesByExternalBasket,
        checkoutOtherSection: otherReferences,
        deliveryType: validationDeliveryAddress,
        paymentClientCode: billingAccount,
      }),
    );
    trackAppEvent(TRACKING_EVENT_CART_VALIDATION);
    next();
  }, [
    billingAccount,
    dispatch,
    validationDeliveryAddress,
    next,
    selectedOtherReferences,
    otherSectionValidation,
    selectedExternalsReferences,
    externalValidation,
    selectedVehiclesReferences,
    vehiclesValidation,
    orderMKTPDeliveryMode,
  ]);

  useEffect(() => {
    if (changedWarehouses && !isLoading(changedWarehouses)) {
      if (changedWarehouses.length > 0) {
        onOpenStockConfirmation();
      } else {
        handleCheckout();
      }
    }
  }, [changedWarehouses, onOpenStockConfirmation, handleCheckout]);

  useEffect(() => {
    const allClientCodes = [activeSeller?.primaryClientCode, ...(activeSeller?.secondaryClientCodes ?? [])].filter(
      (code): code is string => code !== undefined,
    );

    if (!allClientCodes.includes(billingAccount)) {
      setBillingAccount(activeSeller?.primaryClientCode ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSeller?.primaryClientCode]);

  useEffect(() => {
    dispatch(resetReferencesDeliveryType());
    return () => updateExadisDeliveryMode(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateStocks = () => {
    if (
      (!selectedVehiclesReferences || selectedVehiclesReferences?.length === 0) &&
      (!selectedOtherReferences || selectedOtherReferences?.length === 0) &&
      (!selectedExternalsReferences || selectedExternalsReferences.length === 0)
    ) {
      notifyTop('error', 'No items for checkout', null);
      return;
    }

    const references = Array.from(referenceWithHighestQuantityMap.values()).map((ref) => {
      return {
        referenceNumber: ref.referenceNumber,
        type: ref.type ?? 'STANDARD',
        quantity: ref.quantity,
        deliveryType: ref.deliveryType,
      };
    });
    const requestId = uuidv4();
    setPendingStockCheckId(requestId);
    dispatch(checkIfStocksAreStillUpToDateRequestSaga({ requestId, references }));
  };

  const resetVerificationPaymentMethods = (
    vehiclesToReset: VehicleLocal[],
    externalsToReset: ExternalBasketSectionLocal[],
  ) => {
    vehiclesToReset.forEach((vehicle) => {
      dispatch(
        resetValidationPaymentMethodSaga({ vehicleKey: vehicle.vehicleDetail.vehicleKey, externalBasketId: undefined }),
      );
    });
    externalsToReset.forEach((ext) => {
      dispatch(resetValidationPaymentMethodSaga({ vehicleKey: undefined, externalBasketId: ext.externalBasketId }));
    });
    dispatch(resetValidationPaymentMethodSaga({ vehicleKey: undefined, externalBasketId: undefined }));
  };

  const resetVerificationDeliveryAddress = (
    vehiclesToReset: VehicleLocal[],
    externalsToReset: ExternalBasketSectionLocal[],
  ) => {
    vehiclesToReset.forEach((vehicle) => {
      dispatch(
        resetValidationDeliveryAddressSaga({
          vehicleKey: vehicle.vehicleDetail.vehicleKey,
          externalBasketId: undefined,
        }),
      );
    });
    externalsToReset.forEach((ext) => {
      dispatch(resetValidationDeliveryAddressSaga({ vehicleKey: undefined, externalBasketId: ext.externalBasketId }));
    });
    dispatch(resetValidationDeliveryAddressSaga({ vehicleKey: undefined, externalBasketId: undefined }));
  };

  const updateDeliveryAddress = (address: OrderDeliveryType) => {
    setValidationDeliveryAddress(address);
    dispatch(setDefaultValidationDeliveryAddressSaga(address));
    resetVerificationDeliveryAddress(vehicles, externalBaskets);
  };

  const updateOrderMKTPDeliveryMode = (mode: OrderMKTPDeliveryMode) => {
    dispatch(setOrderMKTPDeliveryMode(mode));
  };

  const updateExadisDeliveryMode = (isExadisPickUp: boolean) => {
    dispatch(resetReferencesDeliveryType());
    dispatch(setExadisDeliveryMode(isExadisPickUp));
    //take actual snapshot of stocks for diff of red lines/references
    if (isExadisPickUp) {
      dispatch(setReferencesStocksSnapshotData());
    }
    const refsOther = selectedOtherReferences
      .filter(
        (r) =>
          r.catalogSource === 'IAM' &&
          r.brand !== 'MOTRIO' &&
          r.origin === 'IAM' &&
          !stocksR1BasketIAMWithoutMotrioReferences.find((s) => s?.reference === r.referenceNumber),
      )
      .map((r) => {
        return {
          referenceNumber: r.referenceNumber,
          type: r.type,
          quantity: r.quantity,
          origin: r.origin,
          supplierCode: r.supplierCode,
          referenceSource: r.referenceSource,
          isPrimaryStockRequest: isExadisPickUp,
        };
      });

    const refsVehicles = selectedVehiclesReferences.flatMap((v) => {
      return v.references
        .filter(
          (r) =>
            r.catalogSource === 'IAM' &&
            r.brand !== 'MOTRIO' &&
            r.origin === 'IAM' &&
            !stocksR1BasketIAMWithoutMotrioReferences.find((s) => s?.reference === r.referenceNumber),
        )
        .map((r) => {
          return {
            referenceNumber: r.referenceNumber,
            type: r.type,
            quantity: r.quantity,
            origin: r.origin,
            supplierCode: r.supplierCode,
            referenceSource: r.referenceSource,
            isPrimaryStockRequest: isExadisPickUp,
          };
        });
    });

    const refsExternal = selectedExternalsReferences.flatMap((e) => {
      return e.references
        .filter(
          (r) =>
            r.catalogSource === 'IAM' &&
            r.brand !== 'MOTRIO' &&
            r.origin === 'IAM' &&
            !stocksR1BasketIAMWithoutMotrioReferences.find((s) => s?.reference === r.referenceNumber),
        )
        .map((r) => {
          return {
            referenceNumber: r.referenceNumber,
            type: r.type,
            quantity: r.quantity,
            origin: r.origin,
            supplierCode: r.supplierCode,
            referenceSource: r.referenceSource,
            isPrimaryStockRequest: isExadisPickUp,
          };
        });
    });

    dispatch(fetchStocks({ requestStockDetails: [...refsOther, ...refsVehicles, ...refsExternal] }));
  };

  const updateBillingAccount = (account: string) => {
    setBillingAccount(account);
    if (delegateSession === undefined) {
      dispatch(setDefaultValidationPaymentMethodSaga(account));
    }
    resetVerificationPaymentMethods(vehicles, externalBaskets);
  };

  const handleCancelStock = () => {
    setPendingStockCheckId(undefined);
    history.push(ROUTER_CART);
    onClose();
  };

  const handleConfirmStock = () => {
    setPendingStockCheckId(undefined);
    handleCheckout();
    onClose();
  };
  const validationEntitiesCount =
    selectedVehiclesReferences.length + (selectedOtherReferences && selectedOtherReferences.length > 0 ? 1 : 0);

  return (
    <Flex direction={'row'} justify={'center'}>
      <Flex direction={'column'} maxWidth={1600} justify={'center'}>
        {hasData(changedWarehouses) && disclosureStock.isOpen && (
          <CardStockValidation
            referenceStock={changedWarehouses}
            disclosureStock={disclosureStock}
            handleConfirm={handleConfirmStock}
            handleCancel={handleCancelStock}
          />
        )}
        <Text type={'h1'}>{t('order.validation', 'Validate your order')}</Text>
        <MarginBox mt={36} />
        <Flex direction={'row'} align={'flex-start'}>
          <Flex direction={'column'} minWidth={1000}>
            <DeliveryAddressSection
              updateDeliveryAddress={updateDeliveryAddress}
              sellerName={activeSeller?.name}
              validationDeliveryAddress={validationDeliveryAddress}
              basketHasMKTPReference={basketHasMKTPReference}
              updateOrderMKTPDeliveryMode={updateOrderMKTPDeliveryMode}
              orderMKTPDeliveryMode={orderMKTPDeliveryMode}
              updateExadisDeliveryMode={updateExadisDeliveryMode}
              basketIAMReferencesCount={basketIAMWithoutMotrioReferencesCount}
              basketExadisDeliveryMode={basketExadisDeliveryMode}
            />
            <BillingAccountSection
              updateBillingAccount={updateBillingAccount}
              billingOptions={billingOptions}
              selectedBillingAccount={billingAccount}
            />
            <VerificationSection
              vehicles={selectedVehiclesReferences}
              billingOptions={billingOptions?.filter((option) => option.value !== billingAccount)}
              deliveryOptions={deliveryOptions?.filter((option) => option.value !== validationDeliveryAddress)}
              externalBaskets={selectedExternalsReferences ?? []}
              otherReferences={selectedOtherReferences}
              allowPaymentChanges={validationEntitiesCount > 1}
              allowAddressChanges={validationEntitiesCount > 1}
              basketExadisDeliveryMode={basketExadisDeliveryMode}
            />
          </Flex>
          {xlarge && (
            <StickyBox offsetTop={offsetTop + CART_STEPS_HEIGHT}>
              <MarginBox ml={30}>
                <CheckoutCardSection
                  totalPriceVatIncluded={totalPriceVatIncluded ?? 0}
                  totalPriceVatExcluded={totalPriceVatExcluded ?? 0}
                  totalVat={(totalPriceVatIncluded ?? 0) - (totalPriceVatExcluded ?? 0)}
                  currency={currency}
                  onCheckout={validateStocks}
                  loading={isLoading(stockUpdateCheckResult)}
                  vehicles={selectedVehiclesReferences ?? []}
                  externalBaskets={selectedExternalsReferences ?? []}
                  otherReferences={selectedOtherReferences}
                  validationStep
                  totalDiscount={totalDiscount ?? 0}
                />
              </MarginBox>
            </StickyBox>
          )}
        </Flex>
        {!xlarge && (
          <>
            <MarginBox ml={30} />
            <Flex direction={'row'}>
              <Flex />
              <StickyBox>
                <CheckoutCardSection
                  totalPriceVatIncluded={totalPriceVatIncluded ?? 0}
                  totalPriceVatExcluded={totalPriceVatExcluded ?? 0}
                  totalVat={(totalPriceVatIncluded ?? 0) - (totalPriceVatExcluded ?? 0)}
                  currency={currency}
                  onCheckout={validateStocks}
                  loading={isLoading(stockUpdateCheckResult)}
                  vehicles={selectedVehiclesReferences ?? []}
                  externalBaskets={selectedExternalsReferences ?? []}
                  otherReferences={selectedOtherReferences}
                  validationStep
                  totalDiscount={totalDiscount ?? 0}
                />
              </StickyBox>
              <Flex />
            </Flex>
            <MarginBox mt={30} />
          </>
        )}
      </Flex>
    </Flex>
  );
};

export default ValidationStep;
