/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from 'app/AppStore';
import BatteryInfoCard from 'components/BatteryInfo';
import { DataContainer } from 'components/DataContainer';
import Filter from 'components/Filter';
import { getFiltersMap, RangeFacetLocal, TextFacetLocal } from 'components/Filter/Filter.types';
import FilterQuantity from 'components/Filter/FilterQuantity';
import FilterTags from 'components/Filter/FilterTags';
import {
  getIamCatalogFilters,
  getLastSearchedVehicleKey,
  resetIamRangeFilters,
  resetIamTextFilters,
  setIamRangeFilter,
  setIamTextFilter,
} from 'domains/catalog/Catalog.store';
import { IAMLaborTimesWrapper, IAMReferencesWrapper, OTHER_BRAND_KEY } from 'domains/catalog/Catalog.types';
import { getIAMReferences } from 'domains/references';
import { getSparePartsView, SparePartsViewType } from 'domains/user';
import { getIamBatteryPathname } from 'pages/CatalogPage/common/QuickAccess/useQuickAccessLinks';
import ReferenceCardsContainer from 'pages/CatalogPage/IAM/SubcategorySection/SparePartsSection/ReferenceCardsContainer';
import { getIamContainerLoader } from 'pages/CatalogPage/IAM/SubcategorySection/SparePartsSection/ReferenceCardsContainer/ReferenceCardsContainer';
import { Flex, MarginBox } from 'UI';
import ViewTabs from 'UI/Tabs/ViewTabs';
import { ERROR, getData, LOADING, NO_DATA, NOT_FOUND, useLarge } from 'utils';
import { useResetScroll } from 'utils/hooks/useResetScroll';

export interface OtherBrandSectionProps {
  referencesWrapper: IAMReferencesWrapper;
  nodeId?: string;
  laborTimesWrapper: NO_DATA | IAMLaborTimesWrapper;
}

const resetOtherBrandFilters = (dispatch: Dispatch) => {
  dispatch(resetIamTextFilters({ filterType: OTHER_BRAND_KEY }));
  dispatch(resetIamRangeFilters({ filterType: OTHER_BRAND_KEY }));
};

const OtherBrandTab = ({
  sparePartsView,
  referencesWrapper,
  nodeId,
  laborTimesWrapper,
  setNumOfProducts,
}: {
  sparePartsView: SparePartsViewType;
  referencesWrapper: IAMReferencesWrapper;
  nodeId?: string;
  laborTimesWrapper: NO_DATA | IAMLaborTimesWrapper;
  setNumOfProducts: (num: number) => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const referencesWrapperData = getData(referencesWrapper);
  const filters = useSelector(getIamCatalogFilters);
  const large = useLarge();
  const [textFilters, setTextFilters] = useState<TextFacetLocal[]>(referencesWrapperData?.textFilters ?? []);
  const [rangeFilters, setRangeFilters] = useState<RangeFacetLocal[]>(referencesWrapperData?.rangeFilters ?? []);
  const referenceNumbers = referencesWrapperData?.referenceNumbers ?? [];
  const vehicleKey = useSelector(getLastSearchedVehicleKey) ?? '';
  const references = useSelector((state: RootState) => getIAMReferences(state, { vehicleKey, referenceNumbers }));
  const urlPathname = window.location.pathname;
  const iamBatteryPathname = getIamBatteryPathname();
  const isBatteryUrl = urlPathname.includes(iamBatteryPathname);
  const isCompatibleBatteries = references.some((r) => r.isBatteryCompatibleWithVehicle);

  useEffect(() => {
    setTextFilters(referencesWrapperData?.textFilters ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referencesWrapperData?.textFilters?.length, nodeId]);

  useEffect(() => {
    setRangeFilters(referencesWrapperData?.rangeFilters ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referencesWrapperData?.rangeFilters?.length, nodeId]);

  useEffect(() => {
    resetOtherBrandFilters(dispatch);
    if (referencesWrapper === NOT_FOUND || referencesWrapper === ERROR) {
      setNumOfProducts(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referencesWrapper]);

  return (
    <MarginBox mt={15}>
      <Flex direction={large ? 'row' : 'column'}>
        <Flex direction={'column'} size={0}>
          <MarginBox mb={15}>
            <BatteryInfoCard />
          </MarginBox>
          <Filter
            filters={filters.otherBrandFilters}
            textFilters={textFilters}
            rangeFilters={rangeFilters}
            setTextFilters={(id, item) => dispatch(setIamTextFilter({ id, item, filterType: OTHER_BRAND_KEY }))}
            setRangeFilters={(id, range) => dispatch(setIamRangeFilter({ id, range, filterType: OTHER_BRAND_KEY }))}
            resetFilters={() => resetOtherBrandFilters(dispatch)}
            loading={referencesWrapper === LOADING}
            facetsMap={getFiltersMap(t)}
          />
        </Flex>
        <MarginBox mr={large ? 15 : 0} mt={large ? 0 : 15} />
        <DataContainer data={referencesWrapper} Loading={getIamContainerLoader}>
          <ReferenceCardsContainer
            isCompatible={isBatteryUrl && isCompatibleBatteries}
            sparePartsView={sparePartsView}
            referenceNumbers={referenceNumbers}
            nodeId={nodeId}
            laborTimesWrapper={laborTimesWrapper}
            setNumOfProducts={setNumOfProducts}
            textFilters={textFilters}
            rangeFilters={rangeFilters}
            setTextFilters={setTextFilters}
            setRangeFilters={setRangeFilters}
          />
        </DataContainer>
      </Flex>
    </MarginBox>
  );
};

const OtherBrandSection = ({ referencesWrapper, nodeId, laborTimesWrapper }: OtherBrandSectionProps) => {
  const dispatch = useDispatch();
  const filters = useSelector(getIamCatalogFilters);
  const sparePartsView = useSelector(getSparePartsView);
  const [numOfProducts, setNumOfProducts] = useState<number>(0);

  useResetScroll();

  return (
    <>
      <FilterTags
        filters={filters.otherBrandFilters}
        setTextFilters={(id, item) => dispatch(setIamTextFilter({ id, item, filterType: OTHER_BRAND_KEY }))}
        setRangeFilters={(id, range) => dispatch(setIamRangeFilter({ id, range, filterType: OTHER_BRAND_KEY }))}
      />
      <MarginBox my={15} />
      <MarginBox mb={-30}>
        <FilterQuantity numberOfProducts={numOfProducts} />
      </MarginBox>
      <Flex direction={'column'}>
        <ViewTabs />
        <OtherBrandTab
          sparePartsView={sparePartsView}
          referencesWrapper={referencesWrapper}
          nodeId={nodeId}
          laborTimesWrapper={laborTimesWrapper}
          setNumOfProducts={setNumOfProducts}
        />
      </Flex>
    </>
  );
};

export default OtherBrandSection;
