import React, { useCallback, useEffect, useState } from 'react';
import { Slider } from 'antd-v5';

export interface InputSliderBaseProps {
  min?: number;
  max?: number;
}

export interface InputSliderProps extends InputSliderBaseProps {
  value: number;
  onChange?: (x: number) => void;
}

export const InputSlider = ({ value, onChange, min, max }: InputSliderProps) => {
  const [inputValue, setInputValue] = useState(value);

  const handleChange = useCallback(
    (val: number) => {
      setInputValue(val);
      if (onChange) {
        onChange(val);
      }
    },
    [setInputValue, onChange],
  );
  return <Slider value={inputValue} onChange={handleChange} min={min} max={max} />;
};

export interface InputRangeProps extends InputSliderBaseProps {
  value: number[];
  onChange?: (x: number[]) => void;
  tipFormatter?: ((value?: number | undefined) => React.ReactNode) | null;
  disabled?: boolean;
  step?: number;
}

export const InputRange = ({ value, onChange, min, max, tipFormatter, disabled, step }: InputRangeProps) => {
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleChange = useCallback(
    (val: number[]) => {
      setInputValue(val);
    },
    [setInputValue],
  );

  const handleOnChangeComplete = useCallback(
    (val: number[]) => {
      onChange && onChange(val);
    },
    [onChange],
  );

  return (
    <Slider
      range
      value={inputValue}
      onChange={handleChange}
      onChangeComplete={handleOnChangeComplete}
      disabled={disabled}
      min={min}
      step={step}
      max={max}
      tooltip={{ formatter: tipFormatter }}
    />
  );
};
