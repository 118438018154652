import { closestCorners, DndContext, DragEndEvent, DragStartEvent } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { DataContainer } from 'components/DataContainer';
import { useFetchMyStoreBundles } from 'domains/myStore/MyStore.requests';
import { getBundlesSections, moveBundlesSectionRequestSaga } from 'domains/myStore/MyStore.store';
import MyStoreHeader from 'pages/MyStorePage/MyStoreHeader';
import BundlesHeader from 'pages/MyStorePage/Pages/Bundles/BundlesHeader';
import BundlesSection from 'pages/MyStorePage/Pages/Bundles/BundlesSection';
import { CenteredSpin, Container, Flex, MarginBox } from 'UI';
import { restrictToContainerId } from 'utils/dnd-utils';
import { useResetScroll } from 'utils/hooks/useResetScroll';

const Bundles = () => {
  const bundlesSections = useSelector(getBundlesSections);
  const [isDraggingSectionId, setIsDraggingSectionId] = React.useState<string>('');
  const dispatch = useDispatch();

  const handleDragStart = (event: DragStartEvent) => {
    setIsDraggingSectionId(event.active.id as string);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    setIsDraggingSectionId('');
    dispatch(moveBundlesSectionRequestSaga(event));
  };

  useFetchMyStoreBundles();
  useResetScroll();

  return (
    <Flex direction={'column'}>
      <DataContainer
        data={bundlesSections.sections.searchStatus}
        Loading={() => (
          <MarginBox mt={'10%'}>
            <CenteredSpin />
          </MarginBox>
        )}
      >
        <MyStoreHeader rightSection={<BundlesHeader />} />
        <Container>
          <div id={'dnd-container'}>
            <Flex direction={'column'} gap={15}>
              <DndContext
                collisionDetection={closestCorners}
                onDragEnd={handleDragEnd}
                onDragStart={handleDragStart}
                modifiers={[restrictToVerticalAxis, restrictToContainerId('dnd-container')]}
              >
                <SortableContext items={bundlesSections.sections.data ?? []} strategy={verticalListSortingStrategy}>
                  {bundlesSections.sections.data?.map((bundlesSection, index) => (
                    <BundlesSection
                      key={bundlesSection.id}
                      sectionId={bundlesSection.id}
                      title={bundlesSection?.title ?? ''}
                      bundles={bundlesSection.bundles ?? []}
                      isDragging={bundlesSection.id === isDraggingSectionId}
                      initiallyOpen={index === 0}
                    />
                  ))}
                </SortableContext>
              </DndContext>
            </Flex>
          </div>
        </Container>
      </DataContainer>
    </Flex>
  );
};

export default Bundles;
