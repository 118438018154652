/* eslint-disable max-len */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Document } from '@1po/1po-bff-fe-spec/generated/catalog/references/iam/model/Document';
import { DataContainer } from 'components/DataContainer';
import { DocumentScrollModal } from 'components/DocumentScrollModal/DocumentScrollModal';
import { DefaultCarImage } from 'pages/CatalogPage/common';
import { SupersessionTooltip } from 'pages/CatalogPage/common/ReferenceSection/SupersessionTooltip';
import { Box, CenteredSpin, Flex, FlexJustifyType, Image, LinkRoundButton, MarginBox, Text, URL } from 'UI';
import { BreakpointSelectable } from 'utils';
import { SReferenceImageCard } from './IAMReferenceCard.styled';
import ReferenceImage from './ReferenceImage';

const FLEX_START: BreakpointSelectable<FlexJustifyType> = 'flex-start';
const FLEX_END: BreakpointSelectable<FlexJustifyType> = 'flex-end';

const SubstitutionButton = ({ handleShowMore }: { handleShowMore?: () => void }) => (
  <Flex justify={FLEX_END} align={FLEX_END}>
    <MarginBox mt={-15} mr={-15} />
    <SupersessionTooltip>
      <LinkRoundButton onClick={handleShowMore} />
    </SupersessionTooltip>
  </Flex>
);

interface DefaultCarImageProps {
  displaySubstitutionButton?: boolean;
  handleShowMore?: () => void;
}

export const DefaultCarPicture = ({ displaySubstitutionButton, handleShowMore }: DefaultCarImageProps) => (
  <Flex>
    <DefaultCarImage />
    {displaySubstitutionButton && (
      <Flex align={FLEX_END}>
        <MarginBox mr={-15} mt={55} />
        <SupersessionTooltip>
          <LinkRoundButton onClick={handleShowMore} />
        </SupersessionTooltip>
      </Flex>
    )}
  </Flex>
);

interface OEMImageSectionProps {
  vehicleImage?: string;
  displaySubstitutionButton?: boolean;
  handleShowMore?: () => void;
  styles?: {
    imageBackground?: string;
  };
}

const OEMImageSection = ({ vehicleImage, displaySubstitutionButton, handleShowMore, styles }: OEMImageSectionProps) => (
  <MarginBox mt={5}>
    <Box width={displaySubstitutionButton ? 135 : 120} height={displaySubstitutionButton ? 135 : 120}>
      <DataContainer
        data={vehicleImage}
        Loading={() => (
          <Box width={70}>
            <CenteredSpin />
          </Box>
        )}
        NotFound={() => (
          <DefaultCarPicture displaySubstitutionButton={displaySubstitutionButton} handleShowMore={handleShowMore} />
        )}
        Skeleton={() => (
          <DefaultCarPicture displaySubstitutionButton={displaySubstitutionButton} handleShowMore={handleShowMore} />
        )}
        ErrorState={() => (
          <DefaultCarPicture displaySubstitutionButton={displaySubstitutionButton} handleShowMore={handleShowMore} />
        )}
      >
        <Flex background={styles?.imageBackground}>
          <Image src={vehicleImage} type={URL} alt="carPic" width={120} height={120} />
          {displaySubstitutionButton && <SubstitutionButton handleShowMore={handleShowMore} />}
        </Flex>
      </DataContainer>
    </Box>
  </MarginBox>
);

interface NonOEMImageSectionProps {
  documents?: Document[];
  displaySubstitutionButton?: boolean;
  handleShowMore?: () => void;
  brandImage?: string;
  referenceImage?: string;
  styles?: {
    imageBackground?: string;
  };
}

const NonOEMImageSection = ({
  displaySubstitutionButton,
  handleShowMore,
  documents,
  brandImage,
  referenceImage,
  styles,
}: NonOEMImageSectionProps) => {
  const { t } = useTranslation();
  const [documentsModalVisible, setDocumentsModalVisible] = useState(false);
  const additionalElements = documents ? documents.length - 1 : 0;

  return (
    <MarginBox ml={5} mt={5} mr={15}>
      <Box width={displaySubstitutionButton ? 150 : 135} height={displaySubstitutionButton ? 135 : 120}>
        <Flex background={styles?.imageBackground}>
          <DocumentScrollModal
            documents={documents ?? []}
            visible={documentsModalVisible}
            onVisibleChange={setDocumentsModalVisible}
          >
            <SReferenceImageCard>
              <ReferenceImage brandImage={brandImage} referenceImage={referenceImage} />
            </SReferenceImageCard>
          </DocumentScrollModal>
          {displaySubstitutionButton && <SubstitutionButton handleShowMore={handleShowMore} />}
        </Flex>
        {documents && documents.length > 1 && (
          <Flex justify={FLEX_START}>
            <Text
              type="text"
              displayStyle="link"
              cursor="pointer"
              hoverUnderLine
              onClick={(e) => {
                setDocumentsModalVisible(true);
                e.stopPropagation();
              }}
            >
              {`+${additionalElements} ${t('common.elements', 'elements', { count: additionalElements })}`}
            </Text>
          </Flex>
        )}
      </Box>
    </MarginBox>
  );
};

interface PictureSectionProps {
  isOEM?: boolean;
  documents?: Document[];
  vehicleImage?: string;
  displaySubstitutionButton?: boolean;
  handleShowMore?: () => void;
  brandImage?: string;
  referenceImage?: string;
  styles?: {
    imageBackground?: string;
  };
}

export const PictureSection = ({
  isOEM,
  vehicleImage,
  displaySubstitutionButton,
  handleShowMore,
  documents,
  brandImage,
  referenceImage,
  styles,
}: PictureSectionProps) => {
  return isOEM ? (
    <OEMImageSection
      vehicleImage={vehicleImage}
      displaySubstitutionButton={displaySubstitutionButton}
      handleShowMore={handleShowMore}
      styles={styles}
    />
  ) : (
    <NonOEMImageSection
      displaySubstitutionButton={displaySubstitutionButton}
      handleShowMore={handleShowMore}
      documents={documents}
      brandImage={brandImage}
      referenceImage={referenceImage}
      styles={styles}
    />
  );
};
