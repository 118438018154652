import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTER_ESTIMATE } from 'app/AppRouter';
import { getCurrentEstimateId, getSelectedTab, setCurrentEstimateId } from 'domains/estimate/Estimate.store';
import { ESTIMATE_TAB, EstimateIdParam, EstimateTabName, EstimateTabParam } from 'domains/estimate/Estimate.types';
import EstimatePage from 'pages/EstimatePage/EstimatePage';

export const EstimateBase = () => {
  const selectedTab = useSelector(getSelectedTab);
  const globalStoreEstimateId = useSelector(getCurrentEstimateId);
  const params = useMemo(
    () => new URLSearchParams(location.search),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [globalStoreEstimateId, location.search],
  );
  const history = useHistory();
  const currentLocation = useLocation();
  const dispatch = useDispatch();
  const [tabKey, setTabKey] = useState<ESTIMATE_TAB>((params.get(EstimateTabParam) as ESTIMATE_TAB) || selectedTab);

  const estimateIdParam = params.get(EstimateIdParam);

  const [localEstimateId, setLocalEstimateId] = useState(globalStoreEstimateId);

  // set estimateId parameter properly in the URL and in store - do not set it in history and settings
  useEffect(() => {
    if (!tabKey) {
      setTabKey(EstimateTabName);
      history.replace(`${ROUTER_ESTIMATE}?${EstimateTabParam}=estimate&${EstimateIdParam}=${globalStoreEstimateId}`);
      return;
    }

    if (tabKey !== params.get(EstimateTabParam)) {
      setTabKey(params.get(EstimateTabParam) as ESTIMATE_TAB);
      return;
    }
    if (tabKey !== EstimateTabName) {
      return;
    }

    // udpate from UUID
    if (
      estimateIdParam &&
      estimateIdParam.substring(estimateIdParam.length - 36) ===
        globalStoreEstimateId.substring(globalStoreEstimateId.length - 36) &&
      globalStoreEstimateId.length > 36
    ) {
      history.replace(`${ROUTER_ESTIMATE}?${EstimateTabParam}=estimate&${EstimateIdParam}=${globalStoreEstimateId}`);
      dispatch(setCurrentEstimateId(globalStoreEstimateId));
      return;
    }

    // globalStoreEstimateId was updated -> update local state and url
    if (globalStoreEstimateId && globalStoreEstimateId !== localEstimateId) {
      setLocalEstimateId(globalStoreEstimateId);
      history.replace(`${ROUTER_ESTIMATE}?${EstimateTabParam}=estimate&${EstimateIdParam}=${globalStoreEstimateId}`);
      return;
    }

    // url estimateIdParam was updated -> update local state and global state
    if (estimateIdParam && estimateIdParam !== localEstimateId) {
      setLocalEstimateId(estimateIdParam);
      dispatch(setCurrentEstimateId(estimateIdParam));
      return;
    }

    if (!estimateIdParam && globalStoreEstimateId) {
      if (currentLocation.pathname === '/estimates' && currentLocation.search === '?tab=estimate') {
        history.replace(`${ROUTER_ESTIMATE}?${EstimateTabParam}=estimate&${EstimateIdParam}=${globalStoreEstimateId}`);
        return;
      } else {
        history.push(`${ROUTER_ESTIMATE}?${EstimateTabParam}=estimate&${EstimateIdParam}=${globalStoreEstimateId}`);
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estimateIdParam, globalStoreEstimateId, dispatch, history, tabKey, selectedTab, params]);
  return <EstimatePage estimateId={localEstimateId} tabKey={tabKey} setTabKey={setTabKey} />;
};
