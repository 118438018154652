/* eslint-disable max-len */
import { StatusType } from '@1po/1po-bff-fe-spec/generated/backoffice/common/StatusType';
import { FlashPromotionBanner } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/model/FlashPromotionBanner';
import { FullPromotion } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/model/FullPromotion';
import { AdjustPromotionByR1Request } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/request/AdjustPromotionByR1Request';
import { ChangeStatusTypePromotion } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/request/ChangeStatusTypePromotion';
import { DeletePromotion } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/request/DeletePromotion';
import { GetCurrentPromotions } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/request/GetCurrentPromotions';
import { GetDashboardPromotions } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/request/GetDashboardPromotions';
import { GetFullPromotionForEdit } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/request/GetFullPromotionForEdit';
import { GetPromotion } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/request/GetPromotion';
import { SubscribeToNumberOfNotYetAdjustedPromotions } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/request/SubscribeToNumberOfNotYetAdjustedPromotions';
import { AdjustPromotionByR1Response } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/response/AdjustPromotionByR1Response';
import { CurrentPromotionsResponse } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/response/CurrentPromotionsResponse';
import { DashboardPromotionsResponse } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/response/DashboardPromotionsResponse';
import { PromotionBannersResponse } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/response/PromotionBannersResponse';
import { PromotionBannersUpdateResponse } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/response/PromotionBannersUpdateResponse';
import { Promotion } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/response/PromotionResponse';
import { PromotionTargetedAudienceFileUploadResponse } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/response/PromotionTargetedAudienceFileUploadResponse';
import { UpdateCurrentPromotionsResponse } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/response/UpdateCurrentPromotionsResponse';
import { FileUploadRequest } from '@1po/1po-bff-fe-spec/generated/common/file_upload/request/FileUploadRequest';
import {
  BACKOFFICE_ADJUST_PROMOTION_BY_R1_RESPONSE,
  BACKOFFICE_ARCHIVE_PROMOTION_RESPONSE,
  BACKOFFICE_CURRENT_PROMOTIONS_RESPONSE,
  BACKOFFICE_DASHBOARD_PROMOTIONS_RESPONSE,
  BACKOFFICE_DELETE_PROMOTION_RESPONSE,
  BACKOFFICE_DELETE_PROMOTION_SUBSCRIPTION_RESPONSE,
  BACKOFFICE_FULL_PROMOTION_RESPONSE,
  BACKOFFICE_NUMBER_OF_NOT_YET_ADJUSTED_PROMOTIONS_RESPONSE,
  BACKOFFICE_PRODUCTS_VALIDATION_RESPONSE,
  BACKOFFICE_PROMOTION_BANNERS_RESPONSE,
  BACKOFFICE_PROMOTION_BANNERS_UPDATE_RESPONSE,
  BACKOFFICE_PROMOTION_RESPONSE,
  BACKOFFICE_PROMOTION_UPLOAD_REFERENCES_FILE_RESPONSE,
  BACKOFFICE_PROMOTION_UPLOAD_TARGETED_AUDIENCE_FILE_RESPONSE,
  BACKOFFICE_PROMOTION_GET_FAMILY_CODES_RESPONSE,
  BACKOFFICE_PUBLISH_PROMOTION_RESPONSE,
  BACKOFFICE_SAVE_PROMOTION_RESPONSE,
  BACKOFFICE_UPDATE_CURRENT_PROMOTIONS_RESPONSE,
} from '@1po/1po-bff-fe-spec/generated/common/ResponseType';
import { createAction, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { v4 as uuidV4 } from 'uuid';
import { RootState } from 'app/AppStore';
import { FILE, NO_FILE } from 'components/FileUpload/paramsByState';
import {
  ADJUST_PROMOTION_BY_R1_REQUEST,
  ARCHIVE_PROMOTION_REQUEST,
  CreatePromotionModel,
  DELETE_PROMOTION_REQUEST,
  DuplicateReferenceAdjustment,
  GET_CURRENT_PROMOTIONS_REQUEST,
  GET_DASHBOARD_PROMOTIONS_REQUEST,
  GET_FAMILY_CODES_REQUEST,
  GET_FULL_PROMOTION_REQUEST,
  GET_NOTIFICATION_NUMBER,
  GET_PROMOTION_BANNERS_REQUEST,
  GET_PROMOTION_REQUEST,
  LoadedPromotions,
  PROMOTION_NAMESPACE,
  PromotionBannerCarousel,
  PromotionFilterTypeOption,
  PromotionState,
  SAVE_PROMOTION_REQUEST,
  SUBSCRIBE_CURRENT_PROMOTIONS_REQUEST,
  UPLOAD_REFERENCES_FILE,
  UPLOAD_TARGETED_AUDIENCE_FILE,
  UploadedReferencesFileInfo,
  VALIDATE_PRODUCTS,
} from 'domains/promotion/Promotion.types';
import { FOUND, getData, hasData, localeCompareSort, NO_DATA, NOT_FOUND, SEARCH_STATUS } from 'utils';

function switchLoadedPromotions(statusType: StatusType, state: Draft<PromotionState>): Draft<LoadedPromotions> {
  switch (statusType) {
    case 'PUBLISHED':
      return state.loadedPromotions.published;
    case 'PLANNED':
      return state.loadedPromotions.planned;
    case 'SAVED':
      return state.loadedPromotions.saved;
    case 'ARCHIVED':
      return state.loadedPromotions.archived;
  }
}

function deleteBanner(
  promotionId: string,
  banners: NO_DATA | FlashPromotionBanner[] | PromotionBannerCarousel[],
): boolean {
  if (!hasData(banners)) {
    return false;
  }
  const index = banners.findIndex((x) => x.promotionId === promotionId);
  if (index > -1) {
    banners.splice(index, 1);
    return true;
  }
  return false;
}

export function initialCreatePromotion(): CreatePromotionModel {
  return {
    promotionId: uuidV4(),
    promotionType: 'DISCOUNT',
    title: undefined,
    catchLine: undefined,
    discountType: undefined,
    description: undefined,
    publicationStart: undefined,
    publicationEnd: undefined,
    image: undefined,
    containsCustomImage: true,
    targetAudienceType: 'NETWORK',
    targetAudience: {},
    uploadedTargetedAudienceFromFile: {
      rows: [],
      status: NO_FILE,
    },
    quantityLimitFlashPromotion: undefined,
    uploadedReferencesFromFiles: [{ fileId: uuidV4(), rows: [], status: NO_FILE, discount: 0 }],
    commercialFamilyCodes: undefined,
    isSaved: false,
    editing: false,
    additionalInformation: {
      containsAdditionalTitle: false,
      containsAdditionalCatchLine: false,
      containsAdditionalDescription: false,
      containsAdditionalImage: false,
      title: undefined,
      catchLine: undefined,
      description: undefined,
      image: undefined,
      discount: undefined,
    },
    duplicatePromotionIds: [],
  };
}

const initLoadedPromotion: LoadedPromotions = {
  data: [],
  cursor: undefined,
  hasNextPage: true,
  status: undefined,
};

// Init state
const initialState: PromotionState = {
  publishedResponseMap: new Map(),
  duplicatePromotions: [],
  createPromotion: initialCreatePromotion(),
  currentPromotionDetails: {
    data: new Map(),
    cursor: undefined,
    hasNextPage: true,
    status: undefined,
  },
  fullPromotions: new Map(),
  flashPromotionBanners: undefined,
  promotionBanners: undefined,
  loadedPromotions: {
    published: initLoadedPromotion,
    planned: initLoadedPromotion,
    saved: initLoadedPromotion,
    archived: initLoadedPromotion,
  },
  notification: {
    showNotification: false,
    unadjustedPromoCount: undefined,
  },
  familyCodes: {
    familyCodes: [],
    familyCodesLanguage: '',
  },
};

// Saga actions
export const fetchSavePromotionRequestSaga = createAction<{
  shouldPublish: boolean;
}>(SAVE_PROMOTION_REQUEST);
export const handleSavePromotionResponseSaga = createAction(BACKOFFICE_SAVE_PROMOTION_RESPONSE);
export const handlePublishPromotionResponseSaga = createAction(BACKOFFICE_PUBLISH_PROMOTION_RESPONSE);
export const fetchArchivePromotionRequestSaga = createAction<ChangeStatusTypePromotion>(ARCHIVE_PROMOTION_REQUEST);
export const handleArchivePromotionResponseSaga = createAction(BACKOFFICE_ARCHIVE_PROMOTION_RESPONSE);
export const deletePromotionRequestSaga = createAction<DeletePromotion>(DELETE_PROMOTION_REQUEST);
export const handledDeletePromotionResponseSaga = createAction<DeletePromotion>(BACKOFFICE_DELETE_PROMOTION_RESPONSE);
export const handleDeletePromotionSubscriptionResponseSaga = createAction<DeletePromotion>(
  BACKOFFICE_DELETE_PROMOTION_SUBSCRIPTION_RESPONSE,
);
export const adjustPromotionByR1RequestSaga = createAction<AdjustPromotionByR1Request>(ADJUST_PROMOTION_BY_R1_REQUEST);
export const fetchAdjustPromotionByR1ResponseSaga = createAction<AdjustPromotionByR1Response>(
  BACKOFFICE_ADJUST_PROMOTION_BY_R1_RESPONSE,
);
export const fetchPromotionRequestSaga = createAction<GetPromotion>(GET_PROMOTION_REQUEST);
export const fetchPromotionResponseSaga = createAction(BACKOFFICE_PROMOTION_RESPONSE);
export const subscribeCurrentPromotionsRequestSaga = createAction(SUBSCRIBE_CURRENT_PROMOTIONS_REQUEST);
export const fetchUpdateCurrentPromotionsResponseSaga = createAction<UpdateCurrentPromotionsResponse>(
  BACKOFFICE_UPDATE_CURRENT_PROMOTIONS_RESPONSE,
);
export const fetchCurrentPromotionsRequestSaga = createAction<GetCurrentPromotions>(GET_CURRENT_PROMOTIONS_REQUEST);
export const fetchCurrentPromotionsResponseSaga = createAction(BACKOFFICE_CURRENT_PROMOTIONS_RESPONSE);
export const fetchFullPromotionRequestSaga = createAction<GetFullPromotionForEdit>(GET_FULL_PROMOTION_REQUEST);
export const fetchFullPromotionResponseSaga = createAction(BACKOFFICE_FULL_PROMOTION_RESPONSE);
export const fetchPromotionBannersRequestSaga = createAction(GET_PROMOTION_BANNERS_REQUEST);
export const fetchPromotionBannersResponseSaga = createAction(BACKOFFICE_PROMOTION_BANNERS_RESPONSE);
export const fetchPromotionBannersUpdateResponseSaga = createAction(BACKOFFICE_PROMOTION_BANNERS_UPDATE_RESPONSE);
export const uploadReferencesFileSaga = createAction<{
  fileName?: string;
  fileId?: string;
  fileBase64: string;
  promotion: CreatePromotionModel;
}>(UPLOAD_REFERENCES_FILE);
export const fetchReferencesFileUploadResponseSaga = createAction(BACKOFFICE_PROMOTION_UPLOAD_REFERENCES_FILE_RESPONSE);
export const validateProductsRequestSaga = createAction<{ promotion: CreatePromotionModel }>(VALIDATE_PRODUCTS);
export const fetchProductsValidationResponseSaga = createAction(BACKOFFICE_PRODUCTS_VALIDATION_RESPONSE);

export const uploadTargetedAudienceFileSaga = createAction<FileUploadRequest>(UPLOAD_TARGETED_AUDIENCE_FILE);
export const fetchTargetedAudienceFileUploadResponseSaga = createAction(
  BACKOFFICE_PROMOTION_UPLOAD_TARGETED_AUDIENCE_FILE_RESPONSE,
);
export const fetchDashboardPromotionsRequestSaga = createAction<{
  getPromotions: GetDashboardPromotions;
  activeFilters: string[];
  promotionFilterTypes: PromotionFilterTypeOption[];
}>(GET_DASHBOARD_PROMOTIONS_REQUEST);
export const handleDashboardPromotionsResponseSaga = createAction(BACKOFFICE_DASHBOARD_PROMOTIONS_RESPONSE);

export const fetchSubscribeToNumberOfNotYetAdjustedPromotionsRequestSaga = createAction<SubscribeToNumberOfNotYetAdjustedPromotions>(
  GET_NOTIFICATION_NUMBER,
);
export const fetchPromotionNotificationResponseSaga = createAction(
  BACKOFFICE_NUMBER_OF_NOT_YET_ADJUSTED_PROMOTIONS_RESPONSE,
);

export const fetchFamilyCodesRequestSaga = createAction(GET_FAMILY_CODES_REQUEST);
export const fetchFamilyCodesResponseSaga = createAction(BACKOFFICE_PROMOTION_GET_FAMILY_CODES_RESPONSE);

// Slice
const slice = createSlice({
  name: PROMOTION_NAMESPACE,
  initialState,
  reducers: {
    setInitialState: () => initialState,
    setCreatePromotion: (state, { payload }: PayloadAction<CreatePromotionModel>) => {
      state.createPromotion = payload;
    },
    setResubscribeableFieldsUndefined: (state) => {
      state.publishedResponseMap = new Map();
      state.currentPromotionDetails = {
        data: new Map(),
        cursor: undefined,
        hasNextPage: true,
        status: undefined,
      };
      state.fullPromotions = new Map();
      state.flashPromotionBanners = undefined;
      state.promotionBanners = undefined;
      state.loadedPromotions = {
        published: initLoadedPromotion,
        planned: initLoadedPromotion,
        saved: initLoadedPromotion,
        archived: initLoadedPromotion,
      };
    },
    setPromotion: (state, { payload }: PayloadAction<{ promotion: Promotion }>) => {
      const { promotion } = payload;
      state.currentPromotionDetails.data.set(promotion.promotionId, promotion);
    },
    setPromotionDetailDataStatus: (state, { payload }: PayloadAction<{ id: string; status: NO_DATA }>) => {
      state.currentPromotionDetails.data.set(payload.id, payload.status);
    },
    setCurrentPromotions: (state, { payload }: PayloadAction<CurrentPromotionsResponse>) => {
      const { promotions, cursor, hasNextPage } = payload;
      state.currentPromotionDetails.cursor = cursor;
      state.currentPromotionDetails.hasNextPage = hasNextPage;
      promotions?.forEach((promotion) => {
        state.currentPromotionDetails.data.set(promotion.promotionId, promotion);
      });
    },
    unsetCurrentPromotion: (state, action: PayloadAction<string>) => {
      state.currentPromotionDetails.data.delete(action.payload);
    },
    unsetSavedAndPlannedPromotion: (state, action: PayloadAction<string>) => {
      state.loadedPromotions.saved.data = state.loadedPromotions.saved.data.filter(
        (promotion) => promotion.promotionId !== action.payload,
      );
      state.loadedPromotions.planned.data = state.loadedPromotions.planned.data.filter(
        (promotion) => promotion.promotionId !== action.payload,
      );
    },
    unsetCurrentPromotions: (state) => {
      state.currentPromotionDetails.data = new Map();
    },
    reloadCurrentPromotions: (state) => {
      state.currentPromotionDetails = {
        data: new Map(),
        cursor: undefined,
        hasNextPage: true,
        status: undefined,
      };
    },
    setCurrentPromotionsDataStatus: (state, { payload }: PayloadAction<{ status: SEARCH_STATUS }>) => {
      state.currentPromotionDetails.status = payload.status;
    },
    setFullPromotion: (state, { payload }: PayloadAction<{ promotion: FullPromotion }>) => {
      state.fullPromotions.set(payload.promotion.promotionId, payload.promotion);
    },
    setFullPromotionState: (state, { payload }: PayloadAction<{ id: string; status: NO_DATA }>) => {
      state.fullPromotions.set(payload.id, payload.status);
    },
    setInitialCreatePromotion: (state) => {
      state.createPromotion = initialCreatePromotion();
    },
    setPublishedPromotionStatus: (state, { payload }: PayloadAction<{ id: string; status: boolean | NO_DATA }>) => {
      state.publishedResponseMap.set(payload.id, payload.status);
    },
    resetPublishedPromotionStatus: (state, { payload }: PayloadAction<{ id: string }>) => {
      state.publishedResponseMap.delete(payload.id);
    },
    setPromotionBanners: (state, { payload }: PayloadAction<PromotionBannersResponse>) => {
      state.promotionBanners = payload.promotionBanners.map((banner) => ({
        ...banner,
        showInCarousel: true,
      }));
      state.flashPromotionBanners = payload.flashPromotionBanners;
    },
    reloadPromotionBanners: (state) => {
      state.promotionBanners = undefined;
      state.flashPromotionBanners = undefined;
    },
    unsetPromotionBanner: (state, action: PayloadAction<string>) => {
      const promotionId = action.payload;
      const flashPromotionBannerDeleted = deleteBanner(promotionId, state.flashPromotionBanners);
      if (!flashPromotionBannerDeleted) {
        deleteBanner(promotionId, state.promotionBanners);
      }
    },
    setPromotionUpdateBanners: (state, { payload }: PayloadAction<PromotionBannersUpdateResponse>) => {
      switch (payload.type) {
        case 'DELETED': {
          const arr = getData(state.flashPromotionBanners) ?? [];
          const flashPromotionBannersToRemove = payload.flashPromotionBanners;
          if (arr.length > 0 && flashPromotionBannersToRemove && flashPromotionBannersToRemove.length > 0) {
            const arrNew = arr.filter(
              (promo) => !flashPromotionBannersToRemove.map((i) => i.promotionId).includes(promo.promotionId),
            );
            state.flashPromotionBanners = arrNew;
          }
          break;
        }
        case 'UPDATED': {
          const arr = getData(state.flashPromotionBanners) ?? [];
          const flashPromotionBannersToUpdate = payload.flashPromotionBanners;
          if (arr.length > 0 && flashPromotionBannersToUpdate && flashPromotionBannersToUpdate.length > 0) {
            const newArray = arr.map((banner) =>
              banner.promotionId === flashPromotionBannersToUpdate[0].promotionId
                ? {
                    ...banner,
                    remainingQty: flashPromotionBannersToUpdate[0].remainingQty,
                  }
                : banner,
            );
            state.flashPromotionBanners = newArray;
          }
          break;
        }
        default:
          break;
      }
    },
    setShowPromotionNotification: (state, { payload }: PayloadAction<boolean>) => {
      state.notification.showNotification = payload;
    },
    setPromotionNotificationNumber: (state, { payload }: PayloadAction<number>) => {
      const previousNotificationCount = state.notification.unadjustedPromoCount;
      const newNotificationCount = payload;

      if (previousNotificationCount !== undefined && previousNotificationCount < newNotificationCount) {
        state.notification.showNotification = true;
      }
      state.notification.unadjustedPromoCount = newNotificationCount;
    },
    setPromotionBannersStatus: (state, { payload }: PayloadAction<NO_DATA>) => {
      state.flashPromotionBanners = payload;
    },
    setCreatePromotionAlreadyPromotedCommercialFamilyCodes: (state, { payload }: PayloadAction<string[]>) => {
      const alreadyPromotedCodes = payload;
      state.createPromotion.commercialFamilyCodes = state.createPromotion.commercialFamilyCodes?.map((section) => {
        return {
          ...section,
          items: section.items.map((item) => {
            return {
              ...item,
              alreadyPromoted: alreadyPromotedCodes.includes(item.item),
            };
          }),
        };
      });
    },
    setCreatePromotionAlreadyPromotedQuantityLimitedProduct: (
      state,
      { payload }: PayloadAction<{ duplicate: string | undefined }>,
    ) => {
      const { duplicate } = payload;
      const promotedProductSection = state.createPromotion.references?.[0];
      const sectionProduct = promotedProductSection ? promotedProductSection.items?.[0] : undefined;
      if (promotedProductSection && sectionProduct) {
        state.createPromotion.references = [
          {
            ...promotedProductSection,
            items: [{ ...sectionProduct, alreadyPromoted: !!duplicate }],
          },
        ];
      }
    },
    setDuplicatePromotionIds: (state, { payload }: PayloadAction<{ duplicatePromotionIds: string[] }>) => {
      const { duplicatePromotionIds } = payload;
      state.createPromotion.duplicatePromotionIds = duplicatePromotionIds;
    },
    setDuplicatePromotions: (state, { payload }: PayloadAction<{ duplicatePromotions: Promotion[] }>) => {
      const { duplicatePromotions } = payload;
      state.duplicatePromotions = duplicatePromotions;
    },
    setUploadedReferencesFromFileRows: (
      state,
      {
        payload,
      }: PayloadAction<{
        fileId: string;
        correctRows: string[];
        duplicates: string[];
      }>,
    ) => {
      const { fileId, correctRows, duplicates } = payload;

      const promo = state.createPromotion;
      const getFirstCommercialFamilyDiscount = () => {
        if (promo.commercialFamilyCodes && promo.commercialFamilyCodes?.length > 0) {
          return promo?.commercialFamilyCodes[0].discount;
        }
        return undefined;
      };
      const getFirstReferencesDiscount = () => {
        if (promo.references && promo.references?.length > 0) {
          return promo?.references[0].discount;
        }
        return undefined;
      };
      const position = state.createPromotion.uploadedReferencesFromFiles.findIndex((r) => r.fileId === fileId);

      const resolveDiscount = (): number => {
        if (state.createPromotion.promotionType === 'PROMOTION_FLASH_TIME_LIMITED') {
          return getFirstReferencesDiscount() ?? getFirstCommercialFamilyDiscount() ?? 0;
        }
        return 0;
      };
      const discount = resolveDiscount();
      const receivedRows = correctRows.map((reference) => reference.toUpperCase());
      const localDuplicates = state.createPromotion.uploadedReferencesFromFiles.flatMap((s, i) => {
        if (i === position) {
          return [];
        }
        return s.rows?.filter((row) => receivedRows.includes(row)) ?? [];
      });
      const rows = receivedRows.filter((r) => !localDuplicates.includes(r));

      if (position === -1) {
        state.createPromotion.uploadedReferencesFromFiles.push({
          alreadyPromoted: duplicates,
          alreadyLocallyPromoted: localDuplicates,
          status: FILE,
          fileId,
          discount,
          rows,
        });
      } else {
        state.createPromotion.uploadedReferencesFromFiles[position] = {
          ...state.createPromotion.uploadedReferencesFromFiles[position],
          alreadyPromoted: duplicates,
          alreadyLocallyPromoted: localDuplicates,
          status: FILE,
          fileId,
          rows,
        };
      }
    },
    setValidatedReferencesForFileRows: (
      state,
      {
        payload,
      }: PayloadAction<{
        duplicates: string[];
      }>,
    ) => {
      const { duplicates } = payload;
      const uploadedReferencesFromFiles = state.createPromotion.uploadedReferencesFromFiles;

      state.createPromotion.uploadedReferencesFromFiles = uploadedReferencesFromFiles.map((section) => {
        const alreadyPromotedUpdated = [...(section.alreadyPromoted ?? [])]?.reduce(
          (alreadyPromotedArr: DuplicateReferenceAdjustment, ref) => {
            duplicates.includes(ref) ? alreadyPromotedArr.promoted.push(ref) : alreadyPromotedArr.rows.push(ref);
            return alreadyPromotedArr;
          },
          { promoted: [], rows: [] },
        );
        const rowsUpdated: DuplicateReferenceAdjustment = [...(section.rows ?? [])]?.reduce(
          (rowsArr: DuplicateReferenceAdjustment, ref) => {
            duplicates.includes(ref) ? rowsArr.promoted.push(ref) : rowsArr.rows.push(ref);
            return rowsArr;
          },
          { promoted: [], rows: [] },
        );

        const rows: string[] = localeCompareSort([...alreadyPromotedUpdated.rows, ...rowsUpdated.rows]);
        const alreadyPromoted: string[] = localeCompareSort([
          ...alreadyPromotedUpdated.promoted,
          ...rowsUpdated.promoted,
        ]);

        return {
          ...section,
          alreadyPromoted,
          rows,
        };
      });
    },
    setUploadedTargetedAudienceFromFileRows: (
      state,
      { payload }: PayloadAction<PromotionTargetedAudienceFileUploadResponse>,
    ) => {
      state.createPromotion.uploadedTargetedAudienceFromFile.rows = payload.correctRows;
      state.createPromotion.uploadedTargetedAudienceFromFile.status = FILE;
      state.createPromotion.targetAudience.dealers = payload.correctRows;
    },
    setUploadedReferencesFromFileSizeAndName: (
      state,
      { payload }: PayloadAction<{ fileSize: number; fileId: string; fileName: string }>,
    ) => {
      const fileId = payload.fileId;
      const position = state.createPromotion.uploadedReferencesFromFiles.findIndex((r) => r.fileId === fileId);
      if (position === -1) {
        state.createPromotion.uploadedReferencesFromFiles.push({
          fileId,
          rows: [],
          status: NO_FILE,
          fileSize: payload.fileSize,
          fileName: payload.fileName,
          discount: 0,
          uploadTime: new Date().toISOString(),
        });
      } else {
        state.createPromotion.uploadedReferencesFromFiles[position] = {
          ...state.createPromotion.uploadedReferencesFromFiles[position],
          fileSize: payload.fileSize,
          fileName: payload.fileName,
          uploadTime: new Date().toISOString(),
        };
      }
    },
    setUploadedReferencesDiscount: (state, { payload }: PayloadAction<{ fileId: string; discount: number }>) => {
      const fileId = payload.fileId;
      const position = state.createPromotion.uploadedReferencesFromFiles.findIndex((r) => r.fileId === fileId);

      if (position === -1) {
        state.createPromotion.uploadedReferencesFromFiles.push({
          fileId,
          rows: [],
          status: NO_FILE,
          discount: payload.discount,
        });
      } else {
        state.createPromotion.uploadedReferencesFromFiles[position] = {
          ...state.createPromotion.uploadedReferencesFromFiles[position],
          discount: payload.discount,
        };
      }
    },
    setNewUploadedReference: (state, { payload }: PayloadAction<{ fileId: string }>) => {
      const fileId = payload.fileId;
      state.createPromotion.uploadedReferencesFromFiles.push({
        fileId,
        rows: [],
        status: NO_FILE,
        discount: 0,
      });
    },
    setUploadedTargetedAudienceFromFileSizeAndName: (
      state,
      { payload }: PayloadAction<{ fileSize: number; fileName: string }>,
    ) => {
      state.createPromotion.uploadedTargetedAudienceFromFile.fileSize = payload.fileSize;
      state.createPromotion.uploadedTargetedAudienceFromFile.fileName = payload.fileName;
    },
    removeUploadedReferencesRows: (state, { payload }: PayloadAction<{ fileId: string }>) => {
      const fileId = payload.fileId;
      const position = state.createPromotion.uploadedReferencesFromFiles.findIndex((r) => r.fileId === fileId);
      if (position !== -1) {
        state.createPromotion.uploadedReferencesFromFiles[position].rows = undefined;
      }
    },
    removeUploadedTargetedAudienceRows: (state) => {
      state.createPromotion.uploadedTargetedAudienceFromFile.rows = undefined;
    },
    setLoadedPromotions: (state, { payload }: PayloadAction<DashboardPromotionsResponse>) => {
      const loadedPromotions = switchLoadedPromotions(payload.status, state);
      loadedPromotions.data.push(...payload.promotions);
      loadedPromotions.cursor = payload.cursor;
      loadedPromotions.hasNextPage = payload.hasNextPage;
      loadedPromotions.status = payload.promotions.length === 0 ? NOT_FOUND : FOUND;
    },
    setLoadedPromotionsSearchStatus: (
      state,
      { payload }: PayloadAction<{ statusType: StatusType; searchStatus: SEARCH_STATUS }>,
    ) => {
      const loadedPromotions = switchLoadedPromotions(payload.statusType, state);
      loadedPromotions.status = payload.searchStatus;
    },
    setFamilyCodes: (state, { payload }) => {
      state.familyCodes.familyCodes = payload.familyCodes;
      state.familyCodes.familyCodesLanguage = payload.familyCodesLanguage;
    },
    removePromotionFromDashboard: (state, { payload }: PayloadAction<{ status: StatusType; promotionId: string }>) => {
      const { status, promotionId } = payload;
      const loadedPromotions = switchLoadedPromotions(status, state);
      loadedPromotions.data = loadedPromotions.data.filter((d) => d.promotionId !== promotionId);
      if (loadedPromotions.data.length === 0) {
        loadedPromotions.data = initLoadedPromotion.data;
        loadedPromotions.cursor = initLoadedPromotion.cursor;
        loadedPromotions.hasNextPage = initLoadedPromotion.hasNextPage;
        loadedPromotions.status = initLoadedPromotion.status;
      }
    },
    resetLoadedPromotions: (state, { payload }: PayloadAction<StatusType>) => {
      const loadedPromotions = switchLoadedPromotions(payload, state);
      loadedPromotions.data = initLoadedPromotion.data;
      loadedPromotions.cursor = initLoadedPromotion.cursor;
      loadedPromotions.hasNextPage = initLoadedPromotion.hasNextPage;
      loadedPromotions.status = initLoadedPromotion.status;
    },
    updateUploadedFileInfo: (
      state,
      { payload }: PayloadAction<{ fileId: string; fileName: string; fileSize: number }>,
    ) => {
      const fileId = payload.fileId;
      const newFileInfo: UploadedReferencesFileInfo = {
        fileId: payload.fileId,
        fileName: payload.fileName,
        fileSize: payload.fileSize,
        created: new Date().toISOString(),
      };

      if (!state.createPromotion.referenceFileInfos) {
        state.createPromotion.referenceFileInfos = [newFileInfo];
      }

      const position = state.createPromotion.referenceFileInfos.findIndex((r) => r.fileId === fileId);

      if (position === -1) {
        state.createPromotion.referenceFileInfos.push(newFileInfo);
      } else {
        state.createPromotion.referenceFileInfos[position] = newFileInfo;
      }
    },
    removeUploadedFileInfo: (state, { payload }: PayloadAction<{ fileId: string }>) => {
      const fileId = payload.fileId;

      if (!state.createPromotion.referenceFileInfos) {
        return;
      }

      const position = state.createPromotion.referenceFileInfos.findIndex((r) => r.fileId === fileId);

      if (position === -1) {
        return;
      }

      state.createPromotion.referenceFileInfos.splice(position, 1);

      state.createPromotion.referenceFileInfos.forEach((info, index) => {
        if (position > index) {
          return;
        }
      });
    },
  },
});

// Actions
export const {
  setInitialState,
  setCreatePromotion,
  setInitialCreatePromotion,
  setResubscribeableFieldsUndefined,
  setPublishedPromotionStatus,
  resetPublishedPromotionStatus,
  setPromotion,
  setPromotionDetailDataStatus,
  setCurrentPromotions,
  unsetCurrentPromotion,
  unsetSavedAndPlannedPromotion,
  unsetCurrentPromotions,
  reloadCurrentPromotions,
  setCurrentPromotionsDataStatus,
  setFullPromotion,
  setFullPromotionState,
  setPromotionBanners,
  setPromotionBannersStatus,
  setShowPromotionNotification,
  setPromotionNotificationNumber,
  reloadPromotionBanners,
  unsetPromotionBanner,
  setPromotionUpdateBanners,
  setCreatePromotionAlreadyPromotedCommercialFamilyCodes,
  setCreatePromotionAlreadyPromotedQuantityLimitedProduct,
  setDuplicatePromotionIds,
  setDuplicatePromotions,
  setUploadedReferencesFromFileRows,
  setValidatedReferencesForFileRows,
  setUploadedTargetedAudienceFromFileRows,
  setUploadedReferencesFromFileSizeAndName,
  setUploadedReferencesDiscount,
  setNewUploadedReference,
  setUploadedTargetedAudienceFromFileSizeAndName,
  removeUploadedReferencesRows,
  removeUploadedTargetedAudienceRows,
  setLoadedPromotions,
  setLoadedPromotionsSearchStatus,
  resetLoadedPromotions,
  removePromotionFromDashboard,
  setFamilyCodes,
  updateUploadedFileInfo,
  removeUploadedFileInfo,
} = slice.actions;

// Getters/Selectors

export const getCreatePromotion = createSelector(
  (state: RootState) => state.promotion,
  (promotion) => promotion.createPromotion,
);

export const getCurrentPromotionDetails = createSelector(
  (state: RootState) => state.promotion,
  (_state: RootState, promotionId: string) => promotionId,
  (promotion, promotionId) => {
    if (!promotionId) return undefined;
    return promotion.currentPromotionDetails.data.get(promotionId);
  },
);

export const getFullPromotion = createSelector(
  (state: RootState) => state.promotion,
  (_state: RootState, promotionId: string) => promotionId,
  (promotion, promotionId) => {
    if (!promotionId) return undefined;
    return promotion.fullPromotions.get(promotionId);
  },
);

export const getFlashPromotionBanners = createSelector(
  (state: RootState) => state.promotion,
  (promotion) => promotion.flashPromotionBanners,
);

export const getPromotionBanners = createSelector(
  (state: RootState) => state.promotion,
  (promotion) => promotion.promotionBanners,
);

export const getCurrentPromotions = createSelector(
  (state: RootState) => state.promotion,
  (promotion) => promotion.currentPromotionDetails,
);
export const getCurrentPromotionsData = createSelector(
  (state: RootState) => state.promotion.currentPromotionDetails,
  (promotionDetails) => {
    const promotions = promotionDetails.data;
    const promotionsData = Array.from(promotions.values()).map((promo) => getData(promo));
    return promotionsData.filter((p) => p) as Promotion[];
  },
);

export const getFamilyCodes = createSelector(
  (state: RootState) => state.promotion.familyCodes.familyCodes,
  (familyCodes) => familyCodes,
);

export const getFamilyCodesLanguage = createSelector(
  (state: RootState) => state.promotion.familyCodes.familyCodesLanguage,
  (familyCodesLanguage) => familyCodesLanguage,
);

export const getUploadedReferencesFromFile = createSelector(
  (state: RootState) => state.promotion.createPromotion.uploadedReferencesFromFiles,
  (uploadedReferencesFromFiles) => uploadedReferencesFromFiles,
);

export const getUploadedTargetedAudienceFromFile = createSelector(
  (state: RootState) => state.promotion.createPromotion.uploadedTargetedAudienceFromFile,
  (uploadedTargetedAudienceFromFile) => uploadedTargetedAudienceFromFile,
);

export const getPublishedStatus = createSelector(
  (state: RootState) => state.promotion.publishedResponseMap,
  (_state: RootState, promotionId: string | undefined) => promotionId,
  (publishedResponseMap, promotionId) => {
    if (!promotionId) return undefined;
    return publishedResponseMap.get(promotionId);
  },
);

export const getShowNotification = createSelector(
  (state: RootState) => state.promotion.notification.showNotification,
  (showNotification) => showNotification,
);

export const getUnadjustedPromoCount = createSelector(
  (state: RootState) => state.promotion.notification.unadjustedPromoCount,
  (unadjustedPromoCount) => unadjustedPromoCount,
);

export const getLoadedPromotions = createSelector(
  (state: RootState) => state.promotion.loadedPromotions,
  (_state: RootState, status: StatusType) => status,
  (loadedPromotions, status) => {
    switch (status) {
      case 'PUBLISHED':
        return loadedPromotions.published;
      case 'PLANNED':
        return loadedPromotions.planned;
      case 'SAVED':
        return loadedPromotions.saved;
      case 'ARCHIVED':
        return loadedPromotions.archived;
    }
  },
);

export const findStatusTypeByPromotionID = createSelector(
  (state: RootState) => state.promotion.loadedPromotions,
  (_state: RootState, id: string | undefined) => id,
  (loadedPromotions, id): StatusType | undefined => {
    const p1 = loadedPromotions.published.data.find((p) => p.promotionId === id);
    if (p1 !== undefined) return 'PUBLISHED';
    const p2 = loadedPromotions.planned.data.find((p) => p.promotionId === id);
    if (p2 !== undefined) return 'PLANNED';
    const p3 = loadedPromotions.saved.data.find((p) => p.promotionId === id);
    if (p3 !== undefined) return 'SAVED';
    const p4 = loadedPromotions.archived.data.find((p) => p.promotionId === id);
    if (p4 !== undefined) return 'ARCHIVED';
  },
);

export const getDuplicatePromotions = createSelector(
  (state: RootState) => state.promotion,
  (_state: RootState, promotionIds: string[]) => promotionIds,
  (promotion, promotionIds) => {
    if (!promotionIds) return undefined;
    return promotion.duplicatePromotions.filter((duplicatePromotion) =>
      promotionIds.includes(duplicatePromotion.promotionId),
    );
  },
);

export const hasDuplicateCommercialFamilyCodes = (createPromotion: CreatePromotionModel): boolean => {
  return !!createPromotion.commercialFamilyCodes?.some((section) => section.items.length > 0);
};

export const hasDuplicateReferences = (createPromotion: CreatePromotionModel): boolean => {
  return !!createPromotion.uploadedReferencesFromFiles?.some(
    (refSection) =>
      (refSection.rows && refSection.rows.length > 0) ||
      (refSection.alreadyPromoted && refSection.alreadyPromoted.length > 0),
  );
};

export const hasCreatePromotionAnyProductsFilled = (createPromotion: CreatePromotionModel): boolean => {
  const hasCFCodes = hasDuplicateCommercialFamilyCodes(createPromotion);
  const hasReferences = hasDuplicateReferences(createPromotion);

  const hasProduct = !!createPromotion.references?.some((prodSection) => prodSection.items.length > 0);

  return hasCFCodes || hasReferences || hasProduct;
};

export default slice.reducer;
