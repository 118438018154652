import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MyStoreBundle } from '@1po/1po-bff-fe-spec/generated/my_store/bundles/response/GetMyStoreBundlesResponse';
import { ConfigProvider } from 'antd';
import { ROUTER_ESTIMATE } from 'app/AppRouter';
import { trackAppEvent } from 'app/AppTracker';
import { AddToEstimateButtonAndDialog } from 'components/AddToEstimate/AddToEstimateButtonAndDialog';
import { addBundleFromMyStore, setSelectedTab } from 'domains/estimate/Estimate.store';
import { EstimateTabName } from 'domains/estimate/Estimate.types';
import {
  getAllBundleCodes,
  removeBundleRequestSaga,
  updateBundleCodeRequestSaga,
  updateBundleDesignationRequestSaga,
  updateBundlePriceRequestSaga,
} from 'domains/myStore/MyStore.store';
import { STable } from 'pages/EstimatePage/EstimatePage.styled';
import { GetTitle } from 'pages/EstimatePage/TableSection/tableComponents';
import { SelectBundles } from 'pages/MyStorePage/Pages/Bundles/BundlesSection';
import { ValidatedPriceInput, ValidatedTextInput } from 'pages/MyStorePage/Pages/Bundles/BundlesTable/BundlesInput';
import { CenterFlex, Flex, NotificationLink, notifyTop, Text, TrashAltButton } from 'UI';
import { TRACKING_EVENT_ADD_TO_ESTIMATE, TRACKING_EVENT_GO_TO_ESTIMATE } from 'utils/eventTracker/EventTracker.types';

export const NoBundlesMessage = () => {
  const { t } = useTranslation();

  return (
    <Flex justify={'center'}>
      <Text type={'h2'}>
        {t('my_store.bundles.no_bundles_in_category', 'There are no bundles available in this category.')}
      </Text>
    </Flex>
  );
};

const BundlesTable = ({ sectionId, bundles }: { sectionId: string; bundles: MyStoreBundle[] }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const allBundleCodes = useSelector(getAllBundleCodes);
  const dispatch = useDispatch();

  const isBundleCodeUnique = (newBundleCode: string, oldBundleCode: string) => {
    if (newBundleCode === oldBundleCode) {
      return true;
    }
    return !allBundleCodes.includes(newBundleCode);
  };

  const handleAddToEstimateClick = (bundle: MyStoreBundle) => {
    notifyTop(
      'success',
      <Trans i18nKey={'estimate.bundles.addedToEstimate'}>{'Bundle has been added to your estimate.'}</Trans>,
      undefined,
      <NotificationLink
        onClick={() => {
          trackAppEvent(TRACKING_EVENT_GO_TO_ESTIMATE);
          dispatch(setSelectedTab(EstimateTabName));
          history.push(`${ROUTER_ESTIMATE}`);
        }}
      >
        <Trans i18nKey={'catalog.reference_card.added_to_estimate.go_to_estimate'}>{'Go to estimate'}</Trans>
      </NotificationLink>,
    );
    trackAppEvent(TRACKING_EVENT_ADD_TO_ESTIMATE);
    dispatch(addBundleFromMyStore([bundle]));
  };

  function isBundleRowValid(row: MyStoreBundle): boolean {
    return !!row && !!row?.code && !!row?.price && !!row?.designation;
  }

  const columns = [
    {
      title: (
        <CenterFlex>
          <SelectBundles sectionId={sectionId} enabled={true} />
        </CenterFlex>
      ),
      dataIndex: 'id',
      render: (bundleId: string, row: MyStoreBundle) => (
        <CenterFlex>
          <SelectBundles sectionId={sectionId} bundleId={bundleId} enabled={isBundleRowValid(row)} />
        </CenterFlex>
      ),
      width: 48,
    },
    {
      title: GetTitle(t('estimate.bundles.bundle_code', 'Bundle code')),
      dataIndex: 'code',
      render: (code: string, row: MyStoreBundle) => (
        <ValidatedTextInput
          value={code}
          validate={(v) => v.length > 0 && isBundleCodeUnique(v, code)}
          saveFunction={(bundleCode) => {
            if (bundleCode !== code) {
              dispatch(updateBundleCodeRequestSaga({ sectionId, bundleId: row.id, bundleCode }));
            }
          }}
        />
      ),
      width: 300,
    },
    {
      title: GetTitle(t('common.designation', 'Designation')),
      dataIndex: 'designation',
      render: (designation: string, row: MyStoreBundle) => (
        <ValidatedTextInput
          value={designation}
          validate={(v) => v.length > 0}
          saveFunction={(bundleDesignation) => {
            if (bundleDesignation !== designation) {
              dispatch(updateBundleDesignationRequestSaga({ sectionId, bundleId: row.id, bundleDesignation }));
            }
          }}
        />
      ),
    },
    {
      title: GetTitle(t('common.price.VAT_excl_price', 'VAT Excl. Price')),
      dataIndex: 'price',
      render: (price: string, row: MyStoreBundle) => (
        <ValidatedPriceInput
          value={price}
          validate={(v) => v !== undefined && v != null && v?.toString()?.length > 0}
          saveFunction={(bundlePrice) => {
            if (bundlePrice && bundlePrice !== price) {
              dispatch(updateBundlePriceRequestSaga({ sectionId, bundleId: row.id, bundlePrice: Number(bundlePrice) }));
            }
          }}
        />
      ),
      width: 170,
    },
    {
      title: '',
      dataIndex: 'id',
      render: (bundleId: string) => (
        <CenterFlex>
          <TrashAltButton
            onClick={() => {
              dispatch(removeBundleRequestSaga({ sectionId, bundleId }));
            }}
          />
        </CenterFlex>
      ),
      width: 48,
    },
    {
      title: '',
      render: (row: MyStoreBundle) => (
        <CenterFlex>
          <AddToEstimateButtonAndDialog
            buttonComponent={'icon'}
            disabled={!isBundleRowValid(row)}
            handleAddToEstimateClick={() => handleAddToEstimateClick(row)}
          />
        </CenterFlex>
      ),
      width: 48,
    },
  ];

  return (
    <ConfigProvider renderEmpty={() => <NoBundlesMessage />}>
      <STable columns={columns} dataSource={bundles} pagination={false} rowKey={'id'} />
    </ConfigProvider>
  );
};

export default BundlesTable;
