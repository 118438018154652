import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getLastTireProductSearchParams, getTireCatalogFilters } from 'domains/tires/Tire.store';
import { TireFilterParamPrefix } from 'domains/tires/Tire.types';
import { stringifyUrlQueryParams } from 'utils';

export const useUpdateTireUrlQueryParams = (): void => {
  const lastProductSearchParams = useSelector(getLastTireProductSearchParams);
  const tireFilters = useSelector(getTireCatalogFilters);
  const history = useHistory();

  useEffect(() => {
    let urlQueryParams = '';
    if (lastProductSearchParams) {
      urlQueryParams += `${lastProductSearchParams}`;
    }
    if (tireFilters?.textFilters instanceof Map && tireFilters?.textFilters.size > 0) {
      const tireFilterParams = stringifyUrlQueryParams(
        Object.fromEntries(
          Array.from(tireFilters.textFilters).map(([key, value]) => [`${TireFilterParamPrefix}${key}`, value]),
        ),
      );
      urlQueryParams += `${urlQueryParams ? '&' : '?'}${tireFilterParams}`;
    }
    if (urlQueryParams) {
      history.replace({ search: urlQueryParams });
    }
  }, [history, lastProductSearchParams, tireFilters]);
};
