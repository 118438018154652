import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { LaborTime } from '@1po/1po-bff-fe-spec/generated/catalog/labor_time/response/GetLaborTimesResponse';
import { Settings as EstimateSettings } from '@1po/1po-bff-fe-spec/generated/estimate/response/model/Settings';
import { Popover } from 'antd';
import { useTheme } from 'styled-components';
import { EyeIcon, FlagIcon, InfoCircleIcon } from 'assets/icons';
import { useIsB2B } from 'components/B2BComponents/useIsB2B';
import { Dialog, useDisclosure } from 'components/Dialog';
import QuantityModule from 'components/QuantityModule';
import { LaborTimeLocal } from 'domains/laborTime/LaborTime.types';
import { getCurrency } from 'domains/user';
import { getTestText, hasTest } from 'pages/CatalogPage/DH/SubcategorySection/LaborTimeSection/LaborTimeSection.types';
import {
  getLaborDetailList,
  getTechnicityFieldData,
} from 'pages/CatalogPage/DH/SubcategorySection/LaborTimeSection/utils';
import { BlackButton, Box, Flex, Icon, MarginBox, Pipeline, Text } from 'UI';
import { textFormatter } from 'utils';
import { EstimateLaborTimeDetail, PopoverContentInApplicability, PopoverContentInTime } from './LaborTimeSection';
import { IconWrapper, SDialogContent, SDialogContentRow, ZPopover } from './LaborTimeSection.styled';

export interface EstimateColumnProps {
  row: LaborTimeLocal;
  laborTimeToQuantity: Map<string, EstimateLaborTimeDetail>;
  addLaborTimeToEstimate: (laborTimeLocal: LaborTimeLocal, testLaborTimeToQuantityMap: Map<string, number>) => void;
  updateLaborTimeQuantity: (laborTimeCode: string, newQuantity: number) => void;
  isTechnicitySet: boolean;
  estimateSettings: EstimateSettings | undefined;
}

export function EstimateColumn({
  row,
  laborTimeToQuantity,
  addLaborTimeToEstimate,
  updateLaborTimeQuantity,
  isTechnicitySet,
  estimateSettings,
}: EstimateColumnProps) {
  const { t } = useTranslation();
  const disclosure = useDisclosure();
  const { onOpen } = disclosure;
  const theme = useTheme();
  const currency = useSelector(getCurrency);
  const tests = row.subLaborTimes?.testLaborTimes;
  const isB2B = useIsB2B();
  const estimateLaborTimeDetail = laborTimeToQuantity.get(row.code);
  const [testToQuantityMap, setTestToQuantityMap] = useState<Map<string, number>>(
    () =>
      tests?.reduce((acc: Map<string, number>, next: LaborTime) => acc.set(next.code, 1), new Map<string, number>()) ??
      new Map<string, number>(),
  );

  const updateTestToQuantityMap = (code: string, quantity: number | string) => {
    setTestToQuantityMap((map) => new Map(map.set(code, Number(quantity))));
  };

  const updateLaborTimeQuantityCallback = (newQuantity: number | string) => {
    if (estimateLaborTimeDetail) {
      updateLaborTimeQuantity(estimateLaborTimeDetail.itemId, Number(newQuantity));
    }
  };

  function buttonClick() {
    if (hasTest(row.designation.essay)) {
      onOpen();
    } else {
      addLaborTimeToEstimate(row, new Map());
    }
  }

  const displayAddToEstimateButton = () => {
    const isIncluded = row.isSubLaborTime && row.isIncluded;
    return !isIncluded && row.code?.length > 0;
  };

  return (
    <Flex justify={'center'}>
      {displayAddToEstimateButton() &&
        (estimateLaborTimeDetail ? (
          <QuantityModule
            value={estimateLaborTimeDetail.quantity}
            onChange={updateLaborTimeQuantityCallback}
            showDelete={true}
          />
        ) : (
          <BlackButton
            stretch={false}
            onClick={buttonClick}
            disabled={!isTechnicitySet || isB2B}
            dataCy={'button-add-to-estimate'}
          >
            <Trans i18nKey={'catalog.action.add_to_estimate'}>{'Add to estimate'}</Trans>
          </BlackButton>
        ))}
      <Dialog
        disclosure={disclosure}
        title={t('common.dialog.actionRequired', 'Action required')}
        description={[
          t('catalog.parts.category.car_parts.labor_time.dialog.action_require_test', 'This action require a test.'),
          t(
            'catalog.parts.category.car_parts.labor_time.dialog.add_test_to_estimate',
            'Would you like to add this test to your estimate?',
          ),
        ]}
        content={
          testToQuantityMap &&
          tests?.map((testRow, index) => {
            const laborDetailList = getLaborDetailList(
              testRow.time,
              testRow.technicity,
              estimateSettings?.laborPriceList,
            );
            const missingLaborTimeRate = laborDetailList.find(
              (labordDetail) => labordDetail.laborTimeRate === undefined,
            );
            const priceVATExcl = !missingLaborTimeRate
              ? laborDetailList.reduce((total, laborDetail) => {
                  if (!laborDetail.laborTimeRate) {
                    return total;
                  }
                  const laborTimeRate = parseFloat(laborDetail.laborTimeRate);
                  return isNaN(laborTimeRate) ? total : total + laborTimeRate * laborDetail.time;
                }, 0)
              : undefined;

            return (
              <SDialogContent key={index}>
                <SDialogContentRow>
                  <Box>
                    <Text type={'h2'}>{testRow.code}</Text>
                  </Box>
                  <Box>
                    <Text type={'highlight'}>{testRow.designation.title}</Text>
                  </Box>
                  <Flex size={0} align={'center'}>
                    <Text type={'highlight'}>{testRow.time.totalHours}</Text>
                    <MarginBox mr={5} />
                    <ZPopover
                      content={<PopoverContentInTime time={testRow.time} />}
                      placement={'bottomLeft'}
                      trigger="hover"
                    >
                      <IconWrapper>
                        <Icon IconComponent={EyeIcon} color={'grey'} hoverFill={'black'} height={20} width={20} />
                      </IconWrapper>
                    </ZPopover>
                  </Flex>
                  <Text type={'light_12_medium_black_85'}>{getTestText(testRow.designation.essay, t)}</Text>
                </SDialogContentRow>
                <Pipeline size={'100%'} horizontal={true} color={theme.color.grey85} />
                <SDialogContentRow>
                  <MarginBox mr={15}>
                    <Flex direction={'column'} align={'center'}>
                      {laborDetailList.map((laborDetail) => {
                        return (
                          <Text type={'lead_dim'} key={`${laborDetail.laborType}_${laborDetail.laborLevel}`}>
                            {getTechnicityFieldData(
                              t,
                              false,
                              laborDetail.laborType,
                              laborDetail.laborLevel,
                              laborDetail.laborTimeRate,
                            )}
                          </Text>
                        );
                      })}
                    </Flex>
                  </MarginBox>

                  {testRow.applicability?.length > 0 ? (
                    <Popover
                      content={<PopoverContentInApplicability laborTime={testRow} />}
                      placement={'bottomLeft'}
                      trigger="hover"
                    >
                      <IconWrapper>
                        <Icon
                          IconComponent={InfoCircleIcon}
                          color={'grey'}
                          hoverFill={'black'}
                          height={20}
                          width={20}
                        />
                      </IconWrapper>
                    </Popover>
                  ) : (
                    <IconWrapper />
                  )}

                  {priceVATExcl && (
                    <Text type={'highlight'} disableGutter>
                      {textFormatter.formatCurrency(priceVATExcl, currency)}
                    </Text>
                  )}
                  <QuantityModule
                    value={testToQuantityMap.get(testRow.code) ?? 1}
                    onChange={(newQuantity) => updateTestToQuantityMap(testRow.code, newQuantity)}
                    noDebounce
                  />
                </SDialogContentRow>
              </SDialogContent>
            );
          })
        }
        icon={FlagIcon}
        status={'info'}
        handleConfirm={() => addLaborTimeToEstimate(row, testToQuantityMap)}
        handleCancel={() => addLaborTimeToEstimate(row, new Map())}
        buttonsText={{
          cancel: t('catalog.parts.category.car_parts.labor_time.dialog.cancel_test', 'Cancel test'),
          confirm: t('catalog.parts.category.car_parts.labor_time.dialog.add_test', 'Add test'),
        }}
      />
    </Flex>
  );
}
