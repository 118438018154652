import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReferenceStock } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/model/ReferenceStock';
import { useTheme } from 'styled-components';
import { Ban, ExadisStoreIcon } from 'assets/icons';
import { Flex, Icon, Text } from 'UI';
import { ERROR, LOADING, SearchData } from 'utils';

const containsConfirmedQuantity = (stock: SearchData<ReferenceStock> | undefined) =>
  (stock?.data?.warehouses ?? []).filter((warehouse) => warehouse.confirmedQuantity > 0).length > 0;

export function isEmptyStock(stock: SearchData<ReferenceStock> | undefined): boolean {
  if (stock === undefined || stock?.searchStatus === LOADING || stock?.searchStatus === ERROR) {
    return false;
  }
  return !containsConfirmedQuantity(stock);
}

export function EmptyStock({ isExadisPickUp }: Readonly<{ isExadisPickUp?: boolean }>) {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Flex align={'center'} gap={5}>
      <Icon IconComponent={isExadisPickUp ? ExadisStoreIcon : Ban} size={20} color={theme.color.error} noPointer />
      <Text type={isExadisPickUp ? 'light_14_bold_black_65' : 'light_14_black_65'} displayStyle={'error'}>
        {isExadisPickUp
          ? t('catalog.reference.stock.insufficient_exadis_quantity', 'Insufficient quantity for Exadis Pick-Up')
          : t('catalog.reference.stock.out_of_stock', 'Out of stock:')}
      </Text>
      {!isExadisPickUp && (
        <Text type={'light_14_black_65'}>
          {t('catalog.reference.stock.out_of_stock.part_unavailable', 'Part actually unavailable')}
        </Text>
      )}
    </Flex>
  );
}
