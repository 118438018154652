import { APP_NAMESPACE } from 'app/App.constants';
import {
  API_CALL_ERROR,
  FILE_FORMAT_ERROR,
  FILE_STRUCTURE_ERROR,
  ModalStatusType,
  NO_FILE,
  UPLOADING_FILE,
} from 'components/FileUpload/paramsByState';
import {
  FETCHING_FILE,
  FILE_FETCHED,
  FILE_REPLACED,
  FILE_UPLOADED,
  REPLACING_FILE,
  SAME_FILE,
} from 'domains/electronicPartsRepair/ElectronicPartsRepair.types';
import { SEARCH_STATUS } from 'utils';

export const DISCONTINUED_REFERENCES_NAMESPACE = `${APP_NAMESPACE}/DISCONTINUED_REFERENCES`;

export interface DiscontinuedReferencesState {
  fileUploadStatus: DiscontinuedReferencesFileUploadStatus;
  discontinuedReferences: string[];
  fileInformation: DiscontinuedReferencesFileInformation;
  fileInformationForRequest: DiscontinuedReferencesFileInformation;
  searchStatus: SEARCH_STATUS;
}

export interface DiscontinuedReferencesFileUploadStatus {
  fileStatus: DiscontinuedReferencesFileStatus;
  modalStatus: ModalStatusType;
}

export const UPLOAD_FILE = `${DISCONTINUED_REFERENCES_NAMESPACE}/UPLOAD_FILE`;
export const GET_FILE = `${DISCONTINUED_REFERENCES_NAMESPACE}/GET_FILE`;

export type DiscontinuedReferencesFileStatus =
  | typeof FETCHING_FILE
  | typeof REPLACING_FILE
  | typeof NO_FILE
  | typeof UPLOADING_FILE
  | typeof FILE_UPLOADED
  | typeof FILE_FORMAT_ERROR
  | typeof FILE_STRUCTURE_ERROR
  | typeof FILE_REPLACED
  | typeof SAME_FILE
  | typeof FILE_FETCHED
  | typeof API_CALL_ERROR;

export interface DiscontinuedReferencesFileInformation {
  fileName: string;
}
