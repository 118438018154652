import queryString from 'query-string';

export function head<T>(values: T[] | undefined): T | undefined {
  return values && values.length > 0 ? values[0] : undefined;
}

export function mergeMaps<K, V>(map1: Map<K, V>, map2: Map<K, V>): Map<K, V> {
  return new Map<K, V>([...map1, ...map2]);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function mergeObjectInMap<K, V, O extends V>(map: Map<K, V>, key: K, object: O): void {
  const val = map.get(key);
  const newObject = val ? mergeDeep(val, object) : getNotEmpty(object);
  map.set(key, newObject);
}

const isObject = (obj: any): boolean => obj && typeof obj === 'object';

function getNotEmpty(source: any) {
  if (isObject(source)) {
    const isEmpty = getArrayWithoutUndefinedMembers(Object.values(source));
    return isEmpty.length === 0 ? undefined : source;
  }
  return source;
}

/**
 * @author inspired by [jhildenbiddle](https://stackoverflow.com/a/48218209).
 */
function mergeDeep(target: any, source: any) {
  if (!isObject(target) || !isObject(source)) {
    return getNotEmpty(source);
  }

  Object.keys(source).forEach((key) => {
    const targetValue = target[key];
    const sourceValue = source[key];

    if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
      target[key] = sourceValue;
    } else if (isObject(targetValue) && isObject(sourceValue)) {
      target[key] = mergeDeep(Object.assign({}, targetValue), sourceValue);
    } else {
      target[key] = sourceValue;
    }
  });

  return target;
}

export function compareArrays<T>(a: T[], b: T[]): boolean {
  return a.length === b.length && a.every((element, index) => element === b[index]);
}

/**
 * Sorts array of strings based on Numeric values
 * @param array
 */
export function localeCompareSort(array: string[]): string[] {
  return array.sort(function (a, b) {
    return a.localeCompare(b, undefined, {
      numeric: true,
      sensitivity: 'base',
    });
  });
}

export const getArrayWithoutUndefinedMembers = <T>(arr: Array<T | undefined>): Array<T> => arr.filter((x) => x) as T[];

export function decodeBase64(base64: string): string {
  try {
    return atob(base64);
  } catch (e) {
    return '';
  }
}

export const stringifyUrlQueryParams = (obj: unknown): string => {
  try {
    return queryString.stringify(obj as Record<string, unknown>, {
      arrayFormat: 'comma',
      skipEmptyString: true,
      skipNull: true,
    });
  } catch (e) {
    return '';
  }
};

export const toStringArray = (value: unknown): string[] => {
  if (value === null || value === undefined || !value) {
    return [];
  }

  if (Array.isArray(value)) {
    return value.filter((item) => item !== null && item !== undefined && item).map((item) => String(item));
  }

  return [String(value)];
};

export const isNonEmptyString = (value: unknown): boolean => {
  return typeof value === 'string' && value.trim().length > 0;
};
