import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Season } from '@1po/1po-bff-fe-spec/generated/tire/model/Season';
import { TireSeasonSelectionProps } from 'components/TireSeasonSelection';
import { parseTireUrlQueryParams, toSearchParams } from 'domains/tires/Tire.mapper';
import {
  getTireSearchParams,
  setSearchParams,
  setTireSearchQuery,
  setTireSetParam,
  tireProductsSearchRequestSaga,
} from 'domains/tires/Tire.store';
import { ALL } from 'domains/tires/Tire.types';
import { getFavoriteBrandPreferences } from 'domains/user';
import { TireBrandSelectionProps } from 'pages/TiresPage/TireSearch/TireBrandSelection';
import { TireDimensionsSearchBar } from 'pages/TiresPage/TireSearch/TireDimensionsSearchBar';
import { TiresSearchbar } from 'pages/TiresPage/TireSearch/TiresSearchbar';
import { tireBrandsData, TireBrandType } from 'pages/TiresPage/TireSearch/TiresSearchbar/TireBrandsData';
import { Box, Flex, MarginBox, Text } from 'UI';
import { useLarge } from 'utils';

export type TireSearchProps = Omit<TireSeasonSelectionProps, 'menuOpen' | 'setMenuOpen'> & TireBrandSelectionProps;

const TireSearch = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const large = useLarge();
  const searchParamsBase64 = useSelector(getTireSearchParams);
  const favoriteBrandsList = useSelector(getFavoriteBrandPreferences);
  const favoriteBrands = useMemo(() => {
    return tireBrandsData.map((brand) => {
      if (favoriteBrandsList.includes(brand.brand)) {
        return { ...brand, selected: true };
      }
      return brand;
    });
  }, [favoriteBrandsList]);
  const [brands, setBrands] = useState<TireBrandType[]>(favoriteBrands);
  const [season, setSeason] = useState<Season | undefined>(ALL);

  useEffect(() => {
    const parsedParams = parseTireUrlQueryParams(location.search);

    if (parsedParams?.query) {
      if (parsedParams.tireSet) {
        dispatch(setTireSetParam(parsedParams.tireSet));
      }
      dispatch(
        tireProductsSearchRequestSaga({
          query: parsedParams.query,
          season: parsedParams.season,
          brands: parsedParams.brands,
          chargeIndicatorSeparator: '/',
        }),
      );

      const newSearchParams = toSearchParams(parsedParams.query, parsedParams.season, parsedParams.brands, '/');
      dispatch(
        setSearchParams({
          searchParamsBase64: newSearchParams.searchParamsBase64,
        }),
      );
      dispatch(setTireSearchQuery({ query: newSearchParams.tireSearchParams.replaceAll('-', ' ') }));
      setBrands(
        tireBrandsData.map((brand: TireBrandType) => {
          return parsedParams.brands?.includes(brand.brandId)
            ? {
                ...brand,
                selected: !brand.selected,
              }
            : brand;
        }),
      );
      setSeason(parsedParams.season);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex direction={large ? 'row' : 'column'} justify={'center'} align={'center'}>
      {searchParamsBase64 ? (
        <TireDimensionsSearchBar
          brands={brands}
          setBrands={(newBrands) => setBrands(newBrands)}
          season={season}
          setSeason={(newSeason) => setSeason(newSeason)}
        />
      ) : (
        <Box>
          <MarginBox mt={large ? 0 : 60}>
            <Flex direction={'column'} align={'center'}>
              <Text type={'h1_banner_light'} disableGutter>
                {t('catalog.tires.research.title', 'Research tires by Dimensions')}
              </Text>
              <Text type={'h6_black_45'}>
                {t('catalog.tires.research.subtitle', 'Please select seasonality and type your tires size here.')}
              </Text>
            </Flex>
            <MarginBox mt={48} />
            <TiresSearchbar
              brands={brands}
              setBrands={(newBrands) => setBrands(newBrands)}
              season={season}
              setSeason={(newSeason) => setSeason(newSeason)}
            />
          </MarginBox>
        </Box>
      )}
    </Flex>
  );
};

export default TireSearch;
