/* eslint-disable max-len */
import { AdditionalInformation } from '@1po/1po-bff-fe-spec/generated/catalog/references/common/model/AdditionalInformation';

import { Kit } from '@1po/1po-bff-fe-spec/generated/catalog/references/iam/model/Kit';
import { TFunction } from 'i18next';
import { Item } from 'UI';

function addPadding(items: Item[]): void {
  if (items.length % 2 === 1) {
    items.push({
      label: '',
      value: '',
    });
  }
}

function addReferences(
  items: Item[],
  t: TFunction,
  replacingReferences: string[],
  substitutedReferences: string[],
): void {
  const refMaxLength = Math.max(replacingReferences.length, substitutedReferences.length);

  if (replacingReferences.length > 0) {
    items.push({
      label: t('catalog.details.additional_information.replacing_references', 'Replacing references'),
      value: '',
    });
  }

  if (substitutedReferences.length > 0) {
    items.push({
      label: t('catalog.details.additional_information.substituted_references', 'Substituted references'),
      value: '',
    });
  }

  for (let i = 0; i < refMaxLength; i++) {
    if (i < replacingReferences.length) {
      items.push({
        label: '',
        value: replacingReferences.at(i) ?? '',
      });
    }
    if (i < substitutedReferences.length) {
      items.push({
        label: '',
        value: substitutedReferences.at(i) ?? '',
      });
    }
  }
}

function addKitInformation(items: Item[], t: TFunction, kitContent: Kit[], kitsContainingThisPart: Kit[]): void {
  const refMaxLengthKit = Math.max(kitContent.length, kitsContainingThisPart.length);

  if (kitContent.length > 0) {
    items.push({
      label: t('catalog.details.additional_information.kit_contents', 'Kit contents'),
      value: '',
    });
  }

  if (kitsContainingThisPart.length > 0) {
    items.push({
      label: t('catalog.details.additional_information.kits_containing_this_part', 'Kit(s) containing this part'),
      value: '',
    });
  }

  for (let i = 0; i < refMaxLengthKit; i++) {
    if (i < kitContent.length) {
      const item = kitContent.at(i);
      items.push({ label: '', value: '' });
      items.push({
        label: item?.description ?? '',
        value: item?.reference ?? '',
      });
    }
    if (i < kitsContainingThisPart.length) {
      const item = kitsContainingThisPart.at(i);
      items.push({ label: '', value: '' });
      items.push({
        label: item?.description ?? '',
        value: item?.reference ?? '',
      });
    }
  }
}

export function mapAdditionalInformation(
  t: TFunction,
  additionalInformation?: AdditionalInformation[],
  replacingReferences?: string[],
  substitutedReferences?: string[],
  kitContent?: Kit[],
  kitsContainingThisPart?: Kit[],
  quantityPerUnit?: number,
): Item[] {
  // Additional information
  const items: Item[] = [
    ...(quantityPerUnit
      ? [
          {
            label: t('catalog.details.additional_information.quantity_per_unit', 'Quantity per unit'),
            value: quantityPerUnit.toString(),
          },
        ]
      : []),
    ...(additionalInformation ?? []).map((i) => ({
      label: i.title ?? '',
      value: i.description ?? '',
    })),
  ];

  addPadding(items);

  // Replacing and substituted references
  addReferences(items, t, replacingReferences ?? [], substitutedReferences ?? []);
  addPadding(items);

  // Kit
  addKitInformation(items, t, kitContent ?? [], kitsContainingThisPart ?? []);
  addPadding(items);

  return items;
}
