import { LaborTime } from '@1po/1po-bff-fe-spec/generated/estimate/request/model/LaborTime';
import { Reference } from '@1po/1po-bff-fe-spec/generated/estimate/request/model/Reference';
import { Settings } from '@1po/1po-bff-fe-spec/generated/estimate/response/model/Settings';
import {
  GetIAMServiceOperationsResponseLocal,
  KM_TO_MILES,
  mileage_units,
  MILES_TO_KM,
  ServiceOperationLocal,
  ServiceReferenceLocal,
} from 'domains/maintenancePlan/MaintenancePlan.types';
import { concatArrays } from 'domains/maintenancePlan/MaintenancePlan.utils';
import { IAMReferenceLocal, ReferencePriceType } from 'domains/references';

import { getLaborTimeRate, MECHANICS } from 'pages/CatalogPage/DH/SubcategorySection/LaborTimeSection/utils';
import { getData, NO_DATA } from 'utils';

export const buildOperationLabel = (operation: ServiceOperationLocal): string => {
  return `${operation.label} - ${operation.repairLabel}`;
};

export const getMaintenancePlanReferences = (
  allReferences: ServiceReferenceLocal[],
  referencesData: IAMReferenceLocal[],
  referencesPrices: Map<string, NO_DATA | ReferencePriceType>,
): Reference[] => {
  return allReferences.map((referenceInOperation) => {
    const price = getData(referencesPrices.get(referenceInOperation.referenceNumber));
    const refData = referencesData.find((r) => r.referenceNumber === referenceInOperation.referenceNumber);
    return {
      referenceNumber: referenceInOperation.referenceNumber,
      catalogSource: 'IAM',
      designation: refData?.designation ?? '?',
      unitPrice: price?.clientView?.recommendedPriceVatExcluded ?? refData?.price ?? '',
      quantity: referenceInOperation.quantity,
      priceVatExcluded:
        (
          parseFloat(price?.clientView?.recommendedPriceVatExcluded ?? '0') * referenceInOperation.quantity
        ).toString() ?? '',
      garagePrice: price?.garageView?.vatExcludedPrice?.toString() ?? '',
      discountRate: price?.garageView?.discountRate ?? '',
      vatPercentage: price?.garageView?.vatPercentage ?? 20,
      isCompatible: true,
    };
  });
};

export const getMaintenancePlanLaborTimes = (
  operationsData: GetIAMServiceOperationsResponseLocal | undefined,
  estimateSettingsData: NO_DATA | Settings,
): LaborTime[] => {
  const selectedMainOperations = operationsData?.mainOperations
    ?.flatMap((cat) => cat.operations)
    .filter((op) => op.selected);
  const selectedAddOperations = operationsData?.additionalOperations
    ?.flatMap((cat) => cat.operations)
    .filter((op) => op.selected);

  const selectedOperations = concatArrays(selectedMainOperations, selectedAddOperations);

  return selectedOperations.map((item) => {
    const setTechnicity = getLaborTimeRate(MECHANICS, item.technicity, getData(estimateSettingsData)?.laborPriceList)
      ?.price;

    const x: LaborTime = {
      technicity: item.technicity,
      catalogSource: 'IAM',
      code: item.id,
      designation: buildOperationLabel(item),
      pricePerHour: setTechnicity ?? '0',
      numberOfHours: item.time,
      quantity: 1,
      priceVatExcluded: String((item.time ?? 0) * (setTechnicity ? parseFloat(setTechnicity) : 0)),
      vatPercentage: getData(estimateSettingsData)?.vatRate,
      includedOperations: [],
      notIncludedOperations: [],
    };
    return x;
  });
};

export const convertMileage = (apiUnits: string, apiMileage: number): number => {
  return apiUnits === 'km' ? Math.round(apiMileage * KM_TO_MILES) : Math.ceil(apiMileage * MILES_TO_KM);
};

export const convertMileageToCurrentUnits = (unitsKm: mileage_units, apiUnits: string, apiMileage: number): number => {
  return unitsKm === apiUnits ? apiMileage : convertMileage(apiUnits, apiMileage);
};
