import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Popover } from 'antd-v5';
import {
  ROUTER_CART,
  ROUTER_CART_CONFIRMATION,
  ROUTER_CART_DETAILS,
  ROUTER_ESTIMATE,
  ROUTER_HOME,
  ROUTER_ORDERS,
} from 'app/AppRouter';
import { trackAppEvent } from 'app/AppTracker';
import { FolderOpenRegularSecondIcon, QuestionCircleBoldIcon, ShoppingBagIcon } from 'assets/icons';
import { FirstHelpPopin } from 'components/Help/FirstHelpPopin';
import { SupportMenu } from 'components/Page/Header/TopHeader/Support/SupportMenu';
import { SPopover } from 'components/Page/Header/TopHeader/TopHeader.styled';
import { EstimateIconComponent } from 'components/Page/PageSideBar/PageSideBar';
import PrivateComponent from 'composers/PrivateComponent';
import { getBasketNumberOfItems } from 'domains/basket/Basket.store';
import { EstimateTabName, EstimateTabParam } from 'domains/estimate/Estimate.types';
import { UserRole } from 'domains/user';
import CartPopover from 'pages/CartPage/CartPopover/CartPopover';
import FileReferencesUpload from 'pages/CartPage/CartStep/FileUpload';
import { theme } from 'styles';
import { Badge, Icon, Link, MarginBox, WithTooltip } from 'UI';
import {
  TRACKING_EVENT_GO_TO_CART_ICON,
  TRACKING_EVENT_ORDERS_IN_PROGRESS,
} from 'utils/eventTracker/EventTracker.types';

const IconMenu = ({ iconColor, iconHoverFill }: { iconColor: string; iconHoverFill?: string }) => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const numberOfItems = useSelector(getBasketNumberOfItems);
  const isCartPage = [ROUTER_CART, ROUTER_CART_DETAILS, ROUTER_CART_CONFIRMATION].includes(location.pathname);

  const params = new URLSearchParams(location.search);
  params.set(EstimateTabParam, EstimateTabName);

  const [supportVisible, setSupportVisible] = useState<boolean>(false);
  const [canShowBasketPreview, setCanShowBasketPreview] = useState<boolean>(false);

  const setDefaultCartState = () => {
    history.push(ROUTER_CART);
  };

  useEffect(() => {
    if (isCartPage) {
      setCanShowBasketPreview(false);
    }
  }, [isCartPage]);

  const handleBasketPreviewOpenChange = (newOpen: boolean) => {
    if (!isCartPage) {
      setCanShowBasketPreview(newOpen);
    }
  };

  return (
    <>
      <FileReferencesUpload />
      <MarginBox my={'auto'} mx={5}>
        <FirstHelpPopin streamId={ROUTER_HOME} popinId={`${ROUTER_HOME}_estimate`} placement={'bottom'}>
          <Link to={`${ROUTER_ESTIMATE}?${params}`}>
            <EstimateIconComponent
              size={32}
              color={iconColor}
              hoverFill={iconHoverFill}
              badgeSize={15}
              badgeOffset={[-5, 24]}
            />
          </Link>
        </FirstHelpPopin>
      </MarginBox>
      <PrivateComponent
        render={() => (
          <MarginBox my={'auto'} mx={5}>
            <FirstHelpPopin streamId={'close'} popinId={'close'} placement={'bottomLeft'} hideCounter>
              <SPopover
                id={'top_header-support-popover'}
                content={<SupportMenu setSupportVisible={setSupportVisible} />}
                trigger={'click'}
                placement={'bottomRight'}
                visible={supportVisible}
                onVisibleChange={(visible) => setSupportVisible(visible)}
              >
                <WithTooltip title={t('common.action.help', 'Help')} placement={'bottom'}>
                  <Icon
                    IconComponent={QuestionCircleBoldIcon}
                    size={32}
                    display={'block'}
                    color={iconColor}
                    hoverFill={iconHoverFill}
                  />
                </WithTooltip>
              </SPopover>
            </FirstHelpPopin>
          </MarginBox>
        )}
        requiredRights={[UserRole.COMMAND, UserRole.CONNECT_COMMANDE]}
      />
      <PrivateComponent
        render={() => (
          <MarginBox my={'auto'} mx={5}>
            <FirstHelpPopin streamId={ROUTER_HOME} popinId={`${ROUTER_HOME}_basket`} placement={'bottomLeft'}>
              <Popover
                content={<CartPopover />}
                arrow={false}
                open={canShowBasketPreview && !!numberOfItems}
                onOpenChange={handleBasketPreviewOpenChange}
              >
                <div>
                  <Link to={ROUTER_CART} dataCy={'top link cart'}>
                    <Badge
                      size={15}
                      color={theme.color.brand}
                      offset={[-5, 24]}
                      borderColor={'black'}
                      count={numberOfItems}
                    >
                      <WithTooltip
                        title={t('cart', 'Cart')}
                        placement={'bottom'}
                        open={canShowBasketPreview && !!numberOfItems ? false : undefined}
                      >
                        <Icon
                          IconComponent={ShoppingBagIcon}
                          onClick={() => {
                            trackAppEvent(TRACKING_EVENT_GO_TO_CART_ICON);
                            setDefaultCartState();
                          }}
                          size={32}
                          display={'block'}
                          color={iconColor}
                          hoverFill={iconHoverFill}
                        />
                      </WithTooltip>
                    </Badge>
                  </Link>
                </div>
              </Popover>
            </FirstHelpPopin>
          </MarginBox>
        )}
        requiredRights={[UserRole.COMMAND, UserRole.CONNECT_COMMANDE]}
      />
      <PrivateComponent
        render={() => (
          <MarginBox my={'auto'} mx={5}>
            <Link to={ROUTER_ORDERS} dataCy={'top link orders'}>
              <WithTooltip title={t('order.my_orders', 'My Orders')} placement={'bottom'}>
                <Icon
                  IconComponent={FolderOpenRegularSecondIcon}
                  onClick={() => {
                    trackAppEvent(TRACKING_EVENT_ORDERS_IN_PROGRESS);
                  }}
                  size={32}
                  display={'block'}
                  color={iconColor}
                  hoverFill={iconHoverFill}
                />
              </WithTooltip>
            </Link>
          </MarginBox>
        )}
        requiredRights={[UserRole.COMMAND, UserRole.CONNECT_COMMANDE]}
      />
    </>
  );
};

export default IconMenu;
