import { GET_MY_STORE_TIRES_RESPONSE } from '@1po/1po-bff-fe-spec/generated/common/ResponseType';
import {
  MY_STORE_BUNDLES_CREATE_NEW_BUNDLE,
  MY_STORE_BUNDLES_CREATE_NEW_SECTION,
  MY_STORE_BUNDLES_MOVE_SECTION,
  MY_STORE_BUNDLES_REMOVE_BUNDLE,
  MY_STORE_BUNDLES_RENAME_SECTION,
  MY_STORE_BUNDLES_SUBSCRIBE,
  MY_STORE_BUNDLES_UPDATE_BUNDLE_CODE,
  MY_STORE_BUNDLES_UPDATE_BUNDLE_DESIGNATION,
  MY_STORE_BUNDLES_UPDATE_BUNDLE_PRICE,
  MY_STORE_TIRES_SUBSCRIBE,
  MY_STORE_TIRES_UPDATE_DISCOUNT,
  MY_STORE_BUNDLES_REMOVE_SECTION,
} from '@1po/1po-bff-fe-spec/generated/common/WebsocketConstants';
import { CreateMyStoreBundle } from '@1po/1po-bff-fe-spec/generated/my_store/bundles/request/CreateMyStoreBundle';
import { CreateMyStoreBundleSection } from '@1po/1po-bff-fe-spec/generated/my_store/bundles/request/CreateMyStoreBundleSection';
import { MoveMyStoreBundleSection } from '@1po/1po-bff-fe-spec/generated/my_store/bundles/request/MoveMyStoreBundleSection';
import { RemoveMyStoreBundle } from '@1po/1po-bff-fe-spec/generated/my_store/bundles/request/RemoveMyStoreBundle';
import { RemoveMyStoreBundleSection } from '@1po/1po-bff-fe-spec/generated/my_store/bundles/request/RemoveMyStoreBundleSection';
import { RenameMyStoreBundle } from '@1po/1po-bff-fe-spec/generated/my_store/bundles/request/RenameMyStoreBundle';
import { UpdateMyStoreBundleCode } from '@1po/1po-bff-fe-spec/generated/my_store/bundles/request/UpdateMyStoreBundleCode';
import { UpdateMyStoreBundleDesignation } from '@1po/1po-bff-fe-spec/generated/my_store/bundles/request/UpdateMyStoreBundleDesignation';
import { UpdateMyStoreBundlePrice } from '@1po/1po-bff-fe-spec/generated/my_store/bundles/request/UpdateMyStoreBundlePrice';
import { UpdateMyStoreTireDiscountList } from '@1po/1po-bff-fe-spec/generated/my_store/tires/request/UpdateMyStoreTireDiscountList';
import { WebSocketAction } from 'utils';
import { wsSendAction } from 'utils/domainStore/api';
import { RequestResponseMappingManager } from '../webSockets/WebSocket.requestsResponseMapping';

RequestResponseMappingManager.addMapping(MY_STORE_TIRES_UPDATE_DISCOUNT, GET_MY_STORE_TIRES_RESPONSE);

export function sendGetBundlesRequest(): WebSocketAction {
  return wsSendAction(MY_STORE_BUNDLES_SUBSCRIBE, null);
}

export function sendMoveBundleSectionRequest(request: MoveMyStoreBundleSection): WebSocketAction {
  return wsSendAction(MY_STORE_BUNDLES_MOVE_SECTION, request);
}

export function sendAddBundlesSectionRequest(request: CreateMyStoreBundleSection): WebSocketAction {
  return wsSendAction(MY_STORE_BUNDLES_CREATE_NEW_SECTION, request);
}

export function sendDeleteBundlesSectionRequest(request: RemoveMyStoreBundleSection): WebSocketAction {
  return wsSendAction(MY_STORE_BUNDLES_REMOVE_SECTION, request);
}

export function sendRenameBundlesSectionRequest(request: RenameMyStoreBundle): WebSocketAction {
  return wsSendAction(MY_STORE_BUNDLES_RENAME_SECTION, request);
}

export function sendAddBundleRequest(request: CreateMyStoreBundle): WebSocketAction {
  return wsSendAction(MY_STORE_BUNDLES_CREATE_NEW_BUNDLE, request);
}

export function sendRemoveBundleRequest(request: RemoveMyStoreBundle): WebSocketAction {
  return wsSendAction(MY_STORE_BUNDLES_REMOVE_BUNDLE, request);
}

export function sendUpdateBundleCode(request: UpdateMyStoreBundleCode): WebSocketAction {
  return wsSendAction(MY_STORE_BUNDLES_UPDATE_BUNDLE_CODE, request);
}

export function sendUpdateBundlePrice(request: UpdateMyStoreBundlePrice): WebSocketAction {
  return wsSendAction(MY_STORE_BUNDLES_UPDATE_BUNDLE_PRICE, request);
}

export function sendUpdateBundleDesignation(request: UpdateMyStoreBundleDesignation): WebSocketAction {
  return wsSendAction(MY_STORE_BUNDLES_UPDATE_BUNDLE_DESIGNATION, request);
}

//MyStoreTires
export function sendSubscribeTiresRequest(): WebSocketAction {
  return wsSendAction(MY_STORE_TIRES_SUBSCRIBE, null);
}

export function sendUpdateMyStoreTireDiscountListRequest(request: UpdateMyStoreTireDiscountList): WebSocketAction {
  return wsSendAction(MY_STORE_TIRES_UPDATE_DISCOUNT, request);
}
