import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BatteryCard } from 'components/BatteryInfo/BatteryInfo.styled';
import { useIsBatteryUrl } from 'components/BatteryInfo/useIsBatteryUrl';
import { getLastVehicleDetail } from 'domains/catalog/Catalog.store';
import { Flex, Text } from 'UI';

const BatteryInfoCard = () => {
  const { t } = useTranslation();
  const vehicleDetail = useSelector(getLastVehicleDetail);
  const battery = vehicleDetail?.iamVehicle?.compatibleBattery;
  const isBatteryUrl = useIsBatteryUrl();

  const getInfoLine = (label: string, value: string) => {
    return (
      <Flex>
        <Flex size={4}>
          <Text type={'text_dim'}>{label}</Text>
        </Flex>
        <Flex size={2} justify={'flex-start'}>
          {value}
        </Flex>
      </Flex>
    );
  };

  const getStartStopString = (value: string) => {
    switch (value) {
      case '0':
        return t('common.no_capital', 'NO');
      case '1':
        return t('common.yes_capital', 'YES');
      default:
        return '';
    }
  };

  if (!battery || !isBatteryUrl) return null;

  return (
    <BatteryCard direction={'column'} gap={8} maxWidth={350} padding={16}>
      <Text type={'h6'}>{t('catalog.battery.battery_specification', 'BATTERY SPECIFICATION')}</Text>
      {getInfoLine(t('catalog.battery.battery_start_stop', 'Stop and Start'), getStartStopString(battery?.stt ?? ''))}
      {getInfoLine(
        t('catalog.battery.battery_starter_power', 'Starter Battery Power'),
        battery?.starterBatteryPower ?? '',
      )}
      {getInfoLine(
        t('catalog.battery.battery_starter_capacity', 'Starter Battery Capacity'),
        battery?.starterBatteryCapacity ?? '',
      )}
      {getInfoLine(
        t('catalog.battery.battery_', 'Starter Battery Dimensions'),
        battery?.starterBatteryDimensions ?? '',
      )}
    </BatteryCard>
  );
};

export default BatteryInfoCard;
