import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FileUpload } from 'components/FileUpload';
import {
  EMPTY_FILE_ERROR,
  FILE,
  FILE_FORMAT_ERROR,
  FILE_STRUCTURE_ERROR,
  MAX_SIZE_ERROR,
  ModalStatusType,
  NO_FILE,
  SAME_FILE_ERROR,
} from 'components/FileUpload/paramsByState';
import {
  getDiscontinuedReferencesFileInformation,
  getDiscontinuedReferencesFileInformationForRequest,
  getDiscontinuedReferencesFileModalStatus,
  getDiscontinuedReferencesFileUploadStatus,
  setDiscontinuedReferencesFileInformationForRequest,
  setDiscontinuedReferencesFileModalStatus,
  uploadFileDiscontinuedReferencesRequestSaga,
} from 'domains/discontinuedReferences/DiscontinuedReferences.store';
import { FILE_FETCHED, REPLACING_FILE } from 'domains/electronicPartsRepair/ElectronicPartsRepair.types';

const DiscontinuedReferencesFileUpload = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const fileInfoFetched = useSelector(getDiscontinuedReferencesFileInformation);
  const fileInfoRequest = useSelector(getDiscontinuedReferencesFileInformationForRequest);
  const fileUploadStatus = useSelector(getDiscontinuedReferencesFileUploadStatus);

  const modalStatus = useSelector(getDiscontinuedReferencesFileModalStatus);

  const setModalStatus = (status: ModalStatusType) => {
    dispatch(setDiscontinuedReferencesFileModalStatus(status));
  };

  const validateFile = (file: File) => {
    if (!file.name.endsWith('.csv')) {
      setModalStatus(FILE_FORMAT_ERROR);
      return false;
    } else if (file.size > 2000 * 1024) {
      setModalStatus(MAX_SIZE_ERROR);
      return false;
    } else {
      setModalStatus(FILE);
      return true;
    }
  };

  const uploadFile = (fileName: string, _fileSize: number, fileBase64: string) => {
    dispatch(
      uploadFileDiscontinuedReferencesRequestSaga({
        fileName,
        fileBase64,
      }),
    );
    dispatch(setDiscontinuedReferencesFileInformationForRequest({ fileName }));
  };

  const handleNoFile = () => {
    setModalStatus(NO_FILE);
  };

  const getDescriptions = () => {
    switch (modalStatus) {
      case FILE_FORMAT_ERROR: {
        return [t('common.file_upload.error.format', 'Only CSV file is supported, please try again')];
      }
      case MAX_SIZE_ERROR: {
        return [
          t(
            'common.file_upload.error.max_size_error_second_line',
            'Support extension: CSV-Maximum file size: {{size}} {{unit}}',
            { size: 2, unit: 'MB' },
          ),
        ];
      }
      case FILE_STRUCTURE_ERROR: {
        return [t('common.file_upload.error.wrong_format_structure', 'File has wrong structure')];
      }
      case EMPTY_FILE_ERROR: {
        return [t('common.file_upload.error.empty_file_error', 'Your uploaded file is empty')];
      }
      case SAME_FILE_ERROR: {
        return [t('common.file_upload.error.same_file_error', 'This file is already published')];
      }
      default: {
        return [t('common.file_upload.extension.csv', 'Supported extension: CSV')];
      }
    }
  };

  return (
    <FileUpload
      template={{
        link: '/fileTemplates/discontinued_renault_references_template.csv',
        fileName: 'Discontinued Renault references.csv',
      }}
      handleFileBase64={uploadFile}
      validateFile={validateFile}
      handleNoFile={handleNoFile}
      status={modalStatus}
      descriptions={getDescriptions()}
      currentFile={
        modalStatus === FILE
          ? {
              fileType: 'CSV',
              fileName: fileUploadStatus === FILE_FETCHED ? fileInfoFetched.fileName : fileInfoRequest.fileName,
              fileBase64: '',
            }
          : undefined
      }
      isProcessing={fileUploadStatus === REPLACING_FILE}
    />
  );
};

export default DiscontinuedReferencesFileUpload;
