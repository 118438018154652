import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReferenceDiscount } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/model/ReferenceDiscount';
import { Reference, Tire } from '@1po/1po-bff-fe-spec/generated/estimate/response/GetEstimate';
import { EstimateCheckoutItem } from '@1po/1po-bff-fe-spec/generated/order/request/CheckoutB2BEstimate';
import bigDecimal from 'js-big-decimal';
import { RootState } from 'app/AppStore';
import { calculatePriceAfterDiscount } from 'components/Discount';
import {
  checkoutB2BEstimateRequest,
  getEstimateB2BDiscounts,
  getEstimateB2BPrices,
  getEstimateById,
  getEstimateOrderItemDetails,
  setEstimateOrderMktpDeliveryMode,
} from 'domains/estimate/Estimate.store';
import { getDefaultDeliveryAddress } from 'domains/orderValidation';
import { ReferencePriceType } from 'domains/references';
import { getCurrency, getPossibleDelegateSessionBuyers, getPossibleDelegateSessionSellers } from 'domains/user';
import CheckoutCardSection from 'pages/CartPage/CartStep/CheckoutCardSection';
import BillingAccountSection from 'pages/CartPage/ValidationStep/BillingAccountSection';
import DeliveryAddressSection from 'pages/CartPage/ValidationStep/DeliveryAddressSection';
import { getBillingAccountOptions } from 'pages/CartPage/ValidationStep/ValidationStep';
import { Flex } from 'UI';
import { getData, getSearchData, hasData, SearchData } from 'utils';

export const getTotalDiscountPrice = (
  items: (Reference | Tire)[],
  prices: Map<string, SearchData<ReferencePriceType>>,
  discounts: Map<string, ReferenceDiscount>,
) => {
  const totalWithoutDiscounts = items.reduce((total, ref) => {
    const price = getSearchData(prices.get(ref.referenceNumber));
    const itemPrice = Number(price?.garageView?.vatExcludedPrice) * ref.quantity;
    return total.add(new bigDecimal(itemPrice));
  }, new bigDecimal(0));
  return totalWithoutDiscounts.subtract(getTotalVatExclPriceForClient(items, prices, discounts)).getValue();
};

export const getTotalVatExclPriceForClient = (
  items: (Reference | Tire)[],
  prices: Map<string, SearchData<ReferencePriceType>>,
  discounts: Map<string, ReferenceDiscount>,
) => {
  return items.reduce((total, ref) => {
    const price = getSearchData(prices.get(ref.referenceNumber));
    const itemPrice = calculatePriceAfterDiscount(discounts.get(ref.referenceNumber), price, false) * ref.quantity;
    return total.add(new bigDecimal(itemPrice));
  }, new bigDecimal(0));
};

export const getTotalVatInclPriceForClient = (
  items: (Reference | Tire)[],
  prices: Map<string, SearchData<ReferencePriceType>>,
  discounts: Map<string, ReferenceDiscount>,
) => {
  return items.reduce((total, ref) => {
    const price = getSearchData(prices.get(ref.referenceNumber));
    const itemPrice = calculatePriceAfterDiscount(discounts.get(ref.referenceNumber), price, true) * ref.quantity;
    return total.add(new bigDecimal(itemPrice));
  }, new bigDecimal(0));
};

export const getTotalVatPriceForClient = (
  items: (Reference | Tire)[],
  prices: Map<string, SearchData<ReferencePriceType>>,
  discounts: Map<string, ReferenceDiscount>,
) => {
  return getTotalVatInclPriceForClient(items, prices, discounts).subtract(
    getTotalVatExclPriceForClient(items, prices, discounts),
  );
};

const B2BEstimateOrderValidationStep = ({
  estimateId,
  references,
}: {
  estimateId: string;
  references: Reference[];
}) => {
  const dispatch = useDispatch();
  const estimate = getData(useSelector((state: RootState) => getEstimateById(state, estimateId)));
  const currency = useSelector(getCurrency);
  const defaultDeliveryType = useSelector(getDefaultDeliveryAddress);
  const [deliveryType, setDeliveryType] = useState(defaultDeliveryType);

  const sellers = useSelector(getPossibleDelegateSessionSellers)?.data ?? [];
  const activeSeller = sellers.find((s) => s.tresorCode === estimate?.clientContact?.sellerTresorCode);
  const delegationBuyers = useSelector((state: RootState) =>
    getPossibleDelegateSessionBuyers(state, activeSeller?.tresorCode),
  )?.data;
  const buyerData = delegationBuyers?.find((d) => d.tresorCode === estimate?.clientContact.tresorCode);
  const billingOptions = buyerData ? getBillingAccountOptions(buyerData) : undefined;
  const [billingAccount, setBillingAccount] = useState(buyerData?.primaryClientCode);
  const orderDetails = useSelector((state: RootState) => getEstimateOrderItemDetails(state, estimateId));

  const prices = useSelector((state: RootState) => getEstimateB2BPrices(state, estimateId));
  const discounts = useSelector((state: RootState) => getEstimateB2BDiscounts(state, estimateId));

  const isAnyMktpReference =
    estimate?.referenceSubsection?.references.some(
      (r) => r.referenceSource === 'MARKETPLACE' && !hasData(prices.get(r.referenceNumber)?.searchStatus),
    ) ?? false;

  const onCheckout = () => {
    if (billingAccount) {
      const estimateOrderDetails = estimate?.estimateOrderItemDetails;
      const checkoutItems = references.map((ref) => {
        return {
          referenceNumber: ref.referenceNumber,
          note: estimateOrderDetails?.referenceMarks?.get(ref.referenceNumber),
          isUrgentDelivery: estimateOrderDetails?.isUrgentDelivery?.get(ref.referenceNumber),
        } as EstimateCheckoutItem;
      });
      dispatch(
        checkoutB2BEstimateRequest({
          estimateId,
          deliveryType,
          checkoutItems,
          paymentClientCode: billingAccount,
          instruction: '',
        }),
      );
    }
  };

  useEffect(() => {
    if (!billingAccount && buyerData) {
      setBillingAccount(buyerData.primaryClientCode);
    }
    // eslint-disable-next-line
  }, [buyerData]);

  return (
    <Flex direction={'column'} gap={15}>
      <DeliveryAddressSection
        validationDeliveryAddress={deliveryType}
        updateDeliveryAddress={setDeliveryType}
        orderMKTPDeliveryMode={orderDetails?.mktpDeliveryMode}
        basketHasMKTPReference={isAnyMktpReference}
        updateOrderMKTPDeliveryMode={(deliveryMode) =>
          dispatch(setEstimateOrderMktpDeliveryMode({ estimateId, deliveryMode }))
        }
        sellerName={activeSeller?.name}
        compact
      />
      <BillingAccountSection
        updateBillingAccount={setBillingAccount}
        billingOptions={billingOptions}
        selectedBillingAccount={billingAccount}
        compact
      />
      <CheckoutCardSection
        totalPriceVatIncluded={getTotalVatInclPriceForClient(references, prices, discounts).getValue()}
        totalPriceVatExcluded={getTotalVatExclPriceForClient(references, prices, discounts).getValue()}
        totalDiscount={Number(getTotalDiscountPrice(references, prices, discounts))}
        totalVat={getTotalVatPriceForClient(references, prices, discounts).getValue()}
        vehicles={[]}
        externalBaskets={[]}
        currency={currency}
        onCheckout={onCheckout}
        estimateTotalReferences={references.length}
      />
    </Flex>
  );
};

export default B2BEstimateOrderValidationStep;
