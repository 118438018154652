import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { List } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { InfoCircleIcon, SearchIcon } from 'assets/icons';
import {
  InputWrapper,
  LabelDealer,
  SelectDealer,
  Wrapper2,
} from 'components/Page/Header/TopHeader/Profile/DelegateSessionConnectionBox/ListAccounts.styled';
import { theme } from 'styles';
import { Flex, Icon, Input, MarginBox, Text } from 'UI';

export interface AccountListItem {
  tresorCode: string;
  name: string;
  primaryClientCode?: string;
}

interface ListAccountsProps {
  labelInputPlaceholder: string;
  data: AccountListItem[];
  onSelect: (item: AccountListItem) => void;
  warningFilter: string;
}

export const fmt = (name?: string, bir?: string, clientCode?: string) =>
  [name, bir, clientCode].filter((n) => n).join(' - ');

export const ListAccounts = ({ labelInputPlaceholder, data, onSelect, warningFilter }: ListAccountsProps) => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState<string>('');
  const filterData = useMemo(() => {
    return data.filter((a) => {
      return filter.length > 0
        ? a.tresorCode.indexOf(filter) >= 0 ||
            a.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0 ||
            (a.primaryClientCode && a.primaryClientCode.indexOf(filter) >= 0)
        : a;
    });
  }, [data, filter]);

  return (
    <Wrapper2>
      <InputWrapper minHeight={40} maxHeight={40}>
        <Input
          placeholder={labelInputPlaceholder}
          value={filter}
          onChange={(val) => {
            setFilter(val);
          }}
        />
        <Icon IconComponent={SearchIcon} width={24} height={24} mt={'auto'} mb={'auto'} color={theme.color.grey} />
      </InputWrapper>
      <SelectDealer>
        <Flex direction={'row'} wrap={'wrap'}>
          {filter.length > 0 && filterData.length === 0 ? (
            <Flex direction={'column'} maxWidth={350}>
              <Flex direction={'row'}>
                <Text type={'light_14_black_65'}>
                  {t('connect_as.dealer_search_empty_filter', `Sorry, We did not find any result for`)}
                  &nbsp; &quot;{filter}&quot;
                </Text>
              </Flex>
              <MarginBox mt={9} />
              <Flex direction={'row'}>
                <MarginBox mr={8} mt={4}>
                  <InfoCircleIcon width={14} height={14} fill={theme.color.info} />
                </MarginBox>
                <Text type={'light_12_black_90'}>{warningFilter}</Text>
              </Flex>
            </Flex>
          ) : (
            <List
              dataSource={filterData}
              renderItem={(item) => {
                return (
                  <List.Item style={{ padding: 0 }} onClick={() => onSelect(item)} key={item.tresorCode + uuidv4()}>
                    <LabelDealer width={315}>
                      <Text type={'light_14_black_65'} disableGutter transform={'capitalize'}>
                        {fmt(item.name, item.tresorCode, item.primaryClientCode)}
                      </Text>
                    </LabelDealer>
                  </List.Item>
                );
              }}
            />
          )}
        </Flex>
      </SelectDealer>
    </Wrapper2>
  );
};
