import { Table } from 'antd';
import styled from 'styled-components';
import Popover from 'components/Popover';
import { SearchInput } from 'components/SearchInput/SearchInput';
import { MarginBox } from 'UI';
import TableSection from './TableSection/TableSection';

export const EstimateTableSection = styled(TableSection)`
  margin-top: ${({ theme }) => `${theme.spacing.spacing_l}px`};
`;

export const STable = styled(Table)`
  flex: 1;

  thead > tr > th {
    text-align: center;
  }
` as typeof Table;

export const SHistoryTab = styled.div`
  position: relative;
`;

export const SSearchInput = styled(SearchInput)`
  position: absolute;
  top: -126px;
  right: 0;
  width: 450px;

  input {
    border-bottom-left-radius: 25px;
    border-top-left-radius: 25px;
  }

  .ant-input-group-addon {
    border-bottom-right-radius: 25px;
    border-top-right-radius: 25px;
  }
`;

export const SPopover = styled(Popover)`
  .ant-popover-arrow {
    right: 50px;
  }

  .ant-popover-arrow-content,
  .ant-popover-arrow-content::before {
    background: ${({ theme }) => theme.color.blue_dark};
  }

  position: fixed;
`;

export const PositionWrapper = styled(MarginBox)`
  position: relative;
`;
