import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReferenceBrandType } from '@1po/1po-bff-fe-spec/generated/catalog/references/common/model/ReferenceBrandType';
import { TFunction } from 'i18next';
import { ROUTER_SELLER } from 'app/AppRouter';
import { RedoIcon } from 'assets/icons';
import PrivateComponent from 'composers/PrivateComponent';
import { PrivateTabsItems } from 'composers/PrivateTabs';
import PrivateTabs from 'composers/PrivateTabs/PrivateTabs';
import { getMockConfig, modifyMockConfig } from 'domains/appContext/AppContext.store';
import { configureWorkshopId, getUserGarageWorkshopId } from 'domains/garage/Garage.store';
import {
  addFavoriteTireBrandRequestSaga,
  CompactView,
  getCatalogReferenceView,
  getDelegationSession,
  getFavoriteBrandPreferences,
  getUserSellersToChoose,
  removeFavoriteTireBrandRequestSaga,
  resetFavoriteTireBrandRequestSaga,
  StandardView,
  updateDhCatalogReferenceViewRequestSaga,
  UserRole,
} from 'domains/user';
import { fetchSellers } from 'domains/user/User.requests';
import { theme } from 'styles';
import { BlackButton, Flex, Icon, Image, MarginBox, Space, Switch, Text, YellowButton } from 'UI';
import { BrandCheckBox, SCleverInput } from './Settings.styled';
import { tireBrandsData } from '../../TiresPage/TireSearch/TiresSearchbar/TireBrandsData';

function MokDetail({ name }: { name: string }) {
  const dispatch = useDispatch();
  const mockConfig = useSelector(getMockConfig);
  const parameter = `mock${name}`;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const mock: boolean | undefined = !mockConfig ? undefined : mockConfig[parameter];

  const onChange = () => {
    dispatch(
      modifyMockConfig({
        ...mockConfig,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        [parameter]: !mock,
      }),
    );
  };

  if (mock === undefined) return <></>;
  return (
    <>
      <MarginBox mt={10} />
      <Flex>
        <Flex>
          <Text type={'text_dim'}>Mock {name}</Text>
        </Flex>
        <Flex size={0} />
        <Flex>
          <Switch checked={mock} onChange={onChange} />
        </Flex>
      </Flex>
    </>
  );
}

const DmsSettings = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const configuredDmsWorkshopId = useSelector(getUserGarageWorkshopId);
  const [dmsWorkshopId, setDmsWorkshopId] = useState(configuredDmsWorkshopId);

  useEffect(() => {
    setDmsWorkshopId(configuredDmsWorkshopId);
  }, [configuredDmsWorkshopId]);

  return (
    <MarginBox mt={10}>
      <Text type={'h1_banner_light'}>
        {t('profile.settings.configure_dms.title', 'Configure your DMS Workshop ID')}
      </Text>

      <Space direction={'vertical'} size={35}>
        <Text type={'text'}>
          {t(
            'profile.settings.configure_dms.description',
            'Please, set up your DMS Workshop ID to secure your personal access.',
          )}
        </Text>
        <SCleverInput size={'middle'} bordered onChange={(val) => setDmsWorkshopId(val)} value={dmsWorkshopId} />

        <YellowButton
          onClick={() =>
            dmsWorkshopId !== undefined &&
            dmsWorkshopId !== configuredDmsWorkshopId &&
            dispatch(configureWorkshopId(dmsWorkshopId))
          }
          disabled={dmsWorkshopId === undefined}
        >
          {t('profile.settings.configure_dms.save_button_label', 'Validate')}
        </YellowButton>
      </Space>
    </MarginBox>
  );
};

const CatalogSettings = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const referenceView = useSelector(getCatalogReferenceView);

  const handleSwitch = (v: boolean) => {
    if (v) {
      dispatch(updateDhCatalogReferenceViewRequestSaga(CompactView));
    } else {
      dispatch(updateDhCatalogReferenceViewRequestSaga(StandardView));
    }
  };
  return (
    <MarginBox mt={10}>
      <Text type={'h1_banner_light'}>
        {t('profile.settings.catalog_settings.title', 'Display short version of references')}
      </Text>

      <Space direction={'vertical'} size={35}>
        <Text type={'text'}>
          {t(
            'profile.settings.catalog_settings.description',
            // eslint-disable-next-line max-len
            'Discover a world of personalization at your fingertips. Tailor the product list display to your liking with just a few clicks. Your shopping experience, your way.',
          )}
        </Text>
        <Flex>
          <Text type={'text_bold'}>
            {t('profile.settings.catalog_settings.switch.title', 'Display short version of references')}
          </Text>
          <MarginBox ml={15} />
          <Switch onChange={handleSwitch} checked={referenceView === CompactView} dataCy={'toggle-short-references'} />
        </Flex>
      </Space>
    </MarginBox>
  );
};

const TiresPreferencesSettings = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const favoriteBrandsList = useSelector(getFavoriteBrandPreferences);
  const [isAllBrands, setIsAllBrands] = useState(false);

  const addFavoriteBrand = (tireBrand: ReferenceBrandType) => {
    dispatch(addFavoriteTireBrandRequestSaga({ tireBrand }));
  };

  const removeFavoriteBrand = (tireBrand: ReferenceBrandType) => {
    dispatch(removeFavoriteTireBrandRequestSaga({ tireBrand }));
  };

  const resetFavoriteBrands = () => {
    dispatch(resetFavoriteTireBrandRequestSaga());
  };

  return (
    <MarginBox mt={10}>
      <Text type={'h1_banner_light'}>{t('profile.settings.tires_preferences.title', 'My favorite Brands')}</Text>

      <Space direction={'vertical'} size={35}>
        <Text type={'text'}>
          {t(
            'profile.settings.tires_preferences.description',
            'Please select your Brand preferences. Only selected Brands will be displayed when performing a Search on Rpartstore',
          )}
        </Text>
        <Flex direction={'row'} justify={'flex-end'}>
          <MarginBox mr={50}>
            <Flex onClick={() => resetFavoriteBrands()} align={'center'} gap={8}>
              <Icon IconComponent={RedoIcon} size={20} color={theme.color.info_dark} />
              <Text type={'text'} displayStyle={'link'} cursor={'pointer'} hoverUnderLine>
                {t('profile.settings.tires_preferences.action.reset_selection', 'Reset selection')}
              </Text>
            </Flex>
          </MarginBox>
        </Flex>
        <Flex wrap={'wrap'} gap={25} justify={'flex-start'}>
          {tireBrandsData.map((tireBrand) => {
            const isFavoriteBrand = favoriteBrandsList.includes(tireBrand.brand);
            const isShowed = isAllBrands || tireBrand.isDefaultBrand;
            return (
              isShowed && (
                <BrandCheckBox
                  key={tireBrand.brandId}
                  onClick={() =>
                    isFavoriteBrand ? removeFavoriteBrand(tireBrand.brand) : addFavoriteBrand(tireBrand.brand)
                  }
                  $isSelected={isFavoriteBrand}
                >
                  <Image src={tireBrand.image} alt={'brand_image'} width={140} height={75} />
                </BrandCheckBox>
              )
            );
          })}
          <MarginBox ml={10}>
            <Flex maxHeight={95} minHeight={95} minWidth={135} align={'center'} justify={'center'}>
              <Text
                onClick={() => setIsAllBrands(!isAllBrands)}
                type={'h6_light'}
                displayStyle={'link'}
                cursor={'pointer'}
                hoverUnderLine
              >
                {isAllBrands
                  ? t('common.action.show_less', 'Show less')
                  : t('profile.settings.tires_preferences.action.see_all_brands', 'See all Brands')}
              </Text>
            </Flex>
          </MarginBox>
        </Flex>
      </Space>
    </MarginBox>
  );
};

const SellerSettings = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const sellersToChooseFrom = useSelector(getUserSellersToChoose);
  const delegationSession = useSelector(getDelegationSession);

  const requestSellers = () => {
    fetchSellers(dispatch, sellersToChooseFrom);
    history.push(ROUTER_SELLER);
  };

  return (
    <>
      <MarginBox mt={10} />
      {!delegationSession && (
        <PrivateComponent
          render={() => (
            <Flex>
              <BlackButton onClick={requestSellers}>
                {t('profile.settings.request_sellers', 'Request Sellers')}
              </BlackButton>
            </Flex>
          )}
          requiredRights={[UserRole.COMMAND]}
        />
      )}

      <MarginBox mt={50} />
      <Flex direction={'column'} maxWidth={300}>
        <MokDetail name={'AIA'} />
        <MokDetail name={'ARP'} />
        <MokDetail name={'BVE'} />
        <MokDetail name={'DH'} />
        <MokDetail name={'R2A'} />
        <MokDetail name={'REP'} />
        <MokDetail name={'RP4'} />
        <MokDetail name={'IAM'} />
        <MokDetail name={'DRV'} />
        <MokDetail name={'PNE'} />
        <MokDetail name={'Sidexa'} />
      </Flex>
    </>
  );
};

const sellerTab = 'seller_settings';
const dmsSettings = 'dms_settings';
const catalogSettings = 'catalog_settings';
const tiresPreferencesSettings = 'tires_preferences_settings';
type SettingsTabType = typeof sellerTab | typeof dmsSettings;

const getTabItems = (t: TFunction): PrivateTabsItems[] => {
  return [
    {
      key: sellerTab,
      label: t('profile.settings.seller.tab_name', 'Seller'),
      component: <SellerSettings />,
      requiredRights: [UserRole.COMMAND],
      dataCy: 'tab-seller',
    },
    {
      key: dmsSettings,
      label: t('profile.settings.configure_dms.tab_name', 'Configure DMS'),
      component: <DmsSettings />,
      requiredRights: [UserRole.COMMAND],
      requiredDealerTypes: ['R3_MOTRIO'],
      dataCy: 'tab-configure-dms',
    },
    {
      key: catalogSettings,
      label: t('profile.settings.catalog_settings.tab_name', 'Catalog settings'),
      component: <CatalogSettings />,
      requiredRights: [UserRole.COMMAND],
      dataCy: 'tab-catalog-settings',
    },
    {
      key: tiresPreferencesSettings,
      label: t('profile.settings.tires_preferences.tab_name', 'Tires preferences'),
      component: <TiresPreferencesSettings />,
      requiredRights: [UserRole.COMMAND],
      dataCy: 'tab-tires-preferences-settings',
    },
  ];
};

export const Settings = () => {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState<SettingsTabType>(sellerTab);
  return (
    <MarginBox mt={25}>
      <Flex>
        <Text type={'h1_banner_light'}>{t('profile.settings', 'Settings')}</Text>
      </Flex>
      <PrivateTabs
        data={getTabItems(t)}
        activeKey={activeTab}
        onChange={(key: SettingsTabType) => setActiveTab(key)}
        tabBarGutter={15}
      />
    </MarginBox>
  );
};
