export const USER_PROFILE = 'user-profile';
export const R1_NETWORK = 'r1-network';
export const DISCONTINUED_REFERENCES = 'discontinued-references';
export const PROMOTION = 'promotion';
export const ELECTRONIC_PARTS_REPAIR = 'electronic-parts-repair';
export const CROSS_SELLING = 'cross-selling';
export const DELIVERY_LEAD_TIME = 'delivery-lead-time';
export const ASSISTANCE_COUNTRY = 'assistance-country';
export const ASSISTANCE_CENTRAL = 'assistance-central';
export const LIBRARY = 'library';
export const INFORMATION = 'information';
export const NOTIFICATION_CENTER = 'notification-center';
export const CREATE_NEW = 'create-new';
export const EDIT = 'edit';
export const COPY = 'copy';

export const CREATE_NEW_TEMPLATE = 'create-new-template';
export const EDIT_TEMPLATE = 'edit-template';
