/* eslint-disable max-len */
import {
  LaborTime,
  LaborTimeFilterOption,
} from '@1po/1po-bff-fe-spec/generated/catalog/labor_time/response/GetLaborTimesResponse';
import {
  GET_LABOR_TIME_IDS_BY_GENERIC_PARTS_RESPONSE,
  GET_LABOR_TIMES_RESPONSE,
} from '@1po/1po-bff-fe-spec/generated/common/ResponseType';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { v4 as uuidv4 } from 'uuid';
import { RootState } from 'app/AppStore';
import {
  GET_LABOR_TIMES_QUICK_ACCESS_REQUEST,
  GET_LABOR_TIMES_REQUEST,
  LABOR_TIME_NAMESPACE,
  LaborSubtableLabel,
  LaborTimeLocal,
  LaborTimeState,
  VehicleLaborTimes,
} from 'domains/laborTime/LaborTime.types';
import { FOUND, getArrayWithoutUndefinedMembers, hasData, NOT_FOUND, SEARCH_STATUS, SearchData } from 'utils';

export const fetchLaborTimeIdsByGenericPartsRequestSaga = createAction<{
  vehicleKey: string;
  genericPart: string;
}>(GET_LABOR_TIMES_QUICK_ACCESS_REQUEST);

export const fetchLaborTimeIdsByGenericPartsResponseSaga = createAction(GET_LABOR_TIME_IDS_BY_GENERIC_PARTS_RESPONSE);

export const fetchDHLaborTimesRequestSaga = createAction<{
  vehicleKey: string;
  laborTimeIds: string[];
}>(GET_LABOR_TIMES_REQUEST);

export const fetchDHLaborTimesResponseSaga = createAction(GET_LABOR_TIMES_RESPONSE);

const getVehicleLaborTimes = (
  vehicleKey: string,
  vehicleLaborTimes: Map<string, VehicleLaborTimes>,
): VehicleLaborTimes => {
  return (
    vehicleLaborTimes.get(vehicleKey) ?? {
      genericPartToLaborTimeIds: new Map<string, SearchData<string[]>>(),
      laborTimes: new Map<string, SearchData<LaborTimeLocal>>(),
      elements: new Map<string, LaborTimeFilterOption>(),
      operations: new Map<string, LaborTimeFilterOption>(),
    }
  );
};

// Init state
const initialState: LaborTimeState = {
  vehicleLaborTimes: new Map<string, VehicleLaborTimes>(),
};

const slice = createSlice({
  initialState,
  name: LABOR_TIME_NAMESPACE,
  reducers: {
    setInitialState: () => initialState,
    setGenericPartLaborTimesSearchStatus: (
      state,
      {
        payload,
      }: PayloadAction<{
        vehicleKey: string;
        genericPart: string;
        status: SEARCH_STATUS;
      }>,
    ) => {
      const { vehicleKey, genericPart, status } = payload;
      const currVehicleState = getVehicleLaborTimes(vehicleKey, state.vehicleLaborTimes);
      currVehicleState.genericPartToLaborTimeIds.set(genericPart, {
        searchStatus: status,
      });
      state.vehicleLaborTimes.set(vehicleKey, currVehicleState);
    },
    setGenericPartLaborTimesIds: (
      state,
      {
        payload,
      }: PayloadAction<{
        vehicleKey: string;
        genericPart: string;
        ids: string[];
      }>,
    ) => {
      const { vehicleKey, genericPart, ids } = payload;
      const currVehicleState = getVehicleLaborTimes(vehicleKey, state.vehicleLaborTimes);
      currVehicleState.genericPartToLaborTimeIds.set(genericPart, {
        searchStatus: ids.length > 0 ? FOUND : NOT_FOUND,
        data: ids.length > 0 ? ids : undefined,
      });
      state.vehicleLaborTimes.set(vehicleKey, currVehicleState);
    },
    setLaborTimesSearchStatus: (
      state,
      {
        payload,
      }: PayloadAction<{
        vehicleKey: string;
        ids: string[];
        status: SEARCH_STATUS;
      }>,
    ) => {
      const { vehicleKey, ids, status } = payload;
      const currVehicleState = getVehicleLaborTimes(vehicleKey, state.vehicleLaborTimes);
      ids.forEach((id) =>
        currVehicleState.laborTimes.set(id, {
          searchStatus: status,
        }),
      );
      state.vehicleLaborTimes.set(vehicleKey, currVehicleState);
    },
    setLaborTimes: (
      state,
      {
        payload,
      }: PayloadAction<{
        vehicleKey: string;
        laborTimes: LaborTime[];
        elements?: LaborTimeFilterOption[];
        operations?: LaborTimeFilterOption[];
      }>,
    ) => {
      const { vehicleKey, laborTimes, elements, operations } = payload;
      const currVehicleState = getVehicleLaborTimes(vehicleKey, state.vehicleLaborTimes);

      const emptySubmenuLabel = {
        applicability: [],
        id: '',
        code: '',
        designation: {},
        time: {
          totalHours: 1,
        },
        details: {},
        technicity: 'NORMAL',
        subtable: [],
        isIncluded: false,
        isSubLaborTime: true,
      };

      function pushToSubmenu(submenu: LaborTime[], label: LaborSubtableLabel, labTimes?: LaborTime[]): void {
        if (labTimes === undefined || labTimes.length === 0) {
          return;
        }
        submenu.push({
          ...emptySubmenuLabel,
          subtableLabel: label,
          uuid: uuidv4(),
        } as LaborTimeLocal);
        labTimes.forEach((subLabor) => {
          const newSubLabor = subLabor as LaborTimeLocal;
          newSubLabor.uuid = uuidv4();
          newSubLabor.subtable = [];
          newSubLabor.isIncluded = label === 'included';
          newSubLabor.isSubLaborTime = true;
          submenu.push(newSubLabor);
        });
      }

      const laborTimeLocals = laborTimes as LaborTimeLocal[];
      laborTimeLocals.forEach((labor) => {
        labor.subtable = [];
        labor.isIncluded = false;
        labor.isSubLaborTime = false;
        labor.uuid = uuidv4();
        if (labor.subLaborTimes) {
          pushToSubmenu(labor.subtable, 'included', labor.subLaborTimes.includedLaborTimes);
          pushToSubmenu(labor.subtable, 'not_included', labor.subLaborTimes.notIncludedLaborTimes);
          pushToSubmenu(labor.subtable, 'additional', labor.subLaborTimes.additionalToLaborTimes);
        }
      });

      laborTimeLocals.forEach((lt) =>
        currVehicleState.laborTimes.set(lt.id, {
          searchStatus: FOUND,
          data: lt,
        }),
      );

      elements?.forEach((e) => currVehicleState.elements.set(e.code, e));
      operations?.forEach((o) => currVehicleState.operations.set(o.code, o));

      state.vehicleLaborTimes.set(vehicleKey, currVehicleState);
    },
    resetLaborTimes: (
      state,
      {
        payload,
      }: PayloadAction<{
        vehicleKey: string;
      }>,
    ) => {
      const { vehicleKey } = payload;
      const currVehicleState = getVehicleLaborTimes(vehicleKey, state.vehicleLaborTimes);

      state.vehicleLaborTimes.set(vehicleKey, {
        ...currVehicleState,
        laborTimes: new Map<string, SearchData<LaborTimeLocal>>(),
        elements: new Map<string, LaborTimeFilterOption>(),
        operations: new Map<string, LaborTimeFilterOption>(),
      });
    },
  },
});

export const {
  setInitialState,
  setGenericPartLaborTimesSearchStatus,
  setGenericPartLaborTimesIds,
  setLaborTimesSearchStatus,
  setLaborTimes,
  resetLaborTimes,
} = slice.actions;

export const getLaborTimeIdsForGenericPartSearchStatus = createSelector(
  (state: RootState) => state.laborTime.vehicleLaborTimes,
  (
    _state: RootState,
    payload: {
      vehicleKey: string | undefined;
      genericPart: string | undefined;
    },
  ) => payload,
  (vehicleLaborTimes, payload) => {
    const { vehicleKey, genericPart } = payload;
    if (!vehicleKey || !genericPart) {
      return;
    }
    return vehicleLaborTimes.get(vehicleKey)?.genericPartToLaborTimeIds?.get(genericPart)?.searchStatus;
  },
);

export const getLaborTimeById = createSelector(
  (state: RootState) => state.laborTime.vehicleLaborTimes,
  (
    _state: RootState,
    payload: {
      vehicleKey: string | undefined;
      laborTimeId: string;
    },
  ) => payload,
  (vehicleLaborTimes, payload) => {
    const { vehicleKey, laborTimeId } = payload;
    if (!vehicleKey) {
      return;
    }
    return vehicleLaborTimes.get(vehicleKey)?.laborTimes?.get(laborTimeId)?.data;
  },
);

export const getLaborTimesByIds = createSelector(
  (state: RootState) => state.laborTime.vehicleLaborTimes,
  (
    _state: RootState,
    payload: {
      vehicleKey: string | undefined;
      laborTimeIds: string[];
    },
  ) => payload,
  (vehicleLaborTimes, payload) => {
    const { vehicleKey, laborTimeIds } = payload;
    if (!vehicleKey) {
      return;
    }

    const laborTimes = vehicleLaborTimes.get(vehicleKey)?.laborTimes;
    return laborTimeIds.map((id) => laborTimes?.get(id));
  },
);

export const getLaborTimeSearchStatusesMapByIds = createSelector(
  (state: RootState) => state.laborTime.vehicleLaborTimes,
  (
    _state: RootState,
    payload: {
      vehicleKey: string | undefined;
      laborTimeIds: string[];
    },
  ) => payload,
  (vehicleLaborTimes, payload) => {
    const { vehicleKey, laborTimeIds } = payload;
    if (!vehicleKey) {
      return;
    }

    const laborTimes = vehicleLaborTimes.get(vehicleKey)?.laborTimes;
    return laborTimeIds.reduce(
      (acc, next) => acc.set(next, laborTimes?.get(next)?.searchStatus),
      new Map<string, SEARCH_STATUS>(),
    );
  },
);

export const getLaborTimeIdsForGenericPart = createSelector(
  (state: RootState) => state.laborTime.vehicleLaborTimes,
  (
    _state: RootState,
    payload: {
      vehicleKey: string | undefined;
      genericPart: string | undefined;
    },
  ) => payload,
  (vehicleLaborTimes, payload) => {
    const { vehicleKey, genericPart } = payload;
    if (!vehicleKey || !genericPart) {
      return;
    }
    return vehicleLaborTimes.get(vehicleKey)?.genericPartToLaborTimeIds?.get(genericPart);
  },
);

export const getFirstGenericPartWithLaborTimes = createSelector(
  (state: RootState) => state.laborTime.vehicleLaborTimes,
  (
    _state: RootState,
    payload: {
      vehicleKey: string | undefined;
      genericParts: string[];
    },
  ) => payload,
  (vehicleLaborTimes, payload) => {
    const { vehicleKey, genericParts } = payload;
    if (!vehicleKey || genericParts?.length === 0) {
      return;
    }
    const genericPartToLaborTimeIds = vehicleLaborTimes.get(vehicleKey)?.genericPartToLaborTimeIds;
    return genericParts.find((gp) => hasData(genericPartToLaborTimeIds?.get(gp)?.data));
  },
);

export const getElementsByCodes = createSelector(
  (state: RootState) => state.laborTime.vehicleLaborTimes,
  (
    _state: RootState,
    payload: {
      vehicleKey: string | undefined;
      codes: string[];
    },
  ) => payload,
  (vehicleLaborTimes, payload) => {
    const { vehicleKey, codes } = payload;
    if (!vehicleKey) {
      return;
    }

    const elements = vehicleLaborTimes.get(vehicleKey)?.elements;
    return getArrayWithoutUndefinedMembers(codes.map((id) => elements?.get(id))).sort((a, b) =>
      a.code.localeCompare(b.code),
    );
  },
);

export const getOperationsByCodes = createSelector(
  (state: RootState) => state.laborTime.vehicleLaborTimes,
  (
    _state: RootState,
    payload: {
      vehicleKey: string | undefined;
      codes: string[];
    },
  ) => payload,
  (vehicleLaborTimes, payload) => {
    const { vehicleKey, codes } = payload;
    if (!vehicleKey) {
      return;
    }

    const operations = vehicleLaborTimes.get(vehicleKey)?.operations;
    return getArrayWithoutUndefinedMembers(codes.map((id) => operations?.get(id))).sort((a, b) =>
      a.code.localeCompare(b.code),
    );
  },
);

// Export reducer
export default slice.reducer;
