import React from 'react';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ExternalOrderItem } from '@1po/1po-bff-fe-spec/generated/order/response/model/ExternalOrderItem';
import { PresentableOrderStatusType } from '@1po/1po-bff-fe-spec/generated/order/response/model/PresentableOrderStatusType';
import { ROUTER_ORDERS } from 'app/AppRouter';
import { toDate } from 'domains/order/OrderDate';
import { OrderStatus } from 'domains/order/OrderStatus';
import { SOrdersTable } from 'pages/MyOrdersPage/ExternalOrders/OrdersTable/OrdersTable.styled';
import { EXTERNAL } from 'pages/MyOrdersPage/Orders.type';
import { Flex, Text } from 'UI';
import { textFormatter } from 'utils/format';

const orderColumns = () => [
  {
    title: (
      <Text type={'h2'}>
        <Trans i18nKey={'order.order_detail.order_number'}>Order number</Trans>
      </Text>
    ),
    dataIndex: 'orderId',
    key: 'orderId',
    render: function rowSelector(orderId: string) {
      return (
        <Flex>
          <Flex direction={'column'} align={'center'}>
            <Text type={'lead'}>
              <strong>{orderId}</strong>
            </Text>
          </Flex>
        </Flex>
      );
    },
  },
  {
    title: (
      <Text type={'h2'}>
        <Trans i18nKey={'order.order_detail.order_date.title'}>Order date</Trans>
      </Text>
    ),
    dataIndex: 'creationDate',
    key: 'creationDate',
    render: function rowSelector(creationDate: string) {
      return (
        <Flex direction={'column'} align={'center'}>
          {creationDate && <Text type={'light_14_black_85'}>{toDate(creationDate)}</Text>}
        </Flex>
      );
    },
  },
  {
    title: (
      <Text type={'h2'}>
        <Trans i18nKey={'order.order_detail.price'}>Price</Trans>
      </Text>
    ),
    dataIndex: 'totalPriceTaxExcluded',
    key: 'price',
    render: function rowSelector(priceVatExcluded: number, orderItem: ExternalOrderItem) {
      return (
        <Flex direction={'column'} align={'center'}>
          <Text type={'light_14_black_85'}>{textFormatter.formatCurrency(priceVatExcluded, orderItem.currency)}</Text>
        </Flex>
      );
    },
  },
  {
    title: (
      <Text type={'h2'}>
        <Trans i18nKey={'order.order_detail.status'}>Status</Trans>
      </Text>
    ),
    dataIndex: 'presentableOrderStatus',
    key: 'presentableOrderStatus',
    render: function rowSelector(orderStatus: PresentableOrderStatusType) {
      return (
        <Flex direction={'column'} align={'center'}>
          <OrderStatus orderStatus={orderStatus} />
        </Flex>
      );
    },
  },
  {
    title: (
      <Text type={'h2'}>
        <Trans i18nKey={'order.order_detail.ordered_by'}>Ordered by</Trans>
      </Text>
    ),
    dataIndex: 'application',
    key: 'application',
    render: function rowSelector(application: string) {
      return (
        <Flex direction={'column'} align={'center'}>
          <Text type={'light_14_black_85'}>{`${application}`}</Text>
        </Flex>
      );
    },
  },
];

const OrdersTable = ({ orders }: { orders: ExternalOrderItem[] }) => {
  const history = useHistory();
  const columns = orderColumns();

  return (
    <SOrdersTable<ExternalOrderItem>
      columns={columns}
      dataSource={orders}
      pagination={false}
      rowKey={(order) => order.orderId}
      size={'small'}
      bordered={true}
      onRow={(order) => {
        return {
          onClick: () => {
            history.push(`${ROUTER_ORDERS}/${EXTERNAL}/${order.orderId}`);
          },
        };
      }}
    />
  );
};

export default OrdersTable;
