/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from 'app/AppStore';
import { DataContainer } from 'components/DataContainer';
import Filter from 'components/Filter';
import { getOEMFiltersMap, RangeFacetLocal, TextFacetLocal } from 'components/Filter/Filter.types';
import FilterQuantity from 'components/Filter/FilterQuantity';
import FilterTags from 'components/Filter/FilterTags';
import {
  getIamCatalogFilters,
  getLastSearchedVehicleKey,
  resetIamRangeFilters,
  resetIamTextFilters,
  setIamRangeFilter,
  setIamTextFilter,
} from 'domains/catalog/Catalog.store';
import { IAMLaborTimesWrapper, IAMReferencesWrapper, OEM_BRAND_KEY } from 'domains/catalog/Catalog.types';
import { getIAMReferences } from 'domains/references';
import { getSparePartsView, SparePartsViewType } from 'domains/user';
import ReferenceCardsContainer from 'pages/CatalogPage/IAM/SubcategorySection/SparePartsSection/ReferenceCardsContainer';
import { getIamContainerLoader } from 'pages/CatalogPage/IAM/SubcategorySection/SparePartsSection/ReferenceCardsContainer/ReferenceCardsContainer';
import { Flex, MarginBox } from 'UI';
import { ERROR, getData, LOADING, NO_DATA, NOT_FOUND, useLarge } from 'utils';

export interface OtherBrandSectionProps {
  referencesWrapper: IAMReferencesWrapper;
  nodeId?: string;
  laborTimesWrapper: NO_DATA | IAMLaborTimesWrapper;
}

const resetOtherBrandFilters = (dispatch: Dispatch) => {
  dispatch(resetIamTextFilters({ filterType: OEM_BRAND_KEY }));
  dispatch(resetIamRangeFilters({ filterType: OEM_BRAND_KEY }));
};

const OEMBrandTab = ({
  sparePartsView,
  referencesWrapper,
  nodeId,
  laborTimesWrapper,
  setNumOfProducts,
  isCompatibleBatteries,
}: {
  sparePartsView: SparePartsViewType;
  referencesWrapper: IAMReferencesWrapper;
  nodeId?: string;
  laborTimesWrapper: NO_DATA | IAMLaborTimesWrapper;
  setNumOfProducts: (num: number) => void;
  isCompatibleBatteries: boolean;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const referencesWrapperData = getData(referencesWrapper);
  const [textFilters, setTextFilters] = useState<TextFacetLocal[]>(referencesWrapperData?.textFilters ?? []);
  const [rangeFilters, setRangeFilters] = useState<RangeFacetLocal[]>(referencesWrapperData?.rangeFilters ?? []);
  const filters = useSelector(getIamCatalogFilters);
  const large = useLarge();
  const referenceNumbers = referencesWrapperData?.referenceNumbers ?? [];

  useEffect(() => {
    setTextFilters(referencesWrapperData?.textFilters ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referencesWrapperData?.textFilters?.length, nodeId]);

  useEffect(() => {
    setRangeFilters(referencesWrapperData?.rangeFilters ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referencesWrapperData?.rangeFilters?.length, nodeId]);

  useEffect(() => {
    resetOtherBrandFilters(dispatch);
    if (referencesWrapper === NOT_FOUND || referencesWrapper === ERROR) {
      setNumOfProducts(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referencesWrapper]);

  return (
    <Flex direction={large ? 'row' : 'column'}>
      <Flex direction={'column'} size={0}>
        <Filter
          filters={filters.oemFilters}
          textFilters={referencesWrapperData?.textFilters}
          rangeFilters={referencesWrapperData?.rangeFilters}
          setTextFilters={(id, item) => dispatch(setIamTextFilter({ id, item, filterType: OEM_BRAND_KEY }))}
          setRangeFilters={(id, range) => dispatch(setIamRangeFilter({ id, range, filterType: OEM_BRAND_KEY }))}
          resetFilters={() => resetOtherBrandFilters(dispatch)}
          loading={referencesWrapper === LOADING}
          facetsMap={getOEMFiltersMap(t)}
        />
      </Flex>
      <MarginBox mr={large ? 15 : 0} mt={large ? 0 : 15} />
      <DataContainer data={referencesWrapper} Loading={getIamContainerLoader}>
        <ReferenceCardsContainer
          isCompatible={isCompatibleBatteries}
          sparePartsView={sparePartsView}
          referenceNumbers={referenceNumbers}
          nodeId={nodeId}
          laborTimesWrapper={laborTimesWrapper}
          setNumOfProducts={setNumOfProducts}
          textFilters={textFilters}
          rangeFilters={rangeFilters}
          setTextFilters={setTextFilters}
          setRangeFilters={setRangeFilters}
        />
      </DataContainer>
    </Flex>
  );
};

const OEMBrandSection = ({ referencesWrapper, nodeId, laborTimesWrapper }: OtherBrandSectionProps) => {
  const dispatch = useDispatch();
  const sparePartsView = useSelector(getSparePartsView);
  const filters = useSelector(getIamCatalogFilters);
  const [numOfProducts, setNumOfProducts] = useState<number>(0);
  const referencesWrapperData = getData(referencesWrapper);
  const referenceNumbers = referencesWrapperData?.referenceNumbers ?? [];
  const vehicleKey = useSelector(getLastSearchedVehicleKey) ?? '';
  const references = useSelector((state: RootState) => getIAMReferences(state, { vehicleKey, referenceNumbers }));
  const isCompatibleBatteries = references.some((r) => r.isBatteryCompatibleWithVehicle);

  return (
    <>
      <MarginBox my={15} />
      <FilterTags
        filters={filters.oemFilters}
        setTextFilters={(id, item) => dispatch(setIamTextFilter({ id, item, filterType: OEM_BRAND_KEY }))}
        setRangeFilters={(id, range) => dispatch(setIamRangeFilter({ id, range, filterType: OEM_BRAND_KEY }))}
      />
      <MarginBox my={15} />
      <FilterQuantity numberOfProducts={numOfProducts} />
      <Flex direction={'column'}>
        <OEMBrandTab
          sparePartsView={sparePartsView}
          referencesWrapper={referencesWrapper}
          nodeId={nodeId}
          laborTimesWrapper={laborTimesWrapper}
          setNumOfProducts={setNumOfProducts}
          isCompatibleBatteries={isCompatibleBatteries}
        />
      </Flex>
    </>
  );
};

export default OEMBrandSection;
