import { useSortable } from '@dnd-kit/sortable';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { MyStoreBundle } from '@1po/1po-bff-fe-spec/generated/my_store/bundles/response/GetMyStoreBundlesResponse';
import { CSS } from '@dnd-kit/utilities';
import { TFunction } from 'i18next';
import { RootState } from 'app/AppStore';
import { AngleDownIcon, AngleRightIcon, Dots4Icon, PlusCircleIcon, TrashAltIcon } from 'assets/icons';
import { Dialog, useDisclosure } from 'components/Dialog';
import StopPropagation from 'components/StopPropagation/StopPropagation';
import {
  addBundleRequestSaga,
  removeBundleSectionReuestSaga,
  getIsBundleSelected,
  getIsSectionSelected,
  renameBundlesSectionRequestSaga,
  setBundleSelection,
} from 'domains/myStore/MyStore.store';
import { SectionTitleType } from 'domains/myStore/MyStore.types';
import { SectionHeader } from 'pages/MyStorePage/Pages/Bundles/Bundles.styled';
import BundlesTable from 'pages/MyStorePage/Pages/Bundles/BundlesTable';
import { ValidatedTextInput } from 'pages/MyStorePage/Pages/Bundles/BundlesTable/BundlesInput';
import { theme } from 'styles';
import { Box, Flex, Icon, MarginBox, Text, WhiteBlueButton, Checkbox, ErrorButton, PaddingBox } from 'UI';
import { useFocus } from 'utils';

export const SelectBundles = ({
  sectionId,
  bundleId,
  enabled,
}: {
  sectionId: string;
  bundleId?: string;
  enabled: boolean;
}) => {
  const dispatch = useDispatch();
  const isSectionSelected = useSelector((state: RootState) => getIsSectionSelected(state, sectionId));
  const isBundleSelected = useSelector((state: RootState) => getIsBundleSelected(state, bundleId));

  const handleChange = (v: boolean) => {
    dispatch(setBundleSelection({ sectionId, bundleId, isSelected: v }));
  };

  return (
    <Checkbox
      disabled={!enabled}
      checked={enabled && (bundleId ? isBundleSelected : isSectionSelected)}
      onChange={(v) => handleChange(v)}
    />
  );
};

const getSectionTitle = (t: TFunction, title: SectionTitleType) => {
  switch (title) {
    case 'TIRES':
      return t('catalog.tires', 'Tires');
    case 'BRAKING':
      return t('catalog.brakes_category.braking', 'Braking');
    case 'MAINTENANCE':
      return t('catalog.parts.category.maintenance', 'Maintenance');
    case 'OTHER':
      return t('common.other', 'Other');
    case '':
      return t('common.new_bundle', 'New bundle category');
    default:
      return title;
  }
};

const SectionTitle = ({
  sectionId,
  title,
  isEditing,
  setIsEditing,
}: {
  sectionId: string;
  title: string;
  isEditing: boolean;
  setIsEditing: (v: boolean) => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [titleState, setTitleState] = useState(getSectionTitle(t, title));
  const inputRef = useRef(null);

  useFocus(inputRef, [isEditing]);

  useEffect(() => {
    setTitleState(getSectionTitle(t, title));
  }, [t, title]);

  const handleTitleChange = (title: string) => {
    dispatch(renameBundlesSectionRequestSaga({ sectionId, title }));
    setTitleState(title);
  };

  return (
    <>
      {isEditing ? (
        <ValidatedTextInput
          noTooltip
          value={titleState}
          saveFunction={handleTitleChange}
          maxLength={window.innerWidth / 30}
          passRef={inputRef}
          validate={(v) => v.length > 0}
          onBlur={(v) => {
            setIsEditing(false);
            setTitleState(v);
          }}
          isLarge
        />
      ) : (
        <Text type={'h2'} disableGutter onClick={() => setIsEditing(true)} noWrap cursor={'text'}>
          {titleState}
        </Text>
      )}
    </>
  );
};

const BundlesSection = ({
  sectionId,
  title,
  bundles,
  isDragging,
  initiallyOpen = false,
}: {
  sectionId: string;
  title: string;
  bundles: MyStoreBundle[];
  isDragging: boolean;
  initiallyOpen: boolean;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(initiallyOpen);
  const [showDelete, setShowDelete] = useState(false);
  const [isTitleEditing, setIsTitleEditing] = useState(false);
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: sectionId });
  const disclosure = useDisclosure();
  const { onOpen } = disclosure;

  const handleAddBundle = () => {
    dispatch(addBundleRequestSaga({ sectionId }));
  };

  const handleDeleteBundleSection = () => {
    dispatch(removeBundleSectionReuestSaga({ sectionId }));
  };

  const handleOpenClose = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      style={{
        transition,
        transform: CSS.Transform.toString(transform),
        backgroundColor: theme.color.white,
        position: 'relative',
        zIndex: isDragging ? 200 : 100,
      }}
    >
      <Dialog
        title={t('common.dialog.actionRequired', 'Action required')}
        description={[
          t('my_store.bundles.remove.description1', 'You are about to delete all the category'),
          t('my_store.bundles.remove.description2', 'All lots associated to this category will be affected'),
        ]}
        icon={TrashAltIcon}
        handleConfirm={handleDeleteBundleSection}
        disclosure={disclosure}
      />
      <Flex direction={'column'} gap={16}>
        <Flex gap={15} align={'center'}>
          <div ref={setNodeRef} {...attributes} {...listeners}>
            <Icon IconComponent={Dots4Icon} size={16} color={'black'} />
          </div>

          <SectionHeader
            align={'center'}
            gap={16}
            isOpen={isOpen}
            onClick={handleOpenClose}
            onMouseEnter={() => setShowDelete(true)}
            onMouseLeave={() => setShowDelete(false)}
          >
            {!isOpen && (
              <Flex align={'center'} gap={15} size={0} onClick={(e) => e.stopPropagation()}>
                <SelectBundles sectionId={sectionId} enabled={true} />
                <Box height={48} width={1} background={theme.color.grey85} />
              </Flex>
            )}
            <Flex justify={'space-between'}>
              <Flex
                direction={isOpen ? 'row' : 'column'}
                align={isOpen ? 'center' : 'initial'}
                gap={isOpen ? 8 : 0}
                size={isTitleEditing ? 1 : 0}
                onClick={(e) => e.stopPropagation()}
              >
                <SectionTitle
                  sectionId={sectionId}
                  title={title}
                  isEditing={isTitleEditing}
                  setIsEditing={setIsTitleEditing}
                />
                <MarginBox mt={5}>
                  <Text type={'light_dimmer'} noWrap cursor={'text'} onClick={(e) => e.stopPropagation()}>
                    {bundles.length} bundles
                  </Text>
                </MarginBox>
              </Flex>
              <Flex size={0} gap={15} align={'center'} onClick={(e) => e.stopPropagation()}>
                {(isOpen || showDelete) && (
                  <ErrorButton size={'middle'} onClick={onOpen}>
                    <Flex gap={5} align={'center'}>
                      <Icon IconComponent={TrashAltIcon} size={16} />
                      {t('common.action.delete', 'Delete')}
                    </Flex>
                  </ErrorButton>
                )}
                <Icon IconComponent={isOpen ? AngleDownIcon : AngleRightIcon} size={20} onClick={handleOpenClose} />
              </Flex>
            </Flex>
          </SectionHeader>
        </Flex>
        {isOpen && (
          <StopPropagation>
            <PaddingBox pl={30}>
              <BundlesTable sectionId={sectionId} bundles={bundles} />
              <WhiteBlueButton size={'large'} onClick={handleAddBundle}>
                <Flex gap={8} align={'center'}>
                  <Icon IconComponent={PlusCircleIcon} size={20} />
                  {t('my_store.bundles.actions.add_bundle', 'Add bundle')}
                </Flex>
              </WhiteBlueButton>
            </PaddingBox>
          </StopPropagation>
        )}
      </Flex>
    </div>
  );
};

export default BundlesSection;
