import styled from 'styled-components';

export const SCard = styled.div<{
  selected?: boolean;
  isSquared?: boolean;
  isCompact?: boolean;
  bordered?: boolean;
}>`
  position: relative;
  margin-top: 0;
  transition: height 2s;
  padding: ${({ isCompact }) => (isCompact ? '10px 10px' : '15px 15px')};
  background-color: ${({ theme }) => theme.color.white};
  border-radius: ${({ isSquared }) => (isSquared ? '14px 14px 0 0' : '14px')};
  box-shadow: ${({ theme, bordered }) => bordered && `0 4px 10px 0 ${theme.color.grey75}`};
  border: ${({ theme, selected }) =>
    selected ? `solid 2px ${theme.color.clear_blue}` : `solid 2px ${theme.color.transparent}`};
`;

export const ReferenceBrandContainer = styled.div`
  position: absolute;
  display: flex;
  bottom: 0;
  left: 0;
`;

export const SReferenceImageCard = styled.div`
  position: relative;
  align-items: flex-end;
  display: flex;
`;
