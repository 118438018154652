import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Request as AssistanceRequest,
  Type as ContactFormType,
} from '@1po/1po-bff-fe-spec/generated/email/request/SendEmailToAssistance';
import { Form as AntForm } from 'antd';
import { TFunction } from 'i18next';
import { FileUpload } from 'components/FileUpload';
import { FILE, FILE_FORMAT_ERROR, MAX_SIZE_ERROR, ModalStatusType, NO_FILE } from 'components/FileUpload/paramsByState';
import { HEADER_HEIGHT } from 'components/Page/Header/TopHeader/TopHeader';
import {
  sendEmailToAssistanceRequestSaga,
  setEmailToAssistanceAttachments,
  setEmailToAssistanceData,
} from 'domains/email/Email.store';
import {
  CAR_PARTS,
  DEFAULT,
  MEMBERSHIP_AND_SUPPORT,
  REN_DAC,
  SendEmailToAssistanceLocal,
  TECHNICAL_DOCUMENTATION,
} from 'domains/email/Email.types';
import { getUserGarageInfo } from 'domains/garage/Garage.store';
import { SItem } from 'pages/AssistancePage/AssistanceCategories/ContactUs/ContactUs.styled';
import { useEmailToAssistanceState } from 'pages/AssistancePage/AssistanceCategories/ContactUs/useEmailToAssistanceState';
import { imageFileRegex } from 'pages/BackOfficePage/BackOfficeCategories/Promotion/CreatePromotion/DetailsStep/PromotionVisual';
import { Checkbox, Flex, Form, Input, MarginBox, Select, Text, TextArea, YellowButton } from 'UI';
import { getData } from 'utils';
import { scrollToTopSmooth } from 'utils/hooks/useResetScroll';

interface SelectRequestOption {
  title: string;
  value: AssistanceRequest;
}

const requestOptions = (t: TFunction, type: ContactFormType): SelectRequestOption[] => {
  switch (type) {
    case REN_DAC:
      return [
        {
          title: t('assistance.contact_us.select.membership', 'Membership'),
          value: 'MEMBERSHIP',
        },
        {
          title: t('assistance.contact_us.select.renault_motrio_parts', 'Renault and Motrio parts'),
          value: 'RENAULT_AND_MOTRIO_PARTS',
        },
        {
          title: t(
            'assistance.contact_us.select.universal_products_accessories_merchandising',
            'Universal products and Accessories / Merchandising',
          ),
          value: 'UNIVERSAL_PRODUCTS_AND_ACCESSORIES_MERCHANDISING',
        },
        {
          title: t('assistance.contact_us.select.services', 'Services'),
          value: 'SERVICES',
        },
        {
          title: t('assistance.contact_us.select.data_processing', 'Data processing'),
          value: 'DATA_PROCESSING',
        },
        {
          title: t('common.others', 'Others'),
          value: 'OTHERS',
        },
      ];
    case TECHNICAL_DOCUMENTATION:
      return [
        {
          title: t('assistance.contact_us.select.vehicle_identification', 'Vehicle identification'),
          value: 'VEHICLE_IDENTIFICATION',
        },
        {
          title: t('catalog.parts.category.technical_documentation', 'Technical documentation'),
          value: 'TECHNICAL_DOCUMENTATION',
        },
        {
          title: t('catalog.parts.category.car_parts.labor_time', 'Labor time'),
          value: 'LABOR_TIME',
        },
        {
          title: t('common.others', 'Others'),
          value: 'OTHERS',
        },
      ];
    case MEMBERSHIP_AND_SUPPORT:
      return [
        {
          title: t('assistance.contact_us.select.membership', 'Membership'),
          value: 'MEMBERSHIP',
        },
        {
          title: t('catalog.parts.category.navigation_assistance', 'Navigation assistance'),
          value: 'LABOR_TIME',
        },
        {
          title: t('common.others', 'Others'),
          value: 'OTHERS',
        },
      ];
    case CAR_PARTS:
    default:
      return [
        {
          title: t('assistance.contact_us.select.membership', 'Membership'),
          value: 'MEMBERSHIP',
        },
        {
          title: t('assistance.contact_us.select.renault_motrio_parts', 'Renault and Motrio parts'),
          value: 'RENAULT_AND_MOTRIO_PARTS',
        },
        {
          title: t(
            'assistance.contact_us.select.universal_products_accessories_merchandising',
            'Universal products and Accessories / Merchandising',
          ),
          value: 'UNIVERSAL_PRODUCTS_AND_ACCESSORIES_MERCHANDISING',
        },
        {
          title: t('assistance.contact_us.select.orders', 'Orders'),
          value: 'ORDERS',
        },
        {
          title: t('assistance.contact_us.select.services', 'Services'),
          value: 'SERVICES',
        },
        {
          title: t('assistance.contact_us.select.data_processing', 'Data processing'),
          value: 'DATA_PROCESSING',
        },
        {
          title: t('common.others', 'Others'),
          value: 'OTHERS',
        },
      ];
  }
};

interface FormItemProps {
  name: string;
  label?: string;
  type?: 'string' | 'email' | 'boolean' | any;
  required?: boolean;
  message?: string;
  children?: React.ReactNode;
}

const FormItem = ({ name, label, type, required = false, message, children }: FormItemProps) => (
  <SItem name={[name]} label={<Text type={'text_dim_bold'}>{label}</Text>} rules={[{ required, type, message }]}>
    {children}
  </SItem>
);

export const isFilled = (s?: string): boolean => (s?.length ?? 0) > 0;

const isSendDisable = (
  emailToAssistance: SendEmailToAssistanceLocal,
  isEmailValid: boolean,
  type: ContactFormType,
): boolean => {
  if (!emailToAssistance) {
    return false;
  }

  switch (type) {
    case REN_DAC:
      return !(
        isFilled(emailToAssistance.email) &&
        isEmailValid &&
        isFilled(emailToAssistance.socialReason) &&
        isFilled(emailToAssistance.companyRegistrationNumber) &&
        isFilled(emailToAssistance.vrn) &&
        isFilled(emailToAssistance.subject) &&
        isFilled(emailToAssistance.body) &&
        isFilled(emailToAssistance.urlLink) &&
        emailToAssistance.request
      );
    case CAR_PARTS:
      return !(
        isFilled(emailToAssistance.email) &&
        isEmailValid &&
        isFilled(emailToAssistance.socialReason) &&
        isFilled(emailToAssistance.companyRegistrationNumber) &&
        isFilled(emailToAssistance.subject) &&
        isFilled(emailToAssistance.body)
      );
    case DEFAULT:
    case TECHNICAL_DOCUMENTATION:
    case MEMBERSHIP_AND_SUPPORT:
      return !(
        isFilled(emailToAssistance.email) &&
        isEmailValid &&
        isFilled(emailToAssistance.socialReason) &&
        isFilled(emailToAssistance.companyRegistrationNumber) &&
        isFilled(emailToAssistance.subject) &&
        isFilled(emailToAssistance.body) &&
        emailToAssistance.request
      );
    default:
      return false;
  }
};

const ContactUsForm = ({ type }: { type: ContactFormType }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const garageInfo = useSelector(getUserGarageInfo);
  const emailToAssistance = useEmailToAssistanceState(getData(garageInfo));
  const [form] = AntForm.useForm();
  const [fileStatus, setFileStatus] = useState<ModalStatusType>(NO_FILE);
  const attachments = getData(emailToAssistance.attachments);
  const attachment = attachments && attachments.length > 0 ? attachments[0] : undefined;
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
  const values = AntForm.useWatch([], form);

  useEffect(() => {
    form.setFieldsValue(emailToAssistance);
  }, [form, emailToAssistance]);

  useEffect(() => {
    form
      .validateFields(['email'])
      .then(() => setIsEmailValid(true))
      .catch(() => setIsEmailValid(false));
  }, [form, values]);

  const handleFileBase64 = (fileName: string, _fileSize: number, fileBase64: string) => {
    setFileStatus(FILE);
    dispatch(
      setEmailToAssistanceAttachments([
        {
          fileBase64,
          fileName,
        },
      ]),
    );
  };

  const handleNoFile = () => {
    setFileStatus(NO_FILE);
    dispatch(
      setEmailToAssistanceData({
        ...emailToAssistance,
        attachments: [],
      }),
    );
  };
  const descriptions = [t('common.file_upload.image.description', 'Supported extension: Jpg, Png. File size max 2MB.')];
  const getText1Override = () =>
    fileStatus === MAX_SIZE_ERROR ? t('common.file_upload.image.max_size_error', 'File size exceeds 2 MB') : '';
  const validateFile = (file: File) => {
    if (!imageFileRegex.exec(file.name)) {
      setFileStatus(FILE_FORMAT_ERROR);
      return false;
    } else if (file.size > 2 * 1024 * 1024) {
      setFileStatus(MAX_SIZE_ERROR);
      return false;
    } else {
      return true;
    }
  };

  const setEmailData = (emailData: SendEmailToAssistanceLocal) => {
    dispatch(setEmailToAssistanceData(emailData));
  };

  const handleSend = () => {
    dispatch(sendEmailToAssistanceRequestSaga(type));
    scrollToTopSmooth((document.getElementById('assistance-contact_us')?.offsetTop ?? 0) - HEADER_HEIGHT);
  };

  const validationMessages = {
    types: {
      email: t('common.email.validation', 'Please enter valid e-mail address.'),
    },
  };

  return (
    <Form
      form={form}
      validationMessages={validationMessages}
      onFinish={handleSend}
      layout={'vertical'}
      initialValues={emailToAssistance}
    >
      <Flex direction={'row'} align={'flex-start'}>
        <Flex direction={'column'}>
          <FormItem name={'email'} label={t('common.email', 'E-mail address')} type={'email'} required>
            <Input
              value={emailToAssistance?.email}
              onChange={(email) => setEmailData({ ...emailToAssistance, email })}
              placeholder={t('common.email.address.placeholder', 'Type your e-mail address here')}
              bordered
            />
          </FormItem>
          <FormItem
            name={'socialReason'}
            label={t('assistance.contact_us.social_reason', 'Social reason')}
            type={'string'}
            required
          >
            <Input
              value={emailToAssistance?.socialReason}
              onChange={(socialReason) => setEmailData({ ...emailToAssistance, socialReason })}
              placeholder={t('assistance.contact_us.social_reason.placeholder', 'Your social reason')}
              bordered
            />
          </FormItem>
          <FormItem
            name={'companyRegistrationNumber'}
            label={t('assistance.contact_us.company_registration_number', 'Company registration number')}
            type={'string'}
            required
          >
            <Input
              value={emailToAssistance?.companyRegistrationNumber}
              onBlur={(companyRegistrationNumber) => setEmailData({ ...emailToAssistance, companyRegistrationNumber })}
              placeholder={t(
                'assistance.contact_us.company_registration_number.placeholder',
                'Your company registration number',
              )}
              bordered
            />
          </FormItem>
          {type !== CAR_PARTS && (
            <FormItem name={'request'} label={t('assistance.contact_us.request', 'Request')} type={'string'} required>
              <Select
                value={emailToAssistance?.request}
                onChange={(request) =>
                  setEmailData({
                    ...emailToAssistance,
                    request: typeof request === 'string' ? (request as AssistanceRequest) : undefined,
                  })
                }
                options={requestOptions(t, type)}
                placeholder={t('assistance.contact_us.request.placeholder', 'Select a category')}
                bordered
              />
            </FormItem>
          )}
          {type === REN_DAC && (
            <>
              <FormItem
                name={'vrn'}
                label={t('assistance.contact_us.vehicle_registration_number', 'Vehicle registration number')}
                type={'string'}
                required
              >
                <Input
                  value={emailToAssistance?.vrn}
                  onChange={(vrn) => setEmailData({ ...emailToAssistance, vrn })}
                  placeholder={t(
                    'assistance.contact_us.vehicle_registration_number.placeholder',
                    'Your vehicle registration number',
                  )}
                  bordered
                />
              </FormItem>
              <FormItem
                name={'urlLink'}
                label={t('assistance.contact_us.url_link', 'URL link')}
                type={'string'}
                required
              >
                <Input
                  value={emailToAssistance?.urlLink}
                  onChange={(urlLink) => setEmailData({ ...emailToAssistance, urlLink })}
                  placeholder={t('assistance.contact_us.url_link.placeholder', 'Where did you encounter the issue?')}
                  bordered
                />
              </FormItem>
            </>
          )}
        </Flex>
        <MarginBox mr={45} />
        <Flex direction={'column'}>
          <FormItem name={'subject'} label={t('common.email.object', 'Subject')} type={'string'} required>
            <Input
              value={emailToAssistance?.subject}
              onChange={(subject) => setEmailData({ ...emailToAssistance, subject })}
              placeholder={t('common.email.object.placeholder', `Please, enter your e-mail's subject`)}
              bordered
            />
          </FormItem>
          <FormItem name={'body'} label={t('assistance.contact_us.message', 'Message')} type={'string'} required>
            <TextArea
              initialValue={emailToAssistance?.body}
              onChange={(body) => setEmailData({ ...emailToAssistance, body })}
              style={{ height: '200px' }}
              placeholder={t(
                'assistance.contact_us.message_placeholder',
                /* eslint-disable max-len */
                'When sending your comment(s), please ensure that your content is directly related to the subject of your request and that it does not contain any subjective, excessive, or insulting elements or any sensitive personal data (i.e. health information, information related to a possible disability,...).',
              )}
              allowEnterNextLine
            />
          </FormItem>
          <FormItem
            name={'attachments'}
            label={t('assistance.contact_us.attach_document', 'Attach document')}
            type={{}}
          >
            <Flex>
              <FileUpload
                handleFileBase64={handleFileBase64}
                validateFile={validateFile}
                handleNoFile={handleNoFile}
                status={fileStatus}
                descriptions={descriptions}
                getText1Override={getText1Override}
                currentFile={
                  fileStatus !== NO_FILE && attachment
                    ? {
                        fileType: 'IMAGE',
                        fileName: attachment?.fileName ?? '',
                        fileBase64: attachment?.fileBase64 ?? '',
                      }
                    : undefined
                }
              />
            </Flex>
          </FormItem>
          <FormItem name={'sendCopy'} label={'Send Copy'} required={false} type={'boolean'}>
            <>
              <Checkbox
                checked={emailToAssistance?.sendCopy}
                onChange={(sendCopy) => {
                  setEmailData({ ...emailToAssistance, sendCopy });
                }}
                label={t('common.email.request_copy', 'I would like to receive a copy of this e-mail.')}
              />
              <MarginBox mt={15} />
              <Text type={'description'}>
                {t(
                  'assistance.contact_us.privacy_policy_note',
                  "By clicking “Send”, I confirm that I have read Rpartstore's  Privacy Policy.",
                )}
              </Text>
            </>
          </FormItem>
          <MarginBox mt={15} />
          <Flex justify={'flex-end'}>
            <YellowButton disabled={isSendDisable(emailToAssistance, isEmailValid, type)} htmlType={'submit'}>
              {t('common.action.send', 'Send')}
            </YellowButton>
          </Flex>
        </Flex>
      </Flex>
    </Form>
  );
};

export default ContactUsForm;
