/* eslint-disable max-len */
import { DiscontinuedReferencesFileResponse } from '@1po/1po-bff-fe-spec/generated/backoffice/discontinued_references/response/DiscontinuedReferencesFileResponse';
import { BACKOFFICE_DISCONTINUED_REFERENCES_GET_FILE_RESPONSE } from '@1po/1po-bff-fe-spec/generated/common/ResponseType';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { RootState } from 'app/AppStore';
import { ModalStatusType, NO_FILE } from 'components/FileUpload/paramsByState';
import {
  DISCONTINUED_REFERENCES_NAMESPACE,
  DiscontinuedReferencesFileInformation,
  DiscontinuedReferencesFileStatus,
  DiscontinuedReferencesState,
  GET_FILE,
  UPLOAD_FILE,
} from 'domains/discontinuedReferences/DiscontinuedReferences.types';
import { FETCHING_FILE } from 'domains/electronicPartsRepair/ElectronicPartsRepair.types';
import { SEARCH_STATUS } from 'utils';

// Init state
const initialState: DiscontinuedReferencesState = {
  fileUploadStatus: {
    fileStatus: FETCHING_FILE,
    modalStatus: NO_FILE,
  },
  discontinuedReferences: [],
  fileInformation: { fileName: '' },
  fileInformationForRequest: { fileName: '' },
  searchStatus: undefined,
};

// Saga actions
export const uploadFileDiscontinuedReferencesRequestSaga = createAction<{
  fileName: string;
  fileBase64: string;
}>(UPLOAD_FILE);

export const fetchFileDiscontinuedReferencesRequestSaga = createAction(GET_FILE);
export const fetchFileDiscontinuedReferencesResponseSaga = createAction(
  BACKOFFICE_DISCONTINUED_REFERENCES_GET_FILE_RESPONSE,
);

// Slice
const slice = createSlice({
  name: DISCONTINUED_REFERENCES_NAMESPACE,
  initialState,
  reducers: {
    setDiscontinuedReferencesFileUploadStatus: (
      state,
      { payload }: PayloadAction<DiscontinuedReferencesFileStatus>,
    ) => {
      state.fileUploadStatus.fileStatus = payload;
    },
    setDiscontinuedReferencesFileModalStatus: (state, { payload }: PayloadAction<ModalStatusType>) => {
      state.fileUploadStatus.modalStatus = payload;
    },
    setDiscontinuedReferencesFileInformationForRequest: (
      state,
      { payload }: PayloadAction<DiscontinuedReferencesFileInformation>,
    ) => {
      state.fileInformationForRequest = payload;
    },
    setDiscontinuedReferencesSearchFileStatus: (state, { payload }: PayloadAction<SEARCH_STATUS>) => {
      state.searchStatus = payload;
    },
    setDiscontinuedReferencesFileResponse: (state, { payload }: PayloadAction<DiscontinuedReferencesFileResponse>) => {
      const { fileName, fileRows } = payload;
      state.fileInformation = {
        fileName,
      };
      state.discontinuedReferences = fileRows;
    },
  },
});

export const {
  setDiscontinuedReferencesFileModalStatus,
  setDiscontinuedReferencesFileInformationForRequest,
  setDiscontinuedReferencesFileUploadStatus,
  setDiscontinuedReferencesSearchFileStatus,
  setDiscontinuedReferencesFileResponse,
} = slice.actions;

export const getDiscontinuedReferencesFileInformationForRequest = createSelector(
  (state: RootState) => state.discontinuedReferences.fileInformationForRequest,
  (fileInformation) => fileInformation,
);

export const getDiscontinuedReferencesFileInformation = createSelector(
  (state: RootState) => state.discontinuedReferences.fileInformation,
  (fileInformation) => fileInformation,
);

export const getDiscontinuedReferencesFileUploadStatus = createSelector(
  (state: RootState) => state.discontinuedReferences.fileUploadStatus.fileStatus,
  (fileStatus) => fileStatus,
);

export const getDiscontinuedReferencesFileModalStatus = createSelector(
  (state: RootState) => state.discontinuedReferences.fileUploadStatus.modalStatus,
  (modalStatus) => modalStatus,
);

export const getDiscontinuedReferencesFileFetchStatus = createSelector(
  (state: RootState) => state.discontinuedReferences.searchStatus,
  (searchStatus) => searchStatus,
);

export const getDiscontinuedReferences = createSelector(
  (state: RootState) => state.discontinuedReferences.discontinuedReferences,
  (discontinuedReferences) => discontinuedReferences,
);

export default slice.reducer;
