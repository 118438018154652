import { useEffect, useState } from 'react';
import { SupplierReference } from '@1po/1po-bff-fe-spec/generated/catalog/references/iam/model/Reference';
/* eslint-disable max-len */
import { TFunction } from 'i18next';
import { useDebouncedCallback } from 'use-debounce';
import { STANDARD_EXCHANGE_REFERENCE_PATTERN } from 'pages/CatalogPage/IAM/SubcategorySection/SparePartsSection/ReferenceCardsContainer/ReferenceCard/IAMReferenceCard';

export const getTranslatedDisplayName = (
  t: TFunction,
  referenceNumber?: string,
  referenceName?: string,
  designation?: string,
  supplierReferences?: SupplierReference[],
  supplier?: string,
): string => {
  const rawSupplierReferences = supplierReferences?.filter((ref) => ref.type === 'RAW');
  const rawLabels =
    rawSupplierReferences && rawSupplierReferences.length > 0
      ? rawSupplierReferences?.map((rawSupplierReference) => (rawSupplierReference ? rawSupplierReference?.id : ''))
      : [];
  const name = referenceName ?? designation;
  const translatedName = referenceNumber?.startsWith(STANDARD_EXCHANGE_REFERENCE_PATTERN)
    ? `${name} [${t('catalog.iam.standard_exchange', 'STANDARD EXCHANGE')}]`
    : name;
  return [translatedName, supplier, ...rawLabels].filter(Boolean).join(' / ');
};

export const getDisplayName = (
  referenceName?: string,
  designation?: string,
  supplierReferences?: SupplierReference[],
  supplier?: string,
): string => {
  const rawSupplierReferences = supplierReferences?.filter((ref) => ref.type === 'RAW');
  const rawLabels =
    rawSupplierReferences && rawSupplierReferences.length > 0
      ? rawSupplierReferences?.map((rawSupplierReference) => (rawSupplierReference ? rawSupplierReference?.id : ''))
      : [];
  const name = referenceName ?? designation;

  return [name, supplier, ...rawLabels].filter(Boolean).join(' / ');
};

export function useWindowWidth(debounceTime = 100): number {
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  const setNewWindowWidth = useDebouncedCallback(() => {
    setWindowWidth(window.innerWidth);
  }, debounceTime);

  useEffect(() => {
    window.addEventListener('resize', setNewWindowWidth);
    return () => {
      window.removeEventListener('resize', setNewWindowWidth);
    };
  }, [setNewWindowWidth]);

  return windowWidth;
}
