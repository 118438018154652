import styled from 'styled-components';
import { Select } from 'UI';

export const BorderedSelect = styled(Select)`
  .ant-select-selector {
    border: 2px solid black !important;
    border-radius: 5px;
    transition: border-color 0.3s;
  }

  .ant-select-selector:focus,
  .ant-select-selector:hover {
    border-color: ${({ theme }) => theme.color.grey20} !important;
  }

  .ant-select-arrow {
    color: ${({ theme }) => theme.color.grey05_dark};
    font-size: 16px;
    border-width: 2px;
  }

  .ant-select-arrow path {
    stroke-width: 2;
  }

  .ant-select-item-option-selected {
    background-color: ${({ theme }) => theme.color.grey05_dark};
    color: ${({ theme }) => theme.color.grey100};
  }

  .ant-select-item-option-selected:hover {
    background-color: ${({ theme }) => theme.color.grey20};
  }

  .ant-select-selection-placeholder {
    color: ${({ theme }) => theme.color.grey05_dark};
    font-weight: bold;
    opacity: 0.8;
  }
`;
