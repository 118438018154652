import styled from 'styled-components';
import { Flex } from 'UI';

export const SDiv = styled.div`
  width: 500px;
  padding: 8px;
`;

export const SDivider = styled.div`
  height: 8px;
  margin: 10px -20px;
  background-color: black;
`;

export const ScrollDiv = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 500px;
  padding-right: 6px;
  margin-right: -14px;
`;

export const SectionWrapper = styled(Flex)`
  min-height: 70px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
`;

export const ReferenceCardWrapper = styled(Flex)`
  background-color: #f8f8f9;
  min-height: 96px;
  border-top: solid 1px rgba(0, 0, 0, 0.1);
  padding: 10px 16px;
`;
