/* eslint-disable max-len */
/* eslint-disable no-irregular-whitespace */
import React, { ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { ExclamationTriangleIcon } from 'assets/icons';
import { DataContainer } from 'components/DataContainer';
import { getLastTechnicalCriteria } from 'domains/catalog/Catalog.store';
import {
  useFetchIllustrationsPath,
  useFetchMaintenanceValuesXml,
} from 'domains/maintenanceValues/MaintenanceValues.requests';
import { useFetchSignature } from 'domains/pictures/Pictures.requests';
import { theme } from 'styles';
import { Flex, Icon, ImageWithLoader, MarginBox, Text } from 'UI';
import { getData, useBreakpointSelectorFull } from 'utils';

const SMaintenanceValues = styled(Flex)`
  p {
    margin: 0;
  }

  p + p {
    margin-left: 0;
  }

  td {
    padding: 0 20px 0 20px;
    height: 50px;
  }

  span {
    margin: 30px 0 15px 0;
  }

  tbody > tr {
    border-bottom: solid 1px ${theme.color.grey85};
  }

  table {
    margin-bottom: 15px;
  }
`;

type toolType = {
  description: string;
  name: string;
  application: string;
  href: string;
};

export const isCarApplicable = (carCriterias: string[], appl: string): boolean => {
  // description on parsing applicability string:
  //  | - means OR
  //  , - means OR with lower priority
  //  / - means ALL - AND
  const isGroupValid = (group: string): boolean => {
    const andGroups = group.split('/');
    return andGroups.every((andGroup) => {
      const options = andGroup.split(',');
      return options.some((substring) => carCriterias.includes(substring));
    });
  };
  const mainOrGroups = appl.split('|');
  return mainOrGroups.some(isGroupValid);
};

const DITARenderer = ({ xml }: { xml: string }) => {
  const colWidth = useBreakpointSelectorFull()({ xs: 20, sm: 50, md: 100, lg: 150, xl: 200, xxl: 250 });
  const col2Width = useBreakpointSelectorFull()({ xs: 50, sm: 100, md: 200, lg: 300, xl: 400, xxl: 500 });
  const maintenanceValuesXml = useFetchMaintenanceValuesXml(xml, 'DATAHUB');
  const domParser: DOMParser = new DOMParser();
  const technicalCriteria = useSelector(getLastTechnicalCriteria);
  const illustrationsPath = useFetchIllustrationsPath();
  const signature = useFetchSignature('DATAHUB');
  const urlSign = signature ? `?${signature}` : '';
  const { t } = useTranslation();
  const codes = Array.from(getData(technicalCriteria)?.groups?.values() ?? [])
    .flatMap((k) => k)
    .map((v) => v.value ?? '')
    .filter((v) => v);

  const components = {
    description: (node: ChildNode) => {
      if (node instanceof Element) {
        const appl = node.getAttribute('appl');
        if (appl) {
          const show = isCarApplicable(codes, appl);
          return (
            <>
              {show && (
                <div
                  key={uuidv4()}
                  style={{
                    marginTop: 30,
                    padding: 2,
                    borderStyle: 'solid',
                    borderWidth: 3,
                    borderColor: 'black',
                  }}
                >
                  <Trans
                    i18nKey={'catalog.maintenance.maintenance_value.paragraph_is_applicable_for_this_version'}
                    tOptions={{ appl }}
                  >
                    This paragraph is applicable to these versions: {{ appl }}
                  </Trans>
                </div>
              )}
              {show && Array.from(node.childNodes).map((childNode, i) => renderNode(childNode, i, 0))}
            </>
          );
        } else {
          return (
            <React.Fragment key={uuidv4()}>
              {Array.from(node.childNodes).map((childNode, i) => renderNode(childNode, i, 0))}
            </React.Fragment>
          );
        }
      }
    },
    title: (node: ChildNode) => {
      return typeof node.parentNode === 'object' && node.parentNode?.nodeName === 'table' ? (
        <thead style={{ marginTop: 10 }} key={uuidv4()}>
          <tr>
            <th colSpan={3}>
              <Text key={uuidv4()} type={'h4_paragraph'} transform={'capitalize'}>
                {Array.from(node.childNodes).map((childNode, i) => renderNode(childNode, i, 0))}
              </Text>
            </th>
          </tr>
        </thead>
      ) : (
        <Text key={uuidv4()} type={'h4_paragraph'} transform={'capitalize'}>
          {Array.from(node.childNodes).map((childNode, i) => renderNode(childNode, i, 0))}
        </Text>
      );
    },
    descbody: (node: ChildNode) => (
      <div key={uuidv4()}>{Array.from(node.childNodes).map((childNode, i) => renderNode(childNode, i, 0))}</div>
    ),
    descsection: (node: ChildNode) => (
      <div key={uuidv4()}>{Array.from(node.childNodes).map((childNode, i) => renderNode(childNode, i, 0))}</div>
    ),
    table: (node: ChildNode) => (
      <table style={{ borderCollapse: 'collapse', marginTop: '50px', width: '100%' }} key={uuidv4()}>
        {Array.from(node.childNodes).map((childNode, i) => renderNode(childNode, i, 0))}
      </table>
    ),
    tgroup: (node: ChildNode) => (
      <React.Fragment key={uuidv4()}>
        {Array.from(node.childNodes).map((childNode, i) => renderNode(childNode, i, 0))}
      </React.Fragment>
    ),
    tbody: (node: ChildNode) => (
      <tbody key={uuidv4()}>{Array.from(node.childNodes).map((childNode, i) => renderNode(childNode, i, 0))}</tbody>
    ),
    row: (node: ChildNode) => (
      <tr key={uuidv4()}>{Array.from(node.childNodes).map((childNode, i) => renderNode(childNode, i, 0, 0))}</tr>
    ),
    entry: (node: ChildNode, rowIndex = 0, nextRowSpan = 0, colIndex = 0) => {
      let rowSpan = 1;
      const children = Array.from(node.childNodes).map((childNode) => renderNode(childNode));
      const style: any = {};
      if (node instanceof Element) {
        rowSpan = Number(node.getAttribute('morerows')) + 1 || 1;
        if ((node.getAttribute && node.getAttribute('colname') === '1') || rowSpan > 1 || colIndex > 0) {
          style.backgroundColor = theme.color.grey90;
          style.width = colWidth;
          style.fontWeight = 600;
        }
        const nameend = node.getAttribute('nameend');
        if (nameend !== null) {
          return <th colSpan={3}>{children}</th>;
        }
      }
      if (rowIndex < nextRowSpan) {
        return null;
      }

      return (
        <td key={uuidv4()} style={style} rowSpan={rowSpan}>
          {children}
        </td>
      );
    },
    para: (node: ChildNode) => {
      return <p key={uuidv4()}>{Array.from(node.childNodes).map((childNode, i) => renderNode(childNode, i, 0))}</p>;
    },
    b: (node: ChildNode) => {
      return <p key={uuidv4()}>{Array.from(node.childNodes).map((childNode, i) => renderNode(childNode, i, 0))}</p>;
    },
    ingredientname: (node: ChildNode) => {
      return (
        <React.Fragment key={uuidv4()}>
          {Array.from(node.childNodes).map((childNode, i) => renderNode(childNode, i, 0))}
        </React.Fragment>
      );
    },
    introduction: (node: ChildNode) => {
      return (
        <>
          <Text key={uuidv4()} type={'h4_paragraph'} transform={'capitalize'}>
            {t('maintenance_values.introduction', 'INTRODUCTION')}
          </Text>
          <React.Fragment key={uuidv4()}>
            {Array.from(node.childNodes).map((childNode, i) => renderNode(childNode, i, 0))}
          </React.Fragment>
        </>
      );
    },
    i: (node: ChildNode) => {
      return (
        <React.Fragment key={uuidv4()}>
          {Array.from(node.childNodes).map((childNode, i) => renderNode(childNode, i, 0))}
        </React.Fragment>
      );
    },
    figure: (node: ChildNode) => {
      return <p key={uuidv4()}>{Array.from(node.childNodes).map((childNode, i) => renderNode(childNode, i, 0))}</p>;
    },
    illusnum: (node: ChildNode) => {
      return <p key={uuidv4()}>{Array.from(node.childNodes).map((childNode, i) => renderNode(childNode, i, 0))}</p>;
    },
    technicalvalue: (node: ChildNode) => {
      return (
        <React.Fragment key={uuidv4()}>
          &nbsp;
          {Array.from(node.childNodes).map((childNode, i) => renderNode(childNode, i, 0))}
          &nbsp;
        </React.Fragment>
      );
    },
    value: (node: ChildNode) => {
      return (
        <React.Fragment key={uuidv4()}>
          {Array.from(node.childNodes).map((childNode, i) => renderNode(childNode, i, 0))}
          &nbsp;
        </React.Fragment>
      );
    },
    uom: (node: ChildNode) => {
      return (
        <React.Fragment key={uuidv4()}>
          {Array.from(node.childNodes).map((childNode, i) => renderNode(childNode, i, 0))}
          &nbsp;
        </React.Fragment>
      );
    },
    tolerance: (node: ChildNode) => {
      return (
        <React.Fragment key={uuidv4()}>
          {Array.from(node.childNodes).map((childNode, i) => renderNode(childNode, i, 0))}
          &nbsp;
        </React.Fragment>
      );
    },
    tolerancetype: (node: ChildNode) => {
      return (
        <React.Fragment key={uuidv4()}>
          {Array.from(node.childNodes).map((childNode, i) => renderNode(childNode, i, 0))}
          &nbsp;
        </React.Fragment>
      );
    },
    tolerancevalue: (node: ChildNode) => {
      return (
        <React.Fragment key={uuidv4()}>
          {Array.from(node.childNodes).map((childNode, i) => renderNode(childNode, i, 0))}
          &nbsp;
        </React.Fragment>
      );
    },
    toleranceuom: (node: ChildNode) => {
      return (
        <React.Fragment key={uuidv4()}>
          {Array.from(node.childNodes).map((childNode, i) => renderNode(childNode, i, 0))}
          &nbsp;
        </React.Fragment>
      );
    },
    toolref: (node: ChildNode) => {
      return (
        <React.Fragment key={uuidv4()}>
          {Array.from(node.childNodes).map((childNode, i) => renderNode(childNode, i, 0))}
        </React.Fragment>
      );
    },
    linkvalue: (node: ChildNode) => {
      return (
        <React.Fragment key={uuidv4()}>
          ({Array.from(node.childNodes).map((childNode, i) => renderNode(childNode, i, 0))})
        </React.Fragment>
      );
    },
    image: (node: ChildNode) => {
      if (node instanceof Element) {
        const href = node.getAttribute('href') ?? '-';
        const paths = href.split('/');
        const path = illustrationsPath + paths[paths.length - 1] + urlSign;
        return <ImageWithLoader alt={'a'} imageUrl={path} imageHeight={500} imageWidth={500} />;
      } else {
        return <></>;
      }
    },
    noteR: (node: ChildNode) => {
      let type = '';
      if (node instanceof Element) {
        type = node.getAttribute('type') ?? '';
      }
      return (
        <div
          key={uuidv4()}
          style={{
            marginTop: 30,
            marginBottom: 30,
            padding: 2,
            borderStyle: 'solid',
            borderWidth: 3,
            borderColor: 'black',
          }}
        >
          {type === 'warning' && (
            <Flex direction={'row'}>
              <Icon IconComponent={ExclamationTriangleIcon} color={theme.color.warning} size={40} noPointer />
              <MarginBox my={40} ml={6}>
                {t('maintenance_values.warning', 'WARNING')}
              </MarginBox>
            </Flex>
          )}
          <div key={uuidv4()}>{Array.from(node.childNodes).map((childNode, i) => renderNode(childNode, i, 0))}</div>
        </div>
      );
    },
  };

  const defParseHidden = (node: ChildNode) =>
    Array.from(node.childNodes).map((childNode) => renderHiddenNode(childNode));

  const renderNode = (node: ChildNode, rowIndex = 0, nextRowSpan = 0, colIndex = 0): ReactNode => {
    // Ignore Processing Instructions (PI)
    if (node.nodeType === Node.PROCESSING_INSTRUCTION_NODE) {
      return null; // ignored instruction
    }

    const renderComponent = components[node.nodeName as keyof typeof components];
    if (renderComponent) {
      return renderComponent(node, rowIndex, nextRowSpan, colIndex);
    }
    return node.nodeValue ? node.nodeValue.trim() : ''; // for  text nodes
  };

  const toolsItems: Map<string, toolType> = new Map();
  const renderHiddenNode = (node: ChildNode) => {
    if (node.nodeName === 'toolref') {
      const linkvalue = Array.from(node.childNodes)
        .filter((childNode) => childNode.nodeName === 'linkvalue')
        .map((childNode) => {
          return childNode.childNodes.item(0).nodeValue ?? '';
        });
      const linkdesc = Array.from(node.childNodes)
        .filter((childNode) => childNode.nodeName === 'linkdesc')
        .map((childNode) => {
          return childNode.childNodes.item(0).nodeValue ?? '';
        });

      if (node instanceof Element) {
        const href = node.getAttribute('href') ?? '-';
        const newItem = { name: linkvalue[0] ?? '?', description: linkdesc[0] ?? '?', application: '', href };
        if (!toolsItems.get(newItem.name)) {
          toolsItems.set(newItem.name, newItem);
        }
      }
    }
    defParseHidden(node);
  };

  // Extract the children of the root element and convert them to an array
  const doc = domParser.parseFromString(maintenanceValuesXml.data ?? '', 'application/xml');
  const nodes = Array.from(doc.documentElement.children);

  const mandatoryTools = (tools: Map<string, toolType>) => {
    if (!tools || tools.size === 0) {
      return <></>;
    }
    return (
      <>
        <Text type={'h4_paragraph'} transform={'capitalize'}>
          <Trans i18nKey={'catalog.maintenance.maintenance_value.mandatory_specific_tools'}>
            Mandatory specific tool
          </Trans>
        </Text>
        <table style={{ borderCollapse: 'collapse' }}>
          <tbody>
            {Array.from(tools.values()).map((tool) => (
              <tr key={tool.name}>
                <td
                  style={{
                    backgroundColor: theme.color.grey90,
                    width: col2Width,
                    fontWeight: 600,
                  }}
                >
                  {tool.description}
                </td>
                <td>{tool.name}</td>
                <td>{tool.application}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  };

  nodes.forEach((node) => renderHiddenNode(node));

  return (
    <>
      <DataContainer data={maintenanceValuesXml.searchStatus}>
        <SMaintenanceValues direction={'column'}>
          {mandatoryTools(toolsItems)}
          {nodes.map((node, index) => (
            <React.Fragment key={index}>{renderNode(node)}</React.Fragment>
          ))}
        </SMaintenanceValues>
      </DataContainer>
    </>
  );
};

export default DITARenderer;
