/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReferenceTradingDataRequestDetail } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/request/GetReferenceTradingDataRequest';
import { ReferenceField } from '@1po/1po-bff-fe-spec/generated/estimate/request/UpdateReference';
import { Reference } from '@1po/1po-bff-fe-spec/generated/estimate/response/model/Reference';
import { Table } from 'antd';
import { RootState } from 'app/AppStore';
import { ChevronDownIcon, ChevronUpIcon, UnlinkIcon } from 'assets/icons';
import { useIsB2B } from 'components/B2BComponents/useIsB2B';
import { DataContainer } from 'components/DataContainer';
import { Dialog, useDisclosure } from 'components/Dialog';
import { calculatePriceAfterDiscount, DiscountCorner, getDiscountTextStyle } from 'components/Discount';
import { PriceErrorDisplay } from 'components/ReferencePriceSection';
import { StockDisplayBasic } from 'components/StockInfo/StockDisplay';
import {
  getCatalogReferences,
  getEstimateB2BReferenceDiscount,
  getEstimateB2BReferencePrice,
  getEstimateB2BReferenceStock,
  getPendingReferenceValidations,
  getReferences,
  removeKnownReferenceValidation,
  removeReference,
  updateReference,
} from 'domains/estimate/Estimate.store';

import { DHReferenceLocal } from 'domains/references';
import { useFetchDiscounts } from 'domains/references/References.requests';
import { ClientView, getCurrency } from 'domains/user';
import { EstimateGarageMargin } from 'pages/EstimatePage/TableSection/EstimateGarageMargin';
import { EstimateGaragePrice } from 'pages/EstimatePage/TableSection/EstimateGaragePrice';
import { EstimateMarginAlert } from 'pages/EstimatePage/TableSection/EstimateMarginAlert';
import { LinkedReferenceTable } from 'pages/EstimatePage/TableSection/LinkedReferenceTable';
import {
  DiscountInput,
  EstimatePriceInput,
  GetTitle,
  QuantityInput,
  TextInput,
  VatInput,
} from 'pages/EstimatePage/TableSection/tableComponents';
import { TableProps } from 'pages/EstimatePage/TableSection/TableSection';
import { CenterFlex, Flex, Icon, Spin, Text, WithTooltip } from 'UI';
import { getCondArrayItem, getSearchData, NO_DATA } from 'utils';
import { textFormatter } from 'utils/format';
import { STable, TooltipContent } from './TableSection.styled';
/* eslint-enable max-len */

export const ProductPriceB2b = ({
  referenceNumber,
  currency,
  estimateId,
}: {
  referenceNumber: string;
  currency?: string;
  estimateId: string;
}) => {
  const price = useSelector((state: RootState) => getEstimateB2BReferencePrice(state, { estimateId, referenceNumber }));
  const priceData = getSearchData(price);
  return (
    <DataContainer
      data={price?.searchStatus}
      Loading={() => <Spin size={'small'} />}
      ErrorState={() => <PriceErrorDisplay narrow />}
    >
      <Text type={'text_dim'}>
        {textFormatter.formatCurrency(priceData?.clientView?.recommendedPriceVatExcluded ?? 0, currency)}
      </Text>
    </DataContainer>
  );
};

export const B2bStockDisplay = ({
  referenceNumber,
  estimateId,
  quantity,
}: {
  referenceNumber: string;
  estimateId: string;
  quantity: number;
}) => {
  const stock = useSelector((state: RootState) => getEstimateB2BReferenceStock(state, { estimateId, referenceNumber }));
  return (
    <Flex>
      <StockDisplayBasic stock={stock} quantity={quantity} narrow />
    </Flex>
  );
};

export const DiscountedPrice = ({
  referenceNumber,
  quantity,
  estimateId,
  currency,
}: {
  referenceNumber: string;
  quantity?: number;
  estimateId: string;
  currency?: string;
}) => {
  const price = useSelector((state: RootState) => getEstimateB2BReferencePrice(state, { estimateId, referenceNumber }));
  const discount = useSelector((state: RootState) =>
    getEstimateB2BReferenceDiscount(state, { estimateId, referenceNumber }),
  );
  return (
    <CenterFlex>
      <DataContainer data={price?.searchStatus} ErrorState={() => <PriceErrorDisplay narrow />}>
        <Text displayStyle={getDiscountTextStyle(discount)} type={'text_dim'}>
          {textFormatter.formatCurrency(
            calculatePriceAfterDiscount(discount, getSearchData(price), false) * (quantity ?? 1),
            currency,
          )}
        </Text>
      </DataContainer>
    </CenterFlex>
  );
};

export default function ReferenceTable({ estimateId, sparePartsView }: Readonly<TableProps>) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currency = useSelector(getCurrency);
  const data = useSelector((state: RootState) => getReferences(state, estimateId));
  const catalogReferences = useSelector((state: RootState) => getCatalogReferences(state, estimateId));
  const validationRefs = useSelector(getPendingReferenceValidations);
  const disclosure = useDisclosure();
  const isB2B = useIsB2B();
  const isClientView = sparePartsView === ClientView;
  const { onOpen } = disclosure;
  const [validationRow, setValidationRow] = useState<{ itemId: string; referenceNumber: string } | undefined>(
    undefined,
  );
  const [checkedReferences, setCheckedReferences] = useState(new Map<string, DHReferenceLocal | NO_DATA>());

  const unitPriceLabel = GetTitle(t('common.price.unit_price', 'Unit Price'));

  const removeCheckedReferences = (k: string) => {
    checkedReferences.delete(k);
    setCheckedReferences(new Map(checkedReferences));
  };

  const tradingData: ReferenceTradingDataRequestDetail[] = data.map((ref) => {
    return {
      referenceNumber: ref.referenceNumber,
      commercialFamily: '',
    };
  });

  useFetchDiscounts(tradingData);

  useEffect(() => {
    validationRefs
      .flatMap((refForValidation) =>
        catalogReferences.filter((refData) => refData.referenceNumber === refForValidation),
      )
      .forEach((x) => {
        if (isB2B) {
          return;
        }
        if (!x.isCompatible) {
          setValidationRow({ itemId: x.itemId, referenceNumber: x.referenceNumber });
          onOpen();
        }
        dispatch(removeKnownReferenceValidation(x.referenceNumber));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationRefs, dispatch, catalogReferences, onOpen]);

  const handleUpdate = (itemId: string, field: ReferenceField) => (newValue: string) => {
    dispatch(updateReference({ itemId, field, newValue }));
  };

  function continueAdding() {
    setValidationRow(undefined);
  }

  function removeRow(rowId: string) {
    const removedRef = catalogReferences.filter((e) => e.itemId === rowId);
    if (removedRef && removedRef.length === 1 && removedRef[0].referenceNumber) {
      removeCheckedReferences(removedRef[0].referenceNumber);
    }
    dispatch(removeReference({ itemId: rowId }));
  }

  function revertRow() {
    if (validationRow) {
      const refItemToRevert = catalogReferences.filter(
        (refData) => refData.referenceNumber === validationRow.referenceNumber,
      );
      if (refItemToRevert.length === 1) {
        const referenceToRevert = refItemToRevert[0];
        const originalQuantity = referenceToRevert.quantity >= 1 ? referenceToRevert.quantity - 1 : 0;
        if (originalQuantity === 0) {
          dispatch(removeReference({ itemId: validationRow.itemId }));
        } else {
          dispatch(
            updateReference({ itemId: validationRow.itemId, field: 'QUANTITY', newValue: String(originalQuantity) }),
          );
        }
      }
      dispatch(removeKnownReferenceValidation(validationRow.referenceNumber));
      setValidationRow(undefined);
    }
  }

  const tableColumnDiscountCorner = {
    title: '',
    dataIndex: 'referenceNumber',
    render: function rowSelector(referenceNumber: string) {
      return <DiscountCorner reference={referenceNumber} type={'cart'} />;
    },
  };

  const tableColumnReferenceNumber = {
    title: GetTitle(t('catalog.reference', 'Reference')),
    dataIndex: 'referenceNumber',
    width: '8vw',
    render: function rowSelector(referenceNumber: string, row: Reference) {
      const isMarginNegativeOrZero = Number(row.margin) <= 0;
      return (
        <Flex justify={'center'} align={'center'}>
          <TextInput
            value={referenceNumber}
            saveFunction={handleUpdate(row.itemId, 'REFERENCE_NUMBER')}
            removeRow={() => removeRow(row.itemId)}
            readOnly={!row.isCustom}
            minWidth={140}
            isInvalid={isClientView && isMarginNegativeOrZero}
          />
          {!row.isCompatible && (
            <WithTooltip
              title={t('estimate.reference.noncompatible', 'Not compatible with selected vehicle')}
              placement={'top'}
            >
              <TooltipContent>
                <Flex size={0} align={'center'} justify={'center'}>
                  <Icon IconComponent={UnlinkIcon} size={16} color={'black'} display={'inline'} />
                </Flex>
              </TooltipContent>
            </WithTooltip>
          )}
        </Flex>
      );
    },
  };

  const tableColumnDesignation = {
    title: GetTitle(t('common.designation', 'Designation')),
    dataIndex: 'designation',
    render: function rowSelector(designation: string, row: Reference) {
      const isMarginNegativeOrZero = Number(row.margin) <= 0;
      return (
        <TextInput
          value={designation}
          saveFunction={handleUpdate(row.itemId, 'NAME')}
          readOnly={!row.isCustom}
          isInvalid={isClientView && isMarginNegativeOrZero}
        />
      );
    },
  };

  const tableColumnUnitPriceB2C = {
    title: unitPriceLabel,
    dataIndex: 'unitPrice',
    width: '10vw',
    render: function rowSelector(unitPrice: string, row: Reference) {
      const isMarginNegativeOrZero = Number(row.margin) <= 0;
      return (
        <EstimatePriceInput
          value={unitPrice}
          saveFunction={handleUpdate(row.itemId, 'UNIT_PRICE')}
          readOnly={!row.isCustom}
          minWidth={125}
          isInvalid={isClientView && isMarginNegativeOrZero}
        />
      );
    },
  };

  const tableColumnUnitPriceB2B = {
    title: unitPriceLabel,
    dataIndex: 'referenceNumber',
    width: '10vw',
    render: function rowSelector(referenceNumber: string) {
      return <DiscountedPrice referenceNumber={referenceNumber} estimateId={estimateId} currency={currency} />;
    },
  };

  const tableColumnQuantity = {
    title: GetTitle(t('common.quantity', 'Quantity')),
    dataIndex: 'quantity',
    width: '10vw',
    render: function rowSelector(quantity: number, row: Reference) {
      const isMarginNegativeOrZero = Number(row.margin) <= 0;
      return (
        <QuantityInput
          value={quantity}
          saveFunction={handleUpdate(row.itemId, 'QUANTITY')}
          removeRow={() => removeRow(row.itemId)}
          isInvalid={isClientView && isMarginNegativeOrZero}
        />
      );
    },
  };

  const tableColumnDiscount = {
    title: GetTitle(t('common.discount', 'Discount')),
    dataIndex: 'discount',
    width: '10vw',
    render: function rowSelector(discount: string, row: Reference) {
      const isMarginNegativeOrZero = Number(row.margin) <= 0;
      return (
        <DiscountInput
          value={discount}
          saveFunction={handleUpdate(row.itemId, 'DISCOUNT')}
          discountType={row.discountType}
          saveDiscountType={handleUpdate(row.itemId, 'DISCOUNT_TYPE')}
          isInvalid={isClientView && isMarginNegativeOrZero}
        />
      );
    },
  };

  const tableColumnVatExclPrice = {
    title: GetTitle(t('common.price.vat_excl_price', 'VAT excl.Price')),
    dataIndex: 'priceVatExcluded',
    width: '8vw',
    render: function rowSelector(priceVatExcluded: string, row: Reference) {
      const isMarginNegativeOrZero = Number(row.margin) <= 0;
      return (
        <Flex justify={'center'} align={'center'}>
          <Text type={isClientView && isMarginNegativeOrZero ? 'light_14_bold_red' : 'light_14'}>
            {textFormatter.formatCurrency(isNaN(Number(priceVatExcluded)) ? 0 : priceVatExcluded, currency)}
          </Text>
        </Flex>
      );
    },
  };

  const tableColumnVat = {
    title: GetTitle(t('common.price.vat.title', 'VAT')),
    dataIndex: 'vatPercentage',
    width: '8vw',
    render: function rowSelector(vat: number, row: Reference) {
      const isMarginNegativeOrZero = Number(row.margin) <= 0;
      return (
        <VatInput
          value={vat}
          saveFunction={handleUpdate(row.itemId, 'VAT_PERCENTAGE')}
          readOnly={!row.isCustom}
          isInvalid={isClientView && isMarginNegativeOrZero}
        />
      );
    },
  };

  const tableColumnMarginAlert = {
    title: null,
    dataIndex: '',
    width: 20,
    render: function rowSelector(row: Reference) {
      const isMarginNegativeOrZero = Number(row.margin) <= 0;
      return isMarginNegativeOrZero && <EstimateMarginAlert designation={row.designation} t={t} />;
    },
  };

  const tableColumnGaragePrice = {
    title: GetTitle(t('common.price.garage_price', 'Garage Price')),
    dataIndex: 'garagePrice',
    width: '6vw',
    render: function rowSelector(_garagePrice: string, row: Reference) {
      const isMarginNegativeOrZero = Number(row.margin) <= 0;
      return (
        <EstimateGaragePrice
          row={row}
          currency={currency}
          handleUpdate={handleUpdate}
          isInvalid={isMarginNegativeOrZero}
        />
      );
    },
  };

  const tableColumnMargin = {
    title: GetTitle(t('common.price.margin', 'Margin')),
    dataIndex: 'margin',
    width: '6vw',
    render: function rowSelector(_margin: string, row: Reference) {
      const isMarginNegativeOrZero = Number(row.margin) <= 0;
      return (
        <Flex justify={'space-between'} align={'center'} minWidth={70}>
          {isMarginNegativeOrZero ? (
            <EstimateMarginAlert designation={row.designation} t={t} />
          ) : (
            <Flex minWidth={20} />
          )}
          <EstimateGarageMargin
            estimateId={estimateId}
            row={row}
            currency={currency}
            isInvalid={isMarginNegativeOrZero}
          />
        </Flex>
      );
    },
  };

  const tableColumnStocks = {
    title: GetTitle(t('cart.reference_table.stock', 'Stock')),
    dataIndex: 'stock',
    render: function rowSelector(_stock: number, row: Reference) {
      return <B2bStockDisplay referenceNumber={row.referenceNumber} estimateId={estimateId} quantity={row.quantity} />;
    },
  };

  const tableColumnPcl = {
    title: GetTitle(t('common.price.pcl', 'PCL')),
    dataIndex: 'pcl',
    render: function rowSelector(_pcl: string, row: Reference) {
      return <ProductPriceB2b referenceNumber={row.referenceNumber} currency={currency} estimateId={estimateId} />;
    },
  };

  const tableColumnTotalPriceB2B = {
    title: GetTitle(t('common.price.total_price', 'Total price')),
    dataIndex: 'totalPrice',
    render: function rowSelector(_totalPrice: string, row: Reference) {
      return (
        <DiscountedPrice
          referenceNumber={row.referenceNumber}
          quantity={row.quantity}
          estimateId={estimateId}
          currency={currency}
        />
      );
    },
  };

  const getColumns = () => {
    if (isB2B) {
      return [
        tableColumnDiscountCorner,
        tableColumnReferenceNumber,
        tableColumnDesignation,
        tableColumnStocks,
        tableColumnPcl,
        tableColumnUnitPriceB2B,
        tableColumnQuantity,
        tableColumnTotalPriceB2B,
        tableColumnMargin,
      ];
    }
    return [
      tableColumnDiscountCorner,
      tableColumnReferenceNumber,
      tableColumnDesignation,
      tableColumnUnitPriceB2C,
      tableColumnQuantity,
      tableColumnDiscount,
      tableColumnVatExclPrice,
      tableColumnVat,
      ...getCondArrayItem(isClientView && tableColumnMarginAlert),
      ...getCondArrayItem(!isClientView && tableColumnGaragePrice),
      ...getCondArrayItem(!isClientView && tableColumnMargin),
    ];
  };

  if (data.length === 0) {
    return null;
  }
  return (
    <>
      <STable<Reference>
        columns={[...getColumns(), Table.EXPAND_COLUMN]}
        dataSource={data}
        pagination={false}
        rowKey={(row) => row.itemId}
        size={'large'}
        expandable={{
          expandedRowRender: (record) => LinkedReferenceTable(estimateId, record, currency, sparePartsView) ?? null,
          expandIcon: ExpandIcon,
          rowExpandable: (record) => (record.linkedReferences && record.linkedReferences.length > 0) ?? false,
          defaultExpandAllRows: true,
        }}
      />

      <Dialog
        disclosure={disclosure}
        title={t('common.dialog.actionRequired', 'Action required')}
        description={[
          // tableElements.deleteAllDescription,
          t(
            'estimate.noncompatible.confirmationQuestion',
            // eslint-disable-next-line max-len
            'The reference that you are trying to add to your estimate is not compatible with the identified vehicle. Do you want to add it anyway?',
          ),
        ]}
        icon={UnlinkIcon}
        status={'info'}
        handleConfirm={continueAdding}
        handleCancel={revertRow}
      />
    </>
  );
}

function ExpandIcon({ expanded, record, onExpand }: { expanded: boolean; record: Reference; onExpand: any }) {
  if (!record.linkedReferences || record.linkedReferences.length === 0) {
    return null;
  }

  return (
    <Icon
      IconComponent={expanded ? ChevronDownIcon : ChevronUpIcon}
      onClick={(e) => onExpand(record, e)}
      height={15}
      width={15}
    />
  );
}
