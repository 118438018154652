import color from 'styles/color.json';

export type Font = {
  family?: string;
  size: number;
  weight?: number | string;
  lineHeight?: number;
  letterSpacing?: number;
  heading?: number;
  color?: string;
};

export const font: Record<string, Font> = {
  h0: {
    family: 'NouvelR',
    size: 64,
    weight: 700,
    lineHeight: 78,
    letterSpacing: -1.6,
    color: color.grey10,
    heading: 1,
  },
  h1: {
    family: 'NouvelR',
    size: 48,
    weight: 700,
    lineHeight: 60,
    letterSpacing: -1.2,
    color: color.grey10,
    heading: 2,
  },
  h1_white: {
    family: 'NouvelR',
    size: 48,
    weight: 700,
    lineHeight: 60,
    letterSpacing: -1.2,
    color: color.white,
    heading: 2,
  },
  h1_brand: {
    family: 'NouvelR',
    size: 48,
    weight: 700,
    lineHeight: 60,
    letterSpacing: -1.2,
    color: color.brand,
    heading: 2,
  },
  heading_landing: {
    family: 'NouvelR',
    size: 48,
    weight: 600,
    lineHeight: 60,
    letterSpacing: -1.2,
    color: color.white,
    heading: 2,
  },
  text_landing: {
    family: 'NouvelR',
    size: 16,
    weight: 400,
    lineHeight: 24,
    letterSpacing: -0.12,
    color: color.white,
    heading: 2,
  },
  heading_landing_md: {
    family: 'NouvelR',
    size: 24,
    weight: 600,
    lineHeight: 30,
    letterSpacing: -1.2,
    color: color.white,
    heading: 2,
  },
  text_landing_md: {
    family: 'NouvelR',
    size: 14,
    weight: 400,
    lineHeight: 22,
    letterSpacing: -0.12,
    color: color.white,
    heading: 2,
  },
  h1_banner_light: {
    family: 'NouvelR',
    size: 36,
    weight: 700,
    lineHeight: 46,
    letterSpacing: -0.800000011920929,
    color: color.grey10,
    heading: 3,
  },
  h1_banner_black_45: {
    family: 'NouvelR',
    size: 36,
    weight: 700,
    lineHeight: 46,
    letterSpacing: -0.800000011920929,
    color: color.grey55,
    heading: 3,
  },
  h1_banner_dark: {
    family: 'NouvelR',
    size: 36,
    weight: 'bold',
    lineHeight: 46,
    letterSpacing: -0.800000011920929,
    color: color.white,
    heading: 3,
  },
  h1_black: {
    family: 'NouvelR',
    size: 36,
    weight: 'bold',
    lineHeight: 46,
    color: color.brand_black,
    heading: 3,
  },
  h1_lead: {
    family: 'NouvelR',
    size: 30,
    weight: 400,
    lineHeight: 40,
    color: color.brand_black,
    heading: 3,
  },
  promotion_card_title: {
    family: 'NouvelR',
    size: 30,
    weight: 400,
    lineHeight: 40,
    color: color.grey15_dark_70,
    heading: 3,
  },
  h1_lead_white: {
    family: 'NouvelR',
    size: 30,
    weight: 400,
    lineHeight: 40,
    color: color.white,
    heading: 3,
  },
  h1_lead_white_singular: {
    family: 'NouvelR',
    size: 30,
    weight: 700,
    lineHeight: 60,
    letterSpacing: -1.2,
    color: color.white,
    heading: 2,
  },
  h2: {
    family: 'NouvelR',
    size: 24,
    weight: 600,
    letterSpacing: -0.4,
    color: color.grey10,
    heading: 2,
  },
  h2_bold: {
    family: 'NouvelR',
    size: 24,
    weight: 700,
    letterSpacing: -0.4,
    color: color.grey10,
    heading: 2,
  },
  h2_white: {
    family: 'NouvelR',
    size: 24,
    lineHeight: 32,
    letterSpacing: -0.4,
    color: color.white,
    heading: 4,
  },
  h2_black_45: {
    family: 'NouvelR',
    size: 24,
    weight: 600,
    letterSpacing: -0.4,
    color: color.grey55,
    heading: 2,
  },
  header_light_singular: {
    family: 'NouvelR',
    size: 22,
    weight: 100,
    color: color.white,
  },
  price_tag: {
    family: 'NouvelR',
    size: 20,
    weight: 400,
    lineHeight: 26,
    letterSpacing: -0.2,
    color: color.grey05,
    heading: 5,
  },
  text_form: {
    family: 'NouvelR',
    size: 20,
    weight: 400,
    lineHeight: 28,
    color: color.grey35,
  },
  h3: {
    family: 'NouvelR',
    size: 20,
    weight: 600,
    letterSpacing: -0.2,
    lineHeight: 26,
    color: color.grey15,
  },
  h3_dark: {
    family: 'NouvelR',
    size: 20,
    weight: 600,
    letterSpacing: -0.2,
    lineHeight: 26,
    color: color.grey100,
  },
  h3_brand: {
    family: 'NouvelR',
    size: 30,
    weight: 600,
    letterSpacing: -0.67,
    lineHeight: 35,
    color: color.brand,
  },
  h3_dim: {
    family: 'NouvelR',
    size: 20,
    weight: 400,
    lineHeight: 28,
    color: color.grey15,
  },
  h4_white: {
    family: 'NouvelR',
    size: 24,
    weight: 600,
    letterSpacing: -0.4,
    lineHeight: 32,
    color: color.white,
  },
  h4_brand: {
    family: 'NouvelR',
    size: 20,
    weight: 600,
    letterSpacing: -0.45,
    lineHeight: 30,
    color: color.brand,
  },
  h4_paragraph: {
    family: 'NouvelR',
    size: 18,
    weight: 600,
    lineHeight: 22,
    color: color.grey15,
  },
  h4_paragraph_title: {
    family: 'NouvelR',
    size: 36,
    weight: 600,
    lineHeight: 43,
    color: color.grey15,
  },
  h3_white: {
    family: 'NouvelR',
    size: 20,
    weight: 600,
    letterSpacing: -0.2,
    lineHeight: 26,
    color: color.white,
  },
  h3_bold_clear_blue: {
    family: 'NouvelR',
    size: 20,
    weight: 700,
    letterSpacing: -0.2,
    lineHeight: 26,
    color: color.clear_blue,
  },
  text_dim: {
    family: 'NouvelR',
    size: 14,
    weight: 400,
    lineHeight: 22,
    color: color.grey10,
  },
  text_dim_bold: {
    family: 'NouvelR',
    size: 14,
    weight: 700,
    lineHeight: 22,
    color: color.grey10,
  },
  text_white_bold: {
    family: 'NouvelR',
    size: 14,
    weight: 700,
    lineHeight: 22,
    color: color.white,
  },
  text: {
    family: 'NouvelR',
    size: 14,
    weight: 500,
    lineHeight: 22,
    color: color.brand_black,
  },
  text_white: {
    family: 'NouvelR',
    size: 14,
    weight: 500,
    lineHeight: 22,
    color: color.white,
  },
  light_dimmer: {
    family: 'NouvelR',
    size: 12,
    weight: 500,
    lineHeight: 20,
    color: color.grey35,
  },
  dark_14_white_bold: {
    family: 'NouvelR',
    size: 14,
    weight: 600,
    lineHeight: 20,
    color: color.white,
  },
  text_disabled: {
    family: 'NouvelR',
    size: 14,
    weight: 500,
    lineHeight: 22,
    color: color.grey35,
  },
  text_bold: {
    family: 'NouvelR',
    size: 14,
    weight: 'bold',
    lineHeight: 22,
    color: color.grey15,
  },
  highlight: {
    family: 'NouvelR',
    size: 15,
    weight: 600,
    lineHeight: 22,
    letterSpacing: -0.1,
    color: color.grey10,
    heading: 6,
  },
  section: {
    family: 'NouvelR',
    size: 14,
    weight: 400,
    lineHeight: 22,
    letterSpacing: -0.09333333333333334,
    color: color.grey10,
    heading: 6,
  },
  section_bold: {
    family: 'NouvelR',
    size: 14,
    weight: 'bold',
    lineHeight: 22,
    letterSpacing: -0.09333333333333334,
    color: color.grey10,
    heading: 6,
  },
  advertisement_text: {
    family: 'NouvelR',
    size: 15,
    weight: 400,
    lineHeight: 15,
    color: 'white',
  },
  h5_light: {
    family: 'NouvelR',
    size: 18,
    weight: 400,
    lineHeight: 26,
    color: color.grey10,
  },
  h5_light_white: {
    family: 'NouvelR',
    size: 18,
    lineHeight: 26,
    weight: 400,
    letterSpacing: -0.18,
    color: color.white,
    heading: 5,
  },
  h5_bold: {
    family: 'NouvelR',
    size: 18,
    weight: 700,
    lineHeight: 26,
    letterSpacing: -0.18,
    color: color.grey10,
  },
  h5_bold_clear_blue: {
    family: 'NouvelR',
    size: 18,
    weight: 700,
    lineHeight: 26,
    letterSpacing: -0.18,
    color: color.clear_blue,
  },
  card_title_light: {
    family: 'NouvelR',
    size: 18,
    weight: 600,
    lineHeight: 26,
    letterSpacing: -0.1,
    color: color.grey10,
    heading: 5,
  },
  card_title_dark: {
    family: 'NouvelR',
    size: 18,
    weight: 600,
    lineHeight: 26,
    letterSpacing: -0.1,
    color: color.white85,
    heading: 5,
  },
  heading_black_content: {
    family: 'NouvelR',
    size: 14,
    weight: 400,
    lineHeight: 26,
    letterSpacing: -0.1,
    color: color.grey10,
    heading: 6,
  },
  popover_title: {
    family: 'NouvelR',
    size: 18,
    weight: 'bold',
    lineHeight: 22,
    letterSpacing: -0.12,
    color: color.grey10,
  },
  circle_number: {
    family: 'NouvelR',
    size: 8,
    weight: 'bold',
    lineHeight: 10,
    color: color.grey10,
  },
  search_result_base: {
    family: 'NouvelR',
    size: 14,
    weight: 'light',
    color: color.grey35,
  },
  search_result_highlighted: {
    family: 'NouvelRBold',
    size: 14,
    weight: 'bold',
    color: color.brand_black,
  },
  dark_12_narrow_line_spacing: {
    family: 'NouvelR',
    size: 12,
    lineHeight: 20,
  },
  light_14_bold_black_45: {
    family: 'NouvelR',
    size: 14,
    weight: 'bold',
    lineHeight: 22,
    color: color.grey55,
  },
  light_14_bold_black_65: {
    family: 'NouvelR',
    size: 14,
    weight: 'bold',
    lineHeight: 22,
    color: color.grey35,
  },
  light_14_bold_black_85: {
    family: 'NouvelR',
    size: 14,
    weight: 'bold',
    lineHeight: 22,
    color: color.grey15,
  },
  light_14_bold_red: {
    family: 'NouvelR',
    size: 14,
    weight: 600,
    lineHeight: 22,
    color: color.red,
  },
  text_semibold_black_85: {
    family: 'NouvelR',
    size: 14,
    weight: 600,
    lineHeight: 22,
    color: color.grey15,
  },
  h6: {
    family: 'NouvelR',
    size: 16,
    weight: 600,
    lineHeight: 26,
    letterSpacing: -0.1,
    color: color.grey10,
  },
  h6_light: {
    family: 'NouvelR',
    size: 16,
    weight: 400,
    lineHeight: 26,
    letterSpacing: -0.1,
    color: color.grey10,
  },
  h6_black_45: {
    family: 'NouvelR',
    size: 16,
    weight: 600,
    lineHeight: 26,
    letterSpacing: -0.1,
    color: color.grey55,
  },
  h6_white: {
    family: 'NouvelR',
    size: 16,
    weight: 600,
    lineHeight: 24,
    color: color.white,
  },
  h6_dark_dim: {
    family: 'NouvelR',
    size: 16,
    weight: 600,
    lineHeight: 24,
    color: color.grey85,
  },
  h6_dark_blue: {
    family: 'NouvelR',
    size: 16,
    weight: 600,
    lineHeight: 24,
    color: color.blue_dark_3,
  },
  h6_black: {
    family: 'NouvelR',
    size: 16,
    weight: 600,
    lineHeight: 24,
    color: color.brand_black,
  },
  h6_brand: {
    family: 'NouvelR',
    size: 16,
    weight: 600,
    lineHeight: 24,
    color: color.brand,
  },
  link: {
    family: 'NouvelR',
    size: 14,
    weight: 400,
    lineHeight: 22,
    color: color.clear_blue,
  },
  link_bold: {
    family: 'NouvelR',
    size: 14,
    weight: 600,
    lineHeight: 22,
    color: color.clear_blue,
  },
  link_12: {
    family: 'NouvelR',
    size: 12,
    weight: 400,
    lineHeight: 18,
    color: color.clear_blue,
  },
  description: {
    family: 'NouvelR',
    size: 12,
    weight: 400,
    lineHeight: 22,
    color: color.grey55,
  },
  dark_12_white_100: {
    family: 'NouvelR',
    size: 12,
    weight: 600,
    color: color.white,
  },
  dark_12: {
    family: 'NouvelR',
    size: 12,
    lineHeight: 18,
    weight: 600,
    color: color.brand_black,
  },
  mobile_h3: {
    family: 'NouvelR',
    size: 12,
    weight: 600,
    color: color.grey00,
  },
  dark_12_medium_white_85: {
    family: 'NouvelR',
    size: 12,
    weight: 500,
    lineHeight: 20,
    color: color.white85,
  },
  dark_12_white_65: {
    family: 'NouvelR',
    size: 14,
    weight: 400,
    lineHeight: 22,
    color: color.grey55,
  },
  dark_14_white_100: {
    family: 'NouvelR',
    size: 14,
    weight: 400,
    lineHeight: 22,
    color: color.white,
  },
  dark_14_white_65: {
    family: 'NouvelR',
    size: 14,
    weight: 400,
    lineHeight: 22,
    color: color.white65,
  },
  dark_14_medium_white_85: {
    family: 'NouvelR',
    size: 14,
    weight: 500,
    lineHeight: 22,
    color: color.white85,
  },
  lead_text_white: {
    family: 'NouvelR',
    size: 18,
    weight: 500,
    letterSpacing: -0.12,
    color: color.white,
  },
  light_14_black_25: {
    family: 'NouvelR',
    size: 14,
    weight: 400,
    lineHeight: 22,
    color: color.grey75,
  },
  light_14_black_45: {
    family: 'NouvelR',
    size: 14,
    weight: 400,
    lineHeight: 22,
    color: color.grey55,
  },
  light_12_medium_black_85: {
    family: 'NouvelR',
    size: 12,
    weight: 500,
    lineHeight: 20,
    color: color.grey15,
  },
  light_12_black_45: {
    family: 'NouvelR',
    size: 12,
    weight: 400,
    lineHeight: 20,
    color: color.grey55,
  },
  lead_dim: {
    family: 'NouvelR',
    size: 16,
    weight: 400,
    lineHeight: 24,
    color: color.grey35,
  },
  light_14_black_55: {
    family: 'NouvelR',
    size: 14,
    weight: 400,
    lineHeight: 22,
    color: color.grey45,
  },
  light_14_black_65: {
    family: 'NouvelR',
    size: 14,
    weight: 400,
    lineHeight: 22,
    color: color.grey35,
  },
  light_14_black_75: {
    family: 'NouvelR',
    size: 14,
    weight: 400,
    lineHeight: 22,
    letterSpacing: -0.09,
    color: color.grey25,
  },
  light_14_black_85: {
    family: 'NouvelR',
    size: 14,
    weight: 400,
    lineHeight: 22,
    color: color.grey15,
  },
  light_14_red: {
    family: 'NouvelR',
    size: 14,
    weight: 400,
    lineHeight: 22,
    color: color.red,
  },
  light_12: {
    family: 'NouvelR',
    size: 12,
    weight: 400,
    lineHeight: 20,
    color: color.brand_black,
  },
  light_12_dark: {
    family: 'NouvelR',
    size: 12,
    weight: 400,
    lineHeight: 20,
    color: color.white,
  },
  light_10_black_65: {
    family: 'NouvelR',
    size: 10,
    weight: 400,
    lineHeight: 20,
    color: color.grey35,
  },
  light_10: {
    family: 'NouvelR',
    size: 10,
    weight: 400,
    lineHeight: 20,
    color: color.brand_black,
  },
  dark_10_white: {
    family: 'NouvelR',
    size: 10,
    weight: 400,
    lineHeight: 20,
    color: color.white,
  },
  mobile_dim: {
    family: 'NouvelR',
    size: 10,
    weight: 500,
    lineHeight: 16,
    color: color.grey45,
  },
  light_12_black_85: {
    family: 'NouvelR',
    size: 12,
    weight: 400,
    lineHeight: 20,
    color: color.grey15,
  },
  light_12_black_65: {
    family: 'NouvelR',
    size: 12,
    weight: 400,
    lineHeight: 20,
    color: color.grey35,
  },
  light_12_red: {
    family: 'NouvelR',
    size: 12,
    weight: 400,
    lineHeight: 20,
    color: color.red,
  },
  light_12_black_90: {
    family: 'NouvelR',
    size: 12,
    weight: 400,
    lineHeight: 20,
    color: color.grey10,
  },
  lead: {
    family: 'NouvelR',
    size: 16,
    weight: 400,
    lineHeight: 24,
    color: color.grey15,
  },
  lead_white: {
    family: 'NouvelR',
    size: 16,
    weight: 400,
    lineHeight: 24,
    color: color.grey90,
  },
  link_12_red: {
    family: 'NouvelR',
    size: 12,
    weight: 400,
    lineHeight: 20,
    color: color.red,
  },
  link_14_red: {
    family: 'NouvelR',
    size: 14,
    weight: 400,
    lineHeight: 22,
    color: color.red,
  },
};
