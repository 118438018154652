import React from 'react';
import { useTranslation } from 'react-i18next';
import { DeliveryLeadTime } from '@1po/1po-bff-fe-spec/generated/backoffice/delivery_lead_time/response/DeliveryLeadTimeResponse';
import { ReferenceStock } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/model/ReferenceStock';
import {
  DealerType,
  DeliveryLeadTime as OrderDeliveryLeadTime,
  Warehouse,
  WarehouseDeliveryStatus,
} from '@1po/1po-bff-fe-spec/generated/order/response/GetDealerOrderPageResponse';
import { Text as PdfText, View } from '@react-pdf/renderer';
import { fonts, styles } from 'components/Pdf/PdfUI.styles';
import { getDeliveryText, getStockTexts, StockTextWithDeliveryTypeProps } from 'components/StockInfo/StockTexts';
import { MAX_QUANTITY_WITH_STOCKS } from 'domains/references';
import { getData } from 'utils';

function DelayedStock() {
  const { t } = useTranslation();

  return (
    <View style={styles.row}>
      <PdfText style={[fonts.textMid, { marginLeft: 2 }]}>{t('common.restocking', 'Restocking')}</PdfText>
    </View>
  );
}

export const PdfOrderStockDisplay = ({
  orderId,
  referenceNumber,
  narrow,
  warehouseDeliveryStatuses,
  quantity,
  dealerType,
  r1Country,
  isExadisPickUp = false,
}: {
  orderId: string;
  referenceNumber: string;
  narrow: boolean;
  warehouseDeliveryStatuses: WarehouseDeliveryStatus[] | undefined;
  quantity: number;
  dealerType: DealerType | undefined;
  r1Country: string;
  isExadisPickUp: boolean;
}) => {
  if (!warehouseDeliveryStatuses || quantity > MAX_QUANTITY_WITH_STOCKS) {
    return <DelayedStock />;
  }

  const mapDeliveryLeadTime = (
    warehouse: Warehouse,
    leadTime: OrderDeliveryLeadTime | undefined,
  ): DeliveryLeadTime | undefined => {
    if (!leadTime) {
      return undefined;
    }
    return {
      ...leadTime,
      warehouse,
      isDisabled: false,
    } as DeliveryLeadTime;
  };

  const mapStocks = (status: WarehouseDeliveryStatus): ReferenceStock => {
    return {
      reference: referenceNumber,
      confirmedQuantity: status.confirmedQuantity,
      warehouses: [
        {
          order: 0,
          type: status.type,
          availableQuantity: status.availableQuantity,
          confirmedQuantity: status.confirmedQuantity,
          expectedDeliveryDate: status.expectedDeliveryDate,
          deliveryInformation: status.deliveryInformation,
          depan: status.depan,
        },
      ],
    };
  };

  return (
    <View style={styles.col}>
      {warehouseDeliveryStatuses.map((status, index) => (
        <PdfStockTextWithDeliveryType
          key={`${orderId}_${referenceNumber}_stockStatus_${index}`}
          narrow={narrow}
          stock={mapStocks(status)}
          quantity={quantity}
          type={status.type}
          index={0}
          deliveryLeadTime={mapDeliveryLeadTime(status.type, status.deliveryLeadTime)}
          dealerType={dealerType}
          r1Country={r1Country}
          isMKTP
          isExadisPickUp={isExadisPickUp}
        />
      ))}
    </View>
  );
};

export function PdfStockTextWithDeliveryType({
  stock,
  quantity,
  type,
  deliveryLeadTime,
  index,
  dealerType,
  r1Country,
  relativeDate = new Date(),
  isExadisPickUp,
}: Readonly<StockTextWithDeliveryTypeProps>) {
  const { t } = useTranslation();
  const stockData = getData(stock);

  if (!stockData) {
    return null;
  }
  const currentStock = stockData.warehouses[index];
  if (currentStock.confirmedQuantity === 0) {
    return null;
  }
  const showStockForExadis = currentStock.type !== 'EXADIS' || r1Country === 'FR';
  const texts = getStockTexts(t, currentStock, quantity ?? 0, relativeDate, currentStock.depan, isExadisPickUp);
  const deliveryText = getDeliveryText(
    t,
    type,
    deliveryLeadTime,
    currentStock.expectedDeliveryDate,
    currentStock.depan,
    dealerType,
    relativeDate,
    isExadisPickUp,
  );

  const line = `${texts.warehouse} ${texts.description}${deliveryText}`;
  return (
    <>
      {showStockForExadis && (
        <View style={[styles.row]}>
          <View style={{ width: '2px' }} />
          <PdfText
            style={[
              fonts.textMid,
              {
                textOverflow: 'ellipsis',
              },
            ]}
          >
            {line}
          </PdfText>
        </View>
      )}
    </>
  );
}
