/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { VehiclePartCategoryTreeItem } from '@1po/1po-bff-fe-spec/generated/catalog/category/model/VehiclePartCategoryTreeItem';
import { ROUTER_CATALOG_VEHICLE } from 'app/AppRouter';
import { getLastSearchedVehicleKey, getLastVehicleDetail } from 'domains/catalog/Catalog.store';
import { formatCategoryLabelToDataCy, getCatalogSourceUrl } from 'domains/catalog/Catalog.utils';
import {
  CarPartGroupSvg,
  useGetCarPartSvgElement,
} from 'pages/CatalogPage/DH/CarPartGroupsSection/CarPartGroupItem/CarPartGroupSvg/CarPartGroupSvg';
import CategoriesMenu from 'pages/CatalogPage/DH/CategoriesMenu';
import { Box, CenterFlex, sentenceCase } from 'UI';
import { IndexType, useDuplicateSvgLinesAndNumber } from 'utils/svg/common';
import {
  manipulateLinesInSvg,
  svgElementId,
  svgTextPosition,
  useAddListenersToCategorySvg,
  useRemoveMissingCategoriesInSvg,
} from 'utils/svg/manipulateCategorySvg';
import { CarPartGroupCard, PopoverDiv, SText } from './CarPartGroupItem.styled';

interface CarPartsViewProps {
  boxNodeId: string;
  label: string | undefined;
  svgImageKey: string | undefined;
  enlarged: boolean;
  details: VehiclePartCategoryTreeItem[];
}

const CarPartGroupItem = ({ boxNodeId, label, svgImageKey, enlarged, details }: CarPartsViewProps) => {
  const [highlightedPartNodeId, setHighlightedPartNodeId] = useState<IndexType>(null);
  const history = useHistory();
  const { pathname } = useLocation();
  const vehicleKey = useSelector(getLastSearchedVehicleKey);
  const vehicleDetail = useSelector(getLastVehicleDetail);
  const bodyHeight = enlarged ? 514 : 463;
  const cardHeight = bodyHeight + 60;
  const bodyWidth = enlarged ? 812 : 570;

  const svgElement = useGetCarPartSvgElement({ nodeId: boxNodeId, svgImageKey });

  useRemoveMissingCategoriesInSvg(svgElement, boxNodeId, details);
  useDuplicateSvgLinesAndNumber(svgElement, svgElementId, boxNodeId, svgTextPosition, true, details.length);
  useAddListenersToCategorySvg(boxNodeId, enlarged, pathname, history, setHighlightedPartNodeId, svgElement);

  useEffect(() => {
    manipulateLinesInSvg(boxNodeId, highlightedPartNodeId);
  }, [boxNodeId, highlightedPartNodeId]);

  return (
    <>
      <PopoverDiv id={'popover-' + boxNodeId}>
        <a>{sentenceCase(details.find((detail) => detail.nodeId === highlightedPartNodeId)?.label ?? '')}</a>
      </PopoverDiv>
      <Box height={cardHeight}>
        <CenterFlex>
          <CarPartGroupCard
            onClick={() => {
              if (vehicleDetail) {
                history.push({
                  pathname: `${getCatalogSourceUrl(
                    vehicleDetail.catalogSource,
                  )}/${vehicleKey}${ROUTER_CATALOG_VEHICLE}/${boxNodeId}`,
                  search: location.search,
                });
              }
            }}
            title={
              <SText type={'h2'} dataCy={formatCategoryLabelToDataCy(label ?? '', 'title')} disableGutter ellipsis>
                {sentenceCase(label ?? '')}
              </SText>
            }
            width={bodyWidth}
            data-cy={formatCategoryLabelToDataCy(label ?? '', 'content')}
          >
            <CarPartGroupSvg nodeId={boxNodeId} width={bodyWidth} height={bodyHeight} svgElement={svgElement} />
          </CarPartGroupCard>
          <CategoriesMenu
            enlarged={enlarged}
            highlightedPartNodeId={highlightedPartNodeId}
            setHighlightedPartNodeId={setHighlightedPartNodeId}
            pathname={pathname}
            details={details}
          />
        </CenterFlex>
      </Box>
      {enlarged && <Box height={30} />}
    </>
  );
};

export default CarPartGroupItem;
