import { ReferenceStock } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/model/ReferenceStock';
import { ReferenceLocal } from 'domains/references/References.types';
import { getSearchData, SearchData } from 'utils';

export function isRefMKTP(reference?: ReferenceLocal): boolean {
  if (reference !== undefined && 'referenceSource' in reference) return reference.referenceSource === 'MARKETPLACE';
  return false;
}

export const getExadisStockInfoWithDeliveryToday = (
  stockInfo: SearchData<ReferenceStock> | undefined,
): SearchData<ReferenceStock> | undefined => {
  const refStockData = getSearchData(stockInfo);

  if (refStockData) {
    const formattedToday = new Date().toISOString().split('T')[0];
    const exadisStocks = (stockInfo?.data?.warehouses ?? []).filter((warehouse) => warehouse.type === 'EXADIS');
    const exadisStocksWithDeliveryToday = exadisStocks.filter((warehouse) =>
      warehouse.expectedDeliveryDate?.startsWith(formattedToday),
    );
    const exadisConfirmedQuantity: number = exadisStocksWithDeliveryToday.reduce((sum, obj) => {
      return sum + (obj && 'confirmedQuantity' in obj ? Number(obj.confirmedQuantity) || 0 : 0);
    }, 0);

    return {
      ...stockInfo,
      data: { ...refStockData, confirmedQuantity: exadisConfirmedQuantity, warehouses: exadisStocksWithDeliveryToday },
    } as SearchData<ReferenceStock>;
  } else {
    return stockInfo;
  }
};
