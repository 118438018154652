/* eslint-disable max-len */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReferenceDiscount } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/model/ReferenceDiscount';
import { TFunction } from 'i18next';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';
import { RootState } from 'app/AppStore';
import { DataContainer, ErrorWithLabel } from 'components/DataContainer';
import { calculatePriceAfterDiscount, DiscountCornerBasic, getPromotionColor } from 'components/Discount/Discount';
import Popover from 'components/Popover';
import { PriceErrorDisplay } from 'components/ReferencePriceSection';
import { StockDisplayBasic } from 'components/StockInfo/StockDisplay';
import {
  getEstimateB2BDiscounts,
  getEstimateB2BReferenceDiscount,
  getEstimateB2BReferencePrice,
  getEstimateB2BStocks,
  getEstimateOrderItemDetails,
  setEstimateOrderIsUrgent,
  setEstimateReferenceMark,
} from 'domains/estimate/Estimate.store';
import { EstimateOrderViewReference } from 'domains/estimate/Estimate.types';
import { getCurrency, getUserCommercialLink } from 'domains/user';
import { RefInput, STable } from 'pages/CartPage/CartStep/CartItems/CartReferenceTable/CartReferenceTable.styled';
import { Box, CenterFlex, Checkbox, ColoredText, Flex, PencilRoundButton, Spin, Text } from 'UI';
import { textFormatter } from 'utils/format';

const SFlex = styled(Flex)`
  overflow: hidden;
`;

const Stock = ({ reference }: { reference: EstimateOrderViewReference }) => {
  const stocks = useSelector((state: RootState) => getEstimateB2BStocks(state, reference.estimateId));

  return (
    <SFlex maxWidth={200}>
      <StockDisplayBasic
        stock={stocks.get(reference.referenceNumber)}
        isMKTP={reference.isMktp}
        quantity={reference.quantity}
        narrow
      />
    </SFlex>
  );
};

const UrgentDelivery = ({
  referenceNumber,
  currency,
  quantity,
  estimateId,
}: {
  referenceNumber: string;
  currency: string;
  quantity: number;
  estimateId: string;
}) => {
  const dispatch = useDispatch();
  const estimateOrderDetails = useSelector((state: RootState) => getEstimateOrderItemDetails(state, estimateId));
  const isUrgentDelivery = estimateOrderDetails?.isUrgentDelivery.get(referenceNumber) ?? false;
  const priceSearchData = useSelector((state: RootState) =>
    getEstimateB2BReferencePrice(state, { estimateId, referenceNumber }),
  );
  const price = priceSearchData?.data;
  const priceToDisplay = isNaN(Number(price?.garageView?.vatExcludedPriceUrgent))
    ? price?.garageView?.vatExcludedPrice ?? 0
    : Number(price?.garageView?.vatExcludedPriceUrgent) * quantity;
  return (
    <Flex>
      <Checkbox
        checked={isUrgentDelivery}
        onChange={() => {
          dispatch(setEstimateOrderIsUrgent({ estimateId, referenceNumber, isUrgentDelivery }));
        }}
      />
      <Box width={18} />
      {textFormatter.formatCurrency(priceToDisplay, currency)}
    </Flex>
  );
};

const ReferenceMark = ({ reference }: { reference: EstimateOrderViewReference }) => {
  const { t } = useTranslation();
  const { estimateId, referenceNumber } = reference;
  const dispatch = useDispatch();
  const details = useSelector((state: RootState) => getEstimateOrderItemDetails(state, estimateId));
  const referenceMark = details?.referenceMarks?.get(referenceNumber) ?? '';

  const handleRefMarkUpdate = useDebouncedCallback((value) => {
    dispatch(setEstimateReferenceMark({ estimateId, referenceNumber, referenceMark: value }));
  }, 500);

  return (
    <Popover
      content={
        <RefInput
          initialValue={referenceMark}
          onChange={(value) => {
            handleRefMarkUpdate(value);
          }}
          placeholder={t('order.add_reference_mark', 'Type your reference mark here...')}
          bordered
          showCount
          maxLength={17}
        />
      }
      trigger={'click'}
      placement={'bottomLeft'}
      zIndex={9999}
    >
      <PencilRoundButton
        bgColor={referenceMark ? 'selected' : 'grey95'}
        color={referenceMark ? 'white' : 'brand_black'}
        hoverColor={'brand_black'}
        tooltip={t('order.add_reference_mark.add_comment', 'Add comment')}
      />
    </Popover>
  );
};

const ProductPrice = ({ reference, currency }: { reference: EstimateOrderViewReference; currency: string }) => {
  const { estimateId, referenceNumber } = reference;
  const price = useSelector((state: RootState) => getEstimateB2BReferencePrice(state, { estimateId, referenceNumber }));

  return (
    <DataContainer data={price} Loading={() => <Spin size={'small'} />} ErrorState={() => <PriceErrorDisplay narrow />}>
      <Text type={'text_dim'}>
        {textFormatter.formatCurrency(price?.data?.clientView?.recommendedPriceVatExcluded ?? 0, currency)}
      </Text>
    </DataContainer>
  );
};

const Discount = ({ estimateId, referenceNumber }: { estimateId: string; referenceNumber: string }) => {
  const { t } = useTranslation();
  const price = useSelector((state: RootState) => getEstimateB2BReferencePrice(state, { estimateId, referenceNumber }));

  return (
    <DataContainer
      data={price}
      Loading={() => <Spin size={'small'} />}
      ErrorState={() => (
        <ErrorWithLabel
          label={t('common.price.discount.backend_error', 'Discount temporarily unavailable, please try again later.')}
          narrow
        />
      )}
    >
      <Text type={'text_dim'}>{textFormatter.formatPercentDecimal(Number(price?.data?.garageView?.discountRate))}</Text>
    </DataContainer>
  );
};
const PriceWithDiscounts = ({
  reference,
  currency,
  showTotal,
}: {
  reference: EstimateOrderViewReference;
  currency: string;
  showTotal?: boolean;
}) => {
  const { estimateId, referenceNumber } = reference;
  const discount = useSelector((state: RootState) =>
    getEstimateB2BReferenceDiscount(state, { estimateId, referenceNumber }),
  );
  const price = useSelector((state: RootState) => getEstimateB2BReferencePrice(state, { estimateId, referenceNumber }));

  const discountPrice = calculatePriceAfterDiscount(discount, price?.data, false);
  const totalPrice = showTotal ? discountPrice * reference.quantity : discountPrice;

  return (
    <DataContainer data={price} Loading={() => <Spin size={'small'} />} ErrorState={() => <PriceErrorDisplay narrow />}>
      <ColoredText
        type={'text_dim'}
        color={getPromotionColor({
          staticType: undefined,
          referencePromotionType: discount?.promotionType,
        })}
      >
        {textFormatter.formatCurrency(totalPrice, currency)}
      </ColoredText>
    </DataContainer>
  );
};

const dataColumns = (t: TFunction, currency: string, discounts: Map<string, ReferenceDiscount>) => [
  {
    title: '',
    dataIndex: 'referenceNumber',
    render: function rowSelector(referenceNumber: string) {
      return <DiscountCornerBasic discount={discounts.get(referenceNumber)} type={'cart'} />;
    },
  },
  {
    title: t('cart.reference_table.product', 'Product'),
    dataIndex: 'designation',
    key: 'designation',
    render: function renderProductName(designation: string) {
      return <>{designation}</>;
    },
  },
  {
    title: t('cart.reference_table.reference', 'Reference'),
    dataIndex: 'referenceNumber',
    key: 'referenceNumber',
    render: function renderReferenceNumber(referenceNumber: string) {
      return <>{referenceNumber}</>;
    },
  },
  {
    title: t('cart.reference_table.stock', 'Stock'),
    dataIndex: 'referenceNumber',
    key: 'stock',
    width: 200,
    render: function renderStock(_text: string, reference: EstimateOrderViewReference) {
      return <Stock reference={reference} />;
    },
  },
  {
    title: t('common.price.pcl', 'PCL'),
    dataIndex: 'pclPrice',
    key: 'pclPrice',
    render: function renderPrice(_text: string, reference: EstimateOrderViewReference) {
      return <ProductPrice reference={reference} currency={currency} />;
    },
  },
  {
    title: t('cart.reference_table.discount', 'Discount'),
    dataIndex: 'discountAmount',
    key: 'discountAmount',
    width: 100,
    render: function renderPrice(_text: string, reference: EstimateOrderViewReference) {
      return (
        <CenterFlex>
          <Discount referenceNumber={reference.referenceNumber} estimateId={reference.estimateId} />
        </CenterFlex>
      );
    },
  },
  {
    title: t('common.price.garage_price', 'Garage Price'),
    dataIndex: 'garagePrice',
    key: 'garagePrice',
    width: 90,
    render: function renderPrice(_garagePrice: string, reference: EstimateOrderViewReference) {
      return (
        <CenterFlex>
          <PriceWithDiscounts reference={reference} currency={currency} />
        </CenterFlex>
      );
    },
  },
  {
    title: t('order.urgent_delivery', 'Urgent delivery'),
    dataIndex: 'urgentDelivery',
    key: 'urgentDelivery',
    render: function renderUrgentDelivery(_isUrgent: boolean, ref: EstimateOrderViewReference) {
      return (
        <UrgentDelivery
          referenceNumber={ref.referenceNumber}
          currency={currency}
          quantity={ref.quantity}
          estimateId={ref.estimateId}
        />
      );
    },
  },
  {
    title: t('common.quantity', 'Quantity'),
    dataIndex: 'quantity',
    key: 'quantity',
    width: 100,
    render: function renderQuantity(quantity: number) {
      return <CenterFlex>{quantity}</CenterFlex>;
    },
  },
  {
    title: t('common.price.VAT_excl_price', 'VAT Excl. Price'),
    dataIndex: 'priceVatExcluded',
    key: 'priceVatExcluded',
    render: function renderPrice(_priceVatExcluded: string, reference: EstimateOrderViewReference) {
      return (
        <CenterFlex>
          <PriceWithDiscounts reference={reference} showTotal currency={currency} />
        </CenterFlex>
      );
    },
  },
  {
    title: '',
    dataIndex: 'referenceNumber',
    key: 'referenceActions',
    render: function renderActions(_referenceNumber: string, reference: EstimateOrderViewReference) {
      return <ReferenceMark reference={reference} />;
    },
  },
];

const B2BEstimateOrderReferenceTable = ({
  estimateId,
  references,
}: {
  estimateId: string;
  references: EstimateOrderViewReference[];
}) => {
  const { t } = useTranslation();
  const currency = useSelector(getCurrency);
  const commercialLink = useSelector(getUserCommercialLink);
  const discounts = useSelector((state: RootState) => getEstimateB2BDiscounts(state, estimateId));

  const columns = dataColumns(t, currency, discounts);

  return (
    <STable<EstimateOrderViewReference>
      rowKey={(row: EstimateOrderViewReference) => row.referenceNumber}
      columns={
        commercialLink !== null && commercialLink.canPlaceUrgentOrders
          ? columns
          : columns.filter((column) => column.dataIndex !== 'urgentDelivery')
      }
      rowClassName={(row) => (row.hasPriceAndStock ? '' : 'disabled-row')}
      dataSource={references}
      pagination={false}
      data-cy={'reference-table'}
    />
  );
};

export default B2BEstimateOrderReferenceTable;
