import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'app/AppStore';
import { getLastSearchedVehicleKey, getPlateImageKey } from 'domains/catalog/Catalog.store';
import { DATAHUB } from 'domains/catalog/Catalog.types';
import EmptyOrErrorPlate from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/PlateThumbnailSection/EmptyOrErrorPlate';
import { theme } from 'styles';
import { Flex, ImageWithLoader, MarginBox } from 'UI';
import { CardEdge, SBadge, SPlateCard } from './PlateThumbnailCard.styled';

const RenderImage = ({
  plateIds,
  selectedPlateId,
  vehicleKey,
}: {
  plateIds: string[];
  selectedPlateId: string;
  vehicleKey: string | undefined;
}) => {
  const plateImage = useSelector((state: RootState) =>
    getPlateImageKey(state, {
      vehicleKey,
      plateId: plateIds.includes(selectedPlateId) ? selectedPlateId : plateIds[0],
    }),
  );

  return (
    <ImageWithLoader
      imageUrl={plateImage.imageKey}
      alt={`plate-${selectedPlateId}`}
      imageHeight={86}
      imageWidth={120}
      catalogSource={DATAHUB}
      fallbackComponent={<EmptyOrErrorPlate />}
    />
  );
};

// extract please
export const StackedCards = ({ count }: { count: number | undefined }) => {
  if (count === 2) {
    return <CardEdge />;
  }
  if (count === 3) {
    return (
      <>
        <CardEdge />
        <CardEdge />
      </>
    );
  }
  if (count === 4) {
    return (
      <>
        <CardEdge />
        <CardEdge />
        <CardEdge />
      </>
    );
  }
  if (count && count > 4) {
    return (
      <>
        <CardEdge />
        <CardEdge />
        <CardEdge />
        <CardEdge />
      </>
    );
  }
  return null;
};

export const PlateThumbnailCard = ({
  plateIds,
  active,
  onClick,
  first,
  selectedPlateId,
}: {
  plateIds: string[];
  onClick: (e: any) => void;
  active: boolean;
  first?: boolean;
  selectedPlateId?: string;
}) => {
  const vehicleKey = useSelector(getLastSearchedVehicleKey);
  const cards = plateIds.length ?? 1;

  return (
    <MarginBox mx={10 - ((Math.min(4, plateIds?.length) ?? 1) - 1) * 4} mt={first ? 0 : 10} mb={10}>
      <div onClick={onClick}>
        <SBadge
          count={plateIds.length === 1 ? 0 : plateIds.length}
          offset={[-(12 + 10), 9 + 10]}
          color={theme.color.blue_dark_2}
          showZero={false}
        >
          <Flex>
            <StackedCards count={cards} />
            <SPlateCard active={active} className={'thumbnail_id' + plateIds[0]}>
              {selectedPlateId && (
                <RenderImage plateIds={plateIds} selectedPlateId={selectedPlateId} vehicleKey={vehicleKey} />
              )}
            </SPlateCard>
          </Flex>
        </SBadge>
      </div>
    </MarginBox>
  );
};
