import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Reference } from '@1po/1po-bff-fe-spec/generated/basket/response/model/OtherSection';
import { VehicleDetail } from '@1po/1po-bff-fe-spec/generated/basket/response/model/VehicleSection';
import { Popover } from 'antd';
import { TFunction } from 'i18next';
import { useDebouncedCallback } from 'use-debounce';
import { RootState } from 'app/AppStore';
import { AngleDownIcon, AngleRightIcon, CreditCardIcon, ExadisPickUpIcon, TruckIcon } from 'assets/icons';
import { ExternalBasketSectionLocal, VehicleLocal } from 'domains/basket/Basket.types';
import { DATAHUB, IAM } from 'domains/catalog/Catalog.types';
import {
  resetValidationDeliveryAddressSaga,
  resetValidationPaymentMethodSaga,
  setValidationDeliveryAddressSaga,
  setValidationInstructionsSaga,
  setValidationPaymentMethodSaga,
} from 'domains/orderValidation/OrderValidation.store';
import {
  ValidationItemsExternal,
  ValidationItemsOthers,
  ValidationItemsVehicles,
} from 'domains/orderValidation/OrderValidation.types';
import { getReferencesToStockInfos } from 'domains/references';
import { refWithoutDepositRef } from 'domains/references/DepositReference';
import { SectionCard } from 'pages/CartPage/ValidationStep/DeliveryAddressSection.styled';
import { ReferenceCard } from 'pages/CartPage/ValidationStep/VerificationCard/ReferenceCard';
import { SectionImage } from 'pages/CartPage/ValidationStep/VerificationCard/VerificationImage';
import { theme } from 'styles';
import { Box, Flex, Icon, Input, MarginBox, Select, SelectOptionSingle, Text } from 'UI';
import { IconsBackground, ScrollDiv, SectionPicBackground, SelectedItems } from './VerificationCard.styled';

interface VerificationCardProps {
  vehicle?: VehicleLocal;
  externalBasket?: ExternalBasketSectionLocal;
  references?: Reference[];
  billingOptions?: SelectOptionSingle[];
  deliveryOptions?: SelectOptionSingle[];
  handlePaymentMethodUpdate: (vehicle: VehicleLocal | undefined, externalBasketId: string | undefined) => void;
  handleDeliveryAddressUpdate: (vehicle: VehicleLocal | undefined, externalBasketId: string | undefined) => void;
  allowPaymentChanges: boolean;
  allowAddressChanges: boolean;
  validation: ValidationItemsVehicles | ValidationItemsOthers | ValidationItemsExternal | undefined;
  basketExadisDeliveryMode: boolean;
}

const getVehicleTitle = (vehicleDetail?: VehicleDetail) => {
  if (!vehicleDetail) return '';
  switch (vehicleDetail.catalogSource) {
    case DATAHUB:
      return `${vehicleDetail.dataHubVehicle?.name ?? ''} ${vehicleDetail.vrn ?? ''}`;
    case IAM: {
      const vrn = vehicleDetail.vehicleIdentifiedBy === vehicleDetail.vrn ? vehicleDetail.vrn : '';
      return `${vehicleDetail.vehicleBrand ?? vehicleDetail.iamVehicle?.name ?? ''} ${vehicleDetail.model ?? ''} ${
        vehicleDetail.iamVehicle?.phase ?? ''
      } ${vrn ?? ''}`;
    }
    default:
      return '';
  }
};

export const renderVehicleInfoBlock = (
  vehicle: VehicleLocal | undefined,
  externalBasket: ExternalBasketSectionLocal | undefined,
  t: TFunction,
  referencesCount?: number,
) => {
  const countText =
    referencesCount ?? false
      ? ' - ' + t('catalog.checkout_card.total.item2', '{{count}} items', { count: referencesCount })
      : '';
  const vehicleText = getVehicleTitle(vehicle?.vehicleDetail);
  const vinField = (
    <>
      <Text type={'h4_paragraph'} disableGutter>{`${vehicleText}${countText}`}</Text>
      <Text type={'light_14_black_85'}>{`${t('catalog.vehicle.vin', 'VIN')}: ${vehicle?.vehicleDetail.vin}`}</Text>
    </>
  );
  const bmmField = (
    <>
      <Text type={'h4_paragraph'} disableGutter>{`${vehicleText}${countText}`}</Text>
      <Text type={'light_14_black_65'}>{`${
        vehicle?.vehicleDetail.dataHubVehicle?.modelType ?? vehicle?.vehicleDetail.iamVehicle?.bodyType ?? ''
      }`}</Text>
    </>
  );
  const externalField = (
    <>
      <Text type={'h4_paragraph'} disableGutter>
        {`${t('order.verification.external_articles', 'External Articles')}${countText}`}
      </Text>
      <Text type={'light_14_black_85'}>
        {t('cart.external_basket.transferred_sia', '{{sia}} transferred basket', { sia: externalBasket?.sia ?? '' })}
      </Text>
    </>
  );
  const otherRefsField = (
    <>
      <Text type={'h4_paragraph'} disableGutter>
        {`${t('order.verification.other_articles', 'Other Articles')}${countText}`}
      </Text>
      <Text type={'light_14_black_85'}>
        {`${t('order.verification.other_articles.description', 'Not attached to a vehicle')}`}
      </Text>
    </>
  );

  if (!vehicle && externalBasket) return externalField;
  if (!vehicle) return otherRefsField;
  if (vehicle && vehicle.vehicleDetail.vin) return vinField;
  else return bmmField;
};

const VerificationCard = ({
  vehicle,
  externalBasket,
  references,
  billingOptions,
  deliveryOptions,
  handlePaymentMethodUpdate,
  handleDeliveryAddressUpdate,
  allowPaymentChanges,
  allowAddressChanges,
  validation,
  basketExadisDeliveryMode,
}: VerificationCardProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const refArray = (vehicle ? vehicle?.references : references) || [];
  const allStocksReferences = useSelector((state: RootState) =>
    getReferencesToStockInfos(
      state,
      refArray.map((l) => l.referenceNumber),
    ),
  );
  const stocksR1References = allStocksReferences
    .filter((r) => r?.data !== null)
    .map((r) => r?.data)
    .filter((r) => r?.warehouses.some((w) => w.type === 'LOCAL'));

  const showExadisPickUp =
    refArray?.filter(
      (ref) =>
        ref.catalogSource === IAM &&
        ref.brand !== 'MOTRIO' &&
        ref.origin === 'IAM' &&
        !stocksR1References.find((s) => s?.reference === ref.referenceNumber),
    ).length > 0 && basketExadisDeliveryMode;
  const vehicleKey = vehicle?.vehicleDetail.vehicleKey;
  const externalBasketId = externalBasket?.externalBasketId;
  const referencesCount = refWithoutDepositRef(refArray)?.length;
  const selectedBillingAccount = validation?.paymentMethod;
  const selectedAddressKey = validation?.deliveryAddress;
  const selectedAddress = deliveryOptions?.find((option) => option.value === selectedAddressKey)?.title;
  const [addInstructionActive, setAddInstructionsActive] = useState(Boolean(validation?.instructions));
  const showPaymentButton = allowPaymentChanges && billingOptions && billingOptions.length > 0;
  const showAddressButton = allowAddressChanges && deliveryOptions && deliveryOptions.length > 0;
  const vehicleInfoBlock = renderVehicleInfoBlock(vehicle, externalBasket, t, referencesCount);
  const vehicleImageUrl = vehicle?.vehicleDetail?.imageUrl;
  const [collapsedRefs, setCollapsedRefs] = useState<boolean>(true);

  useEffect(() => {
    if (basketExadisDeliveryMode) {
      setCollapsedRefs(false);
    }
  }, [basketExadisDeliveryMode]);

  const handleSetInstructions = useDebouncedCallback((value) => {
    dispatch(
      setValidationInstructionsSaga({
        vehicleKey,
        externalBasketId,
        instructions: value,
      }),
    );
  }, 1000);

  const handleSetInstructionsVisibility = (value: string) => {
    if (!value) {
      setAddInstructionsActive(false);
    }
  };

  return (
    <SectionCard direction={'column'}>
      <Flex direction={'row'}>
        <SectionPicBackground showBackground={!vehicle} height={65} width={128}>
          <SectionImage
            vehicle={vehicle}
            imageUrl={vehicleImageUrl}
            externalBasketId={externalBasketId}
            maxWidth={128}
            maxHeight={65}
          />
        </SectionPicBackground>
        <Box width={15} />
        <Flex direction={'row'} gap={30}>
          <Flex direction={'column'} minHeight={90}>
            {vehicleInfoBlock}
            <Box height={5} />
            <SelectedItems>
              <Flex>
                {selectedAddress && (
                  <>
                    <Icon IconComponent={TruckIcon} size={20} color={theme.color.selected} />
                    <Box width={7} />
                    {selectedAddress}
                  </>
                )}
              </Flex>
              <Flex>
                {selectedBillingAccount && (
                  <>
                    <Icon IconComponent={CreditCardIcon} size={20} color={theme.color.selected} />
                    <Box width={7} />
                    {selectedBillingAccount}
                  </>
                )}
              </Flex>
            </SelectedItems>
            {!addInstructionActive && (
              <Text
                type={'text'}
                displayStyle={'link'}
                cursor={'pointer'}
                onClick={() => setAddInstructionsActive(!addInstructionActive)}
              >
                {t('order.verification.add_instruction', 'Add instructions')}
              </Text>
            )}
            {addInstructionActive && (
              <Input
                initialValue={validation?.instructions}
                maxLength={100}
                onChange={(value) => {
                  handleSetInstructions(value);
                }}
                onBlur={(value) => {
                  handleSetInstructionsVisibility(value);
                }}
                bordered
                placeholder={t('order.add_instruction', 'Add your instruction here...')}
              />
            )}
          </Flex>
          {showExadisPickUp && (
            <Flex maxWidth={150} align={'center'} justify={'center'}>
              <Icon IconComponent={ExadisPickUpIcon} color={theme.color.brand_black} width={150} noPointer />
            </Flex>
          )}
          <Flex direction={'row-reverse'} maxWidth={130} align={'center'}>
            <MarginBox mt={5} ml={7}>
              <Icon
                IconComponent={collapsedRefs ? AngleRightIcon : AngleDownIcon}
                round
                size={35}
                color={theme.color.blue}
                onClick={() => setCollapsedRefs(!collapsedRefs)}
              />
            </MarginBox>
            {showAddressButton && (
              <IconsBackground selected={Boolean(selectedAddress)}>
                <MarginBox mt={10} ml={7}>
                  <Popover
                    content={
                      <Box width={270}>
                        <Select
                          value={selectedAddress}
                          onChange={(value, option) => {
                            if (value)
                              dispatch(
                                setValidationDeliveryAddressSaga({
                                  vehicleKey,
                                  externalBasketId,
                                  deliveryAddress: option.value,
                                }),
                              );
                            handleDeliveryAddressUpdate(vehicle, externalBasketId);
                          }}
                          onClear={() => {
                            dispatch(
                              resetValidationDeliveryAddressSaga({
                                vehicleKey,
                                externalBasketId,
                              }),
                            );
                          }}
                          placeholder={t(
                            'order.validation.address.options.placeholder',
                            'Select your delivery address',
                          )}
                          options={deliveryOptions}
                          bordered
                          allowClear
                        />
                      </Box>
                    }
                    trigger={'click'}
                    placement={'bottomRight'}
                  >
                    <Icon IconComponent={TruckIcon} size={20} color={selectedAddress ? 'white' : 'black'} />
                  </Popover>
                </MarginBox>
              </IconsBackground>
            )}
            <Box width={15} />
            {showPaymentButton && (
              <IconsBackground selected={Boolean(selectedBillingAccount)}>
                <MarginBox mt={10} ml={10}>
                  <Popover
                    content={
                      <Box width={270}>
                        <Select
                          value={selectedBillingAccount}
                          onChange={(value) => {
                            if (value)
                              dispatch(
                                setValidationPaymentMethodSaga({
                                  vehicleKey,
                                  externalBasketId,
                                  paymentMethod: String(value),
                                }),
                              );
                            handlePaymentMethodUpdate(vehicle, externalBasketId);
                          }}
                          onClear={() => {
                            dispatch(
                              resetValidationPaymentMethodSaga({
                                vehicleKey,
                                externalBasketId,
                              }),
                            );
                          }}
                          placeholder={t('order.validation.payment.options.placeholder', 'Select billing account')}
                          options={billingOptions}
                          bordered
                          allowClear
                        />
                      </Box>
                    }
                    trigger={'click'}
                    placement={'bottomRight'}
                  >
                    <Icon IconComponent={CreditCardIcon} size={20} color={selectedBillingAccount ? 'white' : 'black'} />
                  </Popover>
                </MarginBox>
              </IconsBackground>
            )}
          </Flex>
        </Flex>
      </Flex>
      {!collapsedRefs && (
        <>
          <Box height={16} />
          <ScrollDiv>
            {refArray.map((reference, index) => (
              <ReferenceCard
                key={`refCard-${reference.referenceNumber}`}
                reference={reference}
                vehicle={vehicle}
                index={index}
                basketExadisDeliveryMode={basketExadisDeliveryMode}
              />
            ))}
          </ScrollDiv>
        </>
      )}
    </SectionCard>
  );
};

export default VerificationCard;
