import { Garage } from '@1po/1po-bff-fe-spec/generated/garage/response/GetGarageInfo';
import { APP_NAMESPACE } from 'app/App.constants';
import { NO_DATA } from 'utils';

export const GARAGE_NAMESPACE = `${APP_NAMESPACE}/GARAGE`;
export const GET_GARAGE_INFO = `${GARAGE_NAMESPACE}/GET_GARAGE_INFO`;
export const CHANGE_GARAGE_EMAIL = `${GARAGE_NAMESPACE}/CHANGE_EMAIL`;
export const CHANGE_WORKSHOP_ID = `${GARAGE_NAMESPACE}/CONFIGURE_WORKSHOP_ID`;

export interface GarageState {
  garages: Map<string, Garage | NO_DATA>;
}
