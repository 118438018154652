import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CloseableDialog } from 'components/CloseableDialog';
import { DataContainer } from 'components/DataContainer';
import { useDisclosure } from 'components/Dialog';
import { useFetchVehicleTechnicalCriteria } from 'domains/catalog/Catalog.requests';
import {
  fetchMaintenanceValuesRequestSaga,
  getMaintenanceValues,
  setMaintenanceValuesXml,
  setMaintenanceValuesXmlStatus,
} from 'domains/maintenanceValues/MaintenanceValues.store';
import DITARenderer from 'pages/CatalogPage/DH/MaintenanceValues/DITARenderer';
import { BorderedSelect } from 'pages/CatalogPage/DH/MaintenanceValues/DocumentationSelect.styled';
import { useGetCriteria } from 'pages/CatalogPage/DH/VehicleTechnicalDetails/TechnicalCriteria';
import { Box, Flex, SelectOptionSingle } from 'UI';
import { getCondArrayItem, getData, NO_DATA } from 'utils';

export const useGetMaintenanceDocumentation = () => {
  const disclosure = useDisclosure();
  const dispatch = useDispatch();
  const { onOpen } = disclosure;
  const [value, setValue] = useState<SelectOptionSingle | undefined>(undefined);
  const { t } = useTranslation();
  const maintenanceValues = useSelector(getMaintenanceValues);
  const maintenanceValuesData = getData(maintenanceValues);
  const criteria = useGetCriteria();
  useFetchVehicleTechnicalCriteria(criteria.items, false);

  useEffect(() => {
    dispatch(fetchMaintenanceValuesRequestSaga());
  }, [dispatch]);

  useEffect(() => {
    if (value) {
      dispatch(setMaintenanceValuesXml(undefined));
      dispatch(setMaintenanceValuesXmlStatus('not_found'));
      onOpen();
    }
    //eslint-disable-next-line
  }, [value]);

  const documents = maintenanceValuesData?.documents;

  //Vehicle: parts and consumables for the repair => linked MR is RDE-001868443, 'SIE/MR/Content/RDE-001868443.xml';
  const documentPartsConsumables = documents?.find(
    (d) => d.documentName === 'vehicle parts and consumables for the repair',
  );
  const labelVehiclePartsConsumables = t(
    'maintenance_values.vehicle_parts_consumables',
    'Vehicle: parts and consumables for the repair',
  );

  // Gearbox: oil specifications => linked MR is RDE-000162565 , 'SIE/MR/Content/RDE-000162565.xml'
  const documentGearboxOilSpecifications = documents?.find((d) => d.documentName === 'gearbox oil');
  const labelGearboxOilSpecifications = t(
    'maintenance_values.gearbox_oil_specifications',
    'Gearbox: oil specifications',
  );

  //example maintenanceValues contentPath : "SIE/MR/Content/RDE-000003577.xml"
  const documentMaintenanceValues = documents?.find((d) => d.documentName === 'maintenance values');
  const labelMaintenanceValues = t('maintenance_values.maintenance_values', 'Maintenance Values');

  const options: SelectOptionSingle[] | NO_DATA = [
    ...getCondArrayItem(
      documentGearboxOilSpecifications && {
        value: documentGearboxOilSpecifications.contentPath,
        title: labelGearboxOilSpecifications,
      },
    ),
    ...getCondArrayItem(
      documentMaintenanceValues && {
        value: documentMaintenanceValues.contentPath,
        title: labelMaintenanceValues,
      },
    ),
    ...getCondArrayItem(
      documentPartsConsumables && {
        value: documentPartsConsumables.contentPath,
        title: labelVehiclePartsConsumables,
      },
    ),
  ];

  return (
    <>
      <Box width={393}>
        <Flex>
          <BorderedSelect
            bordered
            placeholder={t('maintenance_values.useful_documentation', 'Useful documentation')}
            onChange={(item) => {
              setValue(options.find((o) => o.value === item));
            }}
            options={options}
          />
        </Flex>
      </Box>

      <CloseableDialog
        disclosure={disclosure}
        title={value?.title ?? ''}
        content={<MaintenanceValues selectedContentPath={value} />}
      />
    </>
  );
};

const MaintenanceValues = ({ selectedContentPath }: { selectedContentPath: SelectOptionSingle | NO_DATA }) => {
  const criteria = useGetCriteria();
  useFetchVehicleTechnicalCriteria(criteria.items, false);
  const data = getData(selectedContentPath);
  return (
    <DataContainer data={selectedContentPath}>
      <DITARenderer xml={data?.value ?? ''} />
    </DataContainer>
  );
};

export default MaintenanceValues;
