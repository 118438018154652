import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { ReferenceBrandType } from '@1po/1po-bff-fe-spec/generated/catalog/references/common/model/ReferenceBrandType';
import {
  GET_MY_STORE_BUNDLES_RESPONSE,
  GET_MY_STORE_TIRES_RESPONSE,
} from '@1po/1po-bff-fe-spec/generated/common/ResponseType';
import {
  GetMyStoreBundlesResponse,
  MyStoreBundle,
  MyStoreSection,
} from '@1po/1po-bff-fe-spec/generated/my_store/bundles/response/GetMyStoreBundlesResponse';
import { MyStoreTireDiscount } from '@1po/1po-bff-fe-spec/generated/my_store/tires/model/MyStoreTireDiscount';
import { MyStoreTireRimSizeType } from '@1po/1po-bff-fe-spec/generated/my_store/tires/model/MyStoreTireRimSizeType';
import { MyStoreTireBrand } from '@1po/1po-bff-fe-spec/generated/my_store/tires/response/GetMyStoreTiresResponse';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { RootState } from 'app/AppStore';

import {
  ADD_BUNDLE_REQUEST,
  ADD_BUNDLES_SECTION_REQUEST,
  BundleUpdateType,
  REMOVE_BUNDLE_SECTION_REQUEST,
  GET_BUNDLES_REQUEST,
  GET_TIRES_REQUEST,
  INIT_MY_STORE,
  MOVE_BUNDLES_SECTION_REQUEST,
  MY_STORE_NAMESPACE,
  MyStoreBundles,
  MyStoreState,
  MyStoreTireBrandLocal,
  MyStoreTireRequestData,
  MyStoreTires,
  REMOVE_BUNDLE_REQUEST,
  RENAME_BUNDLES_SECTION_REQUEST,
  UPDATE_BUNDLE_CODE_REQUEST,
  UPDATE_BUNDLE_DESIGNATION_REQUEST,
  UPDATE_BUNDLE_PRICE_REQUEST,
  UPDATE_TIRE_DISCOUNT_LIST_REQUEST,
} from 'domains/myStore/MyStore.types';
import { SEARCH_STATUS } from 'utils';

const isIndexFound = (i: number) => i > -1;

// Init state
const initialState: MyStoreState = {
  bundles: {
    sections: {
      searchStatus: undefined,
    },
    selectedBundleIds: new Set<string>(),
  },
  tires: {
    brands: {
      searchStatus: undefined,
    },
  },
  tiresDiscountChanges: [],
};

// Saga actions
export const initMyStoreRequest = createAction(INIT_MY_STORE);

// BUNDLES
export const getBundlesRequestSaga = createAction(GET_BUNDLES_REQUEST);
export const getBundlesResponseSaga = createAction(GET_MY_STORE_BUNDLES_RESPONSE);
export const addBundlesSectionRequestSaga = createAction(ADD_BUNDLES_SECTION_REQUEST);
export const moveBundlesSectionRequestSaga = createAction<DragEndEvent>(MOVE_BUNDLES_SECTION_REQUEST);
export const renameBundlesSectionRequestSaga = createAction<{ sectionId: string; title: string }>(
  RENAME_BUNDLES_SECTION_REQUEST,
);
export const addBundleRequestSaga = createAction<{ sectionId: string }>(ADD_BUNDLE_REQUEST);
export const removeBundleSectionReuestSaga = createAction<{ sectionId: string }>(REMOVE_BUNDLE_SECTION_REQUEST);
export const removeBundleRequestSaga = createAction<BundleUpdateType>(REMOVE_BUNDLE_REQUEST);
export const updateBundleDesignationRequestSaga = createAction<BundleUpdateType & { bundleDesignation: string }>(
  UPDATE_BUNDLE_DESIGNATION_REQUEST,
);
export const updateBundleCodeRequestSaga = createAction<BundleUpdateType & { bundleCode: string }>(
  UPDATE_BUNDLE_CODE_REQUEST,
);
export const updateBundlePriceRequestSaga = createAction<BundleUpdateType & { bundlePrice: number }>(
  UPDATE_BUNDLE_PRICE_REQUEST,
);

// TIRES
export const subscribeTiresRequestSaga = createAction(GET_TIRES_REQUEST);
export const getTiresResponseSaga = createAction(GET_MY_STORE_TIRES_RESPONSE);

export const updateMyStoreTireDiscountListRequest = createAction<MyStoreTireRequestData[]>(
  UPDATE_TIRE_DISCOUNT_LIST_REQUEST,
);

function getMyStoreTireBrandsMap(tires: MyStoreTires): Map<ReferenceBrandType, MyStoreTireBrandLocal> {
  return tires.brands.data ? tires.brands.data : new Map<ReferenceBrandType, MyStoreTireBrandLocal>();
}

function getMyStoreTireBrandDiscountsMap(
  brand?: MyStoreTireBrandLocal,
): Map<MyStoreTireRimSizeType, MyStoreTireDiscount> {
  return brand?.discounts ? brand.discounts : new Map<MyStoreTireRimSizeType, MyStoreTireDiscount>();
}

// Slice
const slice = createSlice({
  name: MY_STORE_NAMESPACE,
  initialState,
  reducers: {
    setInitialState: () => initialState,
    setBundlesStatus: (state, action: PayloadAction<SEARCH_STATUS>) => {
      state.bundles.sections.searchStatus = action.payload;
    },
    setBundlesSection: (state, action: PayloadAction<GetMyStoreBundlesResponse>) => {
      if (!state.bundles.myStoreBundlesId) {
        state.bundles.myStoreBundlesId = action.payload.myStoreBundlesId;
      }
      state.bundles.sections.data = [...action.payload.sections].sort((a, b) => a.index - b.index);
    },
    setBundleSelection: (
      state,
      { payload }: PayloadAction<{ sectionId: string; bundleId?: string; isSelected: boolean }>,
    ) => {
      const { sectionId, bundleId, isSelected } = payload;
      const section = state.bundles.sections.data?.find((s) => s.id === sectionId);
      const workingBundleIds = bundleId ? [bundleId] : section?.bundles?.map((s) => s.id) ?? [];
      const selectedIds = state.bundles.selectedBundleIds;
      workingBundleIds.forEach((bId) => {
        if (isSelected) {
          selectedIds.add(bId);
        } else {
          selectedIds.delete(bId);
        }
      });
    },
    reorderBundles: (state, { payload }: PayloadAction<DragEndEvent>) => {
      const { active, over } = payload;
      const sections = state.bundles.sections.data;
      if (active.id === over?.id) return;
      const originalPos = sections?.findIndex((s) => s.id === active.id) ?? -1;
      const newPos = sections?.findIndex((s) => s.id === over?.id) ?? -1;
      if (isIndexFound(originalPos) && isIndexFound(newPos) && sections) {
        const newArr = arrayMove(sections, originalPos, newPos);
        state.bundles.sections.data = newArr.map((section, index) => {
          return { ...section, index };
        });
      }
    },
    // MyStoreTires
    setMyStoreTiresId: (state, action: PayloadAction<{ myStoreTiresId: string }>) => {
      const { myStoreTiresId } = action.payload;
      state.tires.myStoreTiresId = myStoreTiresId;
    },
    setMyStoreTiresSearchStatus: (state, action: PayloadAction<SEARCH_STATUS>) => {
      state.tires.brands.searchStatus = action.payload;
    },
    setMyStoreTireBrands: (state, action: PayloadAction<{ brands: MyStoreTireBrand[] }>) => {
      const { brands } = action.payload;
      const brandsDataMap = getMyStoreTireBrandsMap(state.tires);

      brands.forEach((myStoreBrand) => {
        const brand = myStoreBrand.brand;
        const discountsMap = getMyStoreTireBrandDiscountsMap(brandsDataMap.get(brand));
        myStoreBrand.discounts.forEach((myStoreDiscount) => {
          const { rimSizeType, discount } = myStoreDiscount;
          if (rimSizeType) {
            discountsMap.set(rimSizeType, { rimSizeType, discount });
          }
        });
        brandsDataMap.set(brand, { brand, discounts: discountsMap });
      });
      state.tires.brands.data = brandsDataMap;
    },
    setMyStoreTiresChange: (state, action: PayloadAction<{ item: MyStoreTireRequestData }>) => {
      const { item } = action.payload;
      state.tiresDiscountChanges = [
        ...state.tiresDiscountChanges.filter((v) => {
          return !(v.tireRimSizeType === item.tireRimSizeType && v.brandId === item.brandId);
        }),
        item,
      ];
    },
    clearMyStoreTiresChanges: (state) => {
      state.tiresDiscountChanges = [];
    },
  },
});

// Actions
export const {
  setInitialState,
  setBundlesStatus,
  reorderBundles,
  setBundleSelection,
  setBundlesSection,
  setMyStoreTiresId,
  setMyStoreTiresSearchStatus,
  setMyStoreTireBrands,
  setMyStoreTiresChange,
  clearMyStoreTiresChanges,
} = slice.actions;

// Getters/Selectors
export const getBundlesSections = createSelector(
  (state: RootState) => state.myStore.bundles,
  (bundles: MyStoreBundles) => bundles,
);

export const getMyStoreBundlesId = createSelector(
  (state: RootState) => state.myStore.bundles.myStoreBundlesId,
  (myStoreBundlesId) => myStoreBundlesId,
);

export const getIsSectionSelected = createSelector(
  (state: RootState) => state.myStore.bundles.sections.data,
  (state: RootState) => state.myStore.bundles.selectedBundleIds,
  (_state: RootState, sectionId: string) => sectionId,
  (sections, selectedBundleIds, sectionId) => {
    const section = sections?.find((s) => s.id === sectionId);
    return (
      (section?.bundles &&
        section?.bundles.length > 0 &&
        section?.bundles?.every((bundle) => selectedBundleIds.has(bundle.id))) ??
      false
    );
  },
);

export const getIsBundleSelected = createSelector(
  (state: RootState) => state.myStore.bundles.selectedBundleIds,
  (_state: RootState, bundleId?: string) => bundleId,
  (selectedBundleIds, bundleId) => {
    if (!bundleId) return false;
    return selectedBundleIds.has(bundleId);
  },
);

export const getAllSelectedBundles = createSelector(
  (state: RootState) => state.myStore.bundles.sections.data,
  (state: RootState) => state.myStore.bundles.selectedBundleIds,
  (sections, selectedBundleIds) => {
    const all =
      sections?.reduce((result: MyStoreBundle[], section: MyStoreSection) => {
        const allSectionBundles = section.bundles?.map((b) => b ?? []).flat() ?? [];
        return [...result, ...allSectionBundles];
      }, []) ?? [];

    return all.filter((i) => !!i.code && !!i.designation && !!i.price).filter((i) => selectedBundleIds.has(i.id));
  },
);

export const getAllBundleCodes = createSelector(
  (state: RootState) => state.myStore.bundles.sections.data,
  (sections) => {
    return (
      sections?.reduce((result: string[], section: MyStoreSection) => {
        const allSectionCodes = section.bundles?.map((b) => b.code ?? []).flat() ?? [];
        return [...result, ...allSectionCodes];
      }, []) ?? []
    );
  },
);

// My Store Tires
export const getMyStoreTires = createSelector(
  (state: RootState) => state.myStore.tires,
  (tires) => tires,
);

export const getMyStoreTiresId = createSelector(
  (state: RootState) => state.myStore.tires.myStoreTiresId,
  (myStoreTiresId) => myStoreTiresId,
);

export const getMyStoreTiresDiscount = createSelector(
  (state: RootState) => state.myStore.tires,
  (_state: RootState, params: { brand: ReferenceBrandType; tireRimSizeType: MyStoreTireRimSizeType }) => params,
  (tires, params) => {
    const { brand, tireRimSizeType } = params;
    const myStoreBrand = tires.brands?.data?.get(brand);
    if (myStoreBrand) {
      return myStoreBrand.discounts.get(tireRimSizeType);
    }
    return undefined;
  },
);

export const getMyStoreTiresDiscountChange = createSelector(
  (state: RootState) => state.myStore.tiresDiscountChanges,
  (_state: RootState, params: { brand: ReferenceBrandType; tireRimSizeType: MyStoreTireRimSizeType }) => params,
  (discounts: MyStoreTireRequestData[], params) => {
    const { brand, tireRimSizeType } = params;
    return discounts.find((i) => i.tireRimSizeType === tireRimSizeType && i.brandId === brand)?.discount;
  },
);

export const getMyStoreTiresDiscountChanges = createSelector(
  (state: RootState) => state.myStore.tiresDiscountChanges,
  (changes) => changes,
);

export const getMyStoreTiresSearchStatus = createSelector(
  (state: RootState) => state.myStore.tires.brands.searchStatus,
  (searchStatus) => searchStatus,
);

export default slice.reducer;
