/* eslint-disable max-len */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { UPLOADING_FILE } from 'components/FileUpload/paramsByState';
import { useFetchDiscontinuedReferences } from 'domains/discontinuedReferences/DiscontinuedReferences.requests';
import {
  getDiscontinuedReferences,
  getDiscontinuedReferencesFileUploadStatus,
} from 'domains/discontinuedReferences/DiscontinuedReferences.store';
import { REPLACING_FILE } from 'domains/electronicPartsRepair/ElectronicPartsRepair.types';
import DiscontinuedReferencesFileUpload from 'pages/BackOfficePage/BackOfficeCategories/DiscontinuedReferences/DiscontinuedReferencesFileUpload';
import FileRows from 'pages/BackOfficePage/BackOfficeCategories/DiscontinuedReferences/FileRows';
import { Flex, MarginBox, Text } from 'UI';

const DiscontinuedReferences = () => {
  const { t } = useTranslation();
  useFetchDiscontinuedReferences();

  const fileUploadStatus = useSelector(getDiscontinuedReferencesFileUploadStatus);

  const discontinuedReferences = useSelector(getDiscontinuedReferences);

  const showRows =
    discontinuedReferences?.length > 0 && fileUploadStatus !== UPLOADING_FILE && fileUploadStatus !== REPLACING_FILE;

  return (
    <Flex direction={'column'} align={'center'}>
      <MarginBox mt={64}>
        <Text type={'h2'}>
          {t('backoffice.discontinued_references.title', 'Upload discontinued Renault references')}
        </Text>
        <Text type={'section'}>
          {t('backoffice.discontinued_references.description', 'Maximum file size : 2 MB - (Accepted formats : .csv)')}
        </Text>
        <DiscontinuedReferencesFileUpload />
        {showRows && (
          <MarginBox mt={50}>
            <FileRows fileRows={discontinuedReferences} />
          </MarginBox>
        )}
      </MarginBox>
    </Flex>
  );
};
export default DiscontinuedReferences;
