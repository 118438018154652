import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { InformationBanner as InformationBannerType } from '@1po/1po-bff-fe-spec/generated/backoffice/information/model/InformationBanner';
import { FlashPromotionBanner } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/model/FlashPromotionBanner';
import { CATALOG, ROUTER_HOME } from 'app/AppRouter';
import { DataContainer, ErrorWithLabel } from 'components/DataContainer';
import FlashQuantityLimitedBanner from 'components/Page/Header/AdvertisementSpace/FlashQuantityLimitedBanner';
import FlashTimeLimitedBanner from 'components/Page/Header/AdvertisementSpace/FlashTimeLimitedBanner';
import InformationBanner from 'components/Page/Header/AdvertisementSpace/InformationBanner';
import { HEADER_HEIGHT } from 'components/Page/Header/TopHeader/TopHeader';
import { getRoutesFromTargetPage } from 'domains/information/Information.mapper';
import { getInformationBanners } from 'domains/information/Information.store';
import { getFlashPromotionBanners } from 'domains/promotion/Promotion.store';
import { getTokenUser } from 'domains/user';
import { SUBHEADER_ZINDEX } from 'styles';
import { Box, CenteredSpin, StickyBox } from 'UI';
import { getData, hasData } from 'utils';
import { OFFSET_SUB_HEADER } from 'utils/hooks/useOffsetTop';

type BannerType = FlashPromotionBanner | InformationBannerType;

const LoadingComponent = () => (
  <Box height={60}>
    <CenteredSpin />
  </Box>
);

const NotFoundComponent = () => <></>;

const ErrorStateComponent = () => <ErrorWithLabel narrow />;

const AdvertisementSpace = () => {
  const history = useHistory();
  const [currentPosition, setCurrentPosition] = useState(0);
  const flashBanners = useSelector(getFlashPromotionBanners);
  const infoBanners = useSelector(getInformationBanners);
  const isCatalog = history.location.pathname.startsWith(CATALOG);
  const userInfo = useSelector(getTokenUser);

  const isPageIncluded = (banner: InformationBannerType) => {
    const urlPath = history.location.pathname;
    return banner.targetPages.some((page) => {
      const targetRoute = getRoutesFromTargetPage(page);
      if (page === 'LANDING_PAGE' && userInfo !== undefined) {
        return false;
      }
      if (page === 'HOME_PAGE' && userInfo === undefined) {
        return false;
      }
      return targetRoute !== ROUTER_HOME ? urlPath.startsWith(targetRoute) : urlPath === targetRoute;
    });
  };

  const filteredInfoBanners = (getData(infoBanners) ?? []).filter((b) => isPageIncluded(b));
  const banners: (FlashPromotionBanner | InformationBannerType)[] = useMemo(
    () => [...filteredInfoBanners, ...(getData(flashBanners) ?? [])],
    [filteredInfoBanners, flashBanners],
  );

  const incrementAndSetCurrentPosition = useCallback(
    (bannersList: BannerType[]) => {
      setCurrentPosition(currentPosition > bannersList.length - 2 ? 0 : currentPosition + 1);
    },
    [setCurrentPosition, currentPosition],
  );

  useEffect(() => {
    if (!banners[currentPosition]) {
      setCurrentPosition(0);
    }
    // eslint-disable-next-line
  }, [banners.length]);

  useEffect(() => {
    if (!hasData(banners) || banners.length === 0) {
      return () => null;
    }
    const interval = setInterval(() => {
      incrementAndSetCurrentPosition(banners);
    }, 5000);
    return () => clearInterval(interval);
  }, [banners, incrementAndSetCurrentPosition]);

  if (!hasData(banners) || banners.length === 0 || !banners[currentPosition]) {
    return <></>;
  }

  const renderBanners = () => {
    const flashPromotion = banners[currentPosition] as FlashPromotionBanner;
    switch (flashPromotion?.promotionType) {
      case 'PROMOTION_FLASH_TIME_LIMITED':
        return <FlashTimeLimitedBanner banner={banners[currentPosition] as FlashPromotionBanner} />;
      case 'PROMOTION_FLASH_QUANTITY_LIMITED':
        return <FlashQuantityLimitedBanner banner={banners[currentPosition] as FlashPromotionBanner} />;
      default:
        return <InformationBanner banner={banners[currentPosition] as InformationBannerType} />;
    }
  };

  return (
    <DataContainer
      data={banners}
      Loading={LoadingComponent}
      NotFound={NotFoundComponent}
      ErrorState={ErrorStateComponent}
    >
      <StickyBox offsetTop={HEADER_HEIGHT + (isCatalog ? OFFSET_SUB_HEADER : 0)} zIndex={SUBHEADER_ZINDEX}>
        {renderBanners()}
      </StickyBox>
    </DataContainer>
  );
};
export default AdvertisementSpace;
