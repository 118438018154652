import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  getBasketExternalSection,
  getBasketOtherSection,
  getBasketVehicles,
  setFileReferencesUploadStatusIsModalOpen,
} from 'domains/basket/Basket.store';
import { ExternalBasketSectionLocal, ReferenceLocal, VehicleLocal } from 'domains/basket/Basket.types';
import { getUserCommercialLink } from 'domains/user';
import { notifyTop } from 'UI';

export const useSelectedVehiclesReferencesMemo = (): VehicleLocal[] => {
  const vehicles = useSelector(getBasketVehicles);
  const selectedVehiclesReferencesRaw = vehicles?.map((vehicle) => ({
    ...vehicle,
    references: vehicle.references?.filter((reference) => reference.isSelected),
  }));

  return useMemo(
    () => selectedVehiclesReferencesRaw?.filter((vehicle) => vehicle?.references?.length > 0) as VehicleLocal[],
    [selectedVehiclesReferencesRaw],
  );
};

export const useSelectedExternalsReferencesMemo = (): ExternalBasketSectionLocal[] => {
  const externalBaskets = useSelector(getBasketExternalSection);
  const selectedExternalsReferencesRaw = externalBaskets?.map((ext) => ({
    ...ext,
    references: ext.references?.filter((reference) => reference.isSelected),
  }));

  return useMemo(
    () =>
      selectedExternalsReferencesRaw?.filter(
        (vehicle) => vehicle?.references?.length > 0,
      ) as ExternalBasketSectionLocal[],
    [selectedExternalsReferencesRaw],
  );
};

export const useSelectedOtherReferencesMemo = (): ReferenceLocal[] => {
  const otherSection = useSelector(getBasketOtherSection);

  return useMemo(() => otherSection?.references.filter((reference) => reference.isSelected) ?? [], [otherSection]);
};

export const useModalActions = (): {
  openModal: () => void;
} => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const commercialLink = useSelector(getUserCommercialLink);

  const openModal = useCallback(() => {
    if (commercialLink === null) {
      notifyTop('error', t('cart.file_upload.seller_is_missing', 'Seller is missing, contact your R1.'), null);
    } else {
      dispatch(setFileReferencesUploadStatusIsModalOpen(true));
    }
  }, [commercialLink, dispatch, t]);

  return { openModal };
};
