import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Layout } from 'antd';
import { TFunction } from 'i18next';
import styled from 'styled-components';
import { ROUTER_BACKOFFICE } from 'app/AppRouter';
import PrivateComponent from 'composers/PrivateComponent';
import { getUserRights, UserRole } from 'domains/user';
import AdjustmentNumber from 'pages/BackOfficePage/BackOfficeCategories/Promotion/PromotionNotification/AdjustmentNumber';
import {
  ASSISTANCE_CENTRAL,
  ASSISTANCE_COUNTRY,
  CREATE_NEW,
  CROSS_SELLING,
  DELIVERY_LEAD_TIME,
  DISCONTINUED_REFERENCES,
  EDIT,
  ELECTRONIC_PARTS_REPAIR,
  INFORMATION,
  LIBRARY,
  NOTIFICATION_CENTER,
  PROMOTION,
  R1_NETWORK,
  USER_PROFILE,
} from 'pages/BackOfficePage/BackOfficePage.type';
import { Flex, Link, MarginBox, Text } from 'UI';
import { Country } from 'utils/i18n/Country';
import { MenuItemFlex } from './BackOfficeLayout.styled';

const { Sider: AntSider } = Layout;

const SSider = styled(AntSider)`
  background-color: ${({ theme }) => theme.color.white};
`;

export const ELECTRONIC_PARTS_REPAIR_ALLOW_COUNTRIES = [
  Country.FR,
  Country.BE,
  Country.ES,
  Country.CH,
  Country.IT,
  Country.DE,
  Country.GB,
  Country.PL,
  Country.NL,
  Country.AT,
];

export interface BackOfficeMenuItem {
  label: string;
  labelTail?: ReactNode;
  link: string;
  requiredCountries?: Country[];
  requiredRights?: UserRole[];
  subPages?: {
    label: string;
    link: string;
  }[];
}

export const getBackOfficeMenuItems = (userRights: UserRole[] | 'loading', t: TFunction): BackOfficeMenuItem[] => {
  const menuItems = [
    {
      label: t('common.user_profile', 'User profile'),
      link: USER_PROFILE,
      requiredRights: [UserRole.COUNTRY_ADMIN, UserRole.CENTRAL_ADMIN],
    },
    {
      label: t('backoffice.menu.my_network', 'My Network'),
      link: R1_NETWORK,
      requiredRights: [UserRole.R1],
    },
    {
      label: t('backoffice.menu.discontinued_references', 'Discontinued references'),
      link: DISCONTINUED_REFERENCES,
      requiredRights: [UserRole.COUNTRY_ADMIN],
    },

    {
      label: t('backoffice.menu.promotion', 'Promotion'),
      labelTail: <AdjustmentNumber />,
      link: PROMOTION,
      requiredRights: [UserRole.COUNTRY_ADMIN, UserRole.R1],
      subPages: [
        {
          label: t('backoffice.create_promotion', 'Create promotion'),
          link: CREATE_NEW,
        },
        {
          label: t('backoffice.edit_promotion', 'Edit promotion'),
          link: EDIT,
        },
      ],
    },
    {
      label: t('backoffice.cross_selling', 'Cross selling'),
      link: CROSS_SELLING,
      requiredRights: [UserRole.COUNTRY_ADMIN],
    },
    {
      label: t('backoffice.delivery_lead_time', 'Delivery lead time'),
      requiredRights: [UserRole.COUNTRY_ADMIN],
      link: DELIVERY_LEAD_TIME,
    },
    {
      label: t('backoffice.assistance', 'Assistance'),
      requiredRights: [UserRole.COUNTRY_ADMIN],
      link: ASSISTANCE_COUNTRY,
    },
    {
      label: t('backoffice.assistance', 'Assistance'),
      requiredRights: [UserRole.CENTRAL_ADMIN],
      link: ASSISTANCE_CENTRAL,
    },
    {
      label: t('backoffice.electronic_parts_repair', 'Electronic parts repair'),
      link: ELECTRONIC_PARTS_REPAIR,
      requiredCountries: ELECTRONIC_PARTS_REPAIR_ALLOW_COUNTRIES,
      requiredRights: [UserRole.COUNTRY_ADMIN],
    },
    {
      label: t('backoffice.library', 'Library'),
      link: LIBRARY,
      requiredRights: [UserRole.COUNTRY_ADMIN, UserRole.CENTRAL_ADMIN],
    },
    {
      label: t('backoffice.information', 'Information'),
      link: INFORMATION,
      requiredRights: [UserRole.COUNTRY_ADMIN, UserRole.CENTRAL_ADMIN],
      subPages: [
        {
          label: t('backoffice.information.create_information', 'Create information'),
          link: CREATE_NEW,
        },
        {
          label: t('backoffice.information.edit_information', 'Edit information'),
          link: EDIT,
        },
      ],
    },
    {
      label: t('backoffice.notification_center', 'Notification center'),
      link: NOTIFICATION_CENTER,
      requiredRights: [UserRole.COUNTRY_ADMIN, UserRole.CENTRAL_ADMIN],
    },
  ];
  return menuItems.filter((item) => item.requiredRights.some((right) => userRights.includes(right)));
};

const BackOfficeMenu = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const userRights = useSelector(getUserRights);
  const backOfficeMenuItems = getBackOfficeMenuItems(userRights, t);

  const isActive = (link: string) => history.location.pathname.includes(link);

  return (
    <MarginBox ml={18} mt={90}>
      <SSider width={300}>
        <Flex direction={'column'}>
          {backOfficeMenuItems.map((menuItem) => {
            return (
              <PrivateComponent
                key={menuItem.link}
                render={() => (
                  <Link to={`${ROUTER_BACKOFFICE}/${menuItem.link}`}>
                    <MenuItemFlex minHeight={50} align={'center'} $isActive={isActive(menuItem.link)}>
                      <MarginBox ml={30}>
                        <Text type={'section'} cursor={'pointer'} disableGutter>
                          {menuItem.label}
                        </Text>
                      </MarginBox>
                      {menuItem.labelTail && (
                        <Flex direction={'row-reverse'}>
                          <MarginBox mr={15}>{menuItem.labelTail}</MarginBox>
                        </Flex>
                      )}
                    </MenuItemFlex>
                  </Link>
                )}
                requiredRights={menuItem.requiredRights ?? []}
                requiredCountries={menuItem.requiredCountries ?? []}
              />
            );
          })}
        </Flex>
      </SSider>
    </MarginBox>
  );
};

export default BackOfficeMenu;
