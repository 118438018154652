import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  SFlex,
  SHeaderFlex,
} from 'pages/BackOfficePage/BackOfficeCategories/DiscontinuedReferences/DiscontinuedReferences.styled';
import { theme } from 'styles';
import { Flex, InfiniteScroll, Text, useInfiniteScroll } from 'UI';

const FileRows = ({ fileRows }: { fileRows: string[] }) => {
  const { t } = useTranslation();
  const { hasMore, loadMore, currentData } = useInfiniteScroll<string>({
    data: fileRows,
    chunkSize: 50,
  });

  const Cell = ({ children }: { children: React.ReactNode }) => {
    return (
      <SFlex justify={'center'} align={'center'} minHeight={50}>
        {children}
      </SFlex>
    );
  };
  return (
    <Flex direction={'column'}>
      <InfiniteScroll loadMore={loadMore} hasMore={hasMore}>
        <SHeaderFlex background={theme.color.grey95_light_130} justify={'center'} align={'center'} minHeight={50}>
          <Text type={'h6'}>{t('discontinued_references.upload.file.source', 'References')}</Text>
        </SHeaderFlex>
        {currentData.map((row, index) => {
          return (
            <Flex key={`${row}-${index}`}>
              <Cell>
                <Text type={'lead'}>{row}</Text>
              </Cell>
            </Flex>
          );
        })}
      </InfiniteScroll>
    </Flex>
  );
};

export default FileRows;
