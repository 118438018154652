import React, { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ROUTER_CART } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { ExportFileIcon } from 'assets/icons';
import {
  useModalActions,
  useSelectedExternalsReferencesMemo,
  useSelectedOtherReferencesMemo,
  useSelectedVehiclesReferencesMemo,
} from 'domains/basket/Basket.hooks';
import { computeTotalPrice, getActionStatus, getBasketOtherSection } from 'domains/basket/Basket.store';
import { VehicleLocal } from 'domains/basket/Basket.types';
import { getSelectedItemsCount } from 'domains/basket/Basket.utils';
import { getCurrency } from 'domains/user';
import { ScrollDiv, SDiv, SDivider } from 'pages/CartPage/CartPopover/CartPopover.styled';
import SectionCard from 'pages/CartPage/CartPopover/SectionCard';
import CartAddReference, { CartAddReferenceResult } from 'pages/CartPage/CartStep/CartAddReference';
import { theme } from 'styles';
import { BlackButton, Flex, Icon, PaddingBox, SPACE_BETWEEN, Text } from 'UI';
import { textFormatter } from 'utils';

const CartPopover = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { openModal } = useModalActions();

  const [addedOtherReference, setAddedOtherReference] = useState<string | undefined>(undefined);
  const setAddedByReferenceBox = useCallback((referenceNumber?: string) => {
    setAddedOtherReference(referenceNumber);
  }, []);

  const currency = useSelector(getCurrency);
  const otherSection = useSelector(getBasketOtherSection);
  const otherRefsActions = useSelector((state: RootState) => getActionStatus(state, addedOtherReference));

  const selectedVehiclesReferences = useSelectedVehiclesReferencesMemo();
  const selectedExternalsReferences = useSelectedExternalsReferencesMemo();
  const selectedOtherReferences = useSelectedOtherReferencesMemo();
  const itemsCount = getSelectedItemsCount(
    selectedVehiclesReferences,
    selectedExternalsReferences,
    selectedOtherReferences,
  );

  const handleOnSubmit = useCallback(() => {
    history.push(ROUTER_CART);
  }, [history]);

  const basketTotalPriceVatIncluded = computeTotalPrice(
    selectedVehiclesReferences,
    selectedExternalsReferences,
    selectedOtherReferences,
    true,
  );

  return (
    <SDiv>
      <Flex direction={'column'} gap={8}>
        <Flex direction={'row'}>
          <Flex />
          <Flex alignSelf={'center'} minHeight={20}>
            <Text type={'h4_paragraph'}>{t('cart.preview.title', 'My Cart')}</Text>
          </Flex>
          <Icon IconComponent={ExportFileIcon} round size={20} color={theme.color.brand_black} onClick={openModal} />
        </Flex>
        <SDivider />
        <ScrollDiv>
          <Flex direction={'column'} gap={8}>
            <PaddingBox pb={8}>
              <CartAddReference setAddedByReferenceBox={setAddedByReferenceBox} expandable />
              {otherRefsActions && (
                <CartAddReferenceResult
                  otherSection={otherSection}
                  addedOtherReference={addedOtherReference}
                  otherReferencesAction={otherRefsActions}
                />
              )}
            </PaddingBox>
            {selectedVehiclesReferences?.map((vehicle: VehicleLocal) => (
              <SectionCard key={vehicle.vehicleDetail.vehicleKey} vehicle={vehicle} />
            ))}
            {selectedExternalsReferences.map((ext) => (
              <SectionCard key={ext.externalBasketId} externalBasket={ext} references={ext.references} />
            ))}
            {selectedOtherReferences && selectedOtherReferences.length > 0 && (
              <SectionCard references={selectedOtherReferences} />
            )}
          </Flex>
        </ScrollDiv>
        <SDivider />
        <Flex direction={'row'} justify={SPACE_BETWEEN} align={'center'} padding={'10px 0px 0px 0px'}>
          <Text type={'lead'} disableGutter>
            {t('cart.preview.free_delivery', 'Free delivery')}
          </Text>
          <Text type={'lead'}>{t('catalog.checkout_card.total.item2', '{{count}} items', { count: itemsCount })}</Text>
        </Flex>
        <Flex direction={'row'} justify={SPACE_BETWEEN} dataCy={'preview-total-vat-incl'} padding={'10px 0px 10px 0px'}>
          <Text type={'h2'} disableGutter displayStyle={'default'}>{`${t(
            'common.price.total_vat_incl',
            'Total VAT. Incl',
          )}:`}</Text>
          <Text type={'h2'} disableGutter displayStyle={'default'}>
            {textFormatter.formatCurrency(basketTotalPriceVatIncluded ?? 0, currency)}
          </Text>
        </Flex>
        <BlackButton onClick={handleOnSubmit} shape="semiRound" dataCy={'button-preview-go-to-cart'} size={'xlarge'}>
          <Trans i18nKey={'cart.preview.action.go_to_cart'}>{'Go to cart'}</Trans>
        </BlackButton>
      </Flex>
    </SDiv>
  );
};

export default CartPopover;
