import React, { useState } from 'react';
import { isEmpty } from 'fast-glob/out/utils/string';
import { useTheme } from 'styled-components';
import { SearchPlusIcon } from 'assets/icons';
import { Flex, Icon, MarginBox } from 'UI';
import { SReferenceInput } from './ReferenceInput.styled';

interface ReferenceInputProps {
  placeholder?: string;
  initialValue?: string;
  onConfirm: (val: string) => void;
  expandable?: boolean;
}

export function ReferenceInput({ placeholder, initialValue, onConfirm, expandable }: Readonly<ReferenceInputProps>) {
  const theme = useTheme();
  const [referenceToAdd, setReferenceToAdd] = useState(initialValue ?? '');

  function handleConfirm(val: string) {
    const trimmedVal = val.trim();
    if (isEmpty(trimmedVal)) return;
    onConfirm(trimmedVal);
    setReferenceToAdd('');
  }

  return (
    <SReferenceInput
      placeholder={placeholder}
      initialValue={initialValue}
      value={referenceToAdd}
      onChange={setReferenceToAdd}
      onPressEnter={handleConfirm}
      bordered={true}
      expandable={expandable}
      prefix={
        <Flex>
          <Icon
            onClick={() => handleConfirm(referenceToAdd)}
            IconComponent={SearchPlusIcon}
            size={25}
            color={theme.color.grey55}
          />
          <MarginBox mr={10} />
        </Flex>
      }
    />
  );
}
