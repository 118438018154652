import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { Content } from 'antd/es/layout/layout';
import { ROUTER_BACKOFFICE } from 'app/AppRouter';
import PrivateRoute from 'composers/PrivateRoute';
import { UserRole } from 'domains/user';
import CrossSelling from 'pages/BackOfficePage/BackOfficeCategories/CrossSelling';
import DeliveryLeadTime from 'pages/BackOfficePage/BackOfficeCategories/DeliveryLeadTime';
import DiscontinuedReferences from 'pages/BackOfficePage/BackOfficeCategories/DiscontinuedReferences';
import ElectronicPartsRepair from 'pages/BackOfficePage/BackOfficeCategories/ElectronicPartsRepair';
import { InformationBase } from 'pages/BackOfficePage/BackOfficeCategories/Information/InformationBase';
import Library from 'pages/BackOfficePage/BackOfficeCategories/Library';
import NotificationCenter from 'pages/BackOfficePage/BackOfficeCategories/NotificationCenter';
import { PromotionBase } from 'pages/BackOfficePage/BackOfficeCategories/Promotion/PromotionBase';
import R1Network from 'pages/BackOfficePage/BackOfficeCategories/R1Network';
import UserProfile from 'pages/BackOfficePage/BackOfficeCategories/UserProfile';
import { ELECTRONIC_PARTS_REPAIR_ALLOW_COUNTRIES } from 'pages/BackOfficePage/BackOfficeLayout/BackOfficeMenu';
import {
  ASSISTANCE_CENTRAL,
  ASSISTANCE_COUNTRY,
  CROSS_SELLING,
  DELIVERY_LEAD_TIME,
  DISCONTINUED_REFERENCES,
  ELECTRONIC_PARTS_REPAIR,
  INFORMATION,
  LIBRARY,
  NOTIFICATION_CENTER,
  PROMOTION,
  R1_NETWORK,
  USER_PROFILE,
} from 'pages/BackOfficePage/BackOfficePage.type';
import { Container, Flex } from 'UI';
import { CentralAssistance, CountryAssistance } from '../BackOfficeCategories/Assistance';

function RouteSwitch() {
  return (
    <Switch>
      <PrivateRoute
        path={`${ROUTER_BACKOFFICE}/${USER_PROFILE}`}
        render={() => <UserProfile />}
        requiredRights={[UserRole.COUNTRY_ADMIN, UserRole.CENTRAL_ADMIN]}
      />
      <PrivateRoute
        path={`${ROUTER_BACKOFFICE}/${R1_NETWORK}`}
        render={() => <R1Network />}
        requiredRights={[UserRole.R1]}
      />
      <PrivateRoute
        path={`${ROUTER_BACKOFFICE}/${DISCONTINUED_REFERENCES}`}
        render={() => <DiscontinuedReferences />}
        requiredRights={[UserRole.COUNTRY_ADMIN]}
      />
      <PrivateRoute
        path={`${ROUTER_BACKOFFICE}/${PROMOTION}`}
        render={() => <PromotionBase />}
        requiredRights={[UserRole.COUNTRY_ADMIN, UserRole.R1]}
      />
      <PrivateRoute
        path={`${ROUTER_BACKOFFICE}/${ELECTRONIC_PARTS_REPAIR}`}
        render={() => <ElectronicPartsRepair />}
        requiredRights={[UserRole.COUNTRY_ADMIN]}
        requiredCountries={ELECTRONIC_PARTS_REPAIR_ALLOW_COUNTRIES}
        fallback={() => <Redirect to={ROUTER_BACKOFFICE} />}
      />
      <PrivateRoute
        path={`${ROUTER_BACKOFFICE}/${CROSS_SELLING}`}
        render={() => <CrossSelling />}
        requiredRights={[UserRole.COUNTRY_ADMIN, UserRole.CENTRAL_ADMIN]}
      />
      <PrivateRoute
        path={`${ROUTER_BACKOFFICE}/${DELIVERY_LEAD_TIME}`}
        render={() => <DeliveryLeadTime />}
        requiredRights={[UserRole.COUNTRY_ADMIN]}
      />
      <PrivateRoute
        path={`${ROUTER_BACKOFFICE}/${ASSISTANCE_COUNTRY}`}
        render={() => <CountryAssistance />}
        requiredRights={[UserRole.COUNTRY_ADMIN]}
      />
      <PrivateRoute
        path={`${ROUTER_BACKOFFICE}/${ASSISTANCE_CENTRAL}`}
        render={() => <CentralAssistance />}
        requiredRights={[UserRole.CENTRAL_ADMIN]}
      />
      <PrivateRoute
        path={`${ROUTER_BACKOFFICE}/${LIBRARY}`}
        render={() => <Library />}
        requiredRights={[UserRole.COUNTRY_ADMIN, UserRole.CENTRAL_ADMIN]}
      />
      <PrivateRoute
        path={`${ROUTER_BACKOFFICE}/${INFORMATION}`}
        render={() => <InformationBase />}
        requiredRights={[UserRole.COUNTRY_ADMIN, UserRole.CENTRAL_ADMIN]}
      />
      <PrivateRoute
        path={`${ROUTER_BACKOFFICE}/${NOTIFICATION_CENTER}`}
        render={() => <NotificationCenter />}
        requiredRights={[UserRole.COUNTRY_ADMIN, UserRole.CENTRAL_ADMIN]}
      />
      <PrivateRoute
        path={`*`}
        render={() => <UserProfile />}
        requiredRights={[UserRole.COUNTRY_ADMIN, UserRole.CENTRAL_ADMIN]}
      />
    </Switch>
  );
}

const BackOfficeRouter = () => {
  return (
    <Content>
      <Flex>
        <Container minSize={800} size={1200}>
          <RouteSwitch />
        </Container>
        <Flex size={0} basis={300} />
      </Flex>
    </Content>
  );
};

export default BackOfficeRouter;
