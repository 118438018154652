/* eslint-disable max-len */
import {
  DISCONTINUED_REFERENCES_GET_FILE_URL,
  DISCONTINUED_REFERENCES_UPLOAD_FILE_URL,
} from '@1po/1po-bff-fe-spec/generated/backoffice/BackofficeURLs';
import { FileUploadRequest } from '@1po/1po-bff-fe-spec/generated/common/file_upload/request/FileUploadRequest';
import { WebSocketAction } from 'utils';
import { wsSendAction } from 'utils/domainStore/api';

export function sendDiscontinuedReferencesFileRequest(payload: FileUploadRequest): WebSocketAction {
  return wsSendAction(DISCONTINUED_REFERENCES_UPLOAD_FILE_URL, payload);
}

export function sendGetDiscontinuedReferencesFileRequest(): WebSocketAction {
  return wsSendAction(DISCONTINUED_REFERENCES_GET_FILE_URL, null);
}
